import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Typography, Divider as AntDivider } from 'antd';

import MyListEmptyState from 'features/TimeOff/components/MyListEmptyState';
import { UserTimeOff } from 'features/TimeOff/types/calendarTypes';
import style from 'features/TimeOff/constants/style';
import { queries } from 'shared/layout';
import { emitHoverTimeline } from 'features/TimeOff/subjects/hoverTimeline';

import UserCard, { UserCardProps } from './UserCard';
import UserCardLoading from './UserCard/Loading';

const { Text } = Typography;

const GroupName = styled.div<{ groupIndex: number; isIcon: boolean; isPanelOpen: boolean }>`
  color: ${({ theme }) => theme.colors.gray['600']};
  background-color: ${({ theme }) => theme.colors.white};
  display: grid;
  grid-template-columns: ${({ isIcon }) => (isIcon ? 'auto auto 1fr' : 'auto 1fr')};
  gap: 8px;
  align-items: center;
  height: ${style.groupName.height}px;
  position: sticky;
  top: ${({ groupIndex }) => groupIndex * style.groupName.height}px;
  z-index: 1;
  padding-left: 29px;

  ${queries.tablet} {
    grid-template-columns: ${({ isIcon }) => (isIcon ? 'auto auto 1fr' : 'auto 1fr')};
    padding-left: 48px;
  }
`;

const ListSectionText = styled(Text).withConfig({
  shouldForwardProp: (prop) => !['isPanelOpen'].includes(prop),
})<{ isPanelOpen: boolean }>`
  &.ant-typography {
    color: ${({ theme }) => theme.colors.gray['600']};
    font: ${({ theme }) => theme.typography.paragraph.font};
    letter-spacing: ${({ theme }) => theme.typography.paragraph.letterSpacing};
    text-transform: uppercase;
    display: flex;
    align-items: center;

    .title {
      display: ${({ isPanelOpen }) => (isPanelOpen ? 'block' : 'none')};
      padding-right: 2px;

      ${queries.tablet} {
        display: block;
      }
    }
  }
`;

const Divider = styled(AntDivider)``;

export interface UsersGroupProps {
  users: UserTimeOff[];
  title: string;
  addDivider?: boolean;
  icon?: JSX.Element;
  showCount?: boolean;
  onRemove?: (userId: string) => void;
  onAdd?: (userId: string) => void;
  groupIndex?: number;
  lastMemberRef?: React.RefObject<HTMLDivElement> | React.RefCallback<Element>;
  loading: number;
  isPanelOpen: boolean;
}

const MemoizedUserCard = React.memo(UserCard);

const UserCardWithLocalHover = ({
  id,
  setHovered,
  onAdd,
  onRemove,
  ...props
}: UserCardProps & {
  setHovered?: (userId: string | null) => void;
}): JSX.Element => {
  const onAddRef = useRef(onAdd);
  const onRemoveRef = useRef(onAdd);

  useLayoutEffect(() => {
    onAddRef.current = onAdd;
    onRemoveRef.current = onRemove;
  });

  const memoizedOnMouseOver = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      emitHoverTimeline(id);
      setHovered?.(id);
    },
    [id, setHovered]
  );

  const memoizedOnMouseLeave = useCallback(() => {
    emitHoverTimeline(null);
    setHovered?.(null);
  }, [setHovered]);

  const memoizedOnAdd = useCallback(() => {
    onAddRef.current?.(id);
  }, [id]);

  const memoizedOnRemove = useCallback(() => {
    onRemoveRef.current?.(id);
  }, [id]);

  return (
    <MemoizedUserCard
      id={id}
      onMouseOver={memoizedOnMouseOver}
      onMouseLeave={memoizedOnMouseLeave}
      onAdd={onAddRef.current ? memoizedOnAdd : undefined}
      onRemove={onRemoveRef.current ? memoizedOnRemove : undefined}
      {...props}
    />
  );
};

const UsersGroup = ({
  users,
  title,
  icon,
  addDivider = false,
  showCount = false,
  onRemove,
  onAdd,
  groupIndex,
  lastMemberRef,
  loading,
  isPanelOpen,
}: UsersGroupProps): JSX.Element => {
  const [hovered, setHovered] = useState<string | null>(null);

  const count = showCount ? `(${users.length})` : '';

  return (
    <>
      <GroupName isPanelOpen={isPanelOpen} isIcon={!!icon} groupIndex={groupIndex ?? 0}>
        {icon}
        <ListSectionText isPanelOpen={isPanelOpen}>
          <span className="title">{title}</span>
          <span className="count">{count}</span>
        </ListSectionText>
        {addDivider && <Divider />}
      </GroupName>
      {!loading && users.length === 0 && groupIndex === 1 ? (
        <MyListEmptyState isPanelOpen={isPanelOpen} />
      ) : (
        <>
          {users.map((user, index) => {
            return (
              <UserCardWithLocalHover
                key={user.id}
                id={user.id}
                name={user.name}
                title={user.position || ''}
                profilePicture={user.profilePicture}
                hovered={user.id === hovered}
                setHovered={setHovered}
                onAdd={onAdd}
                onRemove={onRemove}
                useRef={index === users.length - 1 ? lastMemberRef : undefined}
                isPanelOpen={isPanelOpen}
              />
            );
          })}
        </>
      )}
      {Array(loading)
        .fill(0)
        .map((_, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <UserCardLoading isPanelOpen={isPanelOpen} key={idx} />
        ))}
    </>
  );
};

export default UsersGroup;
