import React, { useState, useCallback } from 'react';

/**
 * Helper hook for toggle state
 */

const useToggle = (
  initialState: boolean
): {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  toggle: () => void;
} => {
  const [isOpen, setIsOpen] = useState(initialState);

  const toggle = useCallback(() => {
    setIsOpen((open) => !open);
  }, []);

  return { isOpen, setIsOpen, toggle };
};

export default useToggle;
