import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import { ConfigProvider, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { LeadAvatar, ProjectNameParagraph, RoleParagraph } from 'components/Table/Table.styles';

import { useWindowResize } from 'features/Profile/hooks/useWindowResize';
import { useAppDispatch } from 'redux/store';
import {
  actions,
  selectUsers,
  selectSearchStatusUsers,
} from 'features/SearchResults/redux/usersSlice';
import { ReactComponent as IconTimes } from 'assets/iconTimes.svg';
import Filters, { FiltersType } from 'features/SearchResults/components/Filters/Filters';
import Pagination from 'features/SearchResults/components/Pagination/Pagination';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_PAGE_SIZE_SEARCH,
} from 'features/SearchResults/utils/constants/pagination';
import { SEARCH_STATUS } from 'utils/helpers/searchStatus';
import { FilterMatch } from 'features/SearchResults/components/EmptyStates';
import { getFiltersNames } from 'features/SearchResults/utils/helpers/getFiltersNames';
import { UsersList } from 'features/SearchResults/api';
import { OffsetPaginationInput, SkillsOrderInput, User } from 'API';

import {
  Wrapper,
  ResultsContainer,
  SearchResultsCount,
  FiltersContainer,
  Button,
  FilterBreadcrumb,
  Text,
  BreadcrumbCircle,
  TableSpacing,
  HoverableName,
} from './SearchResults.styles';

export type QueryType = {
  filtersValues: FiltersType;
  pagination?: OffsetPaginationInput;
  ordering?: SkillsOrderInput;
};

const SearchResults = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState<number>(DEFAULT_CURRENT_PAGE);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE_SEARCH);
  const searchStatus = useSelector(selectSearchStatusUsers);
  const breakpoint = 1366;
  const { width } = useWindowResize();

  const searchResultsHeaders: ColumnsType<
    Pick<
      User,
      'id' | 'name' | 'profilePicture' | 'role' | 'technicalRole' | 'seniority' | 'department'
    >
  > = [
    {
      key: 'name',
      dataIndex: 'name',
      width: '30%',
      title: t('search-results.name'),
      render: (_: string, { profilePicture, name, id }): JSX.Element => (
        <ProjectNameParagraph>
          <LeadAvatar size={32} icon={<UserOutlined />} src={profilePicture} />
          <Link to={`/profile/${id}`}>
            <HoverableName>{name}</HoverableName>
          </Link>
        </ProjectNameParagraph>
      ),
    },
    {
      key: 'role',
      dataIndex: 'role',
      width: '20%',
      title:
        width > breakpoint
          ? t('search-results.employee-role')
          : t('search-results.employee-role-short'),
      render: (_, { role }): JSX.Element => (
        <RoleParagraph ellipsis={{ tooltip: true }}>{role}</RoleParagraph>
      ),
    },
    {
      key: 'technicalRole',
      dataIndex: 'technicalRole',
      width: '25%',
      title:
        width > breakpoint
          ? t('search-results.technical-role')
          : t('search-results.technical-role-short'),
      render: (_, { technicalRole }): JSX.Element => (
        <RoleParagraph ellipsis={{ tooltip: true }}>{technicalRole}</RoleParagraph>
      ),
    },
    {
      key: 'seniority',
      dataIndex: 'seniority',
      width: '10%',
      title: t('search-results.seniority'),
    },
    {
      key: 'department',
      dataIndex: 'department',
      width: '20%',
      title: t('search-results.department'),
    },
  ];

  const filtersNames = getFiltersNames(t);
  const [filtersValues, setFiltersValues] = useState<FiltersType>(filtersNames);

  const filtersNamesValues = (items: FiltersType): string[] =>
    items
      .map((item) => item.filters.filter((elem) => elem.checkbox === true))
      .flat(1)
      .map((obj) => obj.name);

  const clearAllFilters = (): void => {
    const newValue = filtersValues.reduce<FiltersType>((acc, item) => {
      acc.push({
        title: item.title,
        filters: item.filters.map((elem) => ({ name: elem.name, checkbox: false })),
      });
      return acc;
    }, []);

    setFiltersValues(newValue);
  };

  useEffect(() => {
    const query: QueryType = { filtersValues };

    dispatch(actions.fetchUsers(query));
  }, [dispatch, filtersValues]);

  const users: UsersList = useSelector(selectUsers);

  const countFiltersActive = filtersNamesValues(filtersValues).length;

  const showUsers: UsersList = users.slice(
    currentPage === 1 ? 0 : (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const removeFilter = (filter: string): void => {
    const newValue = filtersValues.reduce<FiltersType>((acc, item) => {
      acc.push({
        title: item.title,
        filters: item.filters.map((elem) =>
          elem.name === filter
            ? { name: elem.name, checkbox: false }
            : { name: elem.name, checkbox: elem.checkbox }
        ),
      });
      return acc;
    }, []);

    setFiltersValues(newValue);
  };

  const shouldShowSearchNameBreadcrumb =
    !!countFiltersActive &&
    (searchStatus === SEARCH_STATUS.FOUND || searchStatus === SEARCH_STATUS.NONE_FOUND);

  return (
    <Wrapper>
      <ResultsContainer>
        <SearchResultsCount id="searchResultsCount">
          {`${t('search-results.search-results')} (${users.length})`}
        </SearchResultsCount>

        {shouldShowSearchNameBreadcrumb && (
          <FiltersContainer>
            {filtersNamesValues(filtersValues).map((filter) => (
              <FilterBreadcrumb key={filter} onClick={() => removeFilter(filter)}>
                <BreadcrumbCircle>
                  <IconTimes />
                </BreadcrumbCircle>
                <Text>{filter}</Text>
              </FilterBreadcrumb>
            ))}
            <Button onClick={clearAllFilters}>{t('search-results.clear-all')}</Button>
          </FiltersContainer>
        )}
        {searchStatus !== SEARCH_STATUS.NONE_FOUND && (
          <>
            <TableSpacing>
              <ConfigProvider>
                <Table
                  loading={searchStatus === SEARCH_STATUS.LOADING}
                  dataSource={showUsers}
                  rowKey="id"
                  pagination={false}
                  columns={searchResultsHeaders}
                />
              </ConfigProvider>
            </TableSpacing>
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              setPageSize={setPageSize}
              amountItems={pageSize}
              total={users.length}
            />
          </>
        )}
        {searchStatus === SEARCH_STATUS.NONE_FOUND && (
          <FilterMatch clearAllFilters={clearAllFilters} />
        )}
      </ResultsContainer>
      <Filters
        values={filtersValues}
        setFiltersValues={setFiltersValues}
        activeFilters={countFiltersActive}
        setCurrentPage={setCurrentPage}
      />
    </Wrapper>
  );
};

export default SearchResults;
