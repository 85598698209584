import { combineReducers } from '@reduxjs/toolkit';

import allUsers from './usersSlice';
import skillAssignments from './skillsAssignmentSlice';

const rootReducer = combineReducers({
  allUsers,
  skillAssignments,
});
export default rootReducer;
