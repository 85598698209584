/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  selectMyPtoLeft,
  selectMyPtoUsed,
  selectMyPtoCarryover,
  selectMyPtoAllowance,
  selectMyAnnualVacationLeave,
} from 'features/TimeOff/redux/timesOffSlice';

import Days from './Days';
import {
  Title,
  Wrapper,
  Group,
  Subtitle,
  SubtitleGroup,
  Year,
  Flex,
  BottomDiv,
  BottomTitle,
  Valid,
  AnnualLeaveWrapper,
} from './PtoTooltips.styles';
import AnnualVacationLeave from './AnnualVacationLeave';

const CarryoverTooltipContent = (): JSX.Element => {
  const { t } = useTranslation();
  const ptoLeft = useSelector(selectMyPtoLeft);
  const ptoUsed = useSelector(selectMyPtoUsed);
  const ptoCarryover = useSelector(selectMyPtoCarryover);
  const ptoAllowance = useSelector(selectMyPtoAllowance);
  const hadAnnualVacationLeave = useSelector(selectMyAnnualVacationLeave);

  const d = new Date();
  const year = d.getFullYear();
  return (
    <>
      <Wrapper>
        <Title>{t('pto-tooltip.balance')}</Title>
        <Group>
          <SubtitleGroup>
            <Subtitle>
              {t('pto-tooltip.carryover')}
              <Year> {year - 1}</Year>
            </Subtitle>
            <Flex>
              <Days neutral count={ptoCarryover} />
              {ptoCarryover === 1 ? t('pto-tooltip.day') : t('pto-tooltip.days')}
            </Flex>
          </SubtitleGroup>
          <SubtitleGroup>
            <Subtitle>
              {t('pto-tooltip.granted')}
              <Year> {year}</Year>
            </Subtitle>
            <Flex>
              <Days neutral count={'value' in ptoAllowance ? ptoAllowance.value : 0} />
              {t('pto-tooltip.days')}
            </Flex>
          </SubtitleGroup>
          <SubtitleGroup>
            <Subtitle>
              {t('pto-tooltip.taken')}
              <Year> {year}</Year>
            </Subtitle>
            <Flex>
              <Days count={ptoUsed} />
              {ptoUsed === 1 || ptoUsed === -1 ? t('pto-tooltip.day') : t('pto-tooltip.days')}
            </Flex>
          </SubtitleGroup>
        </Group>
        <SubtitleGroup>
          <Subtitle bottom>
            {t('pto-tooltip.available')}
            <Year> {year}</Year>
          </Subtitle>
          <Flex bottom>
            <Days count={ptoLeft} />
            {ptoLeft === 1 || ptoLeft === -1 ? t('pto-tooltip.day') : t('pto-tooltip.days')}
          </Flex>
        </SubtitleGroup>
      </Wrapper>
      <BottomDiv>
        <BottomTitle>{t('pto-tooltip.carryover-when')}</BottomTitle>
        <Subtitle>
          <Valid>
            {t('pto-tooltip.carryover-valid')}{' '}
            <span style={{ color: 'white' }}>{t('pto-tooltip.carryover-month')}</span>{' '}
            {t('pto-tooltip.carryover-this-year')}
          </Valid>
        </Subtitle>
        {'value' in hadAnnualVacationLeave && hadAnnualVacationLeave.value === false && (
          <AnnualLeaveWrapper>
            <AnnualVacationLeave />
          </AnnualLeaveWrapper>
        )}
      </BottomDiv>
    </>
  );
};

export default CarryoverTooltipContent;
