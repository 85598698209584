import { PrivateUserTimeOffDatesArgs, PrivateUser } from 'API';
import generateGraphqlCaller from 'utils/helpers/generateGraphqlCaller';

const query = /* GraphQL */ `
  query GetMyTimesOff($before: Date!, $after: Date!) {
    getOwnUser {
      ... on PrivateUser {
        name
        role
        profilePicture
        ptoAllowance {
          ... on IntValue {
            value
          }
          ... on SalesforceError {
            message
          }
          ... on UnexpectedError {
            message
          }
          ... on Error {
            message
          }
        }
        hadAnnualVacationLeaveUop {
          ... on BoolValue {
            value
          }
          ... on JiraError {
            message
          }
          ... on UnexpectedError {
            message
          }
          ... on Error {
            message
          }
        }
        ptoLeft {
          ... on PTOLeftResult {
            ptoCurrent
            ptoLeft
            ptoUsed
          }
          ... on UOPPTOLeftResult {
            ptoCarryover
            ptoCurrent
            ptoLeft
            ptoUsed
          }
          ... on UnlimitedPTOLeftResult {
            ptoUsed
          }
          ... on SalesforceError {
            message
          }
          ... on JiraError {
            message
          }
          ... on BIError {
            message
          }
          ... on UnexpectedError {
            message
          }
          ... on Error {
            message
          }
        }
        hasUnlimitedPto {
          ... on BoolValue {
            value
          }
          ... on SalesforceError {
            message
          }
          ... on UnexpectedError {
            message
          }
          ... on Error {
            message
          }
        }
        timeOffDates(before: $before, after: $after) {
          ... on PrivateTimeOffRecordsResult {
            timeOffs {
              id
              date
              status
              jiraTicketLink
              type
              subtype
            }
          }
          ... on SalesforceError {
            message
          }
          ... on JiraError {
            message
          }
          ... on UnexpectedError {
            message
          }
          ... on Error {
            message
          }
        }
        contractType {
          ... on ContractType {
            shortName
          }
          ... on SalesforceError {
            message
          }
          ... on UnexpectedError {
            message
          }
          ... on Error {
            message
          }
        }
      }
    }
  }
`;

export type VariablesType = Pick<PrivateUserTimeOffDatesArgs, 'before' | 'after'>;
export type ReturnType = {
  getOwnUser: Pick<
    PrivateUser,
    | 'name'
    | 'role'
    | 'profilePicture'
    | 'ptoLeft'
    | 'hasUnlimitedPto'
    | 'timeOffDates'
    | 'ptoAllowance'
    | 'contractType'
    | 'hadAnnualVacationLeaveUop'
  >;
};

export default generateGraphqlCaller<ReturnType, VariablesType>(query);
