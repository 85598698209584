import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input } from 'antd';

import {
  PHONE_NUMBER_MAX_LENGTH,
  EMPLOYEE_LOCATION_MAX_LENGTH,
  EMPLOYEE_WORKING_HOURS_MAX_LENGTH,
} from 'features/Profile/constants/form';

import type { EditProfileErrors } from '../EditProfileForm';

interface EditProfileFormFieldsProps {
  errors: EditProfileErrors;
  inputDisabled: boolean;
}

export const LocationInput = ({
  errors,
  inputDisabled,
}: EditProfileFormFieldsProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Form.Item
      key="location"
      label={t('profile.detail-information.current-location')}
      name="employeeLocation"
      validateStatus={errors.employeeLocation ? 'error' : undefined}
      help={errors.employeeLocation || undefined}
      rules={[
        {
          max: EMPLOYEE_LOCATION_MAX_LENGTH,
          message: t('profile.edit-contact-information.error-message-length', {
            maxLength: EMPLOYEE_LOCATION_MAX_LENGTH,
          }),
        },
      ]}
    >
      <Input
        type="text"
        name="employeeLocation"
        placeholder={t('profile.edit-contact-information.email-placeholder')}
        disabled={inputDisabled}
      />
    </Form.Item>
  );
};

export const HoursInput = ({ errors, inputDisabled }: EditProfileFormFieldsProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Form.Item
      key="workingHours"
      label={`${t('profile.detail-information.working-hours')}`}
      name="workingHours"
      validateStatus={errors.workingHours ? 'error' : undefined}
      help={errors.workingHours || undefined}
      rules={[
        {
          max: EMPLOYEE_WORKING_HOURS_MAX_LENGTH,
          message: t('profile.edit-contact-information.error-message-length', {
            maxLength: EMPLOYEE_WORKING_HOURS_MAX_LENGTH,
          }),
        },
      ]}
    >
      <Input
        type="text"
        name="workingHours"
        placeholder={t('profile.edit-contact-information.working-hours-placeholder')}
        disabled={inputDisabled}
      />
    </Form.Item>
  );
};

export const PhoneNumberInput = ({
  errors,
  inputDisabled,
}: EditProfileFormFieldsProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Form.Item
      key="number"
      label={t('profile.detail-information.phone-number-optional')}
      name="phoneNumber"
      validateStatus={errors.phoneNumber ? 'error' : undefined}
      help={errors.phoneNumber || undefined}
      rules={[
        {
          max: PHONE_NUMBER_MAX_LENGTH,
          message: t('profile.edit-contact-information.error-message-length', {
            maxLength: PHONE_NUMBER_MAX_LENGTH,
          }),
        },
      ]}
    >
      <Input
        type="text"
        name="phoneNumber"
        placeholder="e.g. +48 111 222 333"
        disabled={inputDisabled}
      />
    </Form.Item>
  );
};

export const EmailInput = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Form.Item key="email" label={t('profile.detail-information.email-address')} name="email">
      <Input disabled type="email" name="email" placeholder="floyd.miles@10clouds.com" />
    </Form.Item>
  );
};
