import type { QueryGetUserArgs, PrivateUser } from 'API';
import generateGraphqlCaller from 'utils/helpers/generateGraphqlCaller';

const query = `
query GetOwnAssignedSkills {
    getOwnUser {
      ... on PrivateUser {
        id
        skillAssignments {
          assignments {
            lvl
            name
            type
            updated
            langCode
            status
          }
        }
      }
      ... on UserNotFoundError {
        message
      }
      ... on MultipleUsersFound {
        message
      }
      ... on UnexpectedError {
        message
      }
      ... on Error {
        message
      }
    
  }
}`;

export type ReturnType = {
  getOwnUser?: Pick<PrivateUser, 'skillAssignments'>;
};

export type VariablesType = Pick<QueryGetUserArgs, 'id'>;

export default generateGraphqlCaller<ReturnType, VariablesType>(query);
