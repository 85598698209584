/* eslint-disable no-nested-ternary */

import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: ${({ theme }) => theme.colors.primary};
  width: 210px;
  padding: 12px 16px;
`;

export const Title = styled.p`
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 12px;
`;

export const Subtitle = styled.p<{ bottom?: boolean }>`
  font-weight: 400;
  font-size: ${({ bottom }) => (bottom ? '12px' : '13px')};
  line-height: ${({ bottom }) => (bottom ? '16px' : '20px')};
  letter-spacing: 0.2px;

  margin-top: ${({ bottom }) => (bottom ? '10px' : '0px')};
  br {
    display: block;
    content: ' ';
    margin-top: 8px;
  }

  span {
    margin-top: ${({ bottom }) => (bottom ? '10px' : '0px')};
  }
`;

export const Year = styled.span`
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: ${({ theme }) => theme.colors.checkbox.primary};
`;

export const Valid = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: ${({ theme }) => theme.colors.checkbox.primary};
  width: 178px;
  white-space: pre-line;
`;

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  border-bottom: 1px solid #4e4d6c;
  padding-bottom: 12px;
  width: 100%;
`;

export const SubtitleGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const Count = styled.span<{ variant: 'positive' | 'negative' | 'neutral' }>`
  color: ${({ theme, variant }) =>
    variant === 'neutral'
      ? theme.colors.white
      : variant === 'positive'
      ? theme.colors.text.version
      : theme.colors.text.negative};
  text-align: right;
`;

export const Flex = styled.div<{ bottom?: boolean }>`
  display: flex;

  margin-top: ${({ bottom }) => (bottom ? '10px' : '0px')};
`;

export const BottomDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 16px;
  width: 210px;
  background: ${({ theme }) => theme.colors.backgrounds.dark.tooltip};
  border-top: 1px solid ${({ theme }) => theme.colors.border.dark};
`;

export const BottomTitle = styled(Title)`
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }
`;

export const AnnualLeaveWrapper = styled.div`
  margin-top: 12px;
`;
