import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from 'translations/en/translation.json';
import pl from 'translations/pl/translation.json';

i18n.use(initReactI18next).init({
  lng: 'en-US',
  fallbackLng: 'en',
  debug: false,
  resources: {
    en: {
      translation: en,
    },
    pl: {
      translation: pl,
    },
  },
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false, // TODO: Enable when Suspense is implemented
  },
});

export default i18n;
