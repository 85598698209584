import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReactComponent as Beginner } from 'assets/Beginner.svg';
import { ReactComponent as Intermediate } from 'assets/Intermediate.svg';
import { ReactComponent as Expert } from 'assets/Expert.svg';
import {
  actions,
  selectSkillAssignmentsModalState,
} from 'features/SearchResults/redux/skillsAssignmentSlice';
import { useAppDispatch } from 'redux/store';

import {
  ColumnContainer,
  SubContainer,
  SkillSubHeader,
  SubHeaderTitle,
  SubTitle,
} from './SkillLevelSelection.styles';
import SkillLevelCheckbox from '../SkillLevel/SkillLevelCheckbox';
import { OutlineButton } from '../SkillSelection/SkillSelection.styles';

interface SkillLevelSelectionProps {
  skillName: string;
  level?: boolean[];
}

const SkillLevelSelectionCheckboxes = ({
  skillName,
  level,
}: SkillLevelSelectionProps): JSX.Element => {
  const { t } = useTranslation();
  const expert = t('skills-matrix.skill-experience.expert');
  const intermediate = t('skills-matrix.skill-experience.intermediate');
  const beginner = t('skills-matrix.skill-experience.beginner');
  const modalArray = useSelector(selectSkillAssignmentsModalState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(actions.setModal({ array: level || [] }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [level]);

  useEffect(() => {
    let newCheckedArray = [...modalArray];
    if (modalArray && modalArray.length < 7) {
      newCheckedArray = [...newCheckedArray, false, false, false, false];
    }
    if (!modalArray) {
      actions.setModal({ array: [false, false, false] });
    } else {
      actions.setModal({ array: newCheckedArray });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ColumnContainer>
      <SkillSubHeader>
        <SubHeaderTitle>{skillName}</SubHeaderTitle>
        {modalArray.some((element) => element === true) ? (
          <OutlineButton
            onClick={() => {
              dispatch(actions.setModal({ array: [false, false, false] }));
            }}
          >
            {t('skills-search.modal.unselect-all')}
          </OutlineButton>
        ) : (
          <OutlineButton
            onClick={() => {
              dispatch(actions.setModal({ array: [true, true, true] }));
            }}
          >
            {t('skills-search.modal.select-all')}
          </OutlineButton>
        )}
      </SkillSubHeader>
      <SubContainer>
        <SubTitle>{t('skills-matrix.skill-experience.one-option')}</SubTitle>
        <SkillLevelCheckbox
          name={expert}
          icon={<Expert />}
          checked={modalArray[0] === true}
          onClick={() => {
            const updatedModalArray = [...modalArray];
            updatedModalArray[0] = !updatedModalArray[0];
            dispatch(actions.setModal({ array: updatedModalArray }));
          }}
        />
        <SkillLevelCheckbox
          name={intermediate}
          icon={<Intermediate />}
          checked={modalArray[1] === true}
          onClick={() => {
            const updatedModalArray = [...modalArray];
            updatedModalArray[1] = !updatedModalArray[1];
            dispatch(actions.setModal({ array: updatedModalArray }));
          }}
        />
        <SkillLevelCheckbox
          name={beginner}
          icon={<Beginner />}
          checked={modalArray[2] === true}
          onClick={() => {
            const updatedModalArray = [...modalArray];
            updatedModalArray[2] = !updatedModalArray[2];
            dispatch(actions.setModal({ array: updatedModalArray }));
          }}
        />
      </SubContainer>
    </ColumnContainer>
  );
};

export default SkillLevelSelectionCheckboxes;
