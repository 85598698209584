import { isHoliday } from 'poland-public-holidays';
import {
  isBefore,
  add,
  addDays,
  isWeekend,
  max,
  min,
  isEqual,
  differenceInCalendarMonths,
  format,
  sub,
} from 'date-fns';

import { Timestamp } from 'types/date';

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const dayNames = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

export const dateToTimestamp = (date: Date): Timestamp => {
  return format(date, 'yyyy-MM-dd');
};

export const enumerateDaysBetweenDates = (startDate: Date, endDate: Date): Date[] => {
  const dates = [];

  let currentDate = startDate;

  while (isBefore(currentDate, endDate)) {
    dates.push(currentDate);
    currentDate = add(currentDate, { days: 1 });
  }

  return dates;
};

export const groupDaysByMonth = (startDate: Date, endDate: Date): Date[][] => {
  const dates: Date[][] = [];

  let currentDate = startDate;
  let currentMonth: Date[] = [];

  while (isBefore(currentDate, endDate)) {
    if (currentMonth.length === 0) {
      currentMonth.push(currentDate);
    } else if (
      differenceInCalendarMonths(currentMonth[currentMonth.length - 1], currentDate) !== 0
    ) {
      dates.push(currentMonth);
      currentMonth = [currentDate];
    } else {
      currentMonth.push(currentDate);
    }
    currentDate = add(currentDate, { days: 1 });
  }
  if (currentMonth.length > 0) {
    dates.push(currentMonth);
  }

  return dates;
};

export const workingDaysBetweenDates = (date0: Date, date1: Date): number => {
  const laterDate = max([date0, date1]);
  const earlierDate = min([date0, date1]);

  let count = 0;
  let tmpDate = earlierDate;

  while (isEqual(tmpDate, laterDate) || isBefore(tmpDate, laterDate)) {
    if (!isHoliday(tmpDate) && !isWeekend(tmpDate)) count += 1;
    tmpDate = addDays(tmpDate, 1);
  }
  return count;
};

export const dateToLastSeptember = (today: Date): string => {
  const currentDay: number = today.getDate() - 1;
  const currentMonth: number = today.getMonth();
  const substractDays = sub(today, { days: currentDay });
  return dateToTimestamp(sub(substractDays, { months: currentMonth + 4 }));
};
