import type { QueryGetSkillsArgs, Skill } from 'API';
import generateGraphqlCaller from 'utils/helpers/generateGraphqlCaller';

export const getAllSkills = /* GraphQL */ `
  query GetSkills($type: String) {
    getSkills(type: $type) {
      skills {
        id
        languageCode
        categories
        name
        type
      }
    }
  }
`;

export type ReturnType = {
  getSkills: {
    skills: Pick<Skill, 'id' | 'categories' | 'languageCode' | 'name' | 'type'>[];
  };
};

export default generateGraphqlCaller<ReturnType, QueryGetSkillsArgs>(getAllSkills);
