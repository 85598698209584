import React from 'react';
import styled from 'styled-components';
import { Button as AntButton, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { NavLink as RouterNavLink } from 'react-router-dom';

import { ReactComponent as LogoutIcon } from 'assets/Logout.svg';
import companyLogo from 'assets/10Clogo.svg';
import { useAuth } from 'providers/AuthProvider';

const Text = styled(Typography.Text)`
  &.ant-typography {
    color: ${({ theme }) => theme.colors.text.version};
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    text-align: right;
    letter-spacing: 0.2px;
    text-transform: uppercase;
  }
`;

const Wrapper = styled.div<{ variant?: 'default' | 'onboarding' | 'privacy' }>`
  position: ${({ variant }) => (variant === 'privacy' ? 'static' : 'absolute')};
  display: flex;
  justify-content: space-between;
  background: ${({ theme, variant }) =>
    variant === 'default' || variant === 'privacy'
      ? 'transparent'
      : theme.colors.backgrounds.dark.linear};
  align-items: center;
  padding: 38px 82px;
  width: 100%;
  top: 0;
  & > img {
    user-select: none;
  }
`;
const NavIcon = styled.svg`
  fill: currentColor;
  font-size: 16px;
`;

const NavLink = styled(RouterNavLink)`
  padding: 9px 24px 9px 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray['700']};
  font: ${({ theme }) => theme.typography.body};
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 67px;
    bottom: 0;
    width: 45px;
    height: 2px;
    background-color: transparent;
  }

  &:hover,
  &.active {
    color: ${({ theme }) => theme.colors.white};
    &:after {
      background: ${({ theme }) => theme.colors.secondary['100']};
    }
  }

  .link-text {
    margin-left: 1rem;
    flex: 1;
  }
`;

const Button = styled(AntButton)`
  align-self: flex-end;
  background: none;
  border: none;
  transition: none;
  .ant-button {
    transition: none !important;
  }

  &:active,
  &:focus,
  &:hover {
    color: ${({ theme }) => theme.colors.white};
    background: none;
    border: none;
    & svg path {
      fill: ${({ theme }) => theme.colors.white};
    }
  }

  &::after {
    content: none;
  }
`;

interface ITopHeader {
  variant?: 'default' | 'onboarding' | 'privacy';
}
const TopHeader = ({ variant = 'default' }: ITopHeader): JSX.Element => {
  const { t } = useTranslation();
  const { logout } = useAuth();
  return (
    <Wrapper variant={variant} id="topHeader">
      <img src={companyLogo} alt="10C logo" id="tenCLogo" />
      {variant === 'onboarding' ? (
        <NavLink className="nav-button-footer" as={Button} onClick={logout}>
          <NavIcon as={LogoutIcon} className="link-icon" />
          <span className="link-text" id="logoutButton">
            {t('logout')}
          </span>
        </NavLink>
      ) : (
        <Text id="versionLabel">{`${t('version.title')} ${t('version.number')}`}</Text>
      )}
    </Wrapper>
  );
};

export default TopHeader;
