import React from 'react';
import { QueryClientProvider } from '@tanstack/react-query';

import GlobalStyle from 'components/GlobalStyle';

import { AuthProvider } from 'providers/AuthProvider';
import { queryClient } from 'api/Client';
import AppRouter from 'AppRouter';

const App = (): JSX.Element => {
  return (
    <>
      <GlobalStyle />
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <AppRouter />
        </AuthProvider>
      </QueryClientProvider>
    </>
  );
};

export default App;
