import { User } from 'API';
import generateGraphqlCaller from 'utils/helpers/generateGraphqlCaller';

const query = /* GraphQL */ `
  query GetUserBasicInformation {
    getOwnUser {
      ... on PrivateUser {
        id
        email
        name
        profilePicture
        team
        role
        onboardingCompleted
        isTeamLead
      }
      ... on UserNotFoundError {
        __typename
        message
      }
      ... on MultipleUsersFound {
        __typename
        message
      }
      ... on UnexpectedError {
        __typename
        message
      }
      ... on Error {
        __typename
        message
      }
    }
  }
`;

export type ReturnType = {
  getOwnUser: Pick<
    User,
    | 'id'
    | 'email'
    | 'name'
    | 'profilePicture'
    | 'team'
    | 'role'
    | 'onboardingCompleted'
    | 'technicalRole'
    | 'isTeamLead'
  >;
};

export default generateGraphqlCaller<ReturnType>(query);
