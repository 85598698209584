import styled from 'styled-components';
import { Button, Modal as AntModal, Typography } from 'antd';

export const Modal = styled(AntModal)`
  .ant-modal-header {
    padding: 32px;
    border: none;
  }

  .ant-modal-title {
    font-size: 24px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.primary};
  }

  .ant-modal-body {
    padding: 0;
    height: 54px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ant-modal-footer {
    padding: 32px;
  }
`;

type ButtonProps = {
  approve?: boolean;
};

export const ActionButton = styled('button')<ButtonProps>`
  background: ${(props) =>
    props.approve ? props.theme.colors.button.linear : props.theme.colors.text.negative};
  color: ${({ theme }) => theme.colors.white};
  font-size: 13px;
  line-height: 20px;
  height: 44px;
  padding: 12px 24px;
  text-transform: uppercase;
`;

export const TextButton = styled(Button)`
  font-size: 13px;
  line-height: 20px;
  text-transform: uppercase;
  height: 44px;
  padding: 0 24px;
  background: none !important;
  color: ${({ theme }) => theme.colors.primary} !important;
  border: none;
`;

export const TextBody = styled(Typography.Text)`
  font-size: 15px;
  line-height: 22px;
  background: none !important;
  color: ${({ theme }) => theme.colors.pagination.seondary} !important;
  border: none;
  padding: 0px 32px;
`;

export const TextSkill = styled(Typography.Text)`
  font-size: 15px;
  line-height: 22px;
  background: none !important;
  color: ${({ theme }) => theme.colors.primary} !important;
  border: none;
`;

export const TitleContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const LeftArrow = styled('div')`
  width: 24px;
  height: 24px;
  margin: 0px;
  padding-top: 2px;

  &:hover {
    cursor: pointer;
  }
`;
