import { QueryListUsersArgs, User } from 'API';
import generateGraphqlCaller from 'utils/helpers/generateGraphqlCaller';

const query = /* GraphQL */ `
  query QuerySearchResults($filters: UserFilter) {
    listUsers(filters: $filters) {
      id
      email
      name
      role
      technicalRole
      seniority
      profilePicture
    }
  }
`;

export type ReturnType = {
  listUsers: Pick<
    User,
    'role' | 'technicalRole' | 'seniority' | 'profilePicture' | 'name' | 'id' | 'email'
  >[];
};

export type VariablesType = Pick<QueryListUsersArgs, 'filters'>;

export default generateGraphqlCaller<ReturnType, VariablesType>(query);
