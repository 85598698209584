import removeByIndex from './removeByIndex';

const removeIfKeyValuePairExists = <T extends { id: string }>(
  array: T[],
  value: T[keyof T],
  key: keyof T = 'id'
): T[] => {
  const index = array.findIndex((element) => element[key] === value);
  if (index === -1) {
    return array.slice();
  }
  return removeByIndex(array, index);
};

export default removeIfKeyValuePairExists;
