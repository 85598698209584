import styled from 'styled-components';
import { Avatar, Input } from 'antd';

export const TopBar = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 1px 0px rgb(0 0 0 / 10%);
  padding: 0 48px;
  height: 80px;
  position: relative;
  z-index: 51;
`;

export const SearchGroup = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  min-width: 336px;
  color: ${({ theme }) => theme.colors.text.secondary};

  .anticon {
    font-size: 18px;
  }
`;

export const SearchIcon = styled.div`
  position: absolute;
  left: 18px;
`;

export const ProfileGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
`;

export const IconButton = styled.button`
  background: none;
  max-width: 24px;
  max-height: 24px;
  &:disabled {
    cursor: not-allowed;
    fill: ${({ theme }) => theme.colors.text.secondary};
  }
  & svg path {
    fill: ${({ theme }) => theme.colors.text.secondary};
  }

  .anticon {
    margin-right: 42px;
    font-size: 24px;
    &:disabled {
      cursor: not-allowed;
      fill: ${({ theme }) => theme.colors.text.secondary};
    }
    &:hover {
      color: ${({ theme }) => theme.colors.text.secondary};
    }
    &:disabled {
      cursor: not-allowed;
      fill: ${({ theme }) => theme.colors.text.secondary};
    }
  }

  &:hover svg path {
    fill: ${({ theme }) => theme.colors.text.primary};
  }

  &:disabled:hover svg path {
    fill: ${({ theme }) => theme.colors.text.secondary};
  }
`;

export const ProfileAvatar = styled(Avatar)`
  &:hover {
    cursor: not-allowed;
  }
`;

export const SearchInput = styled(Input)`
  margin: 0 10px;
  height: 40px;
  border-radius: 0;
  border: 1px solid transparent;
  box-sizing: border-box;

  &.ant-input-affix-wrapper {
    &:focus,
    &-focused,
    &:not(.ant-input-affix-wrapper-disabled):hover {
      border: 1px solid ${({ theme }) => theme.colors.secondary['200']};
      box-shadow: none;
    }
  }

  input.ant-input {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: ${({ theme }) => theme.colors.text.primary};
    padding: 0 40px;
    &::placeholder {
      font: ${({ theme }) => theme.typography.paragraph.font};
      color: ${({ theme }) => theme.colors.gray['500']};
    }
  }

  .ant-input-suffix .anticon {
    color: ${({ theme }) => theme.colors.gray['500']};
  }
`;

export const SearchBox = styled.div`
  position: relative;

  .anticon {
    z-index: 2;
    top: 12px;
    left: 30px;
    font-size: 18px;
    color: ${({ theme }) => theme.colors.text.secondary};
  }
`;

export const IconButtons = styled.div`
  display: flex;

  flex-direction: row;
  align-items: flex-start;
  gap: 32px;
  width: 80px;
  height: 24px;
`;

export const IconButtonWrapper = styled.div`
  height: 24px;
`;
