import { EditDeleteSkillResponse, MutationEditSkillAssignmentOfUserArgs } from 'API';
import generateGraphqlCaller from 'utils/helpers/generateGraphqlCaller';

const query = `
mutation EditSkillAssignmentOfUser($lvl: String!, $skillName: String!){
    editSkillAssignmentOfUser(lvl: $lvl, skillName: $skillName) {
            ... on EditDeleteSkillSuccessResponse {
                    skill{
                        name
                    },
                owner{
                    id
                }
            }
            ... on EditDeleteSkillErrorResponse {
                ownerErr: owner{
                    id
                },
                errors {
                ... on Error {
                  message
                }
              }
            }

          }
}
`;

export default generateGraphqlCaller<
  EditDeleteSkillResponse,
  MutationEditSkillAssignmentOfUserArgs
>(query);
