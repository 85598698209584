import styled from 'styled-components';

import { queries } from 'shared/layout';

export const Container = styled.div`
  height: auto;
  display: grid;
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 16px;
  max-width: 1600px;
`;

export const ProfileNavigation = styled.div`
  margin-top: 24px;
  width: 100%;
  max-width: 1600px;
  display: flex;
  gap: 24px;
`;

export const MainWrapper = styled.div`
  width: 100%;
  padding: 40px 32px;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;

  ${queries.desktop} {
    padding: 40px 48px;
  }
`;

export const ContentSection = styled.div`
  display: flex;
  width: 100%;
  max-width: 1900px;
  margin-top: 24px;
`;

export const SectionsContainer = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Section = styled.section`
  background-color: ${({ theme }) => theme.colors.white};
  margin-top: 16px;
  padding: 32px;
  width: 100%;
  max-width: 1600px;
  min-height: 690px;
`;

export const SectionTitle = styled.h3`
  font: ${({ theme }) => theme.typography.paragraph.font};
  letter-spacing: ${({ theme }) => theme.typography.paragraph.letterSpacing};
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.text.secondary};
  padding-bottom: 20px;
`;

export const RightAllignedColumn = styled.div`
  text-align: right;
  font-size: 13px;
  display: flex;
`;

export const UserName = styled.a`
  margin-left: 20px;
  :hover {
    text-decoration: underline;
  }
`;

export const AnnualVacationDiv = styled.div`
  padding-left: 20px;
`;

export const NoDataDiv = styled.div`
  padding-left: 20px;
`;

export const SpanWrapper = styled.span`
  margin: auto 0;
  padding-left: 5px;
  display: flex;
`;
