import React, { useState } from 'react';
import styled, { keyframes, ThemeProps } from 'styled-components';

import { ThemeType } from 'components/ThemeProvider';

import style from 'features/TimeOff/constants/style';
import { UserTimeOff } from 'features/TimeOff/types/calendarTypes';
import { onCalendarMoveFinished$ } from 'features/TimeOff/subjects/calendarMoveFinished';
import { onMoveCalendarTo$ } from 'features/TimeOff/subjects/moveCalendarTo';
import { onHoverTimeline$ } from 'features/TimeOff/subjects/hoverTimeline';
import useSubscription from 'hooks/useSubscription';

const getHighlightAnimation = ({ theme }: ThemeProps<ThemeType>) => keyframes`
  0% {
    background-color: ${theme.colors.gray['300']};
  }
`;

const BackgroundWrapper = styled.div<{ hovered: boolean }>`
  position: absolute;
  height: ${style.timeOffRow.height}px;
  width: 100%;
  z-index: 1;

  background-color: ${({ hovered, theme }) => hovered && theme.colors.gray['300']};

  &.highlight {
    animation: ${(props) => getHighlightAnimation(props)} ${style.highlight.animationDuration}ms
      ease-out;
  }
`;

interface TimeOffRowProps {
  user: UserTimeOff;
}

const TimeOffRowBackground = ({ user }: TimeOffRowProps): JSX.Element => {
  const [hovered, setHovered] = useState<boolean>(false);
  const [highlighted, setHighlighted] = useState<boolean>(false);

  useSubscription(onHoverTimeline$, (payload) => {
    setHovered(!!(payload && payload === user.id));
  });

  useSubscription(onMoveCalendarTo$, (payload) => {
    if (!payload.target) return;
    if (!user.timesOff.map((to) => to.id).includes(payload.target.id)) return;
    setHovered(true);
  });

  useSubscription(onCalendarMoveFinished$, (payload) => {
    if (!user.timesOff.map((to) => to.id).includes(payload.id)) return;
    setHighlighted(true);
    setTimeout(() => setHighlighted(false), 1000);
  });

  return <BackgroundWrapper hovered={hovered} className={`${highlighted && 'highlight'}`} />;
};

export default TimeOffRowBackground;
