import styled from 'styled-components';
import { Typography, Dropdown, Skeleton } from 'antd';

import { ThemeProps } from 'components/ThemeProvider/ThemeProvider';

export const SkillsTableContainer = styled('div')`
  padding: 0px;
  margin-top: 23px;
`;

export const SkillsTableHeader = styled('div')`
  box-sizing: border-box;
  padding: 0px 24px;
  background: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.calendar.timeOffBorder};
`;

export const HeaderName = styled('div')`
  height: 40px;
  padding: 10px 16px;
`;

export const HeaderText = styled(Typography.Title)`
  &.ant-typography {
    color: ${({ theme }) => theme.colors.text.secondary};
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    font-weight: 400;
    margin-bottom: 0px;
  }
`;

export const HeaderLevel = styled('div')`
  height: 40px;
  padding: 10px 16px;
`;

export const HeaderDate = styled('div')`
  height: 40px;
  padding: 10px 16px;
`;

export const HeaderAction = styled('div')`
  height: 40px;
  padding: 10px 8px;
`;

export const SkillsTableContent = styled('div')`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 586px;
`;

export const SkillsTableRow = styled('div')`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  height: 64px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.calendar.timeOffBorder};
  padding: 0px 24px;
`;

export const SkillsRowNameContainer = styled('div')`
  margin-top: 11px;
  padding-left: 16px;
`;

export const SkillsRowLevelContainer = styled('div')`
  span:first-letter {
    text-transform: uppercase;
  }
  text-transform: lowercase;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0px;
`;

export const SkillsRowDateContainer = styled('div')`
  padding: 0px 16px;
`;

export const SkillsRowActionContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
    &:first-child svg path {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const SkillsRowName = styled(Typography.Title)`
  &.ant-typography {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 15px;
    line-height: 22px;
    font-weight: 400;
    margin-bottom: 0px;
  }
`;

export const SkillsRowSubName = styled(Typography.Text)`
  &.ant-typography {
    color: ${({ theme }) => theme.colors.text.secondary};
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: 0.2px;
    margin-bottom: 0px;
  }
`;

export const SkillsRowDate = styled(Typography.Title)`
  &.ant-typography {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 15px;
    line-height: 22px;
    font-weight: 400;
    margin-bottom: 0px;
  }
`;

export const SkillsRowStatus = styled(Typography.Title)`
  &.ant-typography {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 15px;
    line-height: 22px;
    font-weight: 400;
    margin-bottom: 0px;
  }
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const SkillsTableFooter = styled('div')`
  display: flex;
  justify-content: flex-end;
  padding: 32px 24px 0px;
  width: 100%;
  height: 64px;
`;

export const SkillsTablePagination = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 32px;
`;

type ActionButtonProps = {
  active?: boolean;
  theme: ThemeProps;
};

export const SkillPaginationButton = styled('button')<ActionButtonProps>`
  border: 1px solid
    ${(props) =>
      props.active ? props.theme.colors.text.overline : props.theme.colors.calendar.timeOffBorder};
  color: ${(props) =>
    props.active ? props.theme.colors.text.overline : props.theme.colors.calendar.timeOffBorder};
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  background: ${({ theme }) => theme.colors.backgrounds.light.secondary};
`;

export const TooltipActions = styled(Dropdown)``;

export const ActionWrapper = styled('div')`
  padding: 4px 0px 8px 0px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 5;
`;

type ActionProps = {
  remove?: boolean;
};

export const Action = styled('div')<ActionProps>`
  width: 87px;
  font-size: 15px;
  line-height: 22px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.colors.background};
  }
  color: ${(props) => (props.remove ? props.theme.colors.text.error : props.theme.colors.primary)};
`;

export const SkeletonSkill = styled(Skeleton.Input)`
  .ant-skeleton-input {
    height: 54px;
    border-radius: 4px;
    margin-bottom: 12px;
  }
`;

export const TableSpacing = styled.div`
  min-height: 692px;
  width: 100%;
`;

export const SkillsRowLevelIcon = styled('div')`
  width: 20px;
  height: 22px;
  font-size: 1.2em;
`;

export const StatusRowHeader = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;
