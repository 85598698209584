import styled, { css } from 'styled-components';
import { Tabs } from '@reach/tabs';
import '@reach/tabs/styles.css';

export const StyledTabs = styled(Tabs)`
  [data-reach-tab-list] {
    background: none;
  }

  [data-reach-tab] {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 24px;
    padding: 0 0 16px;

    &:last-child {
      margin-right: 0;
    }

    &[data-selected] {
      border-bottom-color: ${({ theme }) => theme.colors.accents.darkA};
    }

    &:active {
      background: none;
    }
  }
`;

export const TabName = styled.div`
  font: ${({ theme }) => theme.typography.subtitles};
  margin-right: 8px;
`;

export const TabValue = styled.div<{ isSelected: boolean }>`
  font: ${({ theme }) => theme.typography.subtitles};
  background: ${({ theme }) => theme.colors.background};
  padding: 2px 8px;

  ${({ isSelected }) =>
    isSelected &&
    css`
      color: ${({ theme }) => theme.colors.white};
      background: ${({ theme }) => theme.colors.accents.darkA};
    `};
`;

export const TabWrapper = styled.div`
  margin-right: 24px;
`;
