import React from 'react';

import { UserTimeOff } from 'features/TimeOff/types/calendarTypes';

import UsersGroup, { UsersGroupProps } from './UsersGroup';

export interface SidePanelUsersGroupProps extends UsersGroupProps {
  onUserRemove?: (user: UserTimeOff) => void;
  onUserAdd?: (user: UserTimeOff) => void;
  lastMemberRef?: React.RefObject<HTMLDivElement> | React.RefCallback<Element>;
  isPanelOpen: boolean;
}

const SidePanelUsersGroup = ({
  users,
  onUserAdd,
  onUserRemove,
  ...props
}: SidePanelUsersGroupProps): JSX.Element => {
  const findUserById = (userId: string) => users.find((userTimeOff) => userTimeOff.id === userId);

  const onUserIdAdd = onUserAdd
    ? (userId: string) => {
        const user = findUserById(userId);
        if (user) {
          onUserAdd(user);
        }
      }
    : undefined;

  const onUserIdRemove = onUserRemove
    ? (userId: string) => {
        const user = findUserById(userId);
        if (user) {
          onUserRemove(user);
        }
      }
    : undefined;

  return <UsersGroup users={users} onAdd={onUserIdAdd} onRemove={onUserIdRemove} {...props} />;
};

export default SidePanelUsersGroup;
