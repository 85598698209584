import {
  PrivateUser,
  PublicUser,
  MutationCreateFavouriteUserArgs,
  CreateFavouriteResponse,
} from 'API';
import generateGraphqlCaller from 'utils/helpers/generateGraphqlCaller';

const query = /* GraphQL */ `
  mutation CreateFavouriteUser($ownerId: String!, $favouritedId: String!) {
    createFavouriteUser(ownerId: $ownerId, favouritedId: $favouritedId) {
      ... on CreateFavouriteSuccessResponse {
        owner {
          id
          favouriteUsers {
            id
          }
        }
      }
      ... on CreateFavouriteErrorResponse {
        errors {
          ... on UserNotFoundError {
            message
          }
          ... on UnexpectedError {
            message
          }
          ... on Error {
            message
          }
        }
      }
    }
  }
`;

export type ReturnType = {
  createFavouriteUser: Pick<PrivateUser, 'id'> & {
    user: Pick<PublicUser, 'id'>;
  };
};

export default generateGraphqlCaller<CreateFavouriteResponse, MutationCreateFavouriteUserArgs>(
  query
);
