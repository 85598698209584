import { EditContactInfoResponse, MutationEditContactInformationArgs } from 'API';
import generateGraphqlCaller from 'utils/helpers/generateGraphqlCaller';

const query = /* GraphQL */ `
  mutation EditContactInformationMutation($input: ContactInformationInput!) {
    editContactInformation(input: $input) {
      ... on EditContactInfoSuccessResponse {
        successUser: user {
          workingHours
          employeeLocation
          phoneNumber
          onboardingCompleted
        }
      }
      ... on EditContactInfoErrorResponse {
        errorUser: user {
          workingHours
          employeeLocation
          phoneNumber
          onboardingCompleted
        }
        errors {
          ... on SlackError {
            message
          }
          ... on UserNotFoundError {
            message
          }
          ... on UnexpectedError {
            message
          }
          ... on Error {
            message
          }
        }
      }
    }
  }
`;

export default generateGraphqlCaller<EditContactInfoResponse, MutationEditContactInformationArgs>(
  query
);
