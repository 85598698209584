import styled from 'styled-components';
import { Button } from 'antd';

export const EditDetailsButton = styled(Button)`
  background: transparent;
  padding: 4px 8px;
  border: none;

  .anticon {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.text.secondary};
  }

  &:hover,
  &:active,
  &:focus {
    background: transparent;

    .anticon {
      color: ${({ theme }) => theme.colors.text.primary};
    }
  }
`;
