import styled from 'styled-components';

import { TimeOffType } from 'API';
import { TimeOff } from 'features/TimeOff/helpers/mergeTimeOffRecords';
import { TimeOffStatus } from 'features/TimeOff/types/calendarTypes';

export const StatusIndicator = styled.div<{ timeOff: TimeOff }>`
  width: 16px;
  height: 16px;
  background-color: ${({ timeOff, theme: { colors } }) => {
    if (timeOff.status === TimeOffStatus.Pending) return colors.pending.default.b;
    if (timeOff.type === TimeOffType.Paid) return colors.paid.default.body;
    if (timeOff.type === TimeOffType.Unpaid) return colors.unpaid.default.body;
    if (timeOff.type === TimeOffType.Other) return colors.otherTimeOff.default.body;
    return colors.approved.default.body;
  }};
  border: 1px solid
    ${({ timeOff, theme: { colors } }) => {
      if (timeOff.status === TimeOffStatus.Pending) return colors.pending.default.b;
      if (timeOff.type === TimeOffType.Paid) return colors.paid.default.border;
      if (timeOff.type === TimeOffType.Unpaid) return colors.unpaid.default.border;
      if (timeOff.type === TimeOffType.Other) return colors.otherTimeOff.default.border;
      return colors.approved.default.border;
    }};
  border-radius: 4px;
`;

export const StatusText = styled.div`
  margin: 0 8px;
  font: ${({ theme }) => theme.typography.paragraph.font};
  letter-spacing: ${({ theme }) => theme.typography.paragraph.letterSpacing};
  color: ${({ theme }) => theme.colors.text.primary};
  text-transform: uppercase;
`;

export const Heading = styled.span`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  letter-spacing: 0.3px;
  text-transform: uppercase;
`;

export const TextContent = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  letter-spacing: 0.3px;
  text-transform: uppercase;
  margin-top: 4px;
`;

export const JiraTicket = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

export const JiraLink = styled.a`
  font: ${({ theme }) => theme.typography.paragraph.font};
  letter-spacing: ${({ theme }) => theme.typography.paragraph.letterSpacing};
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.text.overline};
  &:hover {
    /* 
      For linear text color:
        background: linear(...);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      The underline will be transparent, so I will stay with the hex value
    */
    text-decoration-line: underline;
    color: ${({ theme }) => theme.colors.text.overline};
  }
`;
