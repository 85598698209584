import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, Avatar } from 'antd';
import { Link } from 'react-router-dom';

import { ReactComponent as TimeOffIcon } from 'assets/Time-off.svg';
import { ReactComponent as ProfileIcon } from 'assets/Profile.svg';
import { ReactComponent as LogoutIcon } from 'assets/Logout.svg';
import { ReactComponent as DirectoryIcon } from 'assets/Directory.svg';
import { ReactComponent as MyTeamIcon } from 'assets/MyTeam.svg';
import logo from 'assets/10Clogo.svg';
import { useAuth } from 'providers/AuthProvider';
import navbarShrink from 'assets/navbarShrink.svg';
import navbarExpand from 'assets/navbarExpand.svg';
import { useWindowResize } from 'features/Profile/hooks/useWindowResize';

import {
  Button,
  CloseIcon,
  HamburgerButton,
  HamburgerIcon,
  MobileHeader,
  NavBar,
  NavIcon,
  NavLink,
} from './Navbar.styles';

const Navbar = (): JSX.Element => {
  const { t } = useTranslation();
  const { currentUser } = useAuth();
  const { logout } = useAuth();
  const { width } = useWindowResize();
  const resolutionBreakpoint = 1440;
  const checkNavLocal = () => {
    if (localStorage.getItem('nav') === 'false' && localStorage.getItem('nav') !== null) {
      return false;
    }
    if (localStorage.getItem('nav') === 'true' && localStorage.getItem('nav') !== null) {
      return true;
    }
    return false;
  };
  const [isNavOpen, setIsNavOpen] = useState(checkNavLocal());
  const setNav = (check: boolean) => {
    setIsNavOpen(check);
    localStorage.setItem('nav', JSON.stringify(check));
  };
  const ROUTES = {
    myTeam: '/my-team',
    timeOff: '/time-off',
    currentUserProfile: `/profile/${currentUser?.id}`,
    employeeDirectory: `/search-results`,
  };

  useEffect(() => {
    if (localStorage.getItem('nav') === null) {
      setNav(width >= resolutionBreakpoint);
    }
    if (width < resolutionBreakpoint) {
      setNav(false);
    }
  }, [setIsNavOpen, width]);

  return isNavOpen ? (
    <>
      <MobileHeader>
        <div className="header-content-mobile">
          <HamburgerButton onClick={() => setIsNavOpen(false)}>
            <CloseIcon />
          </HamburgerButton>
          <ul className="nav-list-mobile">
            <li className="nav-item">
              <NavLink
                to={ROUTES.currentUserProfile}
                className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
              >
                <NavIcon as={ProfileIcon} className="link-icon" />
                <span className="link-text">{t('navigation.my-profile')}</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={ROUTES.employeeDirectory}
                className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
              >
                <NavIcon as={DirectoryIcon} className="link-icon" />
                <span className="link-text">{t('employee-directory.title')}</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={ROUTES.timeOff}
                className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
              >
                <NavIcon as={TimeOffIcon} className="link-icon" />
                <span className="link-text">{t('time-off.time-off')}</span>
              </NavLink>
            </li>
            {currentUser?.isTeamLead && (
              <li className="nav-item">
                <NavLink
                  to={ROUTES.myTeam}
                  className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
                >
                  <NavIcon as={MyTeamIcon} className="link-icon" />
                  <span className="link-text">{t('team-lead-view.my-team')}</span>
                </NavLink>
              </li>
            )}
            <br />
            <li className="nav-item">
              <NavLink className="nav-button-footer" as={Button} onClick={logout}>
                <NavIcon as={LogoutIcon} className="link-icon" />
                <span className="link-text">{t('logout')}</span>
              </NavLink>
            </li>
          </ul>
        </div>

        {!isNavOpen && (
          <div>
            <Link to={`/profile/${currentUser?.id}`}>
              <Avatar size={42} src={currentUser?.profilePicture}>
                {currentUser?.name}
              </Avatar>
            </Link>
          </div>
        )}
      </MobileHeader>
      <NavBar id="navigationBar">
        <div className="nav-header">
          <img src={logo} alt="logo" className="logo" id="tenCLogo" />
          <Button
            className="nav-button"
            icon={<img src={navbarShrink} alt="navbar-shrink-button" />}
            onClick={() => setIsNavOpen(false)}
            id="closeNavigationBarButton"
          />
        </div>

        <ul className="nav-list">
          <li className="nav-item">
            <NavLink
              to={ROUTES.currentUserProfile}
              className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
              id="myProfileLink"
            >
              <NavIcon as={ProfileIcon} className="link-icon" />
              <span className="link-text">{t('navigation.my-profile')}</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to={ROUTES.employeeDirectory}
              className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
              id="employeeDirectoryLink"
            >
              <NavIcon as={DirectoryIcon} className="link-icon" />
              <span className="link-text">{t('employee-directory.title')}</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to={ROUTES.timeOff}
              className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
              id="timeOffLink"
            >
              <NavIcon as={TimeOffIcon} className="link-icon" />
              <span className="link-text">{t('time-off.time-off')}</span>
            </NavLink>
          </li>
          {currentUser?.isTeamLead && (
            <li className="nav-item-shrink">
              <Tooltip placement="right" title={t('team-lead-view.my-team')}>
                <NavLink
                  to={ROUTES.myTeam}
                  className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
                >
                  <NavIcon as={MyTeamIcon} className="link-icon" />
                  <span className="link-text">{t('team-lead-view.my-team')}</span>
                </NavLink>
              </Tooltip>
            </li>
          )}
        </ul>

        <div className="nav-footer">
          <li className="nav-item">
            <NavLink className="nav-button-footer" as={Button} onClick={logout} id="logoutButton">
              <NavIcon as={LogoutIcon} className="link-icon" />
              <span className="link-text">{t('logout')}</span>
            </NavLink>
          </li>
        </div>
      </NavBar>
    </>
  ) : (
    <>
      <MobileHeader>
        <div className="header-content-left">
          <HamburgerButton onClick={() => setIsNavOpen(true)}>
            <HamburgerIcon />
          </HamburgerButton>
        </div>

        <div className="header-content-right">
          <Link to={`/profile/${currentUser?.id}`}>
            <Avatar size={42} src={currentUser?.profilePicture}>
              {currentUser?.name}
            </Avatar>
          </Link>
        </div>
      </MobileHeader>
      <NavBar>
        <Tooltip placement="right" title={t('navigation.expand')}>
          <div className="nav-header">
            <Button
              className="nav-button"
              icon={<img src={navbarExpand} alt="navbar-expand-button" />}
              onClick={() => setIsNavOpen(true)}
            />
          </div>
        </Tooltip>
        <ul className="nav-list-shrink">
          <li className="nav-item-shrink">
            <Tooltip placement="right" title={t('navigation.my-profile')}>
              <NavLink
                to={ROUTES.currentUserProfile}
                className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
              >
                <NavIcon as={ProfileIcon} className="link-icon" />
              </NavLink>
            </Tooltip>
          </li>
          <li className="nav-item-shrink">
            <Tooltip placement="right" title={t('employee-directory.title')}>
              <NavLink
                to={ROUTES.employeeDirectory}
                className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
              >
                <NavIcon as={DirectoryIcon} className="link-icon" />
              </NavLink>
            </Tooltip>
          </li>
          <li className="nav-item-shrink">
            <Tooltip placement="right" title={t('time-off.time-off')}>
              <NavLink
                to={ROUTES.timeOff}
                className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
              >
                <NavIcon as={TimeOffIcon} className="link-icon" />
              </NavLink>
            </Tooltip>
          </li>
          {currentUser?.isTeamLead && (
            <li className="nav-item-shrink">
              <Tooltip placement="right" title={t('team-lead-view.my-team')}>
                <NavLink
                  to={ROUTES.myTeam}
                  className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
                >
                  <NavIcon as={MyTeamIcon} className="link-icon" />
                </NavLink>
              </Tooltip>
            </li>
          )}
        </ul>
        <div className="nav-footer">
          <Tooltip placement="right" title={t('logout')}>
            <NavLink as={Button} onClick={logout}>
              <NavIcon as={LogoutIcon} className="link-icon" />
            </NavLink>
          </Tooltip>
        </div>
      </NavBar>
    </>
  );
};

export default Navbar;
