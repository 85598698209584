import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { queries } from 'shared/layout';
import { TimeOffType } from 'API';
import { TimeOffStatus } from 'features/TimeOff/types/calendarTypes';
import { TimeOff } from 'features/TimeOff/helpers/mergeTimeOffRecords';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const MainWrapper = styled.div`
  width: 100%;
  padding: 40px 32px;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;

  ${queries.desktop} {
    padding: 40px 48px;
  }
`;

export const Nav = styled.nav`
  display: flex;
  align-items: center;
  min-height: 30px;
  gap: 8px;
`;

export const BreadcrumbsSection = styled.div`
  width: 100%;
  gap: 8px;
  max-width: 1600px;
`;

export const ContentSection = styled.div`
  display: flex;
  margin-top: 16px;
  width: 100%;
  height: 100%;
  max-width: 1600px;
`;

export const SectionsContainer = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const Section = styled.section`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 32px;
`;

export const TimeOffLoadingContainer = styled.div`
  padding: 4px 8px;
  max-height: 30px;
  display: flex;
  align-items: center;
`;

export const TimeOffStatusContainer = styled.div<{ timeOff: TimeOff }>`
  cursor: pointer;
  margin: 0 16px;
  background: ${({ timeOff, theme: { colors } }) => {
    if (timeOff.status === TimeOffStatus.Pending) return colors.pending.default.a;
    if (timeOff.type === TimeOffType.Paid) return colors.paid.default.body;
    if (timeOff.type === TimeOffType.Unpaid) return colors.unpaid.default.body;
    if (timeOff.type === TimeOffType.Other) return colors.otherTimeOff.default.body;
    return colors.approved.default.body;
  }};
  color: ${({ timeOff, theme: { colors } }) => {
    if (timeOff.status === TimeOffStatus.Pending) return colors.pending.default.c;
    if (timeOff.type === TimeOffType.Paid) return colors.paid.default.text;
    if (timeOff.type === TimeOffType.Unpaid) return colors.unpaid.default.text;
    if (timeOff.type === TimeOffType.Other) return colors.otherTimeOff.default.text;
    return colors.approved.default.text;
  }};
  border: ${({ timeOff, theme: { colors } }) => {
    if (timeOff.status === TimeOffStatus.Pending) return `solid 1px ${colors.pending.default.c}`;
    if (timeOff.type === TimeOffType.Paid) return `solid 1px ${colors.paid.default.border}`;
    if (timeOff.type === TimeOffType.Unpaid) return `solid 1px ${colors.unpaid.default.border}`;
    if (timeOff.type === TimeOffType.Other)
      return `solid 1px ${colors.otherTimeOff.default.border}`;
    return `solid 1px ${colors.approved.default.border}`;
  }};
  padding: 4px 8px;
  max-height: 30px;
  display: flex;
  align-items: center;

  &:hover,
  &:active {
    background: ${({ timeOff, theme: { colors } }) => {
      if (timeOff.status === TimeOffStatus.Pending) return colors.pending.hover.a;
      if (timeOff.type === TimeOffType.Paid) return colors.paid.hover.body;
      if (timeOff.type === TimeOffType.Unpaid) return colors.unpaid.hover.body;
      if (timeOff.type === TimeOffType.Other) return colors.otherTimeOff.hover.body;
      return colors.approved.default.body;
    }};
    color: ${({ timeOff, theme: { colors } }) => {
      if (timeOff.status === TimeOffStatus.Pending) return colors.pending.hover.c;
      if (timeOff.type === TimeOffType.Paid) return colors.paid.hover.text;
      if (timeOff.type === TimeOffType.Unpaid) return colors.unpaid.hover.text;
      if (timeOff.type === TimeOffType.Other) return colors.otherTimeOff.hover.text;
      return colors.approved.default.text;
    }};
    border: ${({ timeOff, theme: { colors } }) => {
      if (timeOff.status === TimeOffStatus.Pending) return `solid 1px ${colors.pending.hover.c}`;
      if (timeOff.type === TimeOffType.Paid) return `solid 1px ${colors.paid.hover.border}`;
      if (timeOff.type === TimeOffType.Unpaid) return `solid 1px ${colors.unpaid.hover.border}`;
      if (timeOff.type === TimeOffType.Other)
        return `solid 1px ${colors.otherTimeOff.hover.border}`;
      return `solid 1px ${colors.approved.hover.border}`;
    }};
  }
`;

export const TimeOffText = styled.span<{ timeOff: TimeOff }>`
  font: ${({ theme }) => theme.typography.paragraph.font};
  letter-spacing: ${({ theme }) => theme.typography.paragraph.letterSpacing};
  color: ${({ timeOff, theme: { colors } }) => {
    if (timeOff.status === TimeOffStatus.Pending) return colors.pending.default.c;
    if (timeOff.type === TimeOffType.Paid) return colors.paid.default.text;
    if (timeOff.type === TimeOffType.Unpaid) return colors.unpaid.default.text;
    if (timeOff.type === TimeOffType.Other) return colors.otherTimeOff.default.text;
    return colors.approved.default.text;
  }};
  text-transform: uppercase;
  margin-left: 8px;
`;

export const TimeOffIconWrapper = styled.div`
  padding: 4px;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-size: 12px;
  display: flex;
  background-color: ${({ theme }) => theme.colors.white};
  align-items: center;
  justify-content: center;
`;

export const ProfileNavigation = styled.div`
  margin-top: 24px;
  width: 100%;
  max-width: 1600px;
  display: flex;
  gap: 24px;
`;

export const ProfileNavLink = styled(NavLink)`
  padding: 0 0 15px;
  border-bottom: 1px solid transparent;
  font-size: 15px;
  line-height: 1.47;
  color: #4e4d6c;
  display: flex;
  align-items: center;

  &[aria-current='page'] {
    border-color: ${({ theme }) => theme.colors.text.overline};
    color: ${({ theme }) => theme.colors.text.primary};

    svg {
      color: ${({ theme }) => theme.colors.text.overline};
    }
  }

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colors.text.overline};
  }
`;

export const ProfileNavLinkIcon = styled.svg`
  margin-right: 8px;
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.colors.text.secondary};
`;
