import { debounce } from 'lodash';

import { FiltersType } from 'features/SearchResults/components/Filters/SkillFilters';

interface IDeboucedSearch {
  searchTerm: string;
  values: FiltersType;
  setter: (array: FiltersType) => void;
}

export const deboucedSearch = debounce(({ searchTerm, values, setter }: IDeboucedSearch) => {
  const filteredArray = values.map((item) => {
    return {
      title: item.title,
      filters: item.filters.filter((filter) => {
        return filter.name.toLowerCase().includes(searchTerm.toLowerCase());
      }),
    };
  });

  setter(filteredArray);
}, 200);
