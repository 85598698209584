import React, { useState, useEffect, ReactChild, useContext } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useTranslation } from 'react-i18next';

import Spinner, { FullPageSpinnerContainer } from 'components/Spinner';

import useUserQuery from 'hooks/useUserQuery';
import { queryClient } from 'api/Client';
import { client } from 'graphql/client';
import { showError } from 'utils/helpers/showMessage';
import { User } from 'API';

interface IAuthContext {
  currentUser:
    | Pick<
        User,
        | 'id'
        | 'email'
        | 'name'
        | 'profilePicture'
        | 'team'
        | 'role'
        | 'onboardingCompleted'
        | 'technicalRole'
        | 'isTeamLead'
      >
    | undefined;
  isLoading: boolean;
  token: string | null;
  setToken: (token: string) => void;
  login: () => void;
  logout: () => Promise<void>;
  getUser: () => Promise<void>;
}

interface IErrors {
  response: {
    errors: Array<Error>;
  };
}

export const AuthContext = React.createContext<IAuthContext | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactChild }): JSX.Element => {
  const [token, setToken] = useState<string | null>(localStorage.getItem('ep_token'));
  const { t } = useTranslation();
  const { currentUser, refetch, isLoading, isFetching } = useUserQuery(token);

  const login = useGoogleLogin({
    flow: 'auth-code',
    redirect_uri: `${process.env.REACT_APP_REDIRECT_URL}/users/google-auth/`,
    scope: 'openid email profile',
    ux_mode: 'redirect',
  });

  const logout = async () => {
    localStorage.removeItem('ep_token');
    try {
      await fetch(`${process.env.REACT_APP_REDIRECT_URL}/users/auth/logout/`, {
        method: 'POST',
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      queryClient.clear();
      setToken(null);
    } catch (err) {
      showError({ content: (err as Error).message });
    }
  };

  const getUser = async () => {
    try {
      await refetch();
    } catch (err) {
      (err as IErrors).response?.errors.map((e) => showError({ content: e.message }));
    }
  };

  useEffect(() => {
    if (!token) {
      return;
    }
    client.setHeaders({ Authorization: `Token ${token}` });
    localStorage.setItem('ep_token', token);
  }, [token]);

  if (isFetching) {
    // Show loading spinner when we're determining the auth state
    return (
      <FullPageSpinnerContainer>
        <Spinner message={t('sign-in.loading')} variant="light" />
      </FullPageSpinnerContainer>
    );
  }

  return (
    <AuthContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{ currentUser, isLoading, login, logout, setToken, token, getUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): IAuthContext => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
