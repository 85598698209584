import { Avatar, ConfigProvider, Table } from 'antd';
import React, { Key, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnsType } from 'antd/lib/table';
import { useSelector } from 'react-redux';

import { ReactComponent as ApproveIcon } from 'assets/Approve.svg';
import { ReactComponent as DeclineIcon } from 'assets/Decline.svg';
import Pagination from 'features/SearchResults/components/Pagination/Pagination';
import useMyTeamSkillsQuery, {
  AssignedSkills,
} from 'features/TeamLeadView/hooks/useMyTeamSkillsQuery';
import defaultPhoto from 'assets/png/10clouds_sygnet.png';
import { getSkillLevelIcon } from 'utils/helpers/getSkillLevelIcon';
import { getLanguageFlag } from 'utils/helpers/getLanguageFlag';
import { getLanguageNames } from 'features/Profile/helpers/getLanguageNames';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_PAGE_SIZE_SEARCH,
} from 'features/SearchResults/utils/constants/pagination';
import TeamLeadModal from 'features/TeamLeadView/components/Modal/TeamLeadModal';
import { EmptyTeamLeadViewSkills } from 'features/TeamLeadView/EmptyState/EmptyTeamLeadViewSkills';
import mutateApproveSkills from 'features/TeamLeadView/api/mutateApproveSkills';
import mutateRejectSkills from 'features/TeamLeadView/api/mutateRejectSkills';
import { useAppDispatch } from 'redux/store';
import { actions, selectSkills } from 'features/Profile/redux/skillSlice';
import { TableSpacing } from 'features/Profile/components/ProfileSkills/SkillsTable/SkillsTable.styles';

import {
  Section,
  SectionTitle,
  SkillsRowLevelContainer,
  SkillsRowLevelIcon,
  SkillsRowName,
  SkillsRowSubName,
  UserName,
  SectionWrapper,
  Wrapper,
  SkillWrapper,
  HeaderButton,
  HeaderWrapper,
  ButtonWrapper,
  SelectAllReminder,
  SelectAll,
  Content,
} from './TeamSkills.styles';

type TeamAssignedSkill = {
  name: string;
  id: string;
  avatar?: string | null;
  skillName: string;
  skillLvl: string;
  skillDate: string;
  skillFlag: string;
  skillType: string;
  select: string;
  skillId: string;
};

type TeamAssignedSkillColumns = Pick<
  TeamAssignedSkill,
  | 'name'
  | 'id'
  | 'select'
  | 'avatar'
  | 'skillName'
  | 'skillLvl'
  | 'skillDate'
  | 'skillFlag'
  | 'skillType'
  | 'skillId'
>;

interface IUserAvatarElement {
  id?: string;
  src?: string;
}

interface SkillGroups {
  userId: string;
  skillNames: string[];
}

const UserAvatarElement = ({ src = defaultPhoto, id }: IUserAvatarElement): JSX.Element => {
  return <Avatar size={32} src={src} />;
};

const TeamSkills: React.FC = (): JSX.Element => {
  const [selectedSkills, setSelectedSkills] = useState<AssignedSkills>([]);
  const [teamSkills, setTeamSkills] = useState<AssignedSkills>([]);
  const [selectedSkillsKeys, setSelectedSkillsKeys] = useState<Key[]>([]);
  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const [visibleAllReminder, setVisibleAllReminder] = useState<boolean>(false);
  const [contentModal, setContentModal] = useState<string>('');
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { skills, isLoading } = useMyTeamSkillsQuery();
  const { hasSkillsInReview } = useSelector(selectSkills);
  const [currentPage, setCurrentPage] = useState<number>(DEFAULT_CURRENT_PAGE);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE_SEARCH);
  const showSkills: AssignedSkills | undefined = teamSkills?.slice(
    currentPage === 1 ? 0 : (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  useEffect(() => {
    if (skills) {
      setTeamSkills(skills);
    }
  }, [skills]);

  const rowSelection = {
    onChange: (selectedRowKeys: Key[], selectedRows: AssignedSkills) => {
      setSelectedSkills((prevSkills) => {
        const newSkills = selectedRows.filter(
          (selectedRow) =>
            !prevSkills.some(
              (prevSkill) =>
                prevSkill.name === selectedRow.name && prevSkill.skillName === selectedRow.skillName
            )
        );
        const updatedSkills = prevSkills.filter((prevSkill) =>
          selectedRows.some(
            (selectedRow) =>
              prevSkill.name === selectedRow.name && prevSkill.skillName === selectedRow.skillName
          )
        );
        return newSkills.length >= 1 ? [...prevSkills, ...newSkills] : updatedSkills;
      });

      setSelectedSkillsKeys((prevKeys) => {
        const newKeys = selectedRowKeys.filter((selectedKey) => !prevKeys.includes(selectedKey));
        const updatedKeys = prevKeys.filter((prevKey) => selectedRowKeys.includes(prevKey));
        return newKeys.length >= 1 ? [...prevKeys, ...newKeys] : [...updatedKeys];
      });
    },
    getCheckboxProps: (record: { name: string }) => ({
      name: record.name,
    }),
  };

  const visibilityHandler = async (visible: boolean) => {
    setVisibleModal(visible);
  };

  const actionHandler = async (action: string) => {
    const groupedSkills: SkillGroups[] = Object.values(
      selectedSkills.reduce((groups: Record<string, SkillGroups>, skill) => {
        const { id, skillName } = skill;
        const userGroup = groups[id] || { userId: id, skillNames: [] };
        userGroup.skillNames.push(skillName);
        return { ...groups, [id]: userGroup };
      }, {})
    );
    const filteredSkills = teamSkills.filter((skill) => !selectedSkills.includes(skill));
    setTeamSkills(filteredSkills);
    if (action === 'approve') {
      await mutateApproveSkills({ input: groupedSkills });
    } else {
      await mutateRejectSkills({ input: groupedSkills });
    }
    if (selectedSkills.length === skills?.length) {
      dispatch(actions.checkIfSkillsInReview());
    }
    setSelectedSkills([]);
    setSelectedSkillsKeys([]);
    setVisibleModal(false);
  };

  const selectAllHandler = () => {
    if (selectedSkills.length === skills?.length) {
      setVisibleAllReminder(false);
      setSelectedSkills([]);
      setSelectedSkillsKeys([]);
      rowSelection.onChange([], []);
    } else if (skills) {
      setSelectedSkills(skills);
      setSelectedSkillsKeys(skills.map((skill) => skill.skillId));
    }
  };

  const skillsHandler = (action: string) => {
    if (action === 'approve') {
      setContentModal('approve');
    } else {
      setContentModal('decline');
    }
    setVisibleModal(true);
  };

  useEffect(() => {
    if (selectedSkills.length >= 1) {
      setVisibleAllReminder(true);
    } else {
      setVisibleAllReminder(false);
    }
  }, [selectedSkills, pageSize, showSkills, currentPage]);

  const skillsHeaders: ColumnsType<TeamAssignedSkillColumns> = [
    {
      key: 'date',
      dataIndex: 'date',
      width: '15%',
      title: <Wrapper>{t('team-lead-view.table.updated')}</Wrapper>,
      render: (_: string, { skillDate }): JSX.Element => (
        <Wrapper>
          {new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
          }).format(new Date(skillDate))}
        </Wrapper>
      ),
    },
    {
      key: 'lvl',
      dataIndex: 'lvl',
      width: '15%',
      title: <Wrapper>{t('team-lead-view.table.lvl')}</Wrapper>,
      render: (_: string, { skillLvl, skillFlag }): JSX.Element => (
        <Wrapper>
          <SkillsRowLevelContainer>
            <span>
              {skillFlag === '' || skillLvl.toLowerCase() !== 'native'
                ? skillLvl
                : getLanguageNames(skillLvl)}
            </span>
            <SkillsRowLevelIcon>
              {skillFlag === '' ? getSkillLevelIcon(skillLvl) : getLanguageFlag(skillFlag)}
            </SkillsRowLevelIcon>
          </SkillsRowLevelContainer>
        </Wrapper>
      ),
    },
    {
      key: 'skill',
      dataIndex: 'skill',
      width: '25%',
      title: <Wrapper>{t('team-lead-view.table.skill-name')}</Wrapper>,
      render: (_: string, { skillName, skillType }): JSX.Element => (
        <Wrapper>
          <SkillWrapper>
            <SkillsRowName>{skillName}</SkillsRowName>
            <SkillsRowSubName>{`${
              skillType === 'TECHNICAL_SKILL' ? 'Technical' : 'Language'
            } skill`}</SkillsRowSubName>
          </SkillWrapper>
        </Wrapper>
      ),
    },
    {
      key: 'name',
      dataIndex: 'name',
      width: '35%',
      title: <SectionWrapper>{t('team-lead-view.table.name')}</SectionWrapper>,
      render: (_, { name, id, avatar }) => (
        <SectionWrapper>
          <UserName href={`../profile/${id}`} target="_blank">
            {name}
          </UserName>
          {avatar && <UserAvatarElement id={id} src={avatar} />}
        </SectionWrapper>
      ),
    },
  ];
  return (
    <Section>
      <TeamLeadModal
        count={selectedSkills.length}
        handler={visibilityHandler}
        handlerAction={actionHandler}
        visible={visibleModal}
        content={contentModal}
      />
      <HeaderWrapper>
        <SectionTitle>{t('team-lead-view.employee-skills')}</SectionTitle>
        {hasSkillsInReview && (
          <ButtonWrapper>
            <HeaderButton
              disabled={selectedSkills.length === 0}
              onClick={() => skillsHandler('approve')}
            >
              <ApproveIcon />
              {t('team-lead-view.table.approve')}
            </HeaderButton>
            <HeaderButton
              disabled={selectedSkills.length === 0}
              onClick={() => skillsHandler('decline')}
            >
              <DeclineIcon />
              {t('team-lead-view.table.decline')}
            </HeaderButton>
          </ButtonWrapper>
        )}
      </HeaderWrapper>
      {hasSkillsInReview && visibleAllReminder && (
        <SelectAllReminder>
          <p>
            {selectedSkills.length === skills?.length && t('team-lead-view.all')}{' '}
            {selectedSkills.length}{' '}
            {selectedSkills.length === 1
              ? t('team-lead-view.selected-end')
              : t('team-lead-view.all-selected-end')}
          </p>
          <SelectAll onClick={selectAllHandler}>
            {selectedSkills.length !== skills?.length
              ? t('team-lead-view.select-all')
              : t('team-lead-view.clear-all')}
          </SelectAll>
        </SelectAllReminder>
      )}
      {!hasSkillsInReview ? (
        <Content>
          <EmptyTeamLeadViewSkills />
        </Content>
      ) : (
        <>
          <TableSpacing>
            <ConfigProvider direction="rtl">
              <Table
                rowSelection={{
                  selectedRowKeys: selectedSkillsKeys,
                  type: 'checkbox',
                  ...rowSelection,
                }}
                loading={isLoading}
                dataSource={showSkills || undefined}
                columns={skillsHeaders}
                pagination={false}
                rowKey="skillId"
                direction="rtl"
              />
            </ConfigProvider>
          </TableSpacing>
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            setPageSize={setPageSize}
            amountItems={pageSize}
            total={skills?.length || 0}
          />
        </>
      )}
    </Section>
  );
};

export default TeamSkills;
