import { Checkbox, Collapse, Input, Typography } from 'antd';
import styled from 'styled-components';

import { ReactComponent as iconList } from 'assets/List.svg';
import { queries } from 'shared/layout';

const { Panel } = Collapse;

export const Wrapper = styled.div`
  margin: 0px 0px 0px 24px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  background-color: ${({ theme }) => theme.colors.white};
  height: max-content;

  ${queries.tablet} {
    margin: 0px 0px 0px 24px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

export const ListIcon = styled(iconList)`
  margin-right: 10px;
`;

export const Title = styled.h2`
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const Button = styled.button`
  border: none;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.text.overline};
  background-color: ${({ theme }) => theme.colors.white};
`;

export const Menu = styled(Collapse)`
  border: none;

  .ant-collapse-item {
    border-bottom: 1px solid ${({ theme }) => theme.colors.text.overline};
    min-width: 251px;
  }

  .ant-collapse-expand-icon {
    position: relative;
    left: 240px;
    top: 10px;
  }

  .ant-collapse-item-active {
    border-bottom: none;

    .ant-collapse-expand-icon {
      left: 200px;
      transform: rotate(180deg);
    }
  }

  .ant-collapse-item > .ant-collapse-header {
    position: relative;

    text-transform: uppercase;
    background-color: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.primary};
  }

  .ant-collapse-content {
    border-top: 1px solid ${({ theme }) => theme.colors.text.overline};
  }
  .ant-collapse-content-box {
    max-height: 260px;
    overflow-y: auto;
    padding: 0;
  }
  .arrow-expanded,
  .arrow-collapsed {
    transition: transform 0.3s;
  }
  .arrow-expanded {
    transform: rotate(0deg);
    fill: ${({ theme }) => theme.colors.text.overline};
  }
  .arrow-collapsed {
    transform: rotate(180deg) !important;
    fill: ${({ theme }) => theme.colors.text.secondary};
  }
`;

export const ItemsContainer = styled(Panel)`
  .ant-collapse-content-box {
    overflow-y: scroll;
    overflow-x: hidden;
  }
`;

export const Item = styled.div`
  display: block;
  text-transform: capitalize;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: ${({ theme }) => theme.colors.text.secondary};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray['400']};
  user-select: none;
`;

export const Check = styled(Checkbox)`
  margin-right: 14px;
  width: 222px;
  padding: 21px 18px;

  .ant-checkbox-inner {
    transition: 0.2s ease-in-out;
    border: 2px solid ${({ theme }) => theme.colors.checkbox.primary};
    ::after {
      left: 20%;
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.colors.text.overline};
    border-color: ${({ theme }) => theme.colors.text.overline};
  }

  &.ant-checkbox-wrapper {
    span {
      transition: 0.2s ease-in-out;
      text-transform: capitalize;
      color: ${({ theme }) => theme.colors.text.secondary};
    }
  }

  &.ant-checkbox-wrapper-checked {
    span {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  &:hover .ant-checkbox .ant-checkbox-inner {
    border-color: ${({ theme }) => theme.colors.text.overline};
  }

  &:hover span {
    transition: 0.2s ease-in-out;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const SearchInput = styled(Input)`
  border-color: ${({ theme }) => theme.colors.pagination.primary};
  box-shadow: none;
  height: 54px;
  min-width: 251px;
  margin-bottom: 24px;
  &.ant-input-affix-wrapper {
    padding: 15px 17px;
  }

  .ant-input-prefix {
    margin-right: 9px;
  }

  &:not(.ant-input-affix-wrapper-disabled):hover {
    border: 1px solid ${({ theme }) => theme.colors.secondary['200']};
    box-shadow: none;
  }
`;

export const EmptyText = styled(Typography.Paragraph)`
  overflow: hidden;
  text-align: center;
  max-height: 64px;
  &.ant-typography {
    padding: 22px;
    color: ${({ theme }) => theme.colors.text.secondary};
    margin: 0;
    z-index: 5;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    background-color: white;
  }
`;
