import { Breadcrumb } from 'antd';
import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { VARIANT } from 'components/AutocompleteFilter/types';
import PageLayout from 'components/PageLayout';
import ProfileTopBar from 'components/TopBar/ProfileTopBar';

import {
  BreadcrumbsSection,
  Nav,
  ProfileNavLink,
} from 'features/Profile/pages/Profile/Profile.styles';
import {
  SectionsContainer,
  Container,
  ProfileNavigation,
  MainWrapper,
} from 'features/SearchResults/pages/SearchResults/SearchResults.styles';
import { useAuth } from 'providers/AuthProvider';

const EmployeeDirectoryPage = (): JSX.Element => {
  const { t } = useTranslation();
  const { currentUser } = useAuth();
  const location = useLocation();
  const atSkills = location.pathname.includes('skills');

  return (
    <PageLayout>
      <Container>
        <ProfileTopBar
          profilePicture={currentUser?.profilePicture ?? ''}
          variant={VARIANT.TOP_BAR}
        />
        <MainWrapper>
          <SectionsContainer>
            <BreadcrumbsSection>
              <Nav>
                <Breadcrumb>
                  <Breadcrumb.Item>{t('employee-directory.title')}</Breadcrumb.Item>
                  {atSkills ? (
                    <Breadcrumb.Item>{t('employee-directory.skills')}</Breadcrumb.Item>
                  ) : (
                    <Breadcrumb.Item>{t('employee-directory.list')}</Breadcrumb.Item>
                  )}
                </Breadcrumb>
              </Nav>
            </BreadcrumbsSection>

            <ProfileNavigation>
              <ProfileNavLink to="/search-results" end>
                {t('employee-directory.list')}
              </ProfileNavLink>
              <ProfileNavLink to="skills">{t('employee-directory.skills')}</ProfileNavLink>
            </ProfileNavigation>

            <Outlet />
          </SectionsContainer>
        </MainWrapper>
      </Container>
    </PageLayout>
  );
};

export default EmployeeDirectoryPage;
