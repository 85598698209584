import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  Container,
  Head,
  Header,
  Paragraph,
  ParagraphWrapped,
  Button,
  StatusWrapper,
} from './TooltipContent.styles';

export enum PtoTooltip {
  STACKED = 'stacked',
  CONTRACTED = 'contracted',
  CARRYOVER = 'carryover',
}

interface Props {
  type: string;
  count?: {
    approved: number;
    in_review: number;
    rejected: number;
  };
}

const TooltipContent: React.FC<Props> = ({ type, count }: Props) => {
  const { t } = useTranslation();

  switch (type) {
    case 'skill_status':
      return (
        <Container>
          <Header>{t('skills-matrix.tooltip.title')}</Header>
          <StatusWrapper>
            <Head>{t('skills-matrix.tooltip.approved')}</Head>
            {count && <Head>{count.approved}</Head>}
          </StatusWrapper>
          <ParagraphWrapped>{t('skills-matrix.tooltip.approved-content')}</ParagraphWrapped>
          <StatusWrapper>
            <Head>{t('skills-matrix.tooltip.in-review')}</Head>
            {count && <Head>{count.in_review}</Head>}
          </StatusWrapper>
          <ParagraphWrapped>{t('skills-matrix.tooltip.in-review-content')}</ParagraphWrapped>
          <StatusWrapper>
            <Head>{t('skills-matrix.tooltip.rejected')}</Head>
            {count && <Head rejected>{count.rejected}</Head>}
          </StatusWrapper>
          <ParagraphWrapped>{t('skills-matrix.tooltip.rejected-content')}</ParagraphWrapped>
        </Container>
      );
    case 'skill_requests':
      return (
        <Container>
          <Head>{t('team-lead-view.notification.title')}</Head>
          <ParagraphWrapped>{t('team-lead-view.notification.content')}</ParagraphWrapped>
          <Link to="../my-team/skills">
            <Button>{t('team-lead-view.notification.button')}</Button>
          </Link>
        </Container>
      );
    case PtoTooltip.STACKED:
      return (
        <Container>
          <Head>{t('pto-tooltip.stacked-what')}</Head>
          <Paragraph>{t('pto-tooltip.stacked-shows')}</Paragraph>
          <Paragraph>{t('pto-tooltip.stacked-uop')}</Paragraph>
        </Container>
      );
    case PtoTooltip.CONTRACTED:
      return (
        <Container>
          <Head>{t('pto-tooltip.contracted-what')}</Head>
          <Paragraph>{t('pto-tooltip.contracted-shows')}</Paragraph>
        </Container>
      );
    case PtoTooltip.CARRYOVER:
      return (
        <Container>
          <Head>{t('pto-tooltip.carryover-when')}</Head>
          <Paragraph>
            {t('pto-tooltip.carryover-when')} {t('pto-tooltip.carryover-valid')}
            {t('pto-tooltip.carryover-month')} {t('pto-tooltip.carryover-this-year')}
          </Paragraph>
        </Container>
      );
    default:
      return null;
  }
};

export default TooltipContent;
