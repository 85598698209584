import React, { ChangeEvent, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as IconSearch } from 'assets/iconSearch.svg';
import { ReactComponent as Arrow } from 'assets/Arrow.svg';
import { DEFAULT_CURRENT_PAGE } from 'features/SearchResults/utils/constants/pagination';
import { deboucedSearch } from 'features/SearchResults/utils/helpers/filtersDebouncedSearch';
import { hasEveryFilterEmpty } from 'features/SearchResults/utils/helpers/hasEveryFilterEmpty';

import {
  Wrapper,
  Header,
  ListIcon,
  Title,
  Button,
  Menu,
  ItemsContainer,
  Item,
  Check,
  SearchInput,
  EmptyText,
} from './Filters.styles';

export type FiltersType = {
  title: string;
  filters: {
    name: string;
    checkbox: boolean;
    level?: boolean[];
  }[];
}[];

type FiltersPropsType = {
  values: FiltersType;
  setFiltersValues: React.Dispatch<React.SetStateAction<FiltersType>>;
  activeFilters: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
};

const Filters = ({
  values,
  setFiltersValues,
  activeFilters,
  setCurrentPage,
}: FiltersPropsType): JSX.Element => {
  const { t } = useTranslation();
  const [results, setResults] = useState<FiltersType>(values);

  useEffect(() => {
    setResults(values);
  }, [values]);

  const handleClick = (title: string, name: string) => {
    const newValue = values.reduce<FiltersType>((acc, item) => {
      acc.push(
        item.title === title
          ? {
              title: item.title,
              filters: item.filters.map((elem) =>
                elem.name === name ? { name: elem.name, checkbox: !elem.checkbox } : elem
              ),
            }
          : item
      );
      return acc;
    }, []);
    setResults(newValue);
    setFiltersValues(newValue);
    setCurrentPage(DEFAULT_CURRENT_PAGE);
  };

  const clearAllFilters = (): void => {
    const newValue = results.reduce<FiltersType>((acc, item) => {
      acc.push({
        title: item.title,
        filters: item.filters.map((elem) => ({
          name: elem.name,
          checkbox: false,
          checkedArray: [false, false, false],
        })),
      });
      return acc;
    }, []);
    setResults(newValue);
    setFiltersValues(newValue);
    setCurrentPage(DEFAULT_CURRENT_PAGE);
  };

  return (
    <Wrapper>
      <Header>
        <Title>
          <ListIcon />
          {t('filters.title')}
        </Title>
        {activeFilters > 0 ? (
          <Button onClick={clearAllFilters}>{t('search-results.clear-all')}</Button>
        ) : null}
      </Header>
      <SearchInput
        defaultValue=""
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          e.preventDefault();
          deboucedSearch({ searchTerm: e.target.value, values, setter: setResults });
        }}
        prefix={<IconSearch />}
        placeholder={t('skills-matrix.add-skills.search')}
        allowClear
      />
      <Menu
        expandIcon={({ isActive }) => (
          <Arrow className={isActive ? 'arrow-expanded' : 'arrow-collapsed'} />
        )}
        expandIconPosition="right"
        defaultActiveKey={['employee role', 'technical role', 'seniority', 'department']}
      >
        {hasEveryFilterEmpty(results) ? (
          <EmptyText>{t('filters.empty-search')}</EmptyText>
        ) : (
          results.map((item) => (
            <ItemsContainer header={item.title} key={item.title}>
              {item.filters.map((element) => (
                <Item key={element.name}>
                  <Check
                    onClick={() => handleClick(item.title, element.name)}
                    checked={element.checkbox}
                  >
                    {element.name}
                  </Check>
                </Item>
              ))}
            </ItemsContainer>
          ))
        )}
      </Menu>
    </Wrapper>
  );
};

export default Filters;
