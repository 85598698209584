import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'redux/rootReducer';
import api from 'features/Profile/api';
import { User } from 'API';
import { SEARCH_STATUS } from 'utils/helpers/searchStatus';

type UserState = Pick<
  User,
  'role' | 'technicalRole' | 'seniority' | 'profilePicture' | 'name' | 'id' | 'email'
>;
interface ProfileState {
  searchStatus: SEARCH_STATUS;
  selectedUser: UserState;
  listUsers: UserState[];
}

const initialState: ProfileState = {
  searchStatus: SEARCH_STATUS.INSUFFICIENT_AMOUT,
  selectedUser: {
    name: '',
    role: '',
    technicalRole: '',
    seniority: '',
    profilePicture: '',
    id: '',
    email: '',
  },
  listUsers: [],
};

const setUserList = (state: ProfileState, action: PayloadAction<UserState[]>): ProfileState => {
  return { ...state, listUsers: action.payload };
};

const setSearchStatus = (
  state: ProfileState,
  action: PayloadAction<SEARCH_STATUS>
): ProfileState => {
  return {
    ...state,
    searchStatus: action.payload,
  };
};

const addSelectedUser = (state: ProfileState, action: PayloadAction<UserState>): ProfileState => {
  return { ...state, selectedUser: action.payload };
};

const searchUsersList = createAsyncThunk('profile/searchUsersList', async (query: string) => {
  const response = await api.searchUsersList(query);
  return response;
});

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setUserList,
    setSearchStatus,
    addSelectedUser,
  },
  extraReducers: (builder) => {
    builder.addCase(searchUsersList.pending, (state) => {
      return { ...state, searchStatus: SEARCH_STATUS.LOADING, listUsers: [] };
    });

    builder.addCase(searchUsersList.fulfilled, (state, action: PayloadAction<UserState[]>) => {
      const searchStatus =
        action.payload.length === 0 ? SEARCH_STATUS.NONE_FOUND : SEARCH_STATUS.FOUND;
      return { ...state, listUsers: action.payload, searchStatus };
    });
  },
});

export const actions = {
  ...profileSlice.actions,
  searchUsersList,
};

export const selectFoundUsers = (state: RootState): ProfileState => state.profile.users;
export const selectSearchStatusUsers = (state: RootState): SEARCH_STATUS =>
  state.profile.users.searchStatus;

export default profileSlice.reducer;
