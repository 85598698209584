import React from 'react';
import styled from 'styled-components';

import companyLogo from 'assets/10Csignet.svg';

const SignetBg = styled.div`
  position: relative;
  width: 96px;
  height: 96px;
  text-align: center;
  background: rgba(248, 248, 252, 0.24);
  opacity: 0.15;
  box-shadow: inset 0px -10px 10px -24px rgba(255, 255, 255, 0.64), inset 0px 8px 8px -4px #ffffff,
    inset 0px 0px 32px rgba(248, 248, 252, 0.5);
  border-radius: 50%;
`;

const SignetWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SignetImg = styled.img`
  position: absolute;
  user-select: none;
`;

const Signet = (): JSX.Element => {
  return (
    <SignetWrapper>
      <SignetBg />
      <SignetImg src={companyLogo} alt="10C logo" id="tenCShortLogo" />
    </SignetWrapper>
  );
};

export default Signet;
