import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import TagManager from 'react-gtm-module';
import { GoogleOAuthProvider } from '@react-oauth/google';

import ThemeProvider from 'components/ThemeProvider';

import store from 'redux/store';

import App from './App';
import 'normalize.css';
import 'focus-visible';
import 'index.less';

import './i18n';

const PRD_DOMAIN_HOSTNAME = 'team.10clouds.com';

const isProd = Boolean(window.location.hostname === PRD_DOMAIN_HOSTNAME);

const tagManagerArgs = {
  gtmId: isProd ? 'GTM-WHJSMSQ' : 'GTM-KBQMB9P',
};
TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Provider store={store}>
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </Provider>
    </GoogleOAuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
