import React from 'react';
import { Button as AntButton } from 'antd';
import { useTranslation } from 'react-i18next';

import ManSearchInBox from 'assets/png/ManSearchInBox.png';

import {
  Container,
  Content,
  Description,
  SecondaryText,
  Title,
  ButtonLink,
  Image,
} from '../EmptyStates.styles';

interface IFilterMatch {
  clearAllFilters: () => void;
}

const FilterMatch = ({ clearAllFilters }: IFilterMatch): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Container>
      <Content>
        <Image src={ManSearchInBox} alt="No results found" />
        <Description>
          <Title>{t('search-results.empty-states.filterMatch.title')}</Title>
          <SecondaryText>{t('search-results.empty-states.filterMatch.description')}</SecondaryText>
        </Description>
        <ButtonLink as={AntButton} size="large" onClick={() => clearAllFilters()}>
          {t('search-results.empty-states.filterMatch.button')}
        </ButtonLink>
      </Content>
    </Container>
  );
};

export default FilterMatch;
