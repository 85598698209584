import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { queries } from 'shared/layout';

export const Container = styled.div`
  height: auto;
  display: grid;
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const ProfileNavigation = styled.div`
  margin-top: 24px;
  width: 100%;
  max-width: 1600px;
  display: flex;
  gap: 24px;
`;

export const MainWrapper = styled.div`
  width: 100%;
  padding: 40px 32px;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;

  ${queries.desktop} {
    padding: 40px 48px;
  }
`;

export const SectionsContainer = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BreadcrumbsSection = styled.div`
  width: 100%;
  gap: 8px;
  max-width: 1600px;
`;

export const ContentSection = styled.div`
  display: flex;
  margin-top: 16px;
  width: 100%;
  height: 100%;
  max-width: 1600px;
`;

export const Nav = styled.nav`
  display: flex;
  align-items: center;
  min-height: 30px;
  gap: 8px;
`;

export const ProfileNavLink = styled(NavLink)`
  padding: 0 0 15px;
  border-bottom: 1px solid transparent;
  font-size: 15px;
  line-height: 1.47;
  color: #4e4d6c;
  display: flex;
  align-items: center;

  &[aria-current='page'] {
    border-color: ${({ theme }) => theme.colors.text.overline};
    color: ${({ theme }) => theme.colors.text.primary};

    svg {
      color: ${({ theme }) => theme.colors.text.overline};
    }
  }

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colors.text.overline};
  }
`;
