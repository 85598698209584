import { ConfigProvider, Table } from 'antd';
import React, { ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ColumnsType } from 'antd/lib/table';
import { UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { uniqueId } from 'lodash';
import { SorterResult } from 'antd/lib/table/interface';

import {
  LeadAvatar,
  ProjectNameParagraph,
  RoleParagraph,
  SkillNameParagraph,
  SubParagraph,
} from 'components/Table/Table.styles';

import {
  HoverableName,
  TableSpacing,
  Wrapper,
  ResultsContainer,
  SearchResultsCount,
  FiltersContainer,
  FilterBreadcrumb,
  FilterBreadcrumbGroup,
  FilterLevelBreadcrumbGroup,
  FilterLevelBreadcrumb,
  Button,
  BreadcrumbCircle,
  Text,
  SkillsRowLevelContainer,
  SkillsRowLevelGroup,
  SkillsRowLevelIcon,
} from 'features/SearchResults/pages/SearchResults/SearchResults.styles';
import { QueryType } from 'features/SearchResults/pages/SearchResults/SearchResults';
import Pagination from 'features/SearchResults/components/Pagination/Pagination';
import {
  actions as skillsActions,
  selectSkillAssignmentsList,
  selectSearchStatusAssignmentsList,
  selectSkillAssignmentsPagination,
} from 'features/SearchResults/redux/skillsAssignmentSlice';
import { ReactComponent as IconTimes } from 'assets/iconTimes.svg';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_PAGE_SIZE_SEARCH,
} from 'features/SearchResults/utils/constants/pagination';
import SkillFilters, { FiltersType } from 'features/SearchResults/components/Filters/SkillFilters';
import { getSkillFiltersNames } from 'features/SearchResults/utils/helpers/getFiltersNames';
import { useAppDispatch } from 'redux/store';
import { useWindowResize } from 'features/Profile/hooks/useWindowResize';
import { FilterMatch } from 'features/SearchResults/components/EmptyStates';
import { SEARCH_STATUS } from 'utils/helpers/searchStatus';
import { OrderingType, SkillMatrixField, SkillsOrderInput, SkillsWithAssignments } from 'API';
import { ReactComponent as Beginner } from 'assets/Beginner.svg';
import { ReactComponent as Intermediate } from 'assets/Intermediate.svg';
import { ReactComponent as Expert } from 'assets/Expert.svg';
import { getLanguageFlag } from 'utils/helpers/getLanguageFlag';

export interface ISkillFilerBreadcrumb {
  name: string;
  checkedArray?: boolean[];
  title?: string;
}

const getSkillLevelIcon = (skillLvl: string): JSX.Element | string => {
  if (skillLvl && skillLvl.toLowerCase() === 'expert') {
    return <Expert />;
  }
  if (skillLvl && skillLvl.toLowerCase() === 'intermediate') {
    return <Intermediate />;
  }
  return <Beginner />;
};

const SearchSkills: React.FC = () => {
  const { t } = useTranslation();
  const filtersNames = getSkillFiltersNames(t);
  const [currentPage, setCurrentPage] = useState<number>(DEFAULT_CURRENT_PAGE);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE_SEARCH);
  const [filtersValues, setFiltersValues] = useState<FiltersType>(filtersNames);
  const [ordering, setOrdering] = useState<SkillsOrderInput | undefined>(undefined);
  const dispatch = useAppDispatch();
  const skills = useSelector(selectSkillAssignmentsList);
  const searchStatus = useSelector(selectSearchStatusAssignmentsList);
  const paginationState = useSelector(selectSkillAssignmentsPagination);
  const [total, setTotal] = useState<number>(paginationState.totalPages * paginationState.pageSize);
  const breakpoint = 1366;
  const { width } = useWindowResize();

  const handlerSorting = (
    sorter: SorterResult<SkillsWithAssignments> | SorterResult<SkillsWithAssignments>[]
  ) => {
    if (sorter && 'field' in sorter && 'order' in sorter) {
      if (sorter.order === undefined) return;
      const order = sorter.order === 'ascend' ? OrderingType.Ascending : OrderingType.Descending;
      const { field } = sorter;
      setOrdering({ field: field as SkillMatrixField, order });
      const query: QueryType = {
        filtersValues,
        pagination: { limit: pageSize, offset: currentPage * pageSize - pageSize },
        ordering: {
          field:
            field === 'seniority'
              ? (field.toUpperCase() as SkillMatrixField)
              : (field as SkillMatrixField),
          order,
        },
      };
      dispatch(skillsActions.fetchSkills(query));
    }
  };

  useEffect(() => {
    setTotal(paginationState.totalPages * paginationState.pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationState]);

  useEffect(() => {
    const query: QueryType = {
      filtersValues,
      pagination: { limit: pageSize, offset: currentPage * pageSize - pageSize },
      ordering,
    };
    dispatch(skillsActions.fetchSkills(query));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersValues, pageSize, currentPage]);

  const filtersNamesValues = (
    items: FiltersType
  ): { name: string; checkedArray?: boolean[]; title?: string }[] =>
    items
      .map((item) => item.filters.map((elem) => ({ ...elem, title: item.title })))
      .flat(1)
      .filter((elem) => elem.checkbox === true)
      .map((obj) => ({
        name: obj.name,
        checkedArray: obj.checkedArray ?? [false, false, false],
        title: obj.title,
      }));

  const countFiltersActive = filtersNamesValues(filtersValues).length;

  const clearAllFilters = (): void => {
    const newValue = filtersValues.reduce<FiltersType>((acc, item) => {
      acc.push({
        title: item.title,
        filters: item.filters.map((elem) => ({
          name: elem.name,
          checkbox: false,
          checkedArray: [false, false, false],
        })),
      });
      return acc;
    }, []);

    setFiltersValues(newValue);
    setCurrentPage(DEFAULT_CURRENT_PAGE);
  };

  const searchResultsHeaders: ColumnsType<SkillsWithAssignments> = [
    {
      dataIndex: SkillMatrixField.SkillName,
      title: width > breakpoint ? t('skills-search.skill-name') : t('skills-search.skill'),
      render: (_: string, { skillName, skillType }): JSX.Element => (
        <SkillNameParagraph>
          <RoleParagraph ellipsis={{ tooltip: true }}>{skillName}</RoleParagraph>
          <SubParagraph ellipsis={{ tooltip: true }}>{skillType.replace('_', ' ')}</SubParagraph>
        </SkillNameParagraph>
      ),
      sorter: true,
    },
    {
      dataIndex: SkillMatrixField.UserName,
      title: width > breakpoint ? t('skills-search.employee-name') : t('search-results.name'),
      render: (_: string, { profilePicture, name, ownerId }): JSX.Element => (
        <ProjectNameParagraph>
          <LeadAvatar size={32} icon={<UserOutlined />} src={profilePicture} />
          <Link to={`/profile/${ownerId}`}>
            <HoverableName>{name}</HoverableName>
          </Link>
        </ProjectNameParagraph>
      ),
      sorter: true,
    },
    {
      dataIndex: 'seniority',
      title: t('search-results.seniority'),
      sorter: true,
    },
    {
      dataIndex: SkillMatrixField.TechnicalRole,

      title:
        width > breakpoint
          ? t('search-results.technical-role')
          : t('search-results.technical-role-short'),
      render: (_: string, { technicalRole }): JSX.Element => (
        <RoleParagraph ellipsis={{ tooltip: true }}>{technicalRole}</RoleParagraph>
      ),
      sorter: true,
    },
    {
      dataIndex: SkillMatrixField.SkillLvl,
      width: '20%',
      title: width > breakpoint ? t('skills-search.knowledge-level') : t('skills-search.level'),
      render: (_: string, { skillLvl, languageCode }): JSX.Element => (
        <SkillsRowLevelContainer>
          <SkillsRowLevelGroup>
            <SkillsRowLevelIcon>
              {languageCode === '' ? getSkillLevelIcon(skillLvl) : getLanguageFlag(languageCode)}
            </SkillsRowLevelIcon>
            <span>{skillLvl}</span>
          </SkillsRowLevelGroup>
        </SkillsRowLevelContainer>
      ),
      sorter: true,
    },
  ];

  const shouldShowSearchNameBreadcrumb =
    !!countFiltersActive &&
    (searchStatus === SEARCH_STATUS.FOUND || searchStatus === SEARCH_STATUS.NONE_FOUND);
  const removeFilter = (filter: ISkillFilerBreadcrumb): void => {
    const newValue = filtersValues.reduce<FiltersType>((acc, item) => {
      acc.push({
        title: item.title,
        filters: item.filters.map((elem) => {
          return elem.name === filter.name
            ? { name: elem.name, checkbox: false, checkedArray: [false, false, false] }
            : { name: elem.name, checkbox: elem.checkbox, checkedArray: elem.checkedArray };
        }),
      });
      return acc;
    }, []);

    setFiltersValues(newValue);
  };

  const getBreadcrumbs = (array: boolean[] | undefined, title?: string): ReactNode => {
    const returnArray = [];
    if (array && array.every((elem) => elem === true)) {
      return (
        <FilterLevelBreadcrumb className="exclude" key={uniqueId('filter_')}>
          <Text>{t('skills-matrix.skill-experience.all')}</Text>
        </FilterLevelBreadcrumb>
      );
    }

    if (title && title === 'technical skill') {
      const skillLevels = [
        { key: 'expert', text: t('skills-matrix.skill-experience.expert') },
        { key: 'intermediate', text: t('skills-matrix.skill-experience.intermediate') },
        { key: 'beginner', text: t('skills-matrix.skill-experience.beginner') },
      ];

      const filterLevelBreadcrumbs = skillLevels.map((level, i) => {
        if (array && array[i]) {
          returnArray.push(
            <FilterLevelBreadcrumb className="exclude" key={uniqueId(`filter_${level.key}`)}>
              <Text>{level.text}</Text>
            </FilterLevelBreadcrumb>
          );
        }
        return null;
      });

      returnArray.push(filterLevelBreadcrumbs);
    } else if (title && title === 'language skill') {
      const skillLevels = [
        { key: 'native-speaker', text: t('skills-matrix.CEFR.native') },
        { key: 'C2', text: t('skills-matrix.CEFR.C2-short') },
        { key: 'C1', text: t('skills-matrix.CEFR.C1-short') },
        { key: 'B2', text: t('skills-matrix.CEFR.B2-short') },
        { key: 'B1', text: t('skills-matrix.CEFR.B1-short') },
        { key: 'A2', text: t('skills-matrix.CEFR.A2-short') },
        { key: 'A1', text: t('skills-matrix.CEFR.A1-short') },
      ];

      const filterLevelBreadcrumbs = skillLevels.map((level, i) => {
        if (array && array[i]) {
          returnArray.push(
            <FilterLevelBreadcrumb className="exclude" key={uniqueId(`filter_${level.key}`)}>
              <Text>{level.text}</Text>
            </FilterLevelBreadcrumb>
          );
        }
        return null;
      });

      returnArray.push(filterLevelBreadcrumbs);
    }
    return returnArray;
  };

  return (
    <Wrapper>
      <ResultsContainer>
        <SearchResultsCount>{t('search-results.search-results')}</SearchResultsCount>
        {shouldShowSearchNameBreadcrumb && (
          <FiltersContainer>
            {filtersNamesValues(filtersValues).map((filter: ISkillFilerBreadcrumb) => (
              <FilterBreadcrumbGroup key={filter.name}>
                <FilterBreadcrumb onClick={() => removeFilter(filter)}>
                  <BreadcrumbCircle>
                    <IconTimes />
                  </BreadcrumbCircle>
                  <Text>{filter.name}</Text>
                </FilterBreadcrumb>
                <FilterLevelBreadcrumbGroup>
                  {getBreadcrumbs(filter.checkedArray, filter.title)}
                </FilterLevelBreadcrumbGroup>
              </FilterBreadcrumbGroup>
            ))}
            <Button onClick={clearAllFilters}>{t('search-results.clear-all')}</Button>
          </FiltersContainer>
        )}
        {searchStatus !== SEARCH_STATUS.NONE_FOUND && (
          <>
            {' '}
            <TableSpacing>
              <ConfigProvider>
                <Table
                  loading={searchStatus === SEARCH_STATUS.LOADING}
                  dataSource={skills}
                  rowKey="entryKey"
                  pagination={false}
                  columns={searchResultsHeaders}
                  onChange={(val, filter, sorter) => {
                    handlerSorting(sorter);
                  }}
                />
              </ConfigProvider>
            </TableSpacing>
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              setPageSize={setPageSize}
              amountItems={pageSize}
              total={total}
            />
          </>
        )}

        {searchStatus === SEARCH_STATUS.NONE_FOUND && (
          <FilterMatch clearAllFilters={clearAllFilters} />
        )}
      </ResultsContainer>
      <SkillFilters
        values={filtersValues}
        setFiltersValues={setFiltersValues}
        activeFilters={countFiltersActive}
        setCurrentPage={setCurrentPage}
      />
    </Wrapper>
  );
};

export default SearchSkills;
