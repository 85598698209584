import React, { useEffect, useMemo, useRef } from 'react';
import _debounce from 'lodash/debounce';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Tooltip } from 'antd';

import AutocompleteFilter, { OptionElement } from 'components/AutocompleteFilter';
import { OnSelectCallback, VARIANT } from 'components/AutocompleteFilter/types';
import UserAvatar from 'components/UserAvatar/UserAvatar';

import { useAppDispatch } from 'redux/store';
import {
  selectFoundUsers,
  selectSearchStatusUsers,
  actions as profileActions,
} from 'features/Profile/redux/profileSlice';
import { DEFAULT_DEBOUNCE_TIME, MIN_SEARCH_QUERY_LENGTH } from 'utils/constants';
import { searchStatusToComponentMap, SEARCH_STATUS } from 'utils/helpers/searchStatus';
import { ReactComponent as IconBell } from 'assets/iconBell.svg';
import { ReactComponent as IconBellActive } from 'assets/iconBellActive.svg';
import { ReactComponent as IconBug } from 'assets/bugReport.svg';
import { ReactComponent as IconSearch } from 'assets/iconSearch.svg';
import { useAuth } from 'providers/AuthProvider';
import TooltipContent from 'features/TeamLeadView/components/TooltipContent/TooltipContent';
import { selectSkills, actions } from 'features/Profile/redux/skillSlice';

import {
  IconButton,
  ProfileGroup,
  SearchGroup,
  TopBar,
  SearchBox,
  SearchInput,
  IconButtons,
  IconButtonWrapper,
} from './ProfileTopBar.styles';

interface ProfileTopBarProps {
  profilePicture: string | undefined;
  variant?: VARIANT;
  searchName?: string;
  setSearchName?: React.Dispatch<React.SetStateAction<string>> | null;
}

const ProfileTopBar = ({
  profilePicture,
  variant,
  searchName,
  setSearchName,
}: ProfileTopBarProps): JSX.Element => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(actions.checkIfSkillsInReview());
  }, [dispatch]);
  const { hasSkillsInReview } = useSelector(selectSkills);
  const { t } = useTranslation();
  const { currentUser } = useAuth();
  const { name: currentUserName = '' } = currentUser ?? {};
  const searchPromiseRef = useRef<ReturnType<typeof dispatch> | null>(null);
  const searchUsers = useSelector(selectFoundUsers);
  const searchStatus = useSelector(selectSearchStatusUsers);
  const navigate = useNavigate();

  const optionElements: OptionElement[] = searchUsers.listUsers.map((searchUser) => {
    const { id, name, role, technicalRole, seniority, profilePicture: avatar } = searchUser;
    return {
      value: id,
      name,
      position: role,
      technicalRole,
      seniority,
      profilePicture: avatar,
    };
  });

  const handleOptionElement: OnSelectCallback = (value) => {
    const selectedUser = searchUsers.listUsers.find((user) => user.id === value);
    if (selectedUser) {
      dispatch(profileActions.addSelectedUser(selectedUser));
      dispatch(profileActions.setUserList([]));
      dispatch(profileActions.setSearchStatus(SEARCH_STATUS.INSUFFICIENT_AMOUT));
      navigate(`/profile/${selectedUser.id}`);
    }
  };

  const debouncedSearch = useMemo(() => {
    const search = (query: string): void => {
      const promise = searchPromiseRef.current as { abort: () => void };
      promise?.abort();
      if (query.length >= MIN_SEARCH_QUERY_LENGTH) {
        searchPromiseRef.current = dispatch(profileActions.searchUsersList(query));
      } else {
        dispatch(profileActions.setSearchStatus(SEARCH_STATUS.INSUFFICIENT_AMOUT));
      }
    };

    return _debounce(search, DEFAULT_DEBOUNCE_TIME);
  }, [dispatch]);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const getParentEl = (node: HTMLElement) => node.parentElement!;

  return (
    <TopBar id="area-profile">
      {setSearchName ? (
        <SearchBox>
          <IconSearch style={{ position: 'absolute', left: '18px' }} />

          <SearchInput
            bordered={false}
            allowClear
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
            placeholder={t('navigation.search-by-person')}
          />
        </SearchBox>
      ) : (
        <SearchGroup id="searchBox">
          <IconSearch style={{ position: 'absolute', left: '18px' }} />
          <AutocompleteFilter
            options={optionElements}
            onSelect={handleOptionElement}
            onSearch={debouncedSearch}
            notFoundContent={searchStatusToComponentMap(t)[searchStatus]}
            getPopupContainer={(node) => getParentEl(node)}
            variant={variant}
          />
        </SearchGroup>
      )}
      <ProfileGroup>
        <IconButtons>
          <IconButtonWrapper>
            <a
              href="https://10clouds.atlassian.net/servicedesk/customer/portal/16/group/36/create/189"
              target="_blank"
              rel="noreferrer"
              id="bugLink"
            >
              <IconButton type="button">
                <Tooltip title={t('report-bug')} placement="bottom">
                  <IconBug />
                </Tooltip>
              </IconButton>
            </a>
          </IconButtonWrapper>
          <IconButtonWrapper>
            {hasSkillsInReview ? (
              <Tooltip placement="bottom" title={<TooltipContent type="skill_requests" />}>
                <IconButton type="button" id="bellButton">
                  <IconBellActive />
                </IconButton>
              </Tooltip>
            ) : (
              <IconButton disabled type="button" id="bellButton">
                <IconBell />
              </IconButton>
            )}
          </IconButtonWrapper>
        </IconButtons>
        <div>
          <Link to={`/profile/${currentUser?.id}`}>
            <UserAvatar size={44} src={profilePicture} name={currentUserName} id="userAvatar" />
          </Link>
        </div>
      </ProfileGroup>
    </TopBar>
  );
};

export default ProfileTopBar;
