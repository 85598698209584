import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SkillsWithAssignments } from 'API';
import { RootState } from 'redux/rootReducer';
import { SEARCH_STATUS } from 'utils/helpers/searchStatus';
import api, { NewSkillsWithAssignments } from 'features/SearchResults/api';

import { QueryType } from '../pages/SearchResults/SearchResults';

export interface ModalState {
  array: boolean[];
}
export interface SkillObject {
  skillName: string;
  skillLevel: string;
  skillType: string;
}
export interface SkillAssignment {
  skillAssignments: SkillObject[];
  name: string;
  profilePicture: string | null;
  seniority: string;
  technicalRole: string;
  ownerId: string;
}
export interface FlatAssignment {
  skillName: string;
  skillLevel: string;
  skillType: string;
  name: string;
  seniority: string;
  technicalRole: string;
  profilePicture: string | null;
  ownerId: string;
}

interface PaginationState {
  pageSize: number;
  currentPage: number;
  totalPages: number;
}

interface SkillsState {
  items: Array<SkillsWithAssignments>;
  searchStatus: SEARCH_STATUS;
  skillModal: ModalState;
  pagination: PaginationState;
}

const initialState: SkillsState = {
  items: [],
  searchStatus: SEARCH_STATUS.LOADING,
  skillModal: { array: [false, false, false] },
  pagination: { currentPage: 0, pageSize: 0, totalPages: 0 },
};

const setSearchStatus = (state: SkillsState, action: PayloadAction<SEARCH_STATUS>): SkillsState => {
  return {
    ...state,
    searchStatus: action.payload,
  };
};

const setModal = (state: SkillsState, action: PayloadAction<ModalState>): SkillsState => {
  return {
    ...state,
    skillModal: { array: action.payload.array },
  };
};

const fetchSkills = createAsyncThunk(
  'seachResults/fetchSkills',
  async (query: QueryType): Promise<NewSkillsWithAssignments> => {
    const response = await api.getSkillAssigmentsList(query);
    return response;
  }
);

const skillsAssignmentSlice = createSlice({
  name: 'skillsAssignment',
  initialState,
  reducers: {
    setSearchStatus,
    setModal,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSkills.pending, (state) => {
      return { ...state, items: [], searchStatus: SEARCH_STATUS.LOADING };
    });
    builder.addCase(
      fetchSkills.fulfilled,
      (state, action: PayloadAction<NewSkillsWithAssignments>) => {
        const searchStatus =
          action.payload.skillsAssignments.length === 0
            ? SEARCH_STATUS.NONE_FOUND
            : SEARCH_STATUS.FOUND;
        return {
          ...state,
          items: action.payload.skillsAssignments,
          searchStatus,
          pagination: {
            pageSize: action.payload.pageSize,
            currentPage: action.payload.currentPage,
            totalPages: action.payload.totalPages,
          },
        };
      }
    );
  },
});

export const actions = {
  ...skillsAssignmentSlice.actions,
  fetchSkills,
};

export const selectSkillAssignmentsList = (state: RootState): Array<SkillsWithAssignments> =>
  state.searchResults.skillAssignments.items;
export const selectSkillAssignmentsPagination = (state: RootState): PaginationState =>
  state.searchResults.skillAssignments.pagination;
export const selectSearchStatusAssignmentsList = (state: RootState): SEARCH_STATUS =>
  state.searchResults.skillAssignments.searchStatus;
export const selectSkillAssignmentsModalState = (state: RootState): Array<boolean> =>
  state.searchResults.skillAssignments.skillModal.array;

export default skillsAssignmentSlice.reducer;
