import React from 'react';
import { Spin } from 'antd';
import { TFunction } from 'i18next';
import styled from 'styled-components';

import { ReactComponent as QuestionMark } from 'assets/searchQuestionIcon.svg';

export enum SEARCH_STATUS {
  LOADING,
  NONE_FOUND,
  FOUND,
  IDLE,
  INSUFFICIENT_AMOUT,
}

type StatusToComponentMap = {
  [KEY in SEARCH_STATUS]?: JSX.Element | string | null;
};

const InsufficientAmount = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  max-height: 22px;
  color: ${({ theme }) => theme.colors.text.secondary};
  padding: 0px 4px;
  gap: 14px;
`;

export const searchStatusToComponentMap = (
  t: TFunction,
  timeOff?: boolean
): StatusToComponentMap => ({
  [SEARCH_STATUS.LOADING]: <Spin />,
  [SEARCH_STATUS.IDLE]: null,
  [SEARCH_STATUS.NONE_FOUND]: t('time-off.search.no-users-found'),
  [SEARCH_STATUS.INSUFFICIENT_AMOUT]: (
    <InsufficientAmount style={{ marginBottom: timeOff ? '16px' : 'unset' }}>
      {!timeOff && <QuestionMark />}
      {t('time-off.search.insufficient-signs-amount')}
    </InsufficientAmount>
  ),
});
