import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { debounce, uniqueId } from 'lodash';

import { ReactComponent as IconSearch } from 'assets/iconSearch.svg';
import { ReactComponent as ExternalIcon } from 'assets/external.svg';
import { ReactComponent as RightIcon } from 'assets/Category_right.svg';
import { ReactComponent as LeftIcon } from 'assets/Category_left.svg';
import { ReactComponent as CloseIcon } from 'assets/Category_close.svg';
import { selectSkills, actions } from 'features/Profile/redux/skillSlice';
import { useAppDispatch } from 'redux/store';
import { Skill } from 'API';

import {
  SearchInput,
  ColumnContainer,
  Column,
  SubTitle,
  SubTitleContainer,
  OutlineButton,
  TagButton,
  TagsContainer,
  SkillText,
  SkillsWrapper,
  HeaderWrapper,
  RightIconContainer,
  LeftIconContainer,
  CloseIconContainer,
  BgBlur,
  EmptyText,
  SkeletonSkill,
} from './SkillSelection.styles';
import SkillButton from '../SkillButton';
import SkillList from '../SkillList';

interface SkillSelectionProps {
  onSkillClick: (skill: string, languageCode: string, levelSelected?: string) => void;
}
interface Categories {
  title: string;
  name: string;
}

interface Filter {
  search: string;
  category: Categories[];
}

const SkillSelection = ({ onSkillClick }: SkillSelectionProps): JSX.Element => {
  const skillCategories = [
    { title: 'MOBILE', name: 'Mobile' },
    { title: 'WEB', name: 'WEB FE & BE' },
    { title: 'DEVOPS', name: 'DevOps' },
    { title: 'QA', name: 'QA' },
    { title: 'DESIGN', name: 'Design' },
    { title: 'DEFI', name: 'DeFi' },
    { title: 'GENERAL', name: 'General' },
    { title: 'PDM', name: 'PDM' },
  ];
  const dispatch = useAppDispatch();
  const { listChosenSkills, listTechnicalSkills, listLanguageSkills, skillModal, isLoading } =
    useSelector(selectSkills);
  const { t } = useTranslation();
  const [categoryPlacememnt, setCategoryPlacement] = useState<number>(0);
  const [categoryStyle, setCategoryStyle] = useState<string>('');
  const [searchName, setSearchName] = useState<string>('');
  const [filteredSkills, setFilteredSkills] = useState<Skill[]>([]);
  const [skillsByType, setSkillsByType] = useState<Skill[]>([]);
  const [categories, setCategories] = useState<Categories[]>(skillCategories);
  const suggestNewSkillLink =
    'https://10clouds.atlassian.net/servicedesk/customer/portal/16/group/36/create/221';
  const excludedSkills = skillsByType.filter((skill) => {
    return listChosenSkills.assignments.every((skillsChosed) => {
      return skill.name !== skillsChosed.name;
    });
  });

  const filterSkills = debounce((filter: Filter) => {
    setSearchName(filter.search);
    setFilteredSkills(
      excludedSkills.filter((skill: Skill) => {
        if (filter.category.length === 1 && filter.search !== undefined) {
          return (
            skill.categories.includes(filter.category[0].name) &&
            skill.name.toLowerCase().includes(filter.search.toLowerCase())
          );
        }
        if (filter.category.length === 1) {
          return skill.categories.find((category: string) => category === filter.category[0].name);
        }
        if (filter.search !== undefined) {
          return skill.name.toLowerCase().includes(filter.search.toLowerCase());
        }
        return skill;
      })
    );
  }, 200);

  const handleCategory = (obj: Categories[]) => {
    setCategories(obj);
    setCategoryStyle('active');
    setCategoryPlacement(-1);
    filterSkills({ category: obj, search: searchName });
  };

  const handleCloseCategory = () => {
    setCategories(skillCategories);
    setCategoryStyle('');
    setCategoryPlacement(0);
    filterSkills({ category: skillCategories, search: searchName });
  };

  useEffect(() => {
    if (categoryPlacememnt === 1) {
      setCategoryStyle('middle');
    }
    if (categoryPlacememnt === 2) {
      setCategoryStyle('right');
    }
    if (categoryPlacememnt === 0) {
      setCategoryStyle('');
    }
  }, [categoryPlacememnt]);

  const prevSkillsByType = useRef<Skill[]>([]);
  useEffect(() => {
    if (skillModal.content === 0 && listTechnicalSkills.skills.length === 0 && !isLoading) {
      dispatch(actions.fetchSkillList('TECHNICAL_SKILL'));
    } else if (skillModal.content === 1 && listLanguageSkills.skills.length === 0 && !isLoading) {
      dispatch(actions.fetchSkillList('LANGUAGE_SKILL'));
    }
    if (skillModal.content === 0 && listTechnicalSkills.skills !== prevSkillsByType.current) {
      setSkillsByType(listTechnicalSkills.skills);
    } else if (skillModal.content === 1 && listLanguageSkills.skills !== prevSkillsByType.current) {
      setSkillsByType(listLanguageSkills.skills);
    }
    prevSkillsByType.current = skillsByType;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillModal.content, listTechnicalSkills.skills, listLanguageSkills.skills, dispatch]);

  return (
    <ColumnContainer>
      <Column left>
        <HeaderWrapper technical={skillModal.content === 0 ? 'technical' : undefined}>
          <SubTitleContainer>
            <SubTitle level={2}>{t('skills-matrix.add-skills.find-skills')}</SubTitle>
            <OutlineButton target="_blank" href={suggestNewSkillLink}>
              <ExternalIcon />
              {t('skills-matrix.add-skills.request-new')}
            </OutlineButton>
          </SubTitleContainer>
          <SearchInput
            onChange={(e) => filterSkills({ search: e.target.value, category: categories })}
            allowClear
            prefix={<IconSearch />}
            placeholder={t('skills-matrix.add-skills.search')}
          />
          {searchName.length > 0 && filteredSkills.length === 0 ? (
            <EmptyText>{t('filters.empty-search')}</EmptyText>
          ) : (
            <>
              {skillModal.content === 0 && (
                <TagsContainer>
                  {categories.length === 1 && (
                    <CloseIconContainer>
                      <CloseIcon onClick={handleCloseCategory} />
                    </CloseIconContainer>
                  )}
                  {categoryPlacememnt > 0 && (
                    <>
                      <LeftIconContainer
                        onClick={() => {
                          setCategoryPlacement((prev) => prev - 1);
                        }}
                      >
                        <LeftIcon />
                      </LeftIconContainer>
                      <BgBlur />
                    </>
                  )}
                  {categories.map((category) => {
                    return (
                      <TagButton
                        key={category.name}
                        onClick={() => {
                          handleCategory([{ title: category.title, name: category.name }]);
                        }}
                        className={categoryStyle}
                      >
                        {category.title}
                      </TagButton>
                    );
                  })}
                  {categoryPlacememnt < 2 && categoryPlacememnt !== -1 && (
                    <>
                      <RightIconContainer
                        onClick={() => {
                          setCategoryPlacement((prev) => prev + 1);
                        }}
                      >
                        <RightIcon />
                      </RightIconContainer>
                      <BgBlur />
                    </>
                  )}
                </TagsContainer>
              )}
              <SkillText language={skillModal.content === 1 ? 'language' : undefined}>
                {t('skills-matrix.add-skills.select-skill-and-level')}
              </SkillText>
            </>
          )}
        </HeaderWrapper>
        <div>
          <SkillsWrapper technical={skillModal.content === 0 ? 'technical' : undefined}>
            {isLoading && (
              <>
                {Array(5)
                  .fill(true)
                  .map(() => (
                    <SkeletonSkill active key={uniqueId()} />
                  ))}
              </>
            )}
            {filteredSkills.length === 0 && searchName.length === 0
              ? excludedSkills.map((skill) => {
                  return (
                    <SkillButton
                      key={skill.name}
                      label={skill.name}
                      onClick={() => {
                        onSkillClick(skill.name, skill.languageCode);
                      }}
                    />
                  );
                })
              : filteredSkills.map((skill) => {
                  return (
                    <SkillButton
                      key={skill.name}
                      label={skill.name}
                      onClick={() => {
                        onSkillClick(skill.name, skill.languageCode);
                      }}
                    />
                  );
                })}
          </SkillsWrapper>
        </div>
      </Column>
      <Column isEmpty right>
        <SkillList handleSkillEdit={onSkillClick} />
      </Column>
    </ColumnContainer>
  );
};

export default SkillSelection;
