import { parseISO } from 'date-fns';
import _ from 'lodash';

import { PrivateTimeOffRecord, PtoStatus, PublicTimeOffRecord, TimeOffType } from 'API';

import { workingDaysBetweenDates } from '../utils/dateUtils';

export type TimeOff = {
  id: string;
  startDate: string;
  endDate: string;
  jiraTicketLink: string;
  status: PtoStatus;
  type?: TimeOffType;
  subtype?: string | null | undefined;
};

export type TimeOffDates = {
  id: string;
  startDate: string;
  endDate: string;
  jiraTicketLink: string;
  status: PtoStatus;
  type?: TimeOffType;
  subtype?: string | null | undefined;
}[];

const mergeTimeOffRecords = (
  dates: Array<PrivateTimeOffRecord | PublicTimeOffRecord>
): TimeOffDates => {
  if (dates.length === 0) return [];
  const sortedDates: Array<PrivateTimeOffRecord | PublicTimeOffRecord> = _.orderBy(
    dates as Array<PrivateTimeOffRecord | PublicTimeOffRecord>,
    'date',
    'asc'
  );

  const groups: Array<PrivateTimeOffRecord | PublicTimeOffRecord>[] = [];
  let currentGroup: Array<PrivateTimeOffRecord | PublicTimeOffRecord> = [];
  sortedDates.forEach((date) => {
    if (currentGroup.length === 0) {
      currentGroup.push(date);
      return;
    }
    const diff = workingDaysBetweenDates(
      parseISO(currentGroup[currentGroup.length - 1].date),
      parseISO(date.date)
    );
    if (diff <= 2) {
      currentGroup.push(date);
      return;
    }
    groups.push([...currentGroup]);
    currentGroup = [date];
  });

  if (currentGroup.length > 0) {
    groups.push([...currentGroup]);
  }

  return groups.map((group: Array<PrivateTimeOffRecord | PublicTimeOffRecord>) =>
    'subtype' in group[0] && 'type' in group[0]
      ? {
          id: group[0].id,
          startDate: group[0].date,
          endDate: group[group.length - 1].date,
          jiraTicketLink: group[0]?.jiraTicketLink,
          status: group[0].status,
          type: group[0].type,
          subtype: group[0].subtype,
        }
      : {
          id: group[0].id,
          startDate: group[0].date,
          endDate: group[group.length - 1].date,
          jiraTicketLink: group[0]?.jiraTicketLink,
          status: group[0].status,
        }
  );
};

export default mergeTimeOffRecords;
