import React from 'react';
import styled from 'styled-components';

import style from 'features/TimeOff/constants/style';
import { LOADING_TIMEOFF_DAYS } from 'features/TimeOff/constants/loading';

import TimeOffElementLoading from '../TimeOffElement/Loading';

const TimeOffRowWrapper = styled.div`
  position: relative;
  height: ${style.timeOffRow.height}px;
`;

export interface TimeOffRowLoadingProps {
  startDate: Date;
  scrollLeft?: number;
}

const TimeOffRowLoading = ({ startDate, scrollLeft }: TimeOffRowLoadingProps): JSX.Element => {
  return (
    <TimeOffRowWrapper>
      {Array(1)
        .fill(0)
        .map((_, idx) => {
          return (
            <TimeOffElementLoading
              scrollLeft={scrollLeft}
              // eslint-disable-next-line react/no-array-index-key
              key={idx}
              startDate={startDate}
              days={LOADING_TIMEOFF_DAYS}
            />
          );
        })}
    </TimeOffRowWrapper>
  );
};

export default TimeOffRowLoading;
