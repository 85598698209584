import React from 'react';
import type { AvatarProps } from 'antd';
import styled from 'styled-components';
import { Avatar } from 'antd';

import getInitials from 'utils/helpers/getInitials';

const StyledAvatar = styled(Avatar)`
  &:not(.ant-avatar-image) {
    color: ${({ theme }) => theme.colors.white};
  }

  .ant-avatar-string {
    font: ${({ theme }) => theme.typography.paragraph.font};
    letter-spacing: ${({ theme }) => theme.typography.paragraph.letterSpacing};
  }
`;

export interface UserAvatarProps extends AvatarProps {
  profilePicture?: string | null;
  name: string;
  id?: string;
}

const UserAvatar = ({ profilePicture, name, ...avatarProps }: UserAvatarProps): JSX.Element => {
  return (
    <StyledAvatar src={profilePicture} {...avatarProps}>
      {name && getInitials(name, '')}
    </StyledAvatar>
  );
};

export default UserAvatar;
