import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { add } from 'date-fns';

import { Timestamp } from 'types/date';
import { dateToLastSeptember, dateToTimestamp } from 'features/TimeOff/utils/dateUtils';
import { RootState } from 'redux/rootReducer';

interface CalendarRangeState {
  startDate: Timestamp;
  endDate: Timestamp;
}

const initialState: CalendarRangeState = {
  startDate: dateToLastSeptember(new Date()),
  endDate: dateToTimestamp(add(new Date(), { months: 13 })),
};

const calendarRangeSlice = createSlice({
  name: 'calendarRange',
  initialState,
  reducers: {
    setCalendarRange: (state, action: PayloadAction<CalendarRangeState>): CalendarRangeState => {
      return { ...state, ...action.payload };
    },
  },
});
export const actions = { ...calendarRangeSlice.actions };

export const selectCalendarRange = (state: RootState): CalendarRangeState =>
  state.calendar.calendarRange;

export default calendarRangeSlice.reducer;
