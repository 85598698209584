import { PrivateUser } from 'API';
import generateGraphqlCaller from 'utils/helpers/generateGraphqlCaller';

const query = `
query queryMyTeamSkills {
    getOwnUser {
      ... on PrivateUser {
        team
        teamMembers {
          ... on TeamMembersResult {
            members {
              id
              name  
              profilePicture
              skillAssignments {
                assignments {
                  langCode
                  lvl
                  name
                  type
                  updated
                  status
                }
              }
            }
          }
        }
      }
    }
  }
  `;

export type ReturnType = {
  getOwnUser: {
    teamMembers: {
      members: Pick<PrivateUser, 'skillAssignments' | 'name' | 'id' | 'profilePicture'>[];
    };
  };
};

export default generateGraphqlCaller<ReturnType>(query);
