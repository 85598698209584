/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Select as AntSelect } from 'antd';
import { useTranslation } from 'react-i18next';
import { SelectValue } from 'antd/lib/select';
import { NavLink as RouterNavLink, useNavigate } from 'react-router-dom';

import { ThemeProps } from 'components/ThemeProvider/ThemeProvider';
import UserAvatar from 'components/UserAvatar/UserAvatar';

import { useAppDispatch } from 'redux/store';
import { ReactComponent as Arrow } from 'assets/Chevron.svg';
import { ReactComponent as IconDirectory } from 'assets/iconDirectory.svg';
import { actions } from 'features/Profile/redux/profileSlice';
import { actions as timesOffActions } from 'features/TimeOff/redux/timesOffSlice';
import { SEARCH_STATUS } from 'utils/helpers/searchStatus';

import {
  AutocompleteFilterProps,
  OptionElement,
  AutocompleteOptionProps,
  OnSelectCallback,
  VARIANT,
} from './types';

const Select = styled(AntSelect)<{ variant: VARIANT }>`
  .ant-select-selection {
    background-color: transparent;
  }

  &.ant-select {
    width: 100%;
    font: ${({ theme }: ThemeProps) => theme.typography.body};
    color: ${({ theme }: ThemeProps) => theme.colors.text.primary};

    .ant-select-selection-search-input,
    .ant-select-selection-search,
    .ant-select-selection-item {
      font: ${({ theme }: ThemeProps) => theme.typography.body};
      color: ${({ theme }: ThemeProps) => theme.colors.text.primary};
    }
    .ant-select-selection-placeholder {
      font: ${({ theme }: ThemeProps) => theme.typography.paragraph.font};
      letter-spacing: ${({ theme }: ThemeProps) => theme.typography.paragraph.letterSpacing};
      color: ${({ theme }: ThemeProps) => theme.colors.gray['500']};
    }

    .ant-select-selector {
      background: ${({ variant, theme }) =>
        variant === VARIANT.TOP_BAR ? 'transparent' : theme.colors.white};
      border: 1px solid
        ${({ variant, theme }) =>
          variant === VARIANT.TOP_BAR ? 'transparent' : theme.colors.gray['400']};
    }

    &.ant-select-single {
      &.ant-select-lg:not(.ant-select-customize-input) {
        .ant-select-selector {
          padding: ${({ variant }) => (variant === VARIANT.TOP_BAR ? '0 50px' : '0 11px')};

          .ant-select-selection-search {
            padding: ${({ variant }) => (variant === VARIANT.TOP_BAR ? '0 40px' : '0')};
          }
        }
      }
    }

    &:not(&.ant-select-disabled) {
      &.ant-select-focused,
      &:hover {
        .ant-select-selector {
          border-color: ${({ theme }: ThemeProps) => theme.colors.secondary['200']};
          box-shadow: none;
        }
      }
    }

    .ant-select-clear {
      ${({ variant }) =>
        variant === VARIANT.TOP_BAR &&
        css`
          right: 20px;
          top: 18px;
          width: 14px;
          height: 14px;
        `}
    }
  }
`;

const OptionLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const OptionLabel = styled.div`
  font: ${({ theme }: ThemeProps) => theme.typography.subtitles};
  color: ${({ theme }: ThemeProps) => theme.colors.text.primary};
`;

const Name = styled.span`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
`;

const OptionLabelSecondary = styled(OptionLabel)`
  font: ${({ theme }: ThemeProps) => theme.typography.paragraph.font};
  letter-spacing: ${({ theme }: ThemeProps) => theme.typography.paragraph.letterSpacing};
  color: ${({ theme }: ThemeProps) => theme.colors.gray['700']};
`;

const OptionLabelSeniority = styled(OptionLabelSecondary)`
  text-transform: lowercase;
  &::first-letter {
    text-transform: uppercase;
  }
`;

const Button = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-top: 1px solid ${({ theme }) => theme.colors.border.base};
`;

const ArrowContainer = styled.div`
  display: flex;
  align-content: flex-end;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;

  svg path {
    fill: #9a99b2;
  }
`;

const NavLink = styled(RouterNavLink)`
  font-size: 15px;
  line-height: 22px;
  height: 58px;
  width: 100%;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin: 0;
  border: none;
  margin-right: 10px;
  padding: 8px 16px;
  color: ${({ theme }: ThemeProps) => theme.colors.text.tertiary};
  gap: 12px;

  .link-text {
    color: ${({ theme }: ThemeProps) => theme.colors.text.overline};
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding: 0px 4px;

  &:hover {
    svg path {
      fill: ${({ theme }) => theme.colors.text.overline};
    }
  }
`;

const HoverableButton = styled(Button)`
  &:hover {
    background-color: ${({ theme }) => theme.colors.neutral['350']} !important;
  }
`;

const Flex = styled.div`
  display: flex;
`;

function AutocompleteFilter({
  options,
  onSelect,
  onSearch,
  variant = VARIANT.MAIN,
  ...props
}: AutocompleteFilterProps): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [selected, setSelected] = useState<SelectValue>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const isTopBarVariant = variant === VARIANT.TOP_BAR;
  const navigate = useNavigate();

  useEffect(() => {
    if (!searchValue) {
      dispatch(actions.setUserList([]));
      dispatch(timesOffActions.setFoundTimesOff([]));
      dispatch(actions.setSearchStatus(SEARCH_STATUS.INSUFFICIENT_AMOUT));
      dispatch(timesOffActions.setSearchStatus(SEARCH_STATUS.IDLE));
    }
  }, [searchValue, dispatch]);

  const localOnSelected: OnSelectCallback = (selectedValue, option) => {
    if (onSelect) {
      onSelect(selectedValue, option);
      setSelected([]);
    }
  };

  const onNavigate = () => {
    navigate('/search-results');
    if (onSearch) {
      onSearch('');
    }
  };

  // Disable local state filtering
  const filterOption = false;

  const renderDropdownButton: JSX.Element | boolean = isTopBarVariant && (
    <HoverableButton>
      <NavLink as={Button} onClick={onNavigate} id="showAllEmployees">
        <IconDirectory />
        <span className="link-text">{t('navigation.see-all-employee')}</span>
      </NavLink>
    </HoverableButton>
  );

  const onSearchValue = (value: string) => {
    if (onSearch) {
      onSearch(value);
    }
    setSearchValue(value);
  };

  return (
    <Select
      value={selected}
      onChange={(value) => setSelected(value)}
      onSearch={onSearchValue}
      defaultActiveFirstOption={false}
      size="large"
      onSelect={localOnSelected}
      allowClear
      showSearch
      listHeight={232}
      showArrow={false}
      optionLabelProp="label"
      filterOption={filterOption}
      placeholder={isTopBarVariant ? t('navigation.search-by-person') : t('search')}
      dropdownClassName="employee-list-autocomplete"
      variant={variant}
      {...props}
      dropdownRender={(menu) => (
        <>
          {menu}
          {renderDropdownButton}
        </>
      )}
      id="searchInput"
    >
      {options?.map((option) => {
        const { name, value, position, seniority, profilePicture } = option;
        return (
          <AntSelect.Option key={value} value={option.value} label={name}>
            <Container>
              {profilePicture && <UserAvatar size={40} src={profilePicture} name={name} />}
              <OptionLabelContainer>
                <OptionLabel>
                  <Name>{name}</Name>
                </OptionLabel>
                <Flex>
                  {position && <OptionLabelSecondary>{position}&nbsp;</OptionLabelSecondary>}
                  {seniority && <OptionLabelSeniority>[{seniority}]</OptionLabelSeniority>}
                </Flex>
              </OptionLabelContainer>
              {profilePicture && (
                <ArrowContainer>
                  <Arrow />
                </ArrowContainer>
              )}
            </Container>
          </AntSelect.Option>
        );
      })}
    </Select>
  );
}

export default AutocompleteFilter;
export type { AutocompleteFilterProps, AutocompleteOptionProps, OptionElement };
