import { Typography, Skeleton } from 'antd';
import styled, { css } from 'styled-components';

import { queries } from 'shared/layout';

interface SpaceProps {
  margin: string | number;
  whiteSpace: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const Space = ({ margin, whiteSpace }: SpaceProps) => css`
  margin: ${margin};
  white-space: ${whiteSpace};
`;

export const Container = styled.div`
  height: auto;
  display: grid;
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 16px;
  max-width: 1600px;
`;

export const ResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 32px 48px;

  gap: 24px;
  width: 100%;
  height: fit-content;
  background: #ffffff;
`;

export const SearchResultsCount = styled.div`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.2px;
  display: flex;
`;
export const FiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
`;
export const FilterBreadcrumbGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
export const FilterBreadcrumb = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  margin: 8px -1px 8px 8px;
  gap: 8px;
  height: 45px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.text.overline};
  cursor: pointer;

  span {
    cursor: pointer;
  }

  &:hover > div:not(.exclude) {
    background-color: #ede7fd;
    cursor: pointer;
    & svg path {
      fill: ${({ theme }) => theme.colors.accents.darkA};
    }
  }
`;
export const FilterLevelBreadcrumbGroup = styled.div`
  display: flex;
  // select & that are not a 1st child
  & div:not(:first-child) {
    border-left: none;
  }
`;
export const FilterLevelBreadcrumb = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  height: 45px;

  background: ${({ theme }) => theme.colors.backgrounds.light.filterLevel};
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.text.overline};
  cursor: pointer;

  span {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.text.overline};
  }
`;

export const Button = styled.button`
  border: none;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.text.overline};
  background-color: ${({ theme }) => theme.colors.white};
  margin: 8px;
`;

export const Text = styled(Typography.Text)<{ variant?: 'default' | 'clear' }>`
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: ${({ theme, variant }) =>
    variant === 'clear' ? theme.colors.accents.darkA : theme.colors.text.primary};
  margin-left: ${({ variant }) => (variant === 'clear' ? '25px' : '0px')};
  cursor: ${({ variant }) => (variant === 'clear' ? 'pointer' : 'default')};
`;

export const BreadcrumbCircle = styled.div`
  border-radius: 50%;
  background-color: transparent;
  width: 21px;
  height: 21px;
`;

export const SkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const SkeletonInput = styled(Skeleton.Input)`
  height: 64px;
  width: 100%;
`;
export const SkeletonTitle = styled(Skeleton.Input)`
  height: 22px;
  width: 150px;
  margin-bottom: 32px;
`;

export const TableSpacing = styled.div`
  min-height: 692px;
  width: 100%;
`;

export const HoverableName = styled.span`
  :hover {
    text-decoration: underline;
  }
`;

export const NameContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  padding-right: 8px;
`;

export const SectionsContainer = styled.div`
  flex-direction: column;
  display: flex;
  height: 100%;
  align-items: center;
`;

export const ProfileNavigation = styled.div`
  margin-top: 24px;
  width: 100%;
  max-width: 1600px;
  display: flex;
  gap: 24px;
`;

export const MainWrapper = styled.div`
  width: 100%;
  padding: 40px 32px;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;

  ${queries.desktop} {
    padding: 40px 48px;
  }
`;

export const Flag = styled.div`
  aspect-ratio: 3/2;
  width: 21px;
  font-size: 21px;
`;

export const SkillsRowLevelContainer = styled('div')`
  display: flex;
  flex-direction: row;
  span:first-letter {
    text-transform: uppercase;
  }
  text-transform: lowercase;
  display: flex;
  align-items: center;
  gap: 8px;
  height: 22px;
  padding: 0px;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
`;

export const SkillsRowLevelGroup = styled('div')`
  display: flex;
  gap: 8px;
`;

export const SkillsRowLevelIcon = styled('div')`
  width: 20px;
  height: 22px;
  font-size: 1.2em;
`;
