import React from 'react';
import styled from 'styled-components';

import { queries } from 'shared/layout';

const Button = styled.button.withConfig({
  shouldForwardProp: (prop) => !['isHovered'].includes(prop),
})<{ isHovered: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  color: ${({ theme }) => theme.colors.primary};
  background: ${({ theme: { colors } }) => colors.neutral['300']};
  border: none;
  cursor: pointer;

  ${queries.tablet} {
    color: ${({ theme }) => theme.colors.gray['700']};
    visibility: ${({ isHovered }) => (isHovered ? 'visible' : 'hidden')};
    background: transparent;
    width: 24px;
    height: 24px;

    &:hover,
    &:active {
      color: ${({ theme }) => theme.colors.primary};
      transition: 0.2s ease-in-out;
    }

    &:active {
      background: ${({ theme }) => theme.colors.gray['400']};
    }
  }
`;

interface IconButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactChild | React.ReactChild[];
  isHovered: boolean;
}

const IconButton = ({ children, onClick, isHovered, ...props }: IconButtonProps): JSX.Element => {
  return (
    <Button type="button" onClick={onClick} isHovered={isHovered} {...props}>
      {children}
    </Button>
  );
};

export default IconButton;
