import { SkillFilter, SkillsWithAssignments, User } from 'API';

import QuerySearchResults from './querySearchResults';
import { QueryType } from '../pages/SearchResults/SearchResults';
import queryGetSkillsWithAssignments from './queryGetSkillsWithAssignments';

export type UsersList = Pick<
  User,
  | 'id'
  | 'name'
  | 'profilePicture'
  | 'role'
  | 'technicalRole'
  | 'seniority'
  | 'department'
  | 'profilePicture'
>[];

export interface NewSkillsWithAssignments {
  skillsAssignments: SkillsWithAssignments[];
  currentPage: number;
  pageSize: number;
  totalPages: number;
}

interface ICustomFilter {
  // iName: string;
  iRole: string[] | null;
  iSeniority: string[] | null;
  iDepartment: string[] | null;
  iTechnicalRole: string[] | null;
}

const getUsersList = async (query: QueryType): Promise<UsersList> => {
  // const { filtersValues: queryFilters, searchName: queryName } = query;
  const { filtersValues: queryFilters } = query;

  const newFilters =
    queryFilters !== null
      ? queryFilters.map((item) => item.filters.filter((elem) => elem.checkbox === true))
      : [];

  const [roleFilter, technicalRoleFilter, seniorityFilter, departmentFilter] = newFilters;
  const queryFilter = {
    customFilter: {
      // iName: queryName,
      iRole: newFilters && roleFilter.length !== 0 ? roleFilter.map((item) => item.name) : null,
      iSeniority:
        newFilters && seniorityFilter.length !== 0
          ? seniorityFilter.map((item) => item.name)
          : null,
      iDepartment:
        newFilters && departmentFilter.length !== 0
          ? departmentFilter.map((item) => item.name)
          : null,
      iTechnicalRole:
        newFilters && technicalRoleFilter.length !== 0
          ? technicalRoleFilter.map((item) => item.name)
          : null,
    },
  };
  const { customFilter } = queryFilter;
  // deleting keys with null value
  Object.keys(customFilter).forEach((key) => {
    if (customFilter[key as keyof ICustomFilter] === null) {
      delete customFilter[key as keyof ICustomFilter];
    }
  });

  const response = await QuerySearchResults({ filters: queryFilter });

  return response.listUsers;
};

const getLevelsArray = (levels: boolean[] | undefined, type: 'language' | 'skill'): string[] => {
  const langLevels: string[] = ['NATIVE', 'C2', 'C1', 'B2', 'B1', 'A2', 'A1'];
  const skillLevels: string[] = ['EXPERT', 'INTERMEDIATE', 'BEGINNER'];
  if (!levels) return [''];

  if (type === 'language') {
    return levels.map((item, index) => (item ? langLevels[index] : '')).filter(Boolean);
  }

  return levels.map((item, index) => (item ? skillLevels[index] : '')).filter(Boolean);
};

const getSkillAssigmentsList = async (query: QueryType): Promise<NewSkillsWithAssignments> => {
  const { filtersValues: queryFilters, pagination, ordering } = query;
  const newFilters =
    queryFilters !== null && queryFilters !== undefined
      ? queryFilters.map((item) => item.filters.filter((elem) => elem.checkbox === true))
      : [];

  const [skillsFilter, languageFilter, seniorityFilter, technicalRoleFilter] = newFilters;
  const languageFilters = languageFilter.map((item) => {
    return { name: item.name, lvls: [...getLevelsArray(item.level, 'language')] };
  });
  const technicalRoleFilters = technicalRoleFilter.map((item) => item.name);

  const seniorityFilters = seniorityFilter.map((item) => item.name);

  const skillsFilters = skillsFilter.map((item) => {
    return { name: item.name, lvls: [...getLevelsArray(item.level, 'skill')] };
  });

  const queryFilter: SkillFilter = {
    languageFilter:
      newFilters && languageFilter.length !== 0
        ? { languageNameAndLvls: languageFilters }
        : undefined,
    seniorityFilter:
      newFilters && seniorityFilter.length !== 0 ? { seniority: seniorityFilters } : undefined,
    skillsFilter:
      newFilters && skillsFilter.length !== 0 ? { iSkillLvls: skillsFilters } : undefined,
    technicalRoleFilter:
      newFilters && technicalRoleFilter.length !== 0
        ? { technicalRole: technicalRoleFilters }
        : undefined,
  };

  Object.keys(queryFilter).forEach((key) => {
    if (queryFilter[key as keyof SkillFilter] === null) {
      delete queryFilter[key as keyof SkillFilter];
    }
  });

  const response = await queryGetSkillsWithAssignments({
    filters: queryFilter,
    pagination,
    ordering,
  });
  return response.getSkillsWithAssignments;
};

export default { getUsersList, getSkillAssigmentsList };
