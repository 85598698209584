import removeByIndex from './removeByIndex';

const removeIfExists = <T extends { id: string }>(
  array: T[],
  removeElement: T,
  identity: keyof T = 'id'
): T[] => {
  const index = array.findIndex((element) => element[identity] === removeElement[identity]);
  if (index === -1) {
    return array.slice();
  }
  return removeByIndex(array, index);
};

export default removeIfExists;
