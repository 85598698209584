import * as profileFns from 'api/profile';

import { apiService } from './index';

export const profileApi = apiService.injectEndpoints({
  endpoints: (builder) => ({
    // eslint-disable-next-line
    // @ts-ignore
    getProfileById: builder.query({
      query: (id: string) => {
        return {
          fn: profileFns.getProfileById,
          payload: { id },
        };
      },
    }),
  }),
  overrideExisting: false,
});

// eslint-disable-next-line
// @ts-ignore
const useGetProfileById = profileApi.endpoints.getProfileById.useQuery;
// eslint-disable-next-line
// @ts-ignore

export { useGetProfileById };
