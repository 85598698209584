/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { BottomTitle, Subtitle, Valid } from './PtoTooltips.styles';

const AnnualVacationLeave = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
      <BottomTitle>{t('pto-tooltip.2-week-leave')}</BottomTitle>
      <Subtitle>
        <Valid>
          {t('pto-tooltip.polish-law-1')}{' '}
          <span style={{ color: 'white' }}>{t('pto-tooltip.14-calendar-days')}</span>{' '}
          {t('pto-tooltip.once-per-year')}{' '}
        </Valid>
      </Subtitle>
    </>
  );
};

export default AnnualVacationLeave;
