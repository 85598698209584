import styled from 'styled-components';

export const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const EmptyStateImage = styled.img`
  height: 185px;
`;

export const EmptyStatePrimaryText = styled.p`
  margin-top: 24px;
  font-weight: 600;
  font-size: 17px;
  line-height: 1.47;
  text-align: center;
`;

export const EmptyStateSecondaryText = styled.p`
  margin-top: 8px;
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
  letter-spacing: 0.3px;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const EmptyStateButtonContainer = styled.div`
  margin-top: 24px;
`;
