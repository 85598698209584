import React from 'react';
import styled from 'styled-components';
import { Skeleton } from 'antd';

import { queries } from 'shared/layout';

const Wrapper = styled.div<{ isPanelOpen: boolean }>`
  display: flex;
  height: 64px;
  padding: ${({ isPanelOpen }) => (isPanelOpen ? '12px 48px' : '12px 16px')};

  ${queries.tablet} {
    padding: 12px 48px;
  }
`;

const StyledSkeleton = styled(Skeleton)<{ isPanelOpen: boolean }>`
  .ant-skeleton-content {
    display: ${({ isPanelOpen }) => (isPanelOpen ? 'table-cell' : 'none')};

    ${queries.tablet} {
      display: table-cell;
    }
  }
`;

export interface UserCardLoadingProps {
  isPanelOpen: boolean;
}

const UserCardLoading = ({ isPanelOpen }: UserCardLoadingProps): JSX.Element => {
  return (
    <Wrapper isPanelOpen={isPanelOpen}>
      <StyledSkeleton
        isPanelOpen={isPanelOpen}
        avatar={{ size: 48 }}
        paragraph={{ rows: 2 }}
        title={false}
        active
      />
    </Wrapper>
  );
};

export default UserCardLoading;
