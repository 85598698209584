import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useAppDispatch } from 'redux/store';
import { actions, selectSkills } from 'features/Profile/redux/skillSlice';
import { ReactComponent as IconDelete } from 'assets/Delete.svg';
import { ReactComponent as IconEdit } from 'assets/Edit.svg';

import {
  SkillHeader,
  SkillHeaderText,
  SkillContainer,
  SkillData,
  DataContainer,
} from './SkillSelected.styles';

interface SkillSelectedProps {
  level: string;
  name: string;
  icon: React.ReactNode;
  handleSkill: (skill: string, language: string, level?: string) => void;
}

const SkillSelected = ({ level, name, icon, handleSkill }: SkillSelectedProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { skillDelete, skillModal } = useSelector(selectSkills);
  const handleSkillDelete = () => {
    dispatch(actions.setModal({ ...skillModal, visible: !skillModal.visible }));
    dispatch(
      actions.setModalDelete({ visible: !skillDelete.visible, name, isAssignedSkills: false })
    );
  };
  return (
    <SkillContainer>
      <SkillHeader onClick={() => handleSkillDelete()}>
        <SkillHeaderText>{name}</SkillHeaderText>
        <IconDelete />
      </SkillHeader>
      <SkillData onClick={() => handleSkill(name, '', level)}>
        <DataContainer>
          {icon}
          <span>{level}</span>
        </DataContainer>
        <DataContainer>
          <span>{t('skills-matrix.skill-experience.edit')}</span>
          <IconEdit />
        </DataContainer>
      </SkillData>
    </SkillContainer>
  );
};

export default SkillSelected;
