import styled from 'styled-components';
import { Button as AntButton } from 'antd';

export const Button = styled(AntButton)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 44px;
  padding: 12px 48px;
  background: ${({ theme }) => theme.colors.button.linear};
  border: none;

  font-weight: 400;
  font-size: 13px;
  line-height: 1.54px;
  letter-spacing: 0.2px;
  text-transform: uppercase;

  &,
  &:hover,
  &:active,
  &:focus {
    color: ${({ theme }) => theme.colors.white};
  }

  &:hover,
  &:focus {
    background: ${({ theme }) => theme.colors.button.linearHover};
  }

  &:active {
    background: ${({ theme }) => theme.colors.button.linearPressed};
  }

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colors.secondary['200']};
  }
`;
