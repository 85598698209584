import { Subject } from 'rxjs';

export interface MoveCalendarPayload {
  direction: 'left' | 'right';
  distance: number;
}
const moveCalendar$ = new Subject<MoveCalendarPayload>();

export const emitMoveCalendar = (payload: MoveCalendarPayload): void => moveCalendar$.next(payload);
export const onMoveCalendar$ = moveCalendar$.asObservable();
