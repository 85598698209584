import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import api, { UsersList } from 'features/SearchResults/api';
import { RootState } from 'redux/rootReducer';
import { SEARCH_STATUS } from 'utils/helpers/searchStatus';

import { QueryType } from '../pages/SearchResults/SearchResults';

interface UsersState {
  items: UsersList;
  searchStatus: SEARCH_STATUS;
}

const initialState: UsersState = {
  items: [
    {
      id: '',
      name: '',
      profilePicture: '',
      role: '',
      technicalRole: '',
      seniority: '',
      department: '',
    },
  ],
  searchStatus: SEARCH_STATUS.LOADING,
};

const setSearchStatus = (state: UsersState, action: PayloadAction<SEARCH_STATUS>): UsersState => {
  return {
    ...state,
    searchStatus: action.payload,
  };
};

const fetchUsers = createAsyncThunk(
  'seachResults/fetchUsers',
  async (query: QueryType): Promise<UsersList> => {
    const response = await api.getUsersList(query);
    return response;
  }
);

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setSearchStatus,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.pending, (state) => {
      return { ...state, items: [], searchStatus: SEARCH_STATUS.LOADING };
    });
    builder.addCase(fetchUsers.fulfilled, (state, action: PayloadAction<UsersList>) => {
      const searchStatus =
        action.payload.length === 0 ? SEARCH_STATUS.NONE_FOUND : SEARCH_STATUS.FOUND;
      return {
        ...state,
        items: action.payload.sort((a, b) => a.name.localeCompare(b.name)),
        searchStatus,
      };
    });
  },
});

export const actions = {
  ...usersSlice.actions,
  fetchUsers,
};

export const selectUsers = (state: RootState): UsersList => state.searchResults.allUsers.items;
export const selectSearchStatusUsers = (state: RootState): SEARCH_STATUS =>
  state.searchResults.allUsers.searchStatus;

export default usersSlice.reducer;
