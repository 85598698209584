import React, { ReactChild } from 'react';
import { ThemeProvider as OThemeProvider } from 'styled-components';

const baseTheme = {
  colors: {
    primary: '#100727',
    secondary: {
      '100': '#00D69E',
      '200': '#602BDF',
    },
    background: '#F8F8FC',
    backgrounds: {
      light: {
        primary: '#F8F8FC',
        secondary: '#FFFFFF',
        panels: '#F6F6FB',
        filterLevel: '#F6F3FE',
        negative: '#FFF5F8',
        tableRow: '#EDE7FD',
      },
      dark: {
        linear: 'linear-gradient(180deg, #070413 0%, #100627 100%)',
        tooltip: '#1c1332',
      },
    },
    gray: {
      '700': '#7B7B8E',
      '600': '#9393A7',
      '500': '#B3B3C1',
      '400': '#DFDFE5',
      '300': 'rgba(232, 234, 242, 0.45)',
    },
    neutral: {
      '500': '#F4F4FB',
      '400': '#F4F4F6',
      '350': '#F8F8FC',
      '300': '#F6F6FB',
      '200': '#FCFCFC',
    },
    white: '#FFFFFF',
    primaryLight: '#202250',
    warn: '#EB554E',
    red: {
      dust5: '#FF4D4F',
      dust4: '#FF7875',
      dust1: '#FFF1F0',
    },
    calendar: {
      today: '#F2F2F8',
      weekend: '#FAFAFA',
      holiday: '#FFF1F0',
      hover: 'rgba(232, 234, 242, 0.45)',
      timeOffHiddenHover: '#F8F8FC',
      timeOffBorder: '#E6E6EF',
    },
    text: {
      tableHeader: '#4E4D6C',
      primary: '#100727',
      primaryLight: '#B4BAC3',
      secondary: '#727292',
      secondaryLight: '#C3C2D4',
      overline: '#602BE9',
      version: '#1BFDBF',
      negative: '#FF4163',
      tertiary: '#0033CC',
      error: '#C8273F',
    },
    checkbox: {
      primary: '#9A99B2',
    },
    pagination: {
      primary: '#D4D4E1',
      seondary: '#727292',
    },
    border: {
      base: '#EBEBEB',
      split: '#F6F6F6',
      dark: '#2d2a48',
    },
    pressedButtons: '#DFDFDE',
    linear: {
      default: 'linear-gradient(90deg, #865EE8 0%, #6536E2 100%)',
      hover: 'linear-gradient(90deg, #7F4FEA 0%, #6128E3 100%)',
      pressed: 'linear-gradient(90deg, #7F4FEA 0%, #6128E3 100%)',
    },
    spinner: {
      dark: {
        primary: '#008482',
        secondary: '#1BFDBF',
      },
      light: {
        primary: '#C7B4FC',
        secondary: '#602BE9',
      },
    },
    button: {
      disabled: {
        background: '#EDECF8',
        color: '#A3A3B8',
      },
      linear: 'linear-gradient(90deg, #865EE8 0%, #6536E2 100%)',
      linearHover: 'linear-gradient(90deg, #7F4FEA 0%, #6128E3 100%)',
      linearPressed: 'linear-gradient(90deg, #7F4FEA 0%, #6128E3 100%)',
      linearDelete: 'linear-gradient(90deg, #FF4163 0%, #EB554E 100%)',
      signIn: {
        hover: '#F0F0F0',
        border: '#949494',
      },
    },
    scrollbar: {
      primary: 'rgba(2, 2, 2, 0.16)',
    },
    approved: {
      default: {
        body: '#EDFAF2',
        background: '#FFFFFF',
        text: '#167B3B',
        border: '#23C45E',
      },
      hover: {
        body: '#E4F4E7',
        background: '#FFFFFF',
        text: '#167B3B',
        border: '#23C45E',
      },
      active: {
        body: '#E4F4E7',
        background: '#FFFFFF',
        text: '#167B3B',
        border: '#23C45E',
      },
    },
    paid: {
      default: {
        body: '#E9F5FC',
        background: '#FFFFFF',
        text: '#076A95',
        border: '#0CA8ED',
      },
      hover: {
        body: '#E4F0FB',
        background: '#FFFFFF',
        text: '#076A95',
        border: '#0CA8ED',
      },
      active: {
        body: '#E4F0FB',
        background: '#FFFFFF',
        text: '#076A95',
        border: '#0CA8ED',
      },
    },
    unpaid: {
      default: {
        body: '#FFECE5',
        background: '#FFFFFF',
        text: '#972A2A',
        border: '#F04343',
      },
      hover: {
        body: '#FCE6E6',
        background: '#FFFFFF',
        text: '#972A2A',
        border: '#F04343',
      },
      active: {
        body: '#FCE6E6',
        background: '#FFFFFF',
        text: '#972A2A',
        border: '#F04343',
      },
    },
    otherTimeOff: {
      default: {
        body: '#FFF9E5',
        background: '#FFFFFF',
        text: '#844D0F',
        border: '#EDB508',
      },
      hover: {
        body: '#FEF9C3',
        background: '#FFFFFF',
        text: '#844D0F',
        border: '#EDB508',
      },
      active: {
        body: '#FEF9C3',
        background: '#FFFFFF',
        text: '#844D0F',
        border: '#EDB508',
      },
    },
    sickleave: {
      default: {
        a: '#FFF7E6',
        b: '#FFE7BA',
        c: '#FFA940',
      },
      hover: {
        a: '#FFE7BA',
        b: '#FFD591',
        c: '#FFA940',
      },
      active: {
        a: '#FFE7BA',
        b: '#FFD591',
        c: '#FA8C16',
      },
    },
    pending: {
      default: {
        a: '#E6F7FF',
        b: '#BAE7FF',
        c: '#40A9FF',
      },
      hover: {
        a: '#BAE7FF',
        b: '#91D5FF',
        c: '#40A9FF',
      },
      active: {
        a: '#BAE7FF',
        b: '#91D5FF',
        c: '#1890FF',
      },
    },
    accents: {
      lightA: '#F1EDFD',
      lightB: '#F2C832',
      lightC: '#FF9790',
      darkA: '#602BE9',
      darkB: '#347A12',
      darkC: '#85B6FF',
      darkD: '#42B8DD',
      darkE: '#D99BFF',
      darkF: '#0FC895',
      darkG: '#602BE9',
    },
    assignmentsStatuses: {
      current: {
        color: '#2C4B45',
        background: '#D7F9E4',
      },
      previous: {
        color: '#373F48',
        background: '#F1F2F4',
      },
    },
  },

  typography: {
    // New design
    heading: 'normal normal 600 26px/36px Bio Sans, sans-serif',
    subtitles: 'normal normal 400 15px/21px Bio Sans, sans-serif',
    body: 'normal normal 600 14px/20px Bio Sans, sans-serif',
    paragraph: {
      font: 'normal normal 400 13px/20px Bio Sans, sans-serif',
      letterSpacing: '0.2px',
    },
    button: {
      font: 'normal normal 600 13px/20px Bio Sans, sans-serif',
      letterSpacing: '0.2px',
      textTransform: 'uppercase',
    },
    status: '600 12px/22px Bio Sans, sans-serif',
    // Old design
    base: 'normal normal 500 14px/22px Inter, sans-serif',
    subtitlesM: '500 13px/22px Inter, sans-serif',
    subtitlesSb: 'normal normal 600 13px/22px Inter, sans-serif',
    body1M: '500 12px/20px Inter, sans-serif',
    body1Sb: 'normal normal 600 12px/20px Inter, sans-serif',
    body2M: '500 11px/20px Inter, sans-serif',
    body2Sb: 'normal normal 600 11px/20px Inter, sans-serif',
  },
};

export type ColorsType = typeof baseTheme['colors'];
export type TypographyType = typeof baseTheme['typography'];
export type ThemeType = typeof baseTheme;
export type ThemeProps = { theme: ThemeType };
export interface Props {
  children: ReactChild;
  theme?: ThemeType;
}

const ThemeProvider = ({ children, theme = baseTheme }: Props): JSX.Element => {
  return <OThemeProvider theme={theme}>{children}</OThemeProvider>;
};

export default ThemeProvider;
