import { Tooltip } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import { LeadAvatar } from 'components/Table/Table.styles';

import { useGetProfileById } from 'services/api/profile';
import defaultPhoto from 'assets/png/10clouds_sygnet.png';

interface ILeadAvatarElement {
  leadID: string;
}

const LeadAvatarElement = ({ leadID }: ILeadAvatarElement): JSX.Element => {
  const leadProfile = useGetProfileById(leadID);
  const { data: leadData } = leadProfile;

  const element = (
    <Tooltip placement="bottom" title={leadData?.getUser?.name}>
      <LeadAvatar
        size={32}
        src={leadData?.getUser?.profilePicture ? leadData?.getUser?.profilePicture : defaultPhoto}
      />
    </Tooltip>
  );

  if (leadID) {
    return <Link to={`/profile/${leadID}`}>{element}</Link>;
  }

  return element;
};

export default LeadAvatarElement;
