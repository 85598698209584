import { isToday, isWeekend } from 'date-fns';
import React, { ComponentPropsWithRef } from 'react';
import styled from 'styled-components';
import { isHoliday } from 'poland-public-holidays';
import { useSelector } from 'react-redux';

import { ThemeType } from 'components/ThemeProvider';

import style from 'features/TimeOff/constants/style';
import { selectCalendarRange } from 'features/TimeOff/redux/calendarRange';
import { enumerateDaysBetweenDates } from 'features/TimeOff/utils/dateUtils';

const MonthsBackgroundWrapper = styled.div<{ numElements: number; numGroups: number }>`
  min-height: 100%;
  height: ${({ numElements, numGroups }) =>
    numElements * style.timeOffRow.height + numGroups * style.groupName.height}px;
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

const DaysWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: 1;
`;

const DaysBody = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  border-top: 0;
  & > div {
    border-top: 0;
  }
`;

interface DayBodyProps {
  today: boolean;
  weekend: boolean;
  holiday: boolean;
  theme: ThemeType;
}

const DayBody = styled.div<DayBodyProps>`
  width: ${style.dayHead.width}px;
  height: 100%;
  border-left: 0.5px solid ${({ theme }) => theme.colors.neutral['300']};
  border-right: 0.5px solid ${({ theme }) => theme.colors.neutral['300']};
  background: ${({ today, weekend, holiday, theme }) => {
    if (holiday) return theme.colors.red.dust1;
    if (today) return theme.colors.calendar.today;
    if (weekend) return theme.colors.neutral['200'];
    return theme.colors.white;
  }};
`;

const Days = ({ sticky = false }: { sticky?: boolean }): JSX.Element => {
  const range = useSelector(selectCalendarRange);
  const days = enumerateDaysBetweenDates(new Date(range.startDate), new Date(range.endDate));

  return (
    <DaysBody className={sticky ? 'sticky' : ''}>
      {days.map((day: Date) => (
        <DayBody
          today={isToday(day)}
          weekend={isWeekend(day)}
          holiday={isHoliday(day)}
          key={day.toISOString()}
        />
      ))}
    </DaysBody>
  );
};

const UserDays = (): JSX.Element => {
  return (
    <DaysWrapper>
      <Days />
    </DaysWrapper>
  );
};

const CalendarBackground = ({
  numElements = 0,
  numGroups = 0,
  ...props
}: {
  numElements?: number;
  numGroups?: number;
} & ComponentPropsWithRef<'div'>): JSX.Element => {
  return (
    <MonthsBackgroundWrapper numElements={numElements} numGroups={numGroups} {...props}>
      <UserDays />
    </MonthsBackgroundWrapper>
  );
};

export default CalendarBackground;
