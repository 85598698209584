/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { useTranslation } from 'react-i18next';

import Days from './Days';
import { Title, Wrapper, Group, Subtitle, SubtitleGroup, Year, Flex } from './PtoTooltips.styles';

const LoadingStateTooltipContent = (): JSX.Element => {
  const { t } = useTranslation();

  const d = new Date();
  const year = d.getFullYear();
  return (
    <Wrapper>
      <Title>{t('pto-tooltip.fetching')}</Title>
      <Group>
        <SubtitleGroup>
          <Subtitle>
            {t('pto-tooltip.granted')}
            <Year> {year}</Year>
          </Subtitle>
          <Flex>
            <Days count={null} />
            {t('pto-tooltip.days')}
          </Flex>
        </SubtitleGroup>
        <SubtitleGroup>
          <Subtitle>
            {t('pto-tooltip.taken')}
            <Year> {year}</Year>
          </Subtitle>
          <Flex>
            <Days count={null} />
            {t('pto-tooltip.days')}
          </Flex>
        </SubtitleGroup>
      </Group>
      <SubtitleGroup>
        <Subtitle bottom>
          {t('pto-tooltip.available')}
          <Year> {year}</Year>
        </Subtitle>
        <Flex bottom>
          <Days count={null} />
          {t('pto-tooltip.days')}
        </Flex>
      </SubtitleGroup>
    </Wrapper>
  );
};

export default LoadingStateTooltipContent;
