import React from 'react';
import { useTranslation } from 'react-i18next';

import EmptyBench from 'assets/png/EmptyBench.png';

import {
  Container,
  Content,
  Description,
  Image,
  SecondaryText,
  Title,
} from '../EmptyStates.styles';

const EmptyProjects = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Container>
      <Content>
        <Image src={EmptyBench} alt="Projects are empty" />
        <Description>
          <Title>{t('profile.user-assignments.empty-states.empty.title')}</Title>
          <SecondaryText>
            {t('profile.user-assignments.empty-states.empty.description')}
          </SecondaryText>
        </Description>
      </Content>
    </Container>
  );
};

export default EmptyProjects;
