import { startOfWeek, endOfWeek } from 'date-fns';
import { isNaN } from 'lodash';

interface IGetCurrentWeekRange {
  startDate: string;
  endDate: string;
}
const isInvalidDate = (date: Date) => isNaN(date.getTime());

export const getCurrentWeekRange = (today: Date): IGetCurrentWeekRange => {
  if (isInvalidDate(today)) {
    throw new RangeError('Invalid date input');
  }

  const startDate = startOfWeek(today, { weekStartsOn: 1 }).toISOString().slice(0, 10);
  const endDate = endOfWeek(today, { weekStartsOn: 1 }).toISOString().slice(0, 10);

  return { startDate, endDate };
};
