import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  Modal,
  ActionButton,
  TextButton,
} from 'features/Profile/components/SkillModal/SkillModal.styles';
import SkillLevelSelectionCheckboxes from 'features/Profile/components/SkillModal/SkillLevelSelection/SkillLevelSelectionCheckboxes';
import LanguageLevelSelectionCheckboxes from 'features/Profile/components/SkillModal/SkillLevelSelection/LanguageLevelSelectionCheckboxes';
import { selectSkillAssignmentsModalState } from 'features/SearchResults/redux/skillsAssignmentSlice';

interface SkillModalProps {
  visible: boolean;
  onCancelClick: () => void;
  skill: {
    name: string;
    title: string;
    level?: boolean[];
    checkedArray: boolean[];
  };
  handleConfirm: (title: string, name: string, array: boolean[]) => void;
}

const SkillSearchLevelModal = ({
  visible,
  onCancelClick,
  skill,
  handleConfirm,
}: SkillModalProps): JSX.Element => {
  const { t } = useTranslation();
  const modalArray = useSelector(selectSkillAssignmentsModalState);

  const onConfirmSkill = () => {
    handleConfirm(skill.title, skill.name, modalArray);
  };

  const modalTitle = t('skills-search.modal.select-level');

  const footer = (
    <>
      <TextButton onClick={onCancelClick}>cancel</TextButton>
      <ActionButton onClick={onConfirmSkill}>
        {t('profile.edit-contact-information.confirm')}
      </ActionButton>
    </>
  );

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      title={modalTitle}
      width={404}
      footer={footer}
    >
      {skill.title === t('filters.titles.technical-skill') && (
        <SkillLevelSelectionCheckboxes level={skill.level} skillName={skill.name} />
      )}
      {skill.title === t('filters.titles.language-skill') && (
        <LanguageLevelSelectionCheckboxes level={skill.level} skillName={skill.name} />
      )}
    </Modal>
  );
};

export default SkillSearchLevelModal;
