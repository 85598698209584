import { AssignSkillsResponse, MutationAssignSkillsToUserArgs } from 'API';
import generateGraphqlCaller from 'utils/helpers/generateGraphqlCaller';

const query = `
mutation AssignSkillsToUser($nameLvlList: [NameLvlInput!]!){
    assignSkillsToUser(nameLvlList: $nameLvlList) {
            ... on AssignSkillsSuccessResponse {
                skillsAssignments{
                    skill{
                        name
                    }
                    lvl
                    lastModified
                },
                owner{
                    id
                }
            }
            ... on AssignSkillsErrorResponse {
                ownerErr: owner{
                    id
                },
                errors {
                ... on Error {
                  message
                }
              }
            }

          }
}
`;

export default generateGraphqlCaller<AssignSkillsResponse, MutationAssignSkillsToUserArgs>(query);
