import capitalize from 'lodash/capitalize';

import { User, UserProject } from 'API';
import { showError } from 'utils/helpers/showMessage';

import QuerySearchResults from './querySearchResults';
import querySearchProjects from './querySearchProjects';

const searchUsersList = async (
  query: string
): Promise<
  Pick<
    User,
    'role' | 'technicalRole' | 'seniorityLevel' | 'profilePicture' | 'name' | 'id' | 'email'
  >[]
> => {
  const queryString = query.split(' ').map(capitalize).join().replace(',', ' ');

  const queryFilter = {
    customFilter: { iName: queryString },
  };
  const response = await QuerySearchResults({
    filters: queryFilter,
  });
  return response.listUsers;
};

const searchProjectsList = async (
  userId: string,
  after: string,
  before: string
): Promise<
  Pick<
    UserProject,
    | 'lead'
    | 'projectStatus'
    | 'weeklyHours'
    | 'totalEstimatedHours'
    | 'currentLoggedHours'
    | 'projectName'
    | 'projectUrl'
    | 'jiraUrl'
  >[]
> => {
  const response = await querySearchProjects({ id: userId, before, after });
  if (response.getUser.userProjects.message) {
    showError({ content: response.getUser.userProjects.message });
  }
  return response.getUser.userProjects.projects;
};

export default {
  searchUsersList,
  searchProjectsList,
};
