import { Subject } from 'rxjs';

import { TimeOff } from '../helpers/mergeTimeOffRecords';

export type CalendarMoveFinishedPayload = TimeOff;

const calendarMoveFinished$ = new Subject<CalendarMoveFinishedPayload>();

export const emitCalendarMoveFinished = (payload: CalendarMoveFinishedPayload): void =>
  calendarMoveFinished$.next(payload);
export const onCalendarMoveFinished$ = calendarMoveFinished$.asObservable();
