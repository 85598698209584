import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ReactComponent as RefreshIcon } from 'assets/Refresh.svg';
import {
  actions,
  selectIsCalendarLoading,
  selectSelectedTimesOff,
} from 'features/TimeOff/redux/timesOffSlice';
import { showError } from 'utils/helpers/showMessage';
import { useAppDispatch } from 'redux/store';
import { useAuth } from 'providers/AuthProvider';

const Button = styled.button`
  padding: 7px;
  border-radius: 100%;
  color: ${({ theme }) => theme.colors.text.primary};
  background: ${({ theme }) => theme.colors.neutral['400']};
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &:not(:disabled) {
    &:hover {
      background: ${({ theme }) => theme.colors.neutral['400']};
    }

    &:active,
    &:focus {
      background: ${({ theme }) => theme.colors.gray['400']};
    }
  }
`;

const Icon = styled(RefreshIcon).withConfig({
  shouldForwardProp: (prop) => !['isLoading'].includes(prop),
})<{
  isLoading: boolean;
}>`
  fill: currentColor;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  ${(props) =>
    props.isLoading &&
    `
    animation: spin 1s linear infinite; 
  `}
`;

type RefreshButtonProps = {
  onUpdate?: () => void;
};

const RefreshButton = ({ onUpdate }: RefreshButtonProps): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const { currentUser } = useAuth();
  const isCalendarLoading = useSelector(selectIsCalendarLoading);
  const selectedTimesOff = useSelector(selectSelectedTimesOff);
  const { t } = useTranslation();

  const handleClick = async (): Promise<void> => {
    if (currentUser) {
      try {
        dispatch(actions.setCalendarLoading(true));
        await Promise.all([
          dispatch(actions.fetchMyTimesOff(currentUser.id)),
          dispatch(actions.fetchFavouriteTimesOff()),
          dispatch(actions.fetchSelectedUsersTimesOff(selectedTimesOff)),
        ]);
        onUpdate?.();
      } catch (err) {
        showError({
          content: t('There was a problem refreshing data. Try again later'),
        });
      } finally {
        dispatch(actions.setCalendarLoading(false));
      }
    }
  };

  const isDisabled = !currentUser || isCalendarLoading;

  return (
    <Button onClick={handleClick} disabled={isDisabled} aria-label={t('time-off.refresh')}>
      <Icon focusable={false} isLoading={isCalendarLoading} />
    </Button>
  );
};

export default RefreshButton;
