import { useSelector } from 'react-redux';
import { differenceInDays, parseISO } from 'date-fns';
import { useCallback } from 'react';

import { selectCalendarRange } from '../redux/calendarRange';
import style from '../constants/style';

/**
 * Use the function this returns to calculate the left offset of a given date based on the calendar start date.
 * This is mostly useful for TimeOffBars.
 * The generated function returns number in pixels;
 */
const useCalendarDateOffset = (): ((targetDate: Date, extraOffset?: number) => number) => {
  const { startDate } = useSelector(selectCalendarRange);

  return useCallback(
    (targetDate: Date, offsetByDays = 0) => {
      const calendarStartDate = parseISO(startDate);
      const days = differenceInDays(targetDate, calendarStartDate) + offsetByDays;
      return style.dayHead.width * days;
    },
    [startDate]
  );
};
export default useCalendarDateOffset;
