import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowLeftIcon } from 'assets/Arrow-left.svg';

import {
  ActionButton,
  LeftArrow,
  Modal,
  TextBody,
  TextButton,
  TitleContainer,
} from './TeamLeadModal.styles';

interface ModalProps {
  visible: boolean;
  content: string;
  count: number;
  handler: (visible: boolean) => void;
  handlerAction: (content: string) => void;
}

const TeamLeadModal = ({ visible, content, handler, handlerAction, count }: ModalProps) => {
  const { t } = useTranslation();
  const closeHandler = () => {
    handler(false);
  };

  const actionHandler = (action: string) => {
    handlerAction(action);
  };

  const footer =
    content === 'approve' ? (
      <>
        <TextButton onClick={closeHandler}>{t('team-lead-view.modal.back')}</TextButton>
        <ActionButton onClick={() => actionHandler(content)} approve>
          {t('team-lead-view.modal.approve-btn')}
        </ActionButton>
      </>
    ) : (
      <>
        <TextButton onClick={closeHandler}>{t('team-lead-view.modal.back')}</TextButton>
        <ActionButton onClick={() => actionHandler(content)}>
          {t('team-lead-view.modal.decline-btn')}
        </ActionButton>
      </>
    );
  const modalContent = (
    <TextBody>
      {content === 'approve'
        ? t('team-lead-view.modal.approve-text')
        : t('team-lead-view.modal.decline-text')}
      {count}
      {t('team-lead-view.modal.text-end')}
    </TextBody>
  );

  const title = (
    <TitleContainer>
      <LeftArrow onClick={closeHandler}>
        <ArrowLeftIcon />
      </LeftArrow>
      {content === 'approve'
        ? t('team-lead-view.modal.approve-title')
        : t('team-lead-view.modal.decline-title')}
    </TitleContainer>
  );
  return (
    <Modal centered closable={false} visible={visible} title={title} width={404} footer={footer}>
      {modalContent}
    </Modal>
  );
};

export default TeamLeadModal;
