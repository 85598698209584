import { useState, useEffect } from 'react';

interface IUseWindowResize {
  width: number;
  height: number;
}

export const useWindowResize = (): IUseWindowResize => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const listener = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', listener);
    return () => {
      window.removeEventListener('resize', listener);
    };
  }, []);

  return {
    width,
    height,
  };
};
