import { useQuery, UseQueryResult } from '@tanstack/react-query';

import GetUserBasicInformation from 'api/GetUserBasicInformation';
import api from 'features/TimeOff/api';
import { TimeOff } from 'features/TimeOff/helpers/mergeTimeOffRecords';

interface IUseProjectsListArgs {
  userId: string;
  endDate: string;
  startDate: string;
}
const oneMinuteInMs = 60000;

export default function useUserTimeOffById({
  userId,
  endDate,
  startDate,
}: IUseProjectsListArgs): UseQueryResult<TimeOff[], unknown> {
  return useQuery(
    ['useUserTimeOffById', userId],
    async () => {
      const profileOwner = await GetUserBasicInformation();
      const ownerId = profileOwner.getOwnUser.id;
      if (userId === ownerId) {
        const response = await api.getMyTimesOff(userId, startDate, endDate);
        return response.timeOff.timesOff;
      }
      const data = await api.getUsersTimesOff(startDate, endDate, [userId]);
      return data[0].timesOff;
    },
    {
      staleTime: oneMinuteInMs,
    }
  );
}
