import { QueryGetUserArgs, UserProject } from 'API';
import generateGraphqlCaller from 'utils/helpers/generateGraphqlCaller';

const query = /* GraphQL */ `
  query GetUser($id: String!, $before: Date!, $after: Date!) {
    getUser(id: $id) {
      ... on PublicUser {
        id
        email
        userProjects(after: $after, before: $before) {
          ... on UserProjectsResult {
            projects {
              lead
              projectName
              weeklyHours
              currentLoggedHours
              totalEstimatedHours
              projectStatus
              jiraUrl
              entryKey
              projectUrl
            }
          }
          ... on SalesforceError {
            __typename
            message
          }
          ... on UnexpectedError {
            __typename
            message
          }
          ... on Error {
            __typename
            message
          }
        }
      }
      ... on UserNotFoundError {
        __typename
        message
      }
      ... on MultipleUsersFound {
        __typename
        message
      }
      ... on UnexpectedError {
        __typename
        message
      }
      ... on Error {
        __typename
        message
      }
    }
  }
`;

export type ReturnType = {
  getUser: {
    userProjects: {
      message?: string;
      projects: Pick<
        UserProject,
        | 'lead'
        | 'projectStatus'
        | 'weeklyHours'
        | 'totalEstimatedHours'
        | 'currentLoggedHours'
        | 'projectName'
        | 'entryKey'
        | 'projectUrl'
        | 'jiraUrl'
      >[];
    };
  };
};

export type VariablesType = Pick<QueryGetUserArgs, 'id'> & { before: string; after: string };

export default generateGraphqlCaller<ReturnType, VariablesType>(query);
