import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Spinner, { FullPageSpinnerContainer } from 'components/Spinner';

import { client } from 'graphql/client';
import { queries } from 'shared/layout';
import { useAuth } from 'providers/AuthProvider';
import { showError } from 'utils/helpers/showMessage';

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.backgrounds.dark.linear};
  ${queries.tablet} {
    flex-direction: row;
  }
`;

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: auto;
  background-position: bottom;
  background-repeat: no-repeat;

  ${queries.tablet} {
    width: 100%;
  }
`;

const Frame = styled.div`
  max-width: 310px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
const TokenRedirect = (): JSX.Element => {
  const { token } = useParams<{ token: string }>();
  const { setToken } = useAuth();
  const navigate = useNavigate();
  const tokenRegex = /^[0-9a-f]{40}$/;

  const { t } = useTranslation();

  useEffect(() => {
    if (!token) return;

    if (!tokenRegex.test(token)) {
      navigate('/');
      showError({ content: t('sign-in.error') });
      return;
    }

    client.setHeaders({ Authorization: `Token ${token}` });
    setToken(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <Container>
      <Wrapper>
        <Frame>
          <FullPageSpinnerContainer>
            <Spinner message={t('sign-in.loading')} variant="light" />
          </FullPageSpinnerContainer>
        </Frame>
      </Wrapper>
    </Container>
  );
};

export default TokenRedirect;
