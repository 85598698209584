import type { User } from 'API';
import mergeTimeOffRecords from 'features/TimeOff/helpers/mergeTimeOffRecords';
import type { UserTimeOff } from 'features/TimeOff/types/calendarTypes';
import GetUserBasicInformation from 'api/GetUserBasicInformation';
import { showError } from 'utils/helpers/showMessage';

import queryMyList from './queryMyList';
import mutateAddToMyList, { ReturnType as AddToMyListApiReturnType } from './mutateAddToMyList';
import mutateRemoveFromMyList from './mutateRemoveFromMyList';

export type AddToMyListResponse = AddToMyListApiReturnType['createFavouriteUser'];

const getMyList = async (after: string, before: string): Promise<UserTimeOff[]> => {
  const response = await queryMyList({
    before,
    after,
  });
  if ('message' in response.getOwnUser) {
    // eslint-disable-next-line no-console
    showError({ content: response.getOwnUser.message });
  }
  return response.getOwnUser.favouriteUsers.map((user) => ({
    id: user.id,
    name: user.name,
    position: user.role ?? '',
    profilePicture: user.profilePicture,

    timesOff:
      'timeOffs' in user.timeOffDates ? mergeTimeOffRecords(user.timeOffDates.timeOffs) : [],
  }));
};

const addToMyList = async (userId: string): Promise<string | null | undefined> => {
  const { getOwnUser } = await GetUserBasicInformation();
  const response = await mutateAddToMyList({
    ownerId: getOwnUser.id,
    favouritedId: userId,
  });
  if ('errors' in response) {
    // eslint-disable-next-line no-console
    console.error(response.errors);
  }
  return response.createdFavouriteId;
};

const removeFromMyList = async (
  myListId: string,
  ownerId: string
): Promise<User['id'] | undefined | null> => {
  const response = await mutateRemoveFromMyList({
    ownerId,
    favouritedId: myListId,
  });

  return response.deleteFavouriteUser.deletedFavouriteId;
};

export default {
  getMyList,
  addToMyList,
  removeFromMyList,
};
