import styled from 'styled-components';
import { Avatar, Typography } from 'antd';
import { Link } from 'react-router-dom';

import { queries } from 'shared/layout';

export const AssignmentsRow = styled.div`
  display: flex;
  align-items: center;
  height: 64px;
  padding: 0 24px;
  box-shadow: 0 1px 0 #dee2e6;
  margin: 1px 0;
  width: 100%;

  ${queries.desktop} {
    padding: 0 32px;
  }
`;

export const AssignmentsHeaderRow = styled(AssignmentsRow)`
  height: 40px;
  background: ${({ theme }) => theme.colors.background};
  padding-top: 10px;
  padding-bottom: 10px;

  .actionCell {
    text-align: right;
  }
`;

export const InfoIconContainer = styled.div`
  margin-left: 8px;

  .anticon {
    font-size: 16px;
    cursor: pointer;

    &:hover {
      color: ${({ theme }) => theme.colors.text.primary};
    }
  }
`;

export const LeadAvatar = styled(Avatar)`
  background-color: ${({ theme }) => theme.colors.secondary['200']};
`;

export const ProjectNameContainer = styled.div<{ shouldRenderSFLink?: boolean | null }>`
  display: grid;
  grid-template-columns: ${({ shouldRenderSFLink }) =>
    shouldRenderSFLink ? '3fr minmax(32px, 1fr)' : '1fr'};
  align-items: center;
  width: 100%;
`;

export const ProjectNameText = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const SalesforceLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.text.secondary};

  .anticon {
    font-size: 16px;
  }

  &:hover {
    .anticon {
      color: ${({ theme }) => theme.colors.text.primary};
    }
  }
`;

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  width: fit-content;
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 15px;
  line-height: 21px;
  letter-spacing: 0px;
  & svg {
    min-width: 16px;
    aspect-ratio: 1;
  }
`;

export const JiraActionLink = styled.a`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.neutral['300']};
  color: ${({ theme }) => theme.colors.text.secondary};

  .anticon {
    font-size: 16px;
    fill: currentColor;
  }

  &:hover {
    .anticon {
      color: ${({ theme }) => theme.colors.text.primary};
    }
  }
`;

export const RenderBodyResult = styled.div`
  width: 100%;
`;

export const RoleParagraph = styled(Typography.Paragraph)`
  &.ant-typography {
    margin-bottom: 0;
  }
  width: 100%;
  white-space: pre-wrap;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
`;

export const SubParagraph = styled(Typography.Paragraph)`
  &.ant-typography {
    margin-bottom: 0;
    color: ${({ theme }) => theme.colors.text.secondary};
    text-transform: lowercase;
  }
  &.ant-typography:first-letter {
    text-transform: uppercase;
  }
  width: 100%;
  white-space: pre-wrap;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.2px;
`;

export const ProjectNameParagraph = styled(Typography.Paragraph)`
  display: flex;
  align-items: center;
  gap: 16px;
  padding-right: 8px;
  white-space: pre-wrap;

  &.ant-typography {
    margin-bottom: 0;
  }

  & ${LeadAvatar} {
    min-width: 32px;
  }
`;
export const SkillNameParagraph = styled(Typography.Paragraph)`
  display: flex;
  flex-direction: column;
  align-items: center;
  &.ant-typography {
    margin-bottom: 0;
  }
  white-space: pre-wrap;
`;
