import styled from 'styled-components';
import { Button, Modal as AntModal } from 'antd';

import { ThemeProps } from 'components/ThemeProvider/ThemeProvider';

export const Modal = styled(AntModal)`
  .ant-modal-header {
    padding: 32px;
    border: none;
  }

  .ant-modal-title {
    font-size: 24px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.primary};
  }

  .ant-modal-body {
    padding: 0;
    height: 486px;
  }

  .ant-modal-footer {
    padding: 31px 32px 32px;
  }
`;
type ActionButtonProps = {
  isDisabled?: boolean;
  theme: ThemeProps;
};

export const ActionButton = styled(Button)<ActionButtonProps>`
  background: ${(props) =>
    props.isDisabled
      ? props.theme.colors.calendar.timeOffBorder
      : props.theme.colors.button.linear};
  color: ${(props) =>
    props.isDisabled ? props.theme.colors.text.secondary : props.theme.colors.white};
  font-size: 13px;
  line-height: 20px;
  text-transform: uppercase;
  height: 44px;
  padding: 0 24px;
  pointer-events: ${(props) => (props.isDisabled ? 'none' : 'all')};
  border: none;

  &:hover,
  &:focus,
  &:active {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.button.linearHover};
  }
`;

export const TextButton = styled(Button)`
  font-size: 13px;
  line-height: 20px;
  text-transform: uppercase;
  height: 44px;
  padding: 0 24px;
  background: none !important;
  color: ${({ theme }) => theme.colors.primary} !important;
  border: none;
`;
