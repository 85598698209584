import { ReviewSkillsResponse, MutationApproveSkillAssignmentsArgs } from 'API';
import generateGraphqlCaller from 'utils/helpers/generateGraphqlCaller';

const query = `
mutation RejectSkillAssignments($input: [SkillReviewInput!]!){
    rejectSkillAssignments(input: $input) {
            ... on ReviewSkillsSuccessResponse {
                reviewOutcome
            }
            ... on ReviewSkillsErrorResponse {
                errors {
                ... on Error {
                  message
                }
              }
            }

          }
}
`;

export default generateGraphqlCaller<ReviewSkillsResponse, MutationApproveSkillAssignmentsArgs>(
  query
);
