import { Breadcrumb } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';

import { VARIANT } from 'components/AutocompleteFilter/types';
import PageLayout from 'components/PageLayout';
import ProfileTopBar from 'components/TopBar/ProfileTopBar';

import { useAuth } from 'providers/AuthProvider';
import { BreadcrumbsSection } from 'features/Profile/pages/Profile/Profile.styles';

import {
  Container,
  MainWrapper,
  Nav,
  ProfileNavigation,
  ProfileNavLink,
  SectionsContainer,
} from './TeamLeadView.styles';

const TeamLeadPage: React.FC = () => {
  const { t } = useTranslation();
  const { currentUser } = useAuth();
  const location = useLocation();
  const atSkills = location.pathname.includes('skills');

  return (
    <PageLayout>
      <Container>
        <ProfileTopBar
          profilePicture={currentUser?.profilePicture ?? ''}
          variant={VARIANT.TOP_BAR}
        />
        <MainWrapper>
          <SectionsContainer>
            <BreadcrumbsSection>
              <Nav>
                <Breadcrumb>
                  <Breadcrumb.Item>{t('team-lead-view.my-team')}</Breadcrumb.Item>
                  {!atSkills ? (
                    <Breadcrumb.Item>{t('team-lead-view.time-off')}</Breadcrumb.Item>
                  ) : (
                    <Breadcrumb.Item>{t('team-lead-view.employee-skills')}</Breadcrumb.Item>
                  )}
                </Breadcrumb>
              </Nav>
            </BreadcrumbsSection>
            <ProfileNavigation>
              <ProfileNavLink to="/my-team" end>
                {t('team-lead-view.time-off')}
              </ProfileNavLink>
              <ProfileNavLink to="skills">{t('team-lead-view.employee-skills')}</ProfileNavLink>
            </ProfileNavigation>
            <Outlet />
          </SectionsContainer>
        </MainWrapper>
      </Container>
    </PageLayout>
  );
};

export default TeamLeadPage;
