import React from 'react';

import { ReactComponent as Beginner } from 'assets/Beginner.svg';
import { ReactComponent as Intermediate } from 'assets/Intermediate.svg';
import { ReactComponent as Expert } from 'assets/Expert.svg';

export const getSkillLevelIcon = (skillLvl: string): JSX.Element => {
  if (skillLvl && skillLvl.toLowerCase() === 'expert') {
    return <Expert />;
  }
  if (skillLvl && skillLvl.toLowerCase() === 'intermediate') {
    return <Intermediate />;
  }
  return <Beginner />;
};
