import React, { useEffect, useState } from 'react';
import '@reach/tabs/styles.css';
import { Tab, TabList } from '@reach/tabs';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { TabName, TabValue, TabWrapper } from 'components/Tabs/Tabs';

import { ReactComponent as Add } from 'assets/Add.svg';
import SkillModal from 'features/Profile/components/SkillModal';
import { ProfileSkillsEmptyState } from 'features/Profile/components/ProfileSkills/EmptyState/ProfileSkillsEmptyState';
import { selectSkills, actions, AssignedSkill } from 'features/Profile/redux/skillSlice';
import SkillsTable from 'features/Profile/components/ProfileSkills/SkillsTable/SkillsTable';
import SkillDelete from 'features/Profile/components/SkillModal/SkillDelete';
import { useIsProfileOwner } from 'features/Profile/hooks/useIsProfileOwner';
import { useAppDispatch } from 'redux/store';

import {
  Container,
  Content,
  Heading,
  TabsComponent,
  TabsContainer,
  TabsPanelComponent,
  TabsPanelsComponent,
  TabsAddButton,
  TabsSeperator,
  TabsWrapper,
} from './ProfileSkills.styles';
import { ProfileRouteParams } from '../Profile/Profile';

export enum Skills {
  technical = 0,
  language = 1,
}

const ProfileSkillsPage = (): JSX.Element => {
  const { listAssignedSkills, skillModal, isLoading } = useSelector(selectSkills);
  const { userId } = useParams<{ userId: string }>() as ProfileRouteParams;
  const isProfileOwner = useIsProfileOwner(userId);
  const [tabIndex, setTabIndex] = useState(skillModal.content);
  const [technicalLength, setTechnicalLength] = useState(0);
  const [languageLength, setLanguageLength] = useState(0);
  const [isEditModal, setIsEditModal] = useState({ name: '', lvl: '', edit: false });
  const [filteredAssignedSkills, setFilteredAssignedSkills] = useState<Array<AssignedSkill>>([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const skillTypes: Record<number, string> = {
    [Skills.technical]: t('skills-matrix.skill-types.technical'),
    [Skills.language]: t('skills-matrix.skill-types.language'),
  };

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    isProfileOwner
      ? dispatch(actions.fetchOwnAssignedSkillList())
      : dispatch(actions.fetchAssignedSkillList(userId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useEffect(() => {
    setFilteredAssignedSkills(listAssignedSkills.assignments);
    dispatch(actions.updateChosenSkills({ assignments: listAssignedSkills.assignments }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listAssignedSkills]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 400);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillModal.content]);

  useEffect(() => {
    setIsEditModal({ name: '', lvl: '', edit: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillModal.content, listAssignedSkills.assignments]);

  useEffect(() => {
    setTechnicalLength(
      filteredAssignedSkills.filter((skill) => skill.type === 'TECHNICAL_SKILL').length
    );
    setLanguageLength(
      filteredAssignedSkills.filter((skill) => skill.type === 'LANGUAGE_SKILL').length
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredAssignedSkills]);

  const handleCloseModal = () => {
    if (skillModal.content === 0) {
      dispatch(
        actions.updateChosenSkills({
          assignments: filteredAssignedSkills.filter((skill) => skill.type === 'TECHNICAL_SKILL'),
        })
      );
    } else if (skillModal.content === 1) {
      dispatch(
        actions.updateChosenSkills({
          assignments: filteredAssignedSkills.filter((skill) => skill.type === 'LANGUAGE_SKILL'),
        })
      );
    }
    setIsEditModal({ name: '', lvl: '', edit: false });
    dispatch(actions.setModal({ ...skillModal, visible: !skillModal.visible }));
  };
  const handleCloseModall = () => {
    setIsEditModal({ name: '', lvl: '', edit: false });
    dispatch(actions.setModal({ ...skillModal, visible: !skillModal.visible }));
  };

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  const handleModalContent = (content: number) => {
    dispatch(actions.setModal({ ...skillModal, content }));
  };

  const handleEditModal = (data: { name: string; lvl: string }) => {
    dispatch(actions.setModal({ ...skillModal, visible: true }));
    setIsEditModal({ name: data.name, lvl: data.lvl, edit: true });
  };

  return (
    <Container>
      <Heading>{t('skills-matrix.heading')}</Heading>
      <TabsContainer>
        <TabsComponent index={tabIndex} onChange={handleTabsChange}>
          <TabList>
            <TabsSeperator>
              <TabsWrapper>
                <TabWrapper onClick={() => handleModalContent(0)}>
                  <Tab>
                    <TabName>{skillTypes[Skills.technical]}</TabName>
                    <TabValue isSelected={!skillModal.content}>{technicalLength}</TabValue>
                  </Tab>
                </TabWrapper>
                <TabWrapper onClick={() => handleModalContent(1)}>
                  <Tab>
                    <TabName>{skillTypes[Skills.language]}</TabName>
                    <TabValue isSelected={skillModal.content === 1}>{languageLength}</TabValue>
                  </Tab>
                </TabWrapper>
              </TabsWrapper>
              {(skillModal.content === 0
                ? filteredAssignedSkills.filter((skill) => skill.type === 'TECHNICAL_SKILL')
                    .length !== 0
                : filteredAssignedSkills.filter((skill) => skill.type === 'LANGUAGE_SKILL')
                    .length !== 0) &&
                isProfileOwner && (
                  <TabsAddButton disabled={loading} onClick={handleCloseModal}>
                    <Add />
                    {skillModal.content === 0
                      ? t('skills-matrix.add-skills.add-technical')
                      : t('skills-matrix.add-skills.add-language')}
                  </TabsAddButton>
                )}
            </TabsSeperator>
          </TabList>
          <TabsPanelsComponent>
            <TabsPanelComponent>
              {filteredAssignedSkills.filter((skill) => skill.type === 'TECHNICAL_SKILL').length ===
                0 && !isLoading ? (
                <Content>
                  <ProfileSkillsEmptyState
                    onButtonClick={handleCloseModal}
                    ctaText={t('skills-matrix.cta.text', { skillType: skillTypes[tabIndex] })}
                  />
                </Content>
              ) : (
                <SkillsTable
                  onSkillClick={handleEditModal}
                  filteredAssignedSkills={filteredAssignedSkills.filter(
                    (skill) => skill.type === 'TECHNICAL_SKILL'
                  )}
                />
              )}
            </TabsPanelComponent>
            <TabsPanelComponent>
              {filteredAssignedSkills.filter((skill) => skill.type === 'LANGUAGE_SKILL').length ===
              0 ? (
                <Content>
                  <ProfileSkillsEmptyState
                    onButtonClick={handleCloseModal}
                    ctaText={t('skills-matrix.cta.text', { skillType: skillTypes[tabIndex] })}
                  />
                </Content>
              ) : (
                <SkillsTable
                  onSkillClick={handleEditModal}
                  filteredAssignedSkills={filteredAssignedSkills.filter(
                    (skill) => skill.type === 'LANGUAGE_SKILL'
                  )}
                />
              )}
            </TabsPanelComponent>
          </TabsPanelsComponent>
        </TabsComponent>
      </TabsContainer>
      <SkillDelete />
      <SkillModal
        visible={skillModal.visible}
        edit={isEditModal}
        onCancelClick={handleCloseModall}
      />
    </Container>
  );
};

export default ProfileSkillsPage;
