import React, { useCallback, useEffect, useRef } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';

type CallbackFn = () => void;
type ScrollContainerRef = React.RefObject<ScrollContainer>;
interface Props {
  horizontal: ScrollContainerRef[];
  vertical: ScrollContainerRef[];
}

/**
 * Easily sync multiple ScrollContainers
 *
 * Usage:
 * ```jsx
 * const getOnScroll = useSyncScrollContainers({
 *   horizontal: [ref1, ref2],
 *   vertical: [ref1, ref2]
 * });
 * ...
 * <ScrollContainer ref={ref1} onScroll={getOnScroll(ref1)} />
 * ```
 * Scrolling `ref1` horizontally or vertically will also scroll `ref2`
 */
const useSyncScrollContainers = ({
  horizontal = [],
  vertical = [],
}: Props): ((scrolledRef: ScrollContainerRef) => CallbackFn) => {
  const horizontalRefsRef = useRef(horizontal);
  const verticalRefsRef = useRef(vertical);
  const shouldPaintFrameRef = useRef(true);

  // Update refs after every render
  useEffect(() => {
    horizontalRefsRef.current = horizontal;
    verticalRefsRef.current = vertical;
  });

  return useCallback((scrolledRef: ScrollContainerRef) => {
    const syncPosition =
      (direction: 'horizontal' | 'vertical') => (targetRef: ScrollContainerRef) => {
        if (!scrolledRef.current || !targetRef.current) return;

        const scrolledRefElement = scrolledRef.current.getElement();
        const targetRefElement = targetRef.current.getElement();

        if (
          direction === 'horizontal' &&
          scrolledRefElement.scrollLeft !== targetRefElement.scrollLeft
        ) {
          targetRefElement.scrollLeft = scrolledRefElement.scrollLeft;
        } else if (
          direction === 'vertical' &&
          scrolledRefElement.scrollTop !== targetRefElement.scrollTop
        ) {
          targetRefElement.scrollTop = scrolledRefElement.scrollTop;
        }
      };

    const syncPositions = () => {
      if (horizontalRefsRef.current.includes(scrolledRef)) {
        horizontalRefsRef.current.forEach(syncPosition('horizontal'));
      }
      if (verticalRefsRef.current.includes(scrolledRef)) {
        verticalRefsRef.current.forEach(syncPosition('vertical'));
      }
    };

    return function handleScroll() {
      /**
       * Avoid calling `requestAnimationFrame` before the frame is painted, to enhance performance
       * */
      if (shouldPaintFrameRef.current) {
        window.requestAnimationFrame(() => {
          syncPositions();
          shouldPaintFrameRef.current = true;
        });
        shouldPaintFrameRef.current = false;
      }
    };
  }, []);
};

export default useSyncScrollContainers;
