import styled from 'styled-components';
import { Typography } from 'antd';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.backgrounds.dark.linear};
`;

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const GoBackText = styled(Typography.Text)`
  &.ant-typography {
    color: ${({ theme }) => theme.colors.white};
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    max-width: 308px;
    transition: 0.3s;
  }
`;

export const GoBackButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 19px;
  :hover {
    span {
      color: ${({ theme }) => theme.colors.text.version};
    }
  }
`;

export const Content = styled.div`
  height: 100%;
  width: 100%;
  max-width: 768px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Title = styled(Typography.Title)<{ isSecondary?: boolean }>`
  &.ant-typography {
    color: ${({ theme }) => theme.colors.white};
    font: ${({ theme }) => theme.typography.heading};
    font-weight: 600;
    font-size: ${({ isSecondary }) => (isSecondary ? '30px' : '52px')};
    line-height: ${({ isSecondary }) => (isSecondary ? '45px' : '78px')};
    letter-spacing: 0.74;
    margin-bottom: '16px';
  }
`;

export const SecondaryText = styled(Typography)`
  &.ant-typography {
    color: ${({ theme }) => theme.colors.pagination.primary};
    font-weight: 400;
    font-size: 17px;
    line-height: 25px;
    white-space: pre-line;
  }
`;

export const TextGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
