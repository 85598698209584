import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import {
  PHONE_NUMBER_MAX_LENGTH,
  EMPLOYEE_LOCATION_MAX_LENGTH,
} from 'features/Profile/constants/form';

import {
  CancelButton,
  EditProfileFormButtonsContainer,
  EditProfileFormContainer,
  Line,
  SaveButton,
  SaveButtonContentContainer,
  SaveButtonText,
} from './EditProfileForm.styles';
import {
  LocationInput,
  HoursInput,
  PhoneNumberInput,
  EmailInput,
} from './EditProfileFormFields/EditProfileFormFields';

export type EditProfileValues = {
  employeeLocation: string | null;
  phoneNumber: string | null;
  workingHours: string | null;
};

export type EditProfileErrors = {
  employeeLocation: string | undefined;
  phoneNumber: string | undefined;
  workingHours: string | undefined;
};

interface EditProfileFormProps {
  onCancel?: () => void;
  onSave: (values: EditProfileValues) => void;
  isLoading: boolean;
  // TODO: Update the data types once the back-end is ready
  email: string;
  employeeLocation: string | null;
  phoneNumber: string | null;
  workingHours: string | null;
  errors: EditProfileErrors;
  variant?: 'onboarding' | 'profile';
}

const EditProfileForm = ({
  onCancel,
  onSave,
  isLoading,
  email,
  employeeLocation,
  phoneNumber,
  workingHours,
  errors,
  variant = 'onboarding',
}: EditProfileFormProps): JSX.Element => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);

  const checkFormValidity = () => {
    const employeeLocationValueLength = form.getFieldValue('employeeLocation')?.length ?? 0;
    const phoneNumberValueLength = form.getFieldValue('phoneNumber')?.length ?? 0;
    const isValid =
      employeeLocationValueLength <= EMPLOYEE_LOCATION_MAX_LENGTH &&
      phoneNumberValueLength <= PHONE_NUMBER_MAX_LENGTH;
    setIsFormValid(isValid);
  };

  const handleValuesChange = (variantParam: 'profile' | 'onboarding') => {
    if (variantParam === 'profile' && form.getFieldValue('workingHours') !== null) {
      const isDirty = form.isFieldsTouched([
        'email',
        'phoneNumber',
        'employeeLocation',
        'workingHours',
      ]);
      checkFormValidity();
      setIsFormDirty(isDirty);
    } else if (
      form.getFieldValue('employeeLocation') === '' ||
      form.getFieldValue('workingHours') == null ||
      form.getFieldValue('workingHours')[0] === undefined ||
      form.getFieldValue('workingHours')[1] === undefined
    ) {
      setIsFormDirty(false);
    } else {
      setIsFormDirty(true);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setIsFormDirty(false);
    if (onCancel) {
      onCancel();
    }
  };

  const onFinish = (values: EditProfileValues) => {
    onSave(values);
    setIsFormDirty(false);
  };

  const onFinishFailed = (errorInfo: unknown) => {
    // eslint-disable-next-line no-console
    console.log('Failed:', errorInfo);
  };

  return (
    <EditProfileFormContainer>
      <Form
        key="form"
        form={form}
        onValuesChange={() => handleValuesChange(variant)}
        name="editProfileForm"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        colon={false}
        initialValues={{
          email,
          phoneNumber,
          employeeLocation,
          workingHours,
        }}
        aria-busy={isLoading}
      >
        {variant === 'onboarding' && (
          <div>
            <LocationInput errors={errors} inputDisabled={isLoading} />
            <HoursInput errors={errors} inputDisabled={isLoading} />
            <PhoneNumberInput errors={errors} inputDisabled={isLoading} />
            <Line />
          </div>
        )}

        {variant === 'profile' && (
          <div>
            <EmailInput />
            <PhoneNumberInput errors={errors} inputDisabled={isLoading} />
            <HoursInput errors={errors} inputDisabled={isLoading} />
            <LocationInput errors={errors} inputDisabled={isLoading} />
          </div>
        )}

        <EditProfileFormButtonsContainer variant={variant}>
          {variant === 'profile' && (
            <Form.Item>
              <CancelButton
                onClick={handleCancel}
                type="text"
                htmlType="button"
                disabled={isLoading}
                id="cancelButton"
              >
                {t('profile.edit-contact-information.cancel')}
              </CancelButton>
            </Form.Item>
          )}
          <Form.Item>
            <SaveButton
              id="confirmButton"
              variant={variant}
              type="primary"
              htmlType="submit"
              disabled={isLoading || !isFormDirty || !isFormValid}
            >
              {isLoading ? (
                <SaveButtonContentContainer>
                  <LoadingOutlined />
                  <SaveButtonText isLoading={isLoading}>
                    {t('profile.edit-contact-information.saving')}
                  </SaveButtonText>
                </SaveButtonContentContainer>
              ) : (
                <SaveButtonText isLoading={isLoading}>
                  {t('profile.edit-contact-information.confirm')}
                </SaveButtonText>
              )}
            </SaveButton>
          </Form.Item>
        </EditProfileFormButtonsContainer>
      </Form>
    </EditProfileFormContainer>
  );
};

export default EditProfileForm;
