import { QueryListUsersArgs, User } from 'API';
import generateGraphqlCaller from 'utils/helpers/generateGraphqlCaller';

const query = /* GraphQL */ `
  query getUser($filters: UserFilter) {
    listUsers(filters: $filters) {
      id
      name
      profilePicture
      role
      technicalRole
      seniority
      department
    }
  }
`;

export type ReturnType = {
  listUsers: Pick<
    User,
    'id' | 'name' | 'profilePicture' | 'role' | 'technicalRole' | 'seniority' | 'department'
  >[];
};

export type VariablesType = Pick<QueryListUsersArgs, 'filters'>;

export default generateGraphqlCaller<ReturnType, VariablesType>(query);
