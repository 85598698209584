import { Typography } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 690px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.background};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  height: 100%;
`;
export const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  flex-wrap: wrap;
  max-width: 380px;
  gap: 8px;
`;

export const Title = styled(Typography.Text)`
  &.ant-typography {
    color: ${({ theme }) => theme.colors.text.primary};
    font-weight: 600;
    font-size: 17px;
    line-height: 25px;
    text-align: center;
  }
`;

export const SecondaryText = styled(Typography)`
  &.ant-typography {
    color: ${({ theme }) => theme.colors.text.secondary};
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.3px;
    text-align: center;
    margin-bottom: 16px;
    max-height: 200px;
  }
`;

export const Image = styled.img`
  margin-bottom: 16px;
  max-width: 198px;
`;

export const Value = styled.span`
  color: ${({ theme }) => theme.colors.text.primary};
`;
