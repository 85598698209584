/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
import React, { ComponentPropsWithRef, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { differenceInDays, parseISO } from 'date-fns';

import { TimeOffStatus } from 'features/TimeOff/types/calendarTypes';
import { workingDaysBetweenDates } from 'features/TimeOff/utils/dateUtils';
import { TimeOffType } from 'API';
import { TimeOff } from 'features/TimeOff/helpers/mergeTimeOffRecords';

import { DaysWrapper, IconWrapper, StatusWrapper, Wrapper } from './TimeOffBar.styles';

export interface TimeOffBarProps extends ComponentPropsWithRef<'div'> {
  timeOff: TimeOff;
  detector?: JSX.Element;
  highlighted?: boolean;
}

const getEmoji = (timeOff: TimeOff) => {
  if (timeOff.status === TimeOffStatus.Pending) return '⏳';
  return '🌴';
};

export const TimeOffBar = React.forwardRef<HTMLDivElement, TimeOffBarProps>(
  (
    { timeOff, highlighted, className: propClasses, ...props }: TimeOffBarProps,
    ref
  ): JSX.Element | null => {
    const { t } = useTranslation();

    const iconRef = useRef<HTMLDivElement>(null);

    const daysOffCount =
      Math.abs(differenceInDays(parseISO(timeOff.startDate), parseISO(timeOff.endDate))) + 1;

    const ptosCount = workingDaysBetweenDates(
      parseISO(timeOff.startDate),
      parseISO(timeOff.endDate)
    );

    const getTimeOffType = (dayOff: TimeOff) => {
      if (daysOffCount > 5) {
        if (dayOff.type === TimeOffType.Unpaid) return t('time-off.unpaid');
        if (dayOff.type === TimeOffType.Other) return t('time-off.other');

        return t('time-off.timeOff');
      }
      if (daysOffCount > 4) {
        if (dayOff.type === TimeOffType.Other) return t('time-off.other-short');
      }
      if (daysOffCount > 3) {
        if (dayOff.type === TimeOffType.Paid) return t('time-off.paid-short');
        if (dayOff.type === TimeOffType.Unpaid) return t('time-off.unpaid-short');

        if (dayOff.type !== TimeOffType.Other) return t('time-off.timeOff');
      }

      return null;
    };
    return (
      <Wrapper
        daysOffCount={daysOffCount}
        timeOff={timeOff}
        className={`${propClasses} ${highlighted && 'highlight'}`}
        ref={ref}
        {...props}
      >
        <IconWrapper daysOffCount={daysOffCount} ref={iconRef}>
          {getEmoji(timeOff)}
        </IconWrapper>
        {getTimeOffType(timeOff) && <StatusWrapper>{getTimeOffType(timeOff)}</StatusWrapper>}

        {daysOffCount > 1 && (
          <DaysWrapper>
            {ptosCount > 4 ? `${t('time-off.days-off-count', { daysOff: ptosCount })}` : ptosCount}
          </DaysWrapper>
        )}
      </Wrapper>
    );
  }
);

export default TimeOffBar;
