import React, { useState } from 'react';
import styled from 'styled-components';

import style from 'features/TimeOff/constants/style';
import { TimeOffGroup, UserTimeOff } from 'features/TimeOff/types/calendarTypes';
import { emitMoveCalendarTo } from 'features/TimeOff/subjects/moveCalendarTo';
import useCalendarDateOffset from 'features/TimeOff/hooks/useDateOffset';
import { onTimeOffVisibilityChange$ } from 'features/TimeOff/subjects/timeOffVisibilityChange';
import { IntersectionState } from 'hooks/useInViewport';
import { emitHoverTimeline } from 'features/TimeOff/subjects/hoverTimeline';
import useSubscription from 'hooks/useSubscription';
import { TimeOff } from 'features/TimeOff/helpers/mergeTimeOffRecords';

import TimeOffHiddenWithPopover from './TimeOffHiddenWithPopover';

const TimesOffWrapper = styled.div`
  margin-bottom: ${style.groupName.height}px;
`;

const ButtonsContainer = styled.div`
  position: relative;
  height: ${style.timeOffRow.height}px;
`;

type HiddenMap = Record<TimeOff['id'], IntersectionState>;

const generateHiddenMap = (timesOff: TimeOff[]): HiddenMap => {
  return timesOff.reduce((acc, item) => {
    // eslint-disable-next-line no-param-reassign
    acc[item.id] = IntersectionState.Unknown;
    return acc;
  }, {} as HiddenMap);
};

const ButtonsRow = ({ row }: { row: UserTimeOff }): JSX.Element => {
  const [hiddenMap, setHiddenMap] = useState<HiddenMap>(generateHiddenMap(row.timesOff));

  const getTimeOffOffset = useCalendarDateOffset();

  useSubscription(onTimeOffVisibilityChange$, (payload) => {
    if (!row.timesOff.find((timeOff) => timeOff.id === payload.timeOff.id)) return;

    setHiddenMap((currentMap) => ({ ...currentMap, [payload.timeOff.id]: payload.state }));
  });

  const onTimeOffClick = (timeOff: TimeOff) => {
    emitMoveCalendarTo({
      destination: getTimeOffOffset(new Date(timeOff.startDate), -2),
      target: timeOff,
    });
  };

  const leftHidden = row.timesOff.filter(
    (timeOff) => hiddenMap[timeOff.id] === IntersectionState.HiddenLeft
  );
  const rightHidden = row.timesOff.filter(
    (timeOff) => hiddenMap[timeOff.id] === IntersectionState.HiddenRight
  );
  const onVisibleChange = (visible: boolean) => {
    emitHoverTimeline(visible ? row.id : null);
  };

  return (
    <ButtonsContainer>
      <TimeOffHiddenWithPopover
        timesOff={leftHidden}
        direction="left"
        onVisibleChange={onVisibleChange}
        onTimeOffClick={onTimeOffClick}
        align={{
          points: ['tl', 'bl'],
          offset: [style.dayHead.width * 0.5, 0],
          overflow: { adjustX: false, adjustY: false },
        }}
      />
      <TimeOffHiddenWithPopover
        timesOff={rightHidden}
        direction="right"
        onVisibleChange={onVisibleChange}
        onTimeOffClick={onTimeOffClick}
        align={{
          points: ['tr', 'br'],
          offset: [-style.dayHead.width * 0.5, 0],
          overflow: { adjustX: false, adjustY: false },
        }}
      />
    </ButtonsContainer>
  );
};

const FutureIndicator = ({ timesOff }: { timesOff: TimeOffGroup }): JSX.Element => {
  return (
    <TimesOffWrapper>
      {timesOff.timesOff.map((group) => {
        return (
          <div key={group.id}>
            <ButtonsRow row={group} />
          </div>
        );
      })}
    </TimesOffWrapper>
  );
};

export default FutureIndicator;
