import styled, { Keyframes, keyframes, ThemeProps } from 'styled-components';

import { ThemeType } from 'components/ThemeProvider';

import style from 'features/TimeOff/constants/style';
import { TimeOffStatus } from 'features/TimeOff/types/calendarTypes';
import { TimeOffType } from 'API';

import { TimeOffBarProps } from './TimeOffBar';

export const getHighlightAnimation = ({
  timeOff,
  theme: { colors },
}: TimeOffBarProps & ThemeProps<ThemeType>): Keyframes => keyframes`
      0% {
        background: ${(() => {
          if (timeOff.status === TimeOffStatus.Pending) return colors.pending.active.a;
          return colors.approved.active.body;
        })()};
        color: ${(() => {
          if (timeOff.status === TimeOffStatus.Pending) return colors.pending.active.c;
          if (timeOff.type === TimeOffType.Paid) return colors.paid.active.text;
          if (timeOff.type === TimeOffType.Unpaid) return colors.unpaid.active.text;
          if (timeOff.type === TimeOffType.Other) return colors.otherTimeOff.active.text;
          return colors.approved.active.text;
        })()};
        border: ${(() => {
          if (timeOff.status === TimeOffStatus.Pending)
            return `solid 1px ${colors.pending.active.c}`;
          if (timeOff.type === TimeOffType.Paid) return `solid 1px ${colors.paid.active.border}`;
          if (timeOff.type === TimeOffType.Unpaid)
            return `solid 1px ${colors.unpaid.active.border}`;
          if (timeOff.type === TimeOffType.Other)
            return `solid 1px ${colors.otherTimeOff.active.border}`;
          return `solid 1px ${colors.approved.active.border}`;
        })()};
  
        & > div {
          background: ${(() => {
            if (timeOff.status === TimeOffStatus.Pending) return colors.pending.active.b;
            if (timeOff.type === TimeOffType.Paid) return colors.paid.active.body;
            if (timeOff.type === TimeOffType.Unpaid) return colors.unpaid.active.body;
            if (timeOff.type === TimeOffType.Other) return colors.otherTimeOff.active.body;
            return colors.approved.active.body;
          })()};
          color: ${(() => {
            if (timeOff.status === TimeOffStatus.Pending) return colors.pending.active.c;
            if (timeOff.type === TimeOffType.Paid) return colors.paid.active.text;
            if (timeOff.type === TimeOffType.Unpaid) return colors.unpaid.active.text;
            if (timeOff.type === TimeOffType.Other) return colors.otherTimeOff.active.text;
            return colors.approved.active.text;
          })()};
        }
      }
  `;

export const Wrapper = styled.div<TimeOffBarProps & { daysOffCount: number }>`
  position: absolute;
  z-index: 2;
  cursor: pointer;
  padding: 6px 8px;
  border-radius: 4px;
  height: ${style.timeOffBar.height}px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  top: 50%;
  transform: translateY(-50%);
  width: ${({ daysOffCount }) => `calc(${daysOffCount} * ${style.dayHead.width}px)`};

  background: ${({ timeOff, theme: { colors } }) => {
    if (timeOff.status === TimeOffStatus.Pending) return colors.pending.default.a;
    if (timeOff.type === TimeOffType.Paid) return colors.paid.default.body;
    if (timeOff.type === TimeOffType.Unpaid) return colors.unpaid.default.body;
    if (timeOff.type === TimeOffType.Other) return colors.otherTimeOff.default.body;
    return colors.approved.default.body;
  }};
  color: ${({ timeOff, theme: { colors } }) => {
    if (timeOff.status === TimeOffStatus.Pending) return colors.pending.default.c;
    if (timeOff.type === TimeOffType.Paid) return colors.paid.default.text;
    if (timeOff.type === TimeOffType.Unpaid) return colors.unpaid.default.text;
    if (timeOff.type === TimeOffType.Other) return colors.otherTimeOff.default.text;
    return colors.approved.default.text;
  }};
  border: ${({ timeOff, theme: { colors } }) => {
    if (timeOff.status === TimeOffStatus.Pending) return `solid 1px ${colors.pending.default.c}`;
    if (timeOff.type === TimeOffType.Paid) return `solid 1px ${colors.paid.default.border}`;
    if (timeOff.type === TimeOffType.Unpaid) return `solid 1px ${colors.unpaid.default.border}`;
    if (timeOff.type === TimeOffType.Other)
      return `solid 1px ${colors.otherTimeOff.default.border}`;
    return `solid 1px ${colors.approved.default.border}`;
  }};

  & > div {
    background: ${({ timeOff, theme: { colors } }) => {
      if (timeOff.status === TimeOffStatus.Pending) return colors.pending.default.b;
      if (timeOff.type === TimeOffType.Paid) return colors.paid.default.background;
      if (timeOff.type === TimeOffType.Unpaid) return colors.unpaid.default.background;
      if (timeOff.type === TimeOffType.Other) return colors.otherTimeOff.default.background;
      return colors.approved.default.background;
    }};
    color: ${({ timeOff, theme: { colors } }) => {
      if (timeOff.status === TimeOffStatus.Pending) return colors.pending.default.c;
      if (timeOff.type === TimeOffType.Paid) return colors.paid.default.text;
      if (timeOff.type === TimeOffType.Unpaid) return colors.unpaid.default.text;
      if (timeOff.type === TimeOffType.Other) return colors.otherTimeOff.default.text;
      return colors.approved.default.text;
    }};
  }

  &:hover {
    background: ${({ timeOff, theme: { colors } }) => {
      if (timeOff.status === TimeOffStatus.Pending) return colors.pending.hover.a;
      if (timeOff.type === TimeOffType.Paid) return colors.paid.hover.body;
      if (timeOff.type === TimeOffType.Unpaid) return colors.unpaid.hover.body;
      if (timeOff.type === TimeOffType.Other) return colors.otherTimeOff.hover.body;
      return colors.approved.hover.body;
    }};
    color: ${({ timeOff, theme: { colors } }) => {
      if (timeOff.status === TimeOffStatus.Pending) return colors.pending.hover.c;
      if (timeOff.type === TimeOffType.Paid) return colors.paid.hover.text;
      if (timeOff.type === TimeOffType.Unpaid) return colors.unpaid.hover.text;
      if (timeOff.type === TimeOffType.Other) return colors.otherTimeOff.hover.text;
      return colors.approved.hover.text;
    }};
    border: ${({ timeOff, theme: { colors } }) => {
      if (timeOff.status === TimeOffStatus.Pending) return `solid 1px ${colors.pending.hover.c}`;
      if (timeOff.type === TimeOffType.Paid) return `solid 1px ${colors.paid.hover.border}`;
      if (timeOff.type === TimeOffType.Unpaid) return `solid 1px ${colors.unpaid.hover.border}`;
      if (timeOff.type === TimeOffType.Other)
        return `solid 1px ${colors.otherTimeOff.hover.border}`;
      return `solid 1px ${colors.approved.hover.border}`;
    }};

    transition: 0.2s ease-in-out;

    & > div {
      background: ${({ timeOff, theme: { colors } }) => {
        if (timeOff.status === TimeOffStatus.Pending) return colors.pending.hover.b;
        if (timeOff.type === TimeOffType.Paid) return colors.paid.hover.background;
        if (timeOff.type === TimeOffType.Unpaid) return colors.unpaid.hover.background;
        if (timeOff.type === TimeOffType.Other) return colors.otherTimeOff.hover.background;
        return colors.approved.hover.background;
      }};
      color: ${({ timeOff, theme: { colors } }) => {
        if (timeOff.status === TimeOffStatus.Pending) return colors.pending.hover.c;
        if (timeOff.type === TimeOffType.Paid) return colors.paid.hover.text;
        if (timeOff.type === TimeOffType.Unpaid) return colors.unpaid.hover.text;
        if (timeOff.type === TimeOffType.Other) return colors.otherTimeOff.hover.text;
        return colors.approved.hover.text;
      }};

      transition: 0.2s ease-in-out;
    }
  }

  &.highlight {
    animation: ${(props) => getHighlightAnimation(props)} ${style.highlight.animationDuration}ms
      ease-out;
  }
`;

export const IconWrapper = styled.div<{ daysOffCount: number }>`
  padding: 4px;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({ daysOffCount }) => (daysOffCount > 1 ? '8px' : '0px')};
`;

export const StatusWrapper = styled.p`
  padding: 0 8px;
  font-size: 11px;
  font: ${({ theme }) => theme.typography.status};
  text-transform: uppercase;
  flex-grow: 1;
  margin-right: 8px;
  margin-bottom: 0;
`;

export const DaysWrapper = styled.div`
  padding: 0 8px;
  border-radius: 4px;
  font: ${({ theme }) => theme.typography.status};
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;
