import { PrivateUser } from 'API';
import generateGraphqlCaller from 'utils/helpers/generateGraphqlCaller';

const query = `
query queryMyTeam {
    getOwnUser {
      ... on PrivateUser {
        team
        teamMembers {
          ... on TeamMembersResult {
            members {
              contractType {
                ... on ContractType {
                  shortName
                }
                ... on SalesforceError {
                  message
                }
                ... on UnexpectedError {
                  message
                }
                ... on Error {
                  message
                }
              }
              name
              id
              profilePicture
              ptoAllowance {
                ... on IntValue {
                  value
                }
                ... on SalesforceError {
                  message
                }
                ... on UnexpectedError {
                  message
                }
                ... on Error {
                  message
                }
              }
              ptoLeft {
            ... on PTOLeftResult {
              ptoCurrent
              ptoLeft
              ptoUsed
            }
            ... on UOPPTOLeftResult {
              ptoCarryover
              ptoCurrent
              ptoLeft
              ptoUsed
            }
            ... on UnlimitedPTOLeftResult {
              ptoUsed
            }
            ... on SalesforceError {
              message
            }
            ... on JiraError {
              message
            }
            ... on BIError {
              message
            }
            ... on UnexpectedError {
              message
            }
            ... on Error {
              message
            }
          }
              hasUnlimitedPto {
            ... on BoolValue {
              value
            }
            ... on SalesforceError {
              message
            }
            ... on UnexpectedError {
              message
            }
            ... on Error {
              message
            }
          }
              hadAnnualVacationLeaveUop {
            ... on BoolValue {
              value
            }
            ... on JiraError {
              message
            }
            ... on UnexpectedError {
              message
            }
            ... on Error {
              message
            }
          }
        }
      }
    }
  }
 }}`;

export type ReturnType = {
  getOwnUser: {
    teamMembers: {
      members: Pick<
        PrivateUser,
        | 'contractType'
        | 'name'
        | 'id'
        | 'profilePicture'
        | 'ptoAllowance'
        | 'ptoLeft'
        | 'hasUnlimitedPto'
        | 'hadAnnualVacationLeaveUop'
      >[];
    };
  };
};

export default generateGraphqlCaller<ReturnType>(query);
