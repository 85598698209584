import React from 'react';
import { useTranslation } from 'react-i18next';

import { getLevelDescription } from 'features/Profile/helpers/getLevelDescription';

import {
  LevelInfo,
  LevelContainer,
  LevelButtons,
  LevelButtonHeader,
  InputCheckbox,
  LevelData,
} from './SkillLevel.styles';

interface SkillLevelProps {
  name: string;
  icon: React.ReactNode;
  checked: boolean;
  onClick: () => void;
  language?: boolean;
}

const SkillLevelCheckbox = ({
  name,
  icon,
  checked,
  onClick,
  language = false,
}: SkillLevelProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <LevelContainer onClick={onClick} className={checked ? 'active' : ''}>
      <LevelButtons>
        <LevelButtonHeader>
          {icon}
          {name}
        </LevelButtonHeader>
        <InputCheckbox type="checkbox" name="skill" checked={checked} value={name} />
      </LevelButtons>
      {!language && (
        <LevelInfo>
          <LevelData>{getLevelDescription(t, name)}</LevelData>
        </LevelInfo>
      )}
    </LevelContainer>
  );
};
export default SkillLevelCheckbox;
