import React from 'react';
import { UserOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { areRolesNotEqual, shouldRenderTechnicalRole } from 'features/Profile/helpers/roleChecks';
import { EditProfile } from 'features/Profile/components/index';
import ProfileDetailsListItem from 'features/Profile/components/ProfileDetailsListItem';
import { ReactComponent as IconMail } from 'assets/iconMail.svg';
import { ReactComponent as IconPhone } from 'assets/iconPhone.svg';
import { ReactComponent as IconPlus } from 'assets/Plus.svg';
import { ReactComponent as IconClock } from 'assets/iconClock.svg';
import { ReactComponent as IconMonitor } from 'assets/iconMonitor.svg';
import { useGetProfileById } from 'services/api/profile';
import { useIsProfileOwner } from 'features/Profile/hooks/useIsProfileOwner';
import useToggle from 'hooks/useToggle';
import { ProfileRouteParams } from 'features/Profile/pages/Profile/Profile';

import {
  DetailInformationContainer,
  DetailInformationHeader,
  DetailInformationList,
  ProfileAvatar,
  SectionTitle,
  UserDetailsCenterWrapper,
  UserDetailsContainer,
  UserDetailsDepartmentBadge,
  UserDetailsName,
  UserDetailsRole,
  UserDetailsSeniority,
  UserDetailsSeniorityLevel,
  UserDetailsTeamContainer,
  UserDetailsTextContainer,
  UserInformationSection,
  UserSeniorityTextContainer,
  UserTechnicalRole,
} from './UserSection.styles';

const ProfileOverviewUserSection = (): JSX.Element => {
  const { userId } = useParams<{ userId: string }>() as ProfileRouteParams;
  const isProfileOwner = useIsProfileOwner(userId);
  const { data: profileData } = useGetProfileById(userId);
  const { isOpen: isEditProfileModalOpen, setIsOpen: setIsEditProfileModalOpen } = useToggle(false);
  const { t } = useTranslation();

  const profile = profileData?.getUser || {};
  const {
    department,
    email,
    employeeLocation,
    name,
    phoneNumber,
    profilePicture,
    role,
    seniority,
    seniorityLevel,
    technicalRole,
    workingHours,
  } = profile;

  const showModal = () => {
    setIsEditProfileModalOpen(true);
  };

  return (
    <UserInformationSection>
      <SectionTitle>{t('profile.profile-details.title')}</SectionTitle>
      <UserDetailsContainer>
        <UserDetailsCenterWrapper>
          <ProfileAvatar
            size={120}
            src={profilePicture?.replace('=s100', '=s200')}
            icon={<UserOutlined />}
          />
          <UserDetailsTextContainer>
            <UserDetailsName id="userDetailsName">{name}</UserDetailsName>
            <UserSeniorityTextContainer id="userTechnicalRoleLabel">
              {role !== 'Designer' && <UserDetailsRole>{`${role} `}</UserDetailsRole>}
              {areRolesNotEqual(role, technicalRole) && (
                <UserTechnicalRole ellipsis={{ tooltip: true }}>
                  {shouldRenderTechnicalRole(role, technicalRole) && `${technicalRole} `}
                </UserTechnicalRole>
              )}

              {seniority !== null && (
                <>
                  <span>[</span>
                  {isProfileOwner && (
                    <>
                      <UserDetailsSeniorityLevel>{seniorityLevel}</UserDetailsSeniorityLevel>
                      <span>{seniorityLevel && <>&nbsp;-&nbsp;</>}</span>
                    </>
                  )}
                  <UserDetailsSeniority>{seniority}</UserDetailsSeniority>
                  <span>]</span>
                </>
              )}
            </UserSeniorityTextContainer>
          </UserDetailsTextContainer>
        </UserDetailsCenterWrapper>
        <UserDetailsTeamContainer id="userDepartmentLabel">
          {department !== null ? (
            <UserDetailsDepartmentBadge>
              {department}
              {t('profile.profile-details.department')}
            </UserDetailsDepartmentBadge>
          ) : (
            ' '
          )}
        </UserDetailsTeamContainer>
      </UserDetailsContainer>
      <DetailInformationContainer>
        <DetailInformationHeader>
          <SectionTitle id="detailInformationLabel">
            {t('profile.detail-information.title')}
          </SectionTitle>
          {isProfileOwner ? (
            <EditProfile
              isEditProfileModalOpen={isEditProfileModalOpen}
              setIsEditProfileModalOpen={setIsEditProfileModalOpen}
            />
          ) : (
            ''
          )}
        </DetailInformationHeader>

        <DetailInformationList>
          {email && (
            <ProfileDetailsListItem
              name={email}
              value={t('profile.detail-information.email-address')}
              icon={<IconMail />}
              id="emailItem"
            />
          )}
          {/* eslint-disable-next-line no-nested-ternary */}
          {phoneNumber && (
            <ProfileDetailsListItem
              name={phoneNumber}
              value={t('profile.detail-information.phone-number')}
              icon={<IconPhone />}
              id="phoneNumberItem"
            />
          )}
          {!phoneNumber && isProfileOwner && (
            <ProfileDetailsListItem
              onClick={() => showModal()}
              name={t('profile.detail-information.phone-number-add')}
              value={t('profile.detail-information.phone-number')}
              icon={<IconPlus />}
              id="addPhoneNumberItem"
            />
          )}
          {workingHours && (
            <ProfileDetailsListItem
              name={workingHours}
              value={t('profile.detail-information.working-hours')}
              icon={<IconClock />}
              id="workingHoursItem"
            />
          )}
          {!workingHours && isProfileOwner && (
            <ProfileDetailsListItem
              onClick={() => showModal()}
              name={t('profile.detail-information.working-hours-add')}
              value={t('profile.detail-information.working-hours')}
              icon={<IconPlus />}
              id="addWorkingHoursItem"
            />
          )}
          {employeeLocation && (
            <ProfileDetailsListItem
              name={employeeLocation}
              value={t('profile.detail-information.current-location')}
              icon={<IconMonitor />}
              id="employeeLocationItem"
            />
          )}
          {!employeeLocation && isProfileOwner && (
            <ProfileDetailsListItem
              onClick={() => showModal()}
              name={t('profile.detail-information.current-location-add')}
              value={t('profile.detail-information.current-location')}
              icon={<IconPlus />}
              id="addEmployeeLocationItem"
            />
          )}
        </DetailInformationList>
      </DetailInformationContainer>
    </UserInformationSection>
  );
};

export default ProfileOverviewUserSection;
