import { DeleteFavouriteResponse, MutationDeleteFavouriteUserArgs } from 'API';
import generateGraphqlCaller from 'utils/helpers/generateGraphqlCaller';

const query = /* GraphQL */ `
  mutation DeleteFavouriteUser($ownerId: String!, $favouritedId: String!) {
    deleteFavouriteUser(ownerId: $ownerId, favouritedId: $favouritedId) {
      ... on DeleteFavouriteSuccessResponse {
        deletedFavouriteId
      }
      ... on DeleteFavouriteErrorResponse {
        deletedId: deletedFavouriteId
        errors {
          ... on UserNotFoundError {
            message
          }
          ... on UnexpectedError {
            message
          }
          ... on Error {
            message
          }
        }
      }
    }
  }
`;

type ReturnType = {
  deleteFavouriteUser: DeleteFavouriteResponse;
};

export default generateGraphqlCaller<ReturnType, MutationDeleteFavouriteUserArgs>(query);
