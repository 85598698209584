import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

export interface TodayButtonProps {
  onButtonPress: () => void;
}

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  background: ${({ theme: { colors } }) => colors.white};
`;

const TitleButton = styled.button`
  height: auto;
  border: none;
  box-shadow: none;
  padding: 1px 8px;
  background: ${({ theme }) => theme.colors.white};
  font: ${({ theme }) => theme.typography.subtitles};
  color: ${({ theme }) => theme.colors.primary};
  &:hover {
    background-color: ${({ theme }) => theme.colors.neutral['400']};
  }
  &:active {
    background-color: ${({ theme }) => theme.colors.neutral['500']};
  }
`;

const Fade = styled.div<{ direction: 'left' | 'right' }>`
  background: ${({ direction, theme }) => {
    const orientation = direction === 'left' ? 'to left' : 'to right';
    const startColor = theme.colors.white;
    const endColor = `rgba(255, 255, 255, 0)`;
    return `linear-gradient(${orientation}, ${startColor} 20%, ${endColor} 100%);`;
  }};
  width: 30px;
  ${({ direction }) =>
    direction === 'right'
      ? css`
          margin-right: -30px;
        `
      : css`
          margin-left: -30px;
        `}
`;

const TodayButton = ({ onButtonPress }: TodayButtonProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <ButtonWrapper>
      <Fade direction="left" />
      <TitleButton onClick={onButtonPress}>{t(`time-off.today`)}</TitleButton>
    </ButtonWrapper>
  );
};

export default TodayButton;
