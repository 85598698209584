import { QueryObserverResult, useQuery } from '@tanstack/react-query';

import { cacheTime } from 'api/Client';
import { showError } from 'utils/helpers/showMessage';
import { PrivateUser } from 'API';

import queryMyTeam, { ReturnType } from '../api/queryMyTeam';

type TeamMembers = Pick<
  PrivateUser,
  | 'contractType'
  | 'name'
  | 'id'
  | 'profilePicture'
  | 'ptoAllowance'
  | 'ptoLeft'
  | 'hasUnlimitedPto'
  | 'hadAnnualVacationLeaveUop'
>[];
interface IUseMyTeamQuery {
  myTeam: TeamMembers | undefined;
  refetch(): Promise<QueryObserverResult<ReturnType, unknown>>;
  isLoading: boolean;
  isFetching: boolean;
  error: unknown;
  isError: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function showErrorIfMessageExists(object: any) {
  if ('message' in object) {
    showError({ content: object.message });
  }
}

export default function useMyTeamQuery(): IUseMyTeamQuery {
  const { data, refetch, isLoading, isFetching, error, isError } = useQuery(
    ['queryMyTeam'],
    async () => {
      const result = await queryMyTeam();

      result.getOwnUser.teamMembers.members.forEach((member) => {
        showErrorIfMessageExists(member.contractType);
        showErrorIfMessageExists(member.ptoLeft);
        showErrorIfMessageExists(member.ptoAllowance);
        showErrorIfMessageExists(member.hasUnlimitedPto);
      });
      return result;
    },
    {
      staleTime: cacheTime,
    }
  );
  const myTeam = data?.getOwnUser.teamMembers.members || undefined;
  return { myTeam, refetch, isLoading, isFetching, error, isError };
}
