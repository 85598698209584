import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { uniqueId } from 'lodash';
import { useSelector } from 'react-redux';

import mutateAssignSkillsToUser from 'features/Profile/api/mutateAssignSkillsToUser';
import { ReactComponent as ArrowLeftIcon } from 'assets/Arrow-left.svg';
import { useAppDispatch } from 'redux/store';
import mutateEditSkillAssignmentOfUser from 'features/Profile/api/mutateEditSkillAssignmentOfUser';
import { actions, selectSkills } from 'features/Profile/redux/skillSlice';
import { ReviewOutcome } from 'API';

import { Modal, ActionButton, TextButton } from './SkillModal.styles';
import { Header, HeaderTitle, LeftArrow } from './SkillLevelSelection/SkillLevelSelection.styles';
import SkillSelection from './SkillSelection';
import SkillLevelSelection from './SkillLevelSelection';

interface SkillModalProps {
  visible: boolean;
  edit?: { name: string; lvl: string; edit: boolean };
  onCancelClick: () => void;
}

const SkillModal = ({ visible, edit, onCancelClick }: SkillModalProps): JSX.Element => {
  const [isLevelSelection, setIsLevelSelection] = useState<boolean>(edit?.edit || false);
  const [level, setLevel] = useState<string>('');
  const [lang, setLang] = useState<string>('');
  const [skillName, setSkillName] = useState<string>('');
  const [type, setType] = useState<string>('TECHNICAL_SKILL');
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { listChosenSkills, listAssignedSkills, skillModal, isLoading } = useSelector(selectSkills);
  const handleSkillClick = (skill: string, language: string, levelSelected?: string) => {
    setIsLevelSelection(true);
    setSkillName(skill);
    setLang(language);
    setLevel(levelSelected || '');
  };

  const today = new Date();
  const formattedDate = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  }).format(today);

  useEffect(() => {
    if (edit?.edit) {
      handleSkillClick(edit.name, '', edit.lvl);
    }
  }, [edit]);

  useEffect(() => {
    if (skillModal.content === 0) {
      setType('TECHNICAL_SKILL');
    } else {
      setType('LANGUAGE_SKILL');
    }
  }, [skillModal.content]);

  const handleLevel = (lvl: string) => {
    setLevel(lvl);
  };
  const onConfirmSkill = async () => {
    if (listChosenSkills.assignments.some((element) => element.name === skillName)) {
      const foundIndex = [...listChosenSkills.assignments]
        .sort((a, b) => a.name.localeCompare(b.name))
        .findIndex((element) => element.name === skillName);
      dispatch(
        actions.updateSkillLevel({
          name: skillName,
          index: foundIndex,
          lvl: level.toUpperCase().split(' ')[0],
          updated: formattedDate,
        })
      );
      if (edit?.edit) {
        await mutateEditSkillAssignmentOfUser({
          lvl: level.toUpperCase().split(' ')[0],
          skillName,
        });
        dispatch(
          actions.updateAssignedSkillLevel({
            name: skillName,
            index: foundIndex,
            lvl: level.toUpperCase().split(' ')[0],
            updated: formattedDate,
          })
        );
        dispatch(actions.setModal({ ...skillModal, visible: false }));
        setIsLevelSelection(false);
      }
      setIsLevelSelection(false);
    } else {
      dispatch(
        actions.setChosenSkills({
          name: skillName,
          lvl: level.toUpperCase().split(' ')[0],
          langCode: lang,
          type,
          updated: formattedDate,
          action: '',
          status: ReviewOutcome.InProgress,
          id: uniqueId(),
        })
      );
      setIsLevelSelection(false);
    }
  };

  const onPublishSkills = async () => {
    dispatch(actions.setLoading(true));
    const skills = listChosenSkills.assignments
      .filter((skill) => !listAssignedSkills.assignments.includes(skill))
      .map((skill) => {
        const lvl = skill.lvl.toUpperCase().split(' ')[0];
        const { name } = skill;
        return {
          name,
          lvl: skill.langCode && lvl.length > 0 ? lvl : skill.lvl.toUpperCase(),
        };
      });
    await mutateAssignSkillsToUser({ nameLvlList: skills });
    dispatch(actions.setAssignedSkills(listChosenSkills));
    onCancelClick();
  };

  const modalContent = isLevelSelection ? (
    <SkillLevelSelection handleLevel={handleLevel} skillName={skillName} skillLevel={level} />
  ) : (
    <SkillSelection onSkillClick={handleSkillClick} />
  );

  const title =
    skillModal.content === 0
      ? t('skills-matrix.add-skills.add-technical')
      : t('skills-matrix.add-skills.add-language');

  const modalTitle = isLevelSelection ? (
    <Header>
      {!edit?.edit && (
        <LeftArrow
          onClick={() => {
            setIsLevelSelection(false);
            setLevel('');
          }}
        >
          <ArrowLeftIcon />
        </LeftArrow>
      )}
      <HeaderTitle>{t('skills-matrix.skill-experience.select-level')}</HeaderTitle>
    </Header>
  ) : (
    title
  );

  const footer = isLevelSelection ? (
    <>
      <TextButton
        onClick={
          !edit?.edit
            ? () => {
                setIsLevelSelection(false);
                setLevel('');
              }
            : () => {
                setIsLevelSelection(false);
                onCancelClick();
                setLevel('');
              }
        }
      >
        {!edit?.edit
          ? t('skills-matrix.skill-experience.back')
          : t('skills-matrix.add-skills.cancel')}
      </TextButton>
      <ActionButton onClick={onConfirmSkill} disabled={level === ''}>
        {t('skills-matrix.skill-experience.confirm')}
      </ActionButton>
    </>
  ) : (
    <>
      <TextButton onClick={onCancelClick}>{t('skills-matrix.add-skills.cancel')}</TextButton>
      <ActionButton disabled={isLoading} onClick={onPublishSkills}>
        {t('skills-matrix.add-skills.publish')}
      </ActionButton>
    </>
  );
  if (skillModal.visible) {
    return (
      <Modal
        centered
        closable={false}
        visible={visible}
        title={modalTitle}
        width={isLevelSelection ? 404 : 800}
        footer={footer}
      >
        {modalContent}
      </Modal>
    );
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};

export default SkillModal;
