import React, { ComponentPropsWithRef } from 'react';
import { Skeleton } from 'antd';
import styled from 'styled-components';

import style from 'features/TimeOff/constants/style';

const TimeOffBarSkeleton = styled(Skeleton.Input)<{ days: number }>`
  border-radius: 4px;
  width: ${({ days }) => `${days * style.dayHead.width}px`};
  height: ${style.timeOffBar.height}px;
  z-index: 15;
`;

const SkeletonWrapper = styled.div`
  position: absolute;
  top: ${style.timeOffBar.top}px;
  z-index: 15;
`;

export interface TimeOffBarLoadingProps extends ComponentPropsWithRef<'div'> {
  days: number;
}

export const TimeOffBarLoading = ({ days, ...props }: TimeOffBarLoadingProps): JSX.Element => {
  return (
    <SkeletonWrapper {...props}>
      <TimeOffBarSkeleton active days={days} />
    </SkeletonWrapper>
  );
};

export default TimeOffBarLoading;
