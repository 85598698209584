import React, { useCallback, useEffect, useRef, useState } from 'react';
import { isToday, isWeekend, parseISO } from 'date-fns';
import { isHoliday } from 'poland-public-holidays';
import styled from 'styled-components';
import { Typography } from 'antd';
import { useSelector } from 'react-redux';
import ScrollContainer from 'react-indiana-drag-scroll';

import { ThemeType } from 'components/ThemeProvider';

import style from 'features/TimeOff/constants/style';
import { selectCalendarRange } from 'features/TimeOff/redux/calendarRange';
import {
  DraggableScrollContainer,
  ScrollableElementProps,
} from 'features/TimeOff/pages/TimeOffPage';
import { queries } from 'shared/layout';
import { dayNames, groupDaysByMonth, monthNames } from 'features/TimeOff/utils/dateUtils';
import useCalendarDateOffset from 'features/TimeOff/hooks/useDateOffset';
import { CALENDAR_SCROLL_DISTANCE } from 'features/TimeOff/constants/controls';
import { emitMoveCalendar } from 'features/TimeOff/subjects/moveCalendar';
import { emitMoveCalendarTo } from 'features/TimeOff/subjects/moveCalendarTo';
import useInViewport, { IntersectionState } from 'hooks/useInViewport';
import { selectIsCalendarLoading } from 'features/TimeOff/redux/timesOffSlice';

import ArrowButtons from '../CalendarControls/ArrowButtons';
import TodayButton from '../CalendarControls/TodayButton';

const { Text, Title } = Typography;

const MonthHeading = styled.div`
  position: relative;
  .month-name {
    display: inline-block;
    font: ${({ theme }) => theme.typography.subtitles};
    color: ${({ theme: { colors } }) => colors.primary};
  }
`;

const DateWrapper = styled.div`
  position: sticky;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const DaysHeading = styled.div`
  display: flex;
  align-self: flex-end;
`;

interface DayHeadProps {
  today: boolean;
  weekend: boolean;
  holiday: boolean;
  theme: ThemeType;
}

const DayHead = styled.div<DayHeadProps>`
  width: ${style.dayHead.width}px;
  height: ${style.dayHead.height}px;
  background: ${({ today, theme }) => (today ? theme.colors.secondary['200'] : theme.colors.white)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .day-number {
    font: ${({ theme }: { theme: ThemeType }) => theme.typography.body};
    font-weight: 400;
    color: ${({ today, weekend, holiday, theme }) => {
      if (today) return theme.colors.white;
      if (holiday) return theme.colors.red.dust5;
      if (weekend) return theme.colors.gray['500'];
      return theme.colors.primary;
    }};
  }

  .day-name {
    font: ${({ theme }: { theme: ThemeType }) => theme.typography.body};
    font-weight: 400;
    color: ${({ today, weekend, holiday, theme }) => {
      if (today) return theme.colors.white;
      if (holiday) return theme.colors.red.dust4;
      if (weekend) return theme.colors.gray['500'];
      return theme.colors.gray['700'];
    }};
  }
`;

const MonthsHeaderWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  // For mobile layout add 78px (EmployeeList width) left padding
  padding-left: 78px;

  ${queries.tablet} {
    padding-left: 0;
  }
`;

const HeaderDivider = styled.div`
  height: 1px;
  width: 100%;
  background: ${({ theme }) => theme.colors.neutral['300']};
`;

const AbsoluteTodayButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: calc(100% - ${style.dayHead.width * 2}px);
  z-index: 4;

  ${queries.phone} {
    left: calc(100% - ${style.dayHead.width * 3}px);
  }
`;

const CurrentMonthWrapper = styled.div`
  background: ${({ theme }) => theme.colors.white};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
`;

const CurrentMonthInnerWrapper = styled.div`
  display: flex;
  position: relative;
`;

const CurrentMonthText = styled.span`
  padding-left: ${style.dayHead.width / 2}px;
  font: ${({ theme }) => theme.typography.subtitles};

  ${queries.phone} {
    padding-left: ${style.dayHead.width}px;
  }
`;

const CurrentMonthRightBlock = styled.div`
  width: 30px;
  margin-right: -30px;
  height: 21px;
  background-image: linear-gradient(to right, white 20%, rgba(255, 255, 255, 0) 100%);
`;

interface DateWrapperContainerProps {
  monthIndex: number;
  setCurrentMonthGroupIndex: (newMonthGroup: number) => void;
  children: React.ReactNode;
  scrollContainerRef: React.RefObject<ScrollContainer>;
  daysArrayLength: number;
}

const DateWrapperContainer = ({
  monthIndex,
  setCurrentMonthGroupIndex,
  children,
  scrollContainerRef,
  daysArrayLength,
}: DateWrapperContainerProps) => {
  const [intersectionRef, intersectionState] = useInViewport('horizontal', {
    root: scrollContainerRef.current?.getElement(),
    rootMargin: `0px 0px 0px -${style.dayHead.width}px`,
  });
  const prevIntersectionStateRef = useRef<IntersectionState | undefined>();

  /*
   * Determine current month based on the current and previous intersection state
   * */
  useEffect(() => {
    if (
      intersectionState === IntersectionState.HiddenLeft &&
      prevIntersectionStateRef.current === IntersectionState.InView
    ) {
      // Scrolling right - Next month is the current month
      setCurrentMonthGroupIndex((monthIndex + 1) % daysArrayLength);
    } else if (
      intersectionState === IntersectionState.InView &&
      prevIntersectionStateRef.current === IntersectionState.HiddenLeft
    ) {
      // Scrolling left - This month is the current month
      setCurrentMonthGroupIndex(monthIndex);
    }

    prevIntersectionStateRef.current = intersectionState;
  }, [daysArrayLength, intersectionState, monthIndex, setCurrentMonthGroupIndex]);

  return <DateWrapper ref={intersectionRef}>{children}</DateWrapper>;
};

const CalendarHeader = ({ scrollContainerRef, onScroll }: ScrollableElementProps): JSX.Element => {
  const calendarRange = useSelector(selectCalendarRange);
  const isCalendarLoading = useSelector(selectIsCalendarLoading);
  const prevIsCalendarLoadingRef = useRef(isCalendarLoading);
  const getDateOffset = useCalendarDateOffset();
  const [currentMonthGroupIndex, setCurrentMonthGroupIndex] = useState(0);
  const daysArray = groupDaysByMonth(
    parseISO(calendarRange.startDate),
    parseISO(calendarRange.endDate)
  );

  const scrollFn = (direction: 'left' | 'right') => {
    const distance = CALENDAR_SCROLL_DISTANCE;
    emitMoveCalendar({ direction, distance });
  };

  const goToToday = useCallback(() => {
    const destination = getDateOffset(new Date(), -3);
    emitMoveCalendarTo({ destination });
  }, [getDateOffset]);

  useEffect(() => {
    setTimeout(() => goToToday());
  }, [goToToday]);

  useEffect(() => {
    if (!isCalendarLoading && prevIsCalendarLoadingRef.current) {
      goToToday();
    }
  }, [goToToday, isCalendarLoading]);

  useEffect(() => {
    prevIsCalendarLoadingRef.current = isCalendarLoading;
  }, [isCalendarLoading]);

  const firstDayOfTheCurrentMonth = daysArray[currentMonthGroupIndex][0];
  const currentMonthFullText = `${
    monthNames[firstDayOfTheCurrentMonth.getMonth()]
  } ${firstDayOfTheCurrentMonth.getFullYear()}`;

  return (
    <>
      <ArrowButtons
        onLeftArrowClick={() => scrollFn('left')}
        onRightArrowClick={() => scrollFn('right')}
      />
      <AbsoluteTodayButtonWrapper>
        <TodayButton onButtonPress={goToToday} />
      </AbsoluteTodayButtonWrapper>
      <CurrentMonthWrapper>
        <CurrentMonthInnerWrapper>
          <CurrentMonthText>{currentMonthFullText}</CurrentMonthText>
          <CurrentMonthRightBlock />
        </CurrentMonthInnerWrapper>
      </CurrentMonthWrapper>

      <DraggableScrollContainer ref={scrollContainerRef} onScroll={onScroll}>
        <MonthsHeaderWrapper>
          {daysArray.map((days, index) => {
            return (
              <DateWrapperContainer
                scrollContainerRef={scrollContainerRef}
                key={days[0].toISOString()}
                monthIndex={index}
                setCurrentMonthGroupIndex={setCurrentMonthGroupIndex}
                daysArrayLength={daysArray.length}
              >
                <MonthHeading>
                  <Title className="month-name" level={5}>
                    {`${monthNames[days[0].getMonth()].substring(0, 3)}`}
                  </Title>
                </MonthHeading>
                <div>
                  <DaysHeading>
                    {days.map((day: Date) => (
                      <DayHead
                        today={isToday(day)}
                        weekend={isWeekend(day)}
                        holiday={isHoliday(day)}
                        key={day.toISOString()}
                      >
                        <Text className="day-number">{day.getDate()}</Text>
                        <Text className="day-name">{dayNames[day.getDay()]}</Text>
                      </DayHead>
                    ))}
                  </DaysHeading>
                  <HeaderDivider />
                </div>
              </DateWrapperContainer>
            );
          })}
        </MonthsHeaderWrapper>
      </DraggableScrollContainer>
    </>
  );
};

export default CalendarHeader;
