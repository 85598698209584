export const getLanguageNames = (level: string): string => {
  const CEFRlevels = new Map<string, string>([
    ['NATIVE', 'Native Speaker'],
    ['C2', 'C2 - Mastery'],
    ['C1', 'C1 - Advanced'],
    ['B2', 'B2 - Upper-Intermediate'],
    ['B1', 'B1 - Intermediate'],
    ['A2', 'A2 - Upper-Elementary'],
    ['A1', 'A1 - Elementary'],
  ]);
  return CEFRlevels.get(level.toUpperCase()) ?? '';
};
