import { TFunction } from 'i18next';

import { FiltersType } from 'features/SearchResults/components/Filters/SkillFilters';

export const getFiltersNames = (t: TFunction): FiltersType => {
  return [
    {
      title: t('filters.titles.employee-role'),
      filters: [
        { name: t('filters.names.employee-role.agile-coach'), checkbox: false },
        { name: t('filters.names.employee-role.assigment-manager'), checkbox: false },
        { name: t('filters.names.employee-role.designer'), checkbox: false },
        { name: t('filters.names.employee-role.devops'), checkbox: false },
        { name: t('filters.names.employee-role.developer'), checkbox: false },
        { name: t('filters.names.employee-role.head'), checkbox: false },
        { name: t('filters.names.employee-role.non-delivery'), checkbox: false },
        { name: t('filters.names.employee-role.opex-lead'), checkbox: false },
        { name: t('filters.names.employee-role.pmo-specialist'), checkbox: false },
        { name: t('filters.names.employee-role.pal'), checkbox: false },
        { name: t('filters.names.employee-role.pre-sales'), checkbox: false },
        { name: t('filters.names.employee-role.pdm'), checkbox: false },
        { name: t('filters.names.employee-role.qa-automation'), checkbox: false },
        { name: t('filters.names.employee-role.qa-manual'), checkbox: false },
        { name: t('filters.names.employee-role.team-lead'), checkbox: false },
        { name: t('filters.names.employee-role.tech-lead'), checkbox: false },
      ],
    },
    {
      title: t('filters.titles.technical-role'),
      filters: [
        { name: t('filters.names.technical-role.3d-designer'), checkbox: false },
        { name: t('filters.names.technical-role.agile-practice'), checkbox: false },
        { name: t('filters.names.technical-role.android'), checkbox: false },
        { name: t('filters.names.technical-role.blockchain'), checkbox: false },
        { name: t('filters.names.technical-role.bac'), checkbox: false },
        { name: t('filters.names.technical-role.cxx'), checkbox: false },
        { name: t('filters.names.technical-role.communications-specialist'), checkbox: false },
        { name: t('filters.names.technical-role.devops'), checkbox: false },
        { name: t('filters.names.technical-role.finance'), checkbox: false },
        { name: t('filters.names.technical-role.flutter'), checkbox: false },
        { name: t('filters.names.technical-role.head'), checkbox: false },
        { name: t('filters.names.technical-role.hr'), checkbox: false },
        { name: t('filters.names.technical-role.javascript'), checkbox: false },
        { name: t('filters.names.technical-role.maintenance'), checkbox: false },
        { name: t('filters.names.technical-role.marketing'), checkbox: false },
        { name: t('filters.names.technical-role.office'), checkbox: false },
        { name: t('filters.names.technical-role.pre-sales'), checkbox: false },
        { name: t('filters.names.technical-role.product-delivery'), checkbox: false },
        { name: t('filters.names.technical-role.product-designer'), checkbox: false },
        { name: t('filters.names.technical-role.product-owner'), checkbox: false },
        { name: t('filters.names.technical-role.python'), checkbox: false },
        { name: t('filters.names.technical-role.qa'), checkbox: false },
        { name: t('filters.names.technical-role.react-native'), checkbox: false },
        { name: t('filters.names.technical-role.sdr'), checkbox: false },
        { name: t('filters.names.technical-role.sales'), checkbox: false },
        { name: t('filters.names.technical-role.tech-lead'), checkbox: false },
        { name: t('filters.names.technical-role.ux-designer'), checkbox: false },
        { name: t('filters.names.technical-role.ux-researcher'), checkbox: false },
        { name: t('filters.names.technical-role.other'), checkbox: false },
      ],
    },
    {
      title: t('filters.titles.seniority'),
      filters: [
        { name: t('filters.names.seniority.junior'), checkbox: false },
        { name: t('filters.names.seniority.mid'), checkbox: false },
        { name: t('filters.names.seniority.senior'), checkbox: false },
      ],
    },
    {
      title: t('filters.titles.department'),
      filters: [
        { name: t('filters.names.department.communications'), checkbox: false },
        { name: t('filters.names.department.design'), checkbox: false },
        { name: t('filters.names.department.engineering'), checkbox: false },
        { name: t('filters.names.department.finance'), checkbox: false },
        { name: t('filters.names.department.fintech'), checkbox: false },
        { name: t('filters.names.department.growth'), checkbox: false },
        { name: t('filters.names.department.hr'), checkbox: false },
        { name: t('filters.names.department.marketing'), checkbox: false },
        {
          name: t('filters.names.department.operational-excellence'),
          checkbox: false,
        },
        { name: t('filters.names.department.product-delivery'), checkbox: false },
        { name: t('filters.names.department.resourcing'), checkbox: false },

        { name: t('filters.names.department.sales'), checkbox: false },
      ],
    },
  ];
};

export const getSkillFiltersNames = (t: TFunction): FiltersType => {
  return [
    {
      title: t('filters.titles.technical-skill'),
      filters: [
        {
          name: t('filters.names.technical-skill.dotnet'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.2d-design'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.3d-design'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.aiohttp'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.arcore'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.arkit'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.aws'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.accessibility-standards'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.admob'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.adobe-after-effects'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.adobe-illustrator'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.adobe-photoshop'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.adobe-premiere-pro'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.affinity-designer'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.affinity-photo'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.ajax'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.android'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.android-auto'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.android-ndk'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.android-studio'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.angular'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.angularjs'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.animation/motion-design'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.animations'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.ansible'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.appcode'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.arbitrage'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.axure'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.azure'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.bdd'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.bash'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.bitcoin'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.bitrise'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.blender'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.blockchain'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.bluetooth'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.bonding-protocols'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.bootstrap'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.borrowing'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.branding-design'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.c++'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.css'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.carplay'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.cherrypy'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.cinema-4d'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.circleci'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.citrus-framework'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.cloud'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.cocoatouch'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.core-spotlight'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.coredata'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.cosign'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.cypress'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.cypress-webdriverio'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.dexes'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.drf'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.dart'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.dash-plotly'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.dashboard-design'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.data-analysis'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.datadriven'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.design-system'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.django'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.django-graphene'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.docker'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.dune-analytics'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.ecs'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.erc1155'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.erc20'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.erc721'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.electron'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.elixir'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.english'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.expressjs'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.fastapi'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.figma'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.firebase'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.firebase-ml'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.flash-loans'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.flask'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.flutter'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.forking-mainnet'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.foundation'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.fractional-nfts'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.full-stack'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.gcp'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.galen'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.games-development'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.gatsby'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.gauge'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.git'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.github-actions'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.go'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.google-analytics'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.governance'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.graphql'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.html'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.healthkit'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.hotjar'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.hybrid'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.ipfs'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.invision'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.in‑app-purchases'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.ionic'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.jquery'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.json'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.java'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.javascript'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.jenkins'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.karate-dsl'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.kotlin'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.kubernetes'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.laravel'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.linux'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.liquidations'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.liquidity-sourcing'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.mainnet-deployment'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.miro'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.mobile-design'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.mongodb'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.nestjs'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.nextjs'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.nodejs'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.objective-c'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.open-test'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.option-trading'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.php'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.phoenix'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.polymer'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.postrgresql'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.print'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.push-notifications'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.pyramid'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.python'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.rest-api'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.react'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.react-native'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.reactive-programming'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.realm'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.rebasing-tokens'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.robotframework'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.room'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.ruby'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.ruby-on-rails'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.rust'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.rxjs'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.sql'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.sqlalchemy'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.sqlite'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.sahi'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.saltstack'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.selenide'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.selenium'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.self-repaying'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.serenity'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.sketch'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.spring'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.strawberry'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.svelte'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.swift'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.tenderly'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.tensorflow'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.terraform'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.tornado'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.twilio-chat'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.typescript'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.ux-writing'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.unit-tests'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.upgrades'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.vr/ar'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.video-editing'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.visual-studio-code'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.vuejs'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.watchkit'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.wear-os'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.web2py'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.webdriver'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.webflow'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.website-design'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.xamarin'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.xcode'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.yield-farming'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.zbrush'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.zapier'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.zeplin'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.ios'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.ios-app-extensions'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.macos-apps'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.walletconnect'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-skill.web3'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
      ],
    },
    {
      title: t('filters.titles.language-skill'),
      filters: [
        {
          name: t('filters.names.language-skill.arabic'),
          checkbox: false,
          checkedArray: [false, false, false, false, false, false, false],
        },
        {
          name: t('filters.names.language-skill.bulgarian'),
          checkbox: false,
          checkedArray: [false, false, false, false, false, false, false],
        },
        {
          name: t('filters.names.language-skill.czech'),
          checkbox: false,
          checkedArray: [false, false, false, false, false, false, false],
        },
        {
          name: t('filters.names.language-skill.english'),
          checkbox: false,
          checkedArray: [false, false, false, false, false, false, false],
        },
        {
          name: t('filters.names.language-skill.estonian'),
          checkbox: false,
          checkedArray: [false, false, false, false, false, false, false],
        },
        {
          name: t('filters.names.language-skill.danish'),
          checkbox: false,
          checkedArray: [false, false, false, false, false, false, false],
        },
        {
          name: t('filters.names.language-skill.dutch'),
          checkbox: false,
          checkedArray: [false, false, false, false, false, false, false],
        },
        {
          name: t('filters.names.language-skill.finnish'),
          checkbox: false,
          checkedArray: [false, false, false, false, false, false, false],
        },
        {
          name: t('filters.names.language-skill.german'),
          checkbox: false,
          checkedArray: [false, false, false, false, false, false, false],
        },
        {
          name: t('filters.names.language-skill.greek'),
          checkbox: false,
          checkedArray: [false, false, false, false, false, false, false],
        },
        {
          name: t('filters.names.language-skill.hungarian'),
          checkbox: false,
          checkedArray: [false, false, false, false, false, false, false],
        },
        {
          name: t('filters.names.language-skill.indonesian'),
          checkbox: false,
          checkedArray: [false, false, false, false, false, false, false],
        },
        {
          name: t('filters.names.language-skill.italian'),
          checkbox: false,
          checkedArray: [false, false, false, false, false, false, false],
        },
        {
          name: t('filters.names.language-skill.japanese'),
          checkbox: false,
          checkedArray: [false, false, false, false, false, false, false],
        },
        {
          name: t('filters.names.language-skill.korean'),
          checkbox: false,
          checkedArray: [false, false, false, false, false, false, false],
        },
        {
          name: t('filters.names.language-skill.latvian'),
          checkbox: false,
          checkedArray: [false, false, false, false, false, false, false],
        },
        {
          name: t('filters.names.language-skill.lithuanian'),
          checkbox: false,
          checkedArray: [false, false, false, false, false, false, false],
        },
        {
          name: t('filters.names.language-skill.mandarin'),
          checkbox: false,
          checkedArray: [false, false, false, false, false, false, false],
        },
        {
          name: t('filters.names.language-skill.polish'),
          checkbox: false,
          checkedArray: [false, false, false, false, false, false, false],
        },
        {
          name: t('filters.names.language-skill.portugese'),
          checkbox: false,
          checkedArray: [false, false, false, false, false, false, false],
        },
        {
          name: t('filters.names.language-skill.romanian'),
          checkbox: false,
          checkedArray: [false, false, false, false, false, false, false],
        },
        {
          name: t('filters.names.language-skill.russian'),
          checkbox: false,
          checkedArray: [false, false, false, false, false, false, false],
        },
        {
          name: t('filters.names.language-skill.slovenian'),
          checkbox: false,
          checkedArray: [false, false, false, false, false, false, false],
        },
        {
          name: t('filters.names.language-skill.spanish'),
          checkbox: false,
          checkedArray: [false, false, false, false, false, false, false],
        },
        {
          name: t('filters.names.language-skill.slovak'),
          checkbox: false,
          checkedArray: [false, false, false, false, false, false, false],
        },
        {
          name: t('filters.names.language-skill.swedish'),
          checkbox: false,
          checkedArray: [false, false, false, false, false, false, false],
        },
        {
          name: t('filters.names.language-skill.turkish'),
          checkbox: false,
          checkedArray: [false, false, false, false, false, false, false],
        },
        {
          name: t('filters.names.language-skill.ukrainian'),
          checkbox: false,
          checkedArray: [false, false, false, false, false, false, false],
        },
      ],
    },
    {
      title: t('filters.titles.seniority'),
      filters: [
        {
          name: t('filters.names.seniority.junior'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.seniority.mid'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.seniority.senior'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
      ],
    },
    {
      title: t('filters.titles.technical-role'),
      filters: [
        {
          name: t('filters.names.technical-role.3d-designer'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-role.agile-practice'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-role.android'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-role.blockchain'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-role.bac'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-role.cxx'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-role.communications-specialist'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-role.devops'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-role.finance'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-role.flutter'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-role.head'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-role.hr'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-role.javascript'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-role.maintenance'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-role.marketing'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-role.office'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-role.pre-sales'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-role.product-delivery'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-role.product-designer'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-role.product-owner'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-role.python'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-role.qa'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-role.react-native'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-role.sdr'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-role.sales'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-role.tech-lead'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-role.ux-designer'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-role.ux-researcher'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
        {
          name: t('filters.names.technical-role.other'),
          checkbox: false,
          checkedArray: [false, false, false],
        },
      ],
    },
  ];
};
