import React, { useMemo } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import TokenRedirect from 'components/TokenRedirect';

import { useAuth } from 'providers/AuthProvider';
import LoginScreen from 'features/LoginScreen';
import PrivacyPolicy from 'features/PrivacyPolicy';
import Onboarding from 'features/Onboarding';
import TimeOffPage from 'features/TimeOff';
import ProfilePage from 'features/Profile';
import SearchResults from 'features/SearchResults';
import ProfileOverviewPage from 'features/Profile/pages/ProfileOverview';
import ProfileSkillsPage from 'features/Profile/pages/ProfileSkills';
import EmployeeDirectoryPage from 'features/SearchResults/pages';
import SearchSkills from 'features/SearchResults/pages/SearchSkills/SearchSkills';
import TeamLeadView from 'features/TeamLeadView';
import TeamSkills from 'features/TeamLeadView/pages/TeamLeadView/TeamSkills';
import TeamTimeOff from 'features/TeamLeadView/pages/TeamLeadView/TeamTimeOff';

const AppRouter = (): JSX.Element => {
  const { currentUser } = useAuth();

  const routes = useMemo(() => {
    if (!currentUser) {
      return (
        <>
          <Route path="/" element={<LoginScreen />} />
          <Route path="/auth-token/:token" element={<TokenRedirect />} />
          <Route path="*" element={<Navigate to="/" />} />
        </>
      );
    }

    if (!currentUser?.onboardingCompleted) {
      return (
        <>
          <Route path="/onboarding" element={<Onboarding />} />
          <Route path="*" element={<Navigate to="/onboarding" />} />
        </>
      );
    }

    const { id } = currentUser;

    return (
      <>
        {currentUser?.isTeamLead && (
          <Route path="/my-team" element={<TeamLeadView />}>
            <Route index element={<TeamTimeOff />} />
            <Route path="skills" element={<TeamSkills />} />
          </Route>
        )}
        <Route path="/time-off" element={<TimeOffPage />} />
        <Route path="/profile/:userId" element={<ProfilePage />}>
          <Route index element={<ProfileOverviewPage />} />
          <Route path="skills" element={<ProfileSkillsPage />} />
        </Route>
        <Route path="/search-results" element={<EmployeeDirectoryPage />}>
          <Route index element={<SearchResults />} />
          <Route path="skills" element={<SearchSkills />} />
        </Route>
        <Route path="*" element={<Navigate to={`/profile/${id}`} />} />
      </>
    );
  }, [currentUser]);

  return (
    <BrowserRouter>
      <Routes>
        {routes}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
