const addUniq = <T extends { id: string }>(
  array: T[],
  newElement: T,
  identity: keyof T = 'id'
): T[] => {
  const existingIndex = array.findIndex((element) => element[identity] === newElement[identity]);
  if (existingIndex === -1) {
    const newArray = [...array, newElement];
    return newArray;
  }
  return array.slice();
};

export default addUniq;
