import { QueryObserverResult, useQuery } from '@tanstack/react-query';

import { UserProject } from 'API';
import { cacheTime } from 'api/Client';
import querySearchProjects from 'features/Profile/api/querySearchProjects';

interface IUseProjectsListArgs {
  userId: string;
  before: string;
  after: string;
}

export default function useProjectsListData({
  userId,
  after,
  before,
}: IUseProjectsListArgs): QueryObserverResult<
  Pick<
    UserProject,
    | 'lead'
    | 'projectStatus'
    | 'weeklyHours'
    | 'totalEstimatedHours'
    | 'currentLoggedHours'
    | 'projectName'
    | 'entryKey'
    | 'projectUrl'
    | 'jiraUrl'
  >[]
> {
  return useQuery(
    ['projectsList', userId],
    async () => {
      const data = await querySearchProjects({ id: userId, before, after });
      return data.getUser.userProjects.projects;
    },
    {
      staleTime: cacheTime,
    }
  );
}
