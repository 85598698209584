import styled from 'styled-components';

export const Container = styled.div`
  padding-left: 15px;
  display: flex;
  align-items: center;
`;

export const Span2Week = styled.span`
  padding-left: 10px;
`;
