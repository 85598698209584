import { combineReducers } from '@reduxjs/toolkit';

import calendar from 'features/TimeOff/redux';
import searchResults from 'features/SearchResults/redux';
import profile from 'features/Profile/redux';
import skill from 'features/Profile/redux/skillSlice';
import skillAssignments from 'features/SearchResults/redux/skillsAssignmentSlice';

import { apiService } from '../services/api';

const rootReducer = combineReducers({
  calendar,
  searchResults,
  profile,
  skill,
  skillAssignments,
  [apiService.reducerPath]: apiService.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
