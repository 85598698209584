import { Button } from 'antd';
import styled from 'styled-components';

export const EditProfileFormContainer = styled.div`
  color: ${({ theme }) => theme.colors.text.primary};

  .ant-btn-primary {
    & button {
      text-transform: uppercase;
    }
  }

  .ant-form-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    .ant-form-item-label {
      text-align: left;

      & > label {
        color: ${({ theme }) => theme.colors.text.primary};
        font: ${({ theme }) => theme.typography.paragraph.font};
        letter-spacing: ${({ theme }) => theme.typography.paragraph.letterSpacing};
        height: 0;
      }
    }

    .ant-form-item-control {
      margin-top: 8px;

      .ant-form-item-control-input {
        min-height: auto;
      }

      .ant-input,
      .ant-picker {
        padding: 16px;
        max-height: 53px;
        border: 1px solid #c1c1d0;
        color: ${({ theme }) => theme.colors.text.primary};
        font: ${({ theme }) => theme.typography.subtitles};
        box-shadow: none;

        &:focus,
        &.ant-picker-focused {
          border-color: ${({ theme }) => theme.colors.text.overline};
        }

        &[disabled] {
          background: #fbfbfb;
          color: ${({ theme }) => theme.colors.gray['700']};
        }

        &::placeholder {
          color: ${({ theme }) => theme.colors.text.secondary};
        }
      }

      .ant-picker {
        width: 100%;

        &.ant-picker-disabled {
          background: #fbfbfb;

          .ant-picker-input {
            & > input {
              color: ${({ theme }) => theme.colors.gray['700']};
            }
          }
        }

        .int-picker &.ant-picker-disabled {
          background: #fbfbfb;
        }

        .ant-picker-active-bar {
          background: ${({ theme }) => theme.colors.text.overline};
        }

        .ant-picker-input {
          width: auto;

          & > input {
            color: ${({ theme }) => theme.colors.text.primary};
            font: ${({ theme }) => theme.typography.subtitles};

            &::placeholder {
              color: ${({ theme }) => theme.colors.text.secondary};
            }
          }
        }

        .ant-picker-suffix {
          flex: 1;
          display: flex;
          justify-content: flex-end;

          .anticon {
            font-size: 20px;
            color: ${({ theme }) => theme.colors.text.secondary};
          }
        }

        .ant-picker-clear {
          right: 16px;
          font-size: 20px;
          color: ${({ theme }) => theme.colors.text.secondary};
        }
      }
    }

    &.ant-form-item-has-error {
      .ant-form-item-control {
        .ant-input,
        .ant-picker {
          border-color: #ed2d01;
        }
      }
    }
  }
`;

export const EditProfileFormButtonsContainer = styled.div<{ variant?: 'onboarding' | 'profile' }>`
  margin-top: 32px;
  display: flex;
  width: 100%;
  flex-direction: ${({ variant }) => (variant === 'onboarding' ? 'column' : 'row')};
  justify-content: ${({ variant }) => (variant !== 'onboarding' ? 'space-between' : 'center')};

  .ant-form-item {
    margin-bottom: 0;
  }
`;

const EditFormButton = styled(Button)`
  padding: 12px 15px;
  height: auto;
  color: ${({ theme }) => theme.colors.text.primary};
  font: ${({ theme }) => theme.typography.paragraph.font};
  font-weight: 600;
  letter-spacing: ${({ theme }) => theme.typography.paragraph.letterSpacing};
  text-transform: uppercase;
  border: none;
`;

export const CancelButton = styled(EditFormButton)`
  background: ${({ theme }) => theme.colors.white};
`;

export const SaveButton = styled(EditFormButton)<{ variant?: 'onboarding' | 'profile' }>`
  background: ${({ theme }) => theme.colors.linear.default};
  color: ${({ theme }) => theme.colors.white};
  width: ${({ variant }) => (variant === 'onboarding' ? '100%' : 'auto')};
  height: ${({ variant }) => (variant === 'onboarding' ? '54px' : 'auto')};
  font-size: 15;
  line-height: 20px;
  letter-spacing: 0.1px;
  &:hover,
  &:active,
  &:focus {
    &:not([disabled]) {
      background: ${({ theme }) => theme.colors.linear.default};
    }
  }

  &[disabled] {
    color: ${({ theme }) => theme.colors.button.disabled.color};
    background: ${({ theme }) => theme.colors.button.disabled.background};
  }
`;

export const SaveButtonContentContainer = styled.div`
  .anticon {
    font-size: 16px;
  }
`;

export const SaveButtonText = styled.span<{ isLoading: boolean }>`
  margin-left: ${({ isLoading }) => (isLoading ? '8px' : '0')};
`;

export const Line = styled.div`
  border: 1px solid #e6e6ef;
  width: 100%;
`;
