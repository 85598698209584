import React from 'react';
import { useTranslation } from 'react-i18next';

import { getLevelDescription } from 'features/Profile/helpers/getLevelDescription';

import {
  LevelInfo,
  LevelContainer,
  LevelButtons,
  LevelButtonHeader,
  InputRadio,
  LevelData,
} from './SkillLevel.styles';

interface SkillLevelProps {
  handleLevel: (lvl: string) => void;
  handleSkill: (lvl: string) => void;
  name: string;
  skill: string;
  icon?: React.ReactNode;
}

const SkillLevel = ({
  handleLevel,
  handleSkill,
  name,
  icon,
  skill,
}: SkillLevelProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <LevelContainer
      onClick={() => {
        handleLevel(name);
        handleSkill(name);
      }}
      className={skill === name ? 'active' : ''}
    >
      <LevelButtons>
        <LevelButtonHeader>
          {icon && icon}
          {name}
        </LevelButtonHeader>
        <InputRadio
          type="radio"
          name="skill"
          value={name}
          checked={skill === name}
          onChange={() => {
            handleLevel(name);
            handleSkill(name);
          }}
        />
      </LevelButtons>
      {icon && (
        <LevelInfo>
          <LevelData>{getLevelDescription(t, name)}</LevelData>
        </LevelInfo>
      )}
    </LevelContainer>
  );
};
export default SkillLevel;
