import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import useCalendarDateOffset from 'features/TimeOff/hooks/useDateOffset';
import { onCalendarMoveFinished$ } from 'features/TimeOff/subjects/calendarMoveFinished';
import { emitTimeOffVisibilityChange } from 'features/TimeOff/subjects/timeOffVisibilityChange';
import useInViewport from 'hooks/useInViewport';
import useSubscription from 'hooks/useSubscription';

import TimeOffBar, { TimeOffBarProps } from './TimeOffBar/TimeOffBar';

export interface TimeOffElementProps {
  leftOffset: number;
}

const TimeOffBarWithOffset = styled(TimeOffBar)<TimeOffElementProps>`
  left: ${({ leftOffset }) => leftOffset}px;
`;

const TimeOffElement = ({ timeOff, ...props }: TimeOffBarProps): JSX.Element | null => {
  const getTimeOffOffset = useCalendarDateOffset();
  const [highlighted, setHighlighted] = useState<boolean>(false);
  const [ref, intersectionState] = useInViewport('horizontal');

  useEffect(() => {
    emitTimeOffVisibilityChange({ timeOff, state: intersectionState });
  }, [intersectionState, timeOff]);

  useSubscription(onCalendarMoveFinished$, (payload) => {
    if (payload.id !== timeOff.id) return;
    setHighlighted(true);
    setTimeout(() => setHighlighted(false), 1000);
  });

  return (
    <TimeOffBarWithOffset
      timeOff={timeOff}
      leftOffset={getTimeOffOffset(new Date(timeOff.startDate))}
      highlighted={highlighted}
      ref={ref}
      {...props}
    />
  );
};

export default TimeOffElement;
