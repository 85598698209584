interface GeneralError {
  message: string;
}

interface FieldErrors {
  [key: string]: string[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getResponseErrors = (errors: any[]): { error?: GeneralError; fieldErrors?: FieldErrors } => {
  let error;
  let fieldErrors;
  errors.forEach((el) => {
    if (el.errorType === 'FieldValidationErrors') {
      fieldErrors = el.errorInfo.fields;
    } else {
      error = el;
    }
  });

  return { error, fieldErrors };
};

export default getResponseErrors;
