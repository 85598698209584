import { Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Text = styled(Typography.Paragraph)`
  overflow: hidden;
  text-align: center;
  max-height: 64px;
  &.ant-typography {
    padding: 22px;
    color: ${({ theme }) => theme.colors.text.secondary};
    margin: 0;
    z-index: 5;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }
`;

const Container = styled.div`
  box-shadow: 0 1px 0 #dee2e6;
`;

const EmptyFilter = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Container>
      <Text>{t('profile.user-assignments.empty')}</Text>
    </Container>
  );
};

export default EmptyFilter;
