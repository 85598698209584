import styled from 'styled-components';
import { Typography } from 'antd';

export const SkillState = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 340px;
`;

export const SkillTitleContainer = styled('div')`
  display: flex;
  align-items: center;
  width: 340px;
`;

export const SkillTitle = styled(Typography.Title)`
  &.ant-typography {
    font-size: 21px;
    line-height: 32px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 0px;
  }
`;

export const SubSkillTitle = styled(Typography.Paragraph)`
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const SubSkillTitleContainer = styled('div')`
  display: flex;
  align-items: center;
  width: 340px;
  margin-top: 24px;
`;
