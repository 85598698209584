import { Typography } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 200px;
`;

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  gap: 24px;
  width: 404px;
  max-height: 664px;
  background: #ffffff;
  box-shadow: 0px 20px 40px -4px rgba(114, 114, 146, 0.12);
`;

export const Title = styled(Typography.Title)`
  &.ant-typography {
    color: ${({ theme }) => theme.colors.primary};
    font: ${({ theme }) => theme.typography.heading};
    height: 36px;
    font-weight: 600;
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 0px;
  }
`;

export const Text = styled(Typography.Text)`
  &.ant-typography {
    color: ${({ theme }) => theme.colors.text.secondary};
    width: 340px;
    height: 40px;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.2px;
    text-align: left;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 8px;
  gap: 8px;
  height: 84px;
`;
