import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { Avatar, Typography } from 'antd';

interface SpaceProps {
  margin: string | number;
  whiteSpace: string;
}

export const Space = ({ margin, whiteSpace }: SpaceProps): FlattenSimpleInterpolation => css`
  margin: ${margin};
  white-space: ${whiteSpace};
`;

export const Section = styled.section`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 32px;
`;

export const SectionTitle = styled.h3`
  font: ${({ theme }) => theme.typography.paragraph.font};
  letter-spacing: ${({ theme }) => theme.typography.paragraph.letterSpacing};
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const UserInformationSection = styled(Section)`
  width: 371px;
  margin-right: 24px;
  align-self: flex-start;
`;

export const UserDetailsContainer = styled.div`
  margin: 24px auto 0;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 227px;
`;

export const UserDetailsTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 16px 0 0;
  flex: 1;
`;

export const UserSeniorityTextContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  color: ${({ theme }) => theme.colors.text.secondary};
  font: ${({ theme }) => theme.typography.paragraph.font};
  letter-spacing: ${({ theme }) => theme.typography.paragraph.letterSpacing};
`;

export const UserDetailsCenterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const UserDetailsName = styled.h4`
  font: ${({ theme }) => theme.typography.subtitles};
`;

export const UserDetailsRole = styled.p`
  ${Space({ margin: 0, whiteSpace: 'pre-wrap' })};
`;

export const UserTechnicalRole = styled(Typography.Paragraph)`
  ${Space({ margin: 0, whiteSpace: 'pre-wrap' })};

  max-width: 120px;
  margin-right: 1px;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  &.ant-typography {
    color: ${({ theme }) => theme.colors.text.secondary};
    margin: 0;
  }
`;

export const UserDetailsSeniorityLevel = styled.p`
  ${Space({ margin: 0, whiteSpace: 'pre-wrap' })};
`;

export const UserDetailsSeniority = styled.p`
  ${Space({ margin: 0, whiteSpace: 'pre-wrap' })};
`;

export const UserDetailsTeamContainer = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: center;
`;

export const UserDetailsDepartmentBadge = styled.div`
  margin: 0 10px;
  padding: 4px 8px;
  background: ${({ theme }) => theme.colors.accents.lightA};
  color: ${({ theme }) => theme.colors.accents.darkA};
  font: ${({ theme }) => theme.typography.paragraph.font};
  letter-spacing: ${({ theme }) => theme.typography.paragraph.letterSpacing};
  text-transform: uppercase;
`;

export const DetailInformationContainer = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
`;

export const DetailInformationHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const DetailInformationList = styled.ul`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
`;

export const ProfileAvatar = styled(Avatar)`
  &:not(.ant-avatar-image) {
    background-color: ${({ theme }) => theme.colors.secondary['200']};
    color: ${({ theme }) => theme.colors.white};
  }

  .ant-avatar-string {
    font: ${({ theme }) => theme.typography.paragraph.font};
    letter-spacing: ${({ theme }) => theme.typography.paragraph.letterSpacing};
  }
`;
