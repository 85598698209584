/**
 * Immutably removes an item from an array by index or returns a shallow
 * copy of the array if index is out of bounds.
 */
const removeByIndex = <T>(array: Array<T>, index: number): Array<T> => {
  if (index < array.length && index >= 0) {
    return [...array.slice(0, index), ...array.slice(index + 1)];
  }
  return array.slice();
};

export default removeByIndex;
