import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { actions, selectSkills } from 'features/Profile/redux/skillSlice';
import { useAppDispatch } from 'redux/store';
import { ReactComponent as ArrowLeftIcon } from 'assets/Arrow-left.svg';
import mutateDeleteSkillAssignmentFromUser from 'features/Profile/api/mutateDeleteSkillAssignmentFromUser';

import {
  TextButton,
  ActionButton,
  Modal,
  TextBody,
  TextSkill,
  TitleContainer,
  LeftArrow,
} from './SkillDelete.styles';

const SkillDelete = (): JSX.Element => {
  const { t } = useTranslation();
  const { skillDelete, skillModal } = useSelector(selectSkills);
  const dispatch = useAppDispatch();
  const handleCloseDelete = () => {
    if (!skillDelete.isAssignedSkills) {
      dispatch(actions.setModal({ ...skillModal, visible: !skillModal.visible }));
    }
    dispatch(actions.setModalDelete({ visible: !skillDelete, name: '', isAssignedSkills: false }));
  };
  const handleSkillDelete = async () => {
    if (skillDelete.isAssignedSkills) {
      await mutateDeleteSkillAssignmentFromUser({ skillName: skillDelete.name });
    }
    dispatch(actions.deleteSkill({ name: skillDelete.name, isAssignedSkills: false }));
    handleCloseDelete();
  };
  const footer = (
    <>
      <TextButton onClick={handleCloseDelete}>{t('skills-matrix.delete-skill.back')}</TextButton>
      <ActionButton onClick={handleSkillDelete}>
        {t('skills-matrix.delete-skill.delete')}
      </ActionButton>
    </>
  );

  const modalContent = (
    <TextBody>
      {t('skills-matrix.delete-skill.text')}
      <TextSkill>{skillDelete.name}</TextSkill>
      {t('skills-matrix.delete-skill.end-text')}
    </TextBody>
  );

  const title = (
    <TitleContainer>
      {!skillDelete.isAssignedSkills && (
        <LeftArrow onClick={handleCloseDelete}>
          <ArrowLeftIcon />
        </LeftArrow>
      )}
      {t('skills-matrix.delete-skill.title')}
    </TitleContainer>
  );

  return (
    <Modal
      centered
      closable={false}
      visible={skillDelete.visible}
      title={title}
      width={404}
      footer={footer}
    >
      {modalContent}
    </Modal>
  );
};

export default SkillDelete;
