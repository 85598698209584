import React, { useEffect, useState } from 'react';
import { useParams, Outlet, useLocation } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import PageLayout from 'components/PageLayout';
import ProfileTopBar from 'components/TopBar/ProfileTopBar';
import { VARIANT } from 'components/AutocompleteFilter/types';
import Spinner from 'components/Spinner';

import { ReactComponent as TimeOffIcon } from 'assets/TimeOff.svg';
import TimeOffPopover from 'features/TimeOff/components/Calendar/TimeOffPopover';
import { useGetProfileById } from 'services/api/profile';
import { actions } from 'features/TimeOff/redux/timesOffSlice';
import useUserTimeOffById from 'hooks/useUserTimeOffById';
import { selectCalendarRange } from 'features/TimeOff/redux/calendarRange';
import { useAppDispatch } from 'redux/store';
import { showError } from 'utils/helpers/showMessage';
import { ReactComponent as AccountCircleIcon } from 'assets/AccountCircle.svg';
import { ReactComponent as BriefcaseIcon } from 'assets/Briefcase.svg';
import { TimeOffType } from 'API';
import { TimeOff } from 'features/TimeOff/helpers/mergeTimeOffRecords';
import { useAuth } from 'providers/AuthProvider';

import {
  BreadcrumbsSection,
  Container,
  ContentSection,
  MainWrapper,
  Nav,
  ProfileNavigation,
  ProfileNavLink,
  ProfileNavLinkIcon,
  SectionsContainer,
  TimeOffIconWrapper,
  TimeOffLoadingContainer,
  TimeOffStatusContainer,
  TimeOffText,
} from './Profile.styles';

export type ProfileRouteParams = {
  userId: string;
};

const ProfilePage = (): JSX.Element => {
  const { userId } = useParams<{ userId: string }>() as ProfileRouteParams;
  const { currentUser } = useAuth();
  const location = useLocation();
  const { t } = useTranslation();
  const { data: profileData } = useGetProfileById(userId);
  const [isTimeOffScheduled, setIsTimeOffScheduled] = useState<boolean>(false);
  const [currentTimeOff, setCurrentTimeOff] = useState<TimeOff>();
  const { startDate, endDate } = useSelector(selectCalendarRange);
  const { data: userTimeOffsData, isLoading: timeOffLoading } = useUserTimeOffById({
    userId,
    endDate,
    startDate,
  });
  const dispatch = useAppDispatch();
  const profile = profileData?.getUser || {};

  useEffect(() => {
    if (userId) dispatch(actions.fetchMyTimesOff(userId));
  }, [userId, dispatch]);

  useEffect(() => {
    const checkTimeOffScheduled = (timesOff: TimeOff[] | undefined) => {
      const today = new Date().toISOString().slice(0, 10);
      if (!timesOff) setIsTimeOffScheduled(false);
      const foundTimeOff = timesOff?.find(
        (timeOff) => timeOff.startDate <= today && timeOff.endDate >= today
      );
      setCurrentTimeOff(foundTimeOff);
      setIsTimeOffScheduled(!!foundTimeOff);
    };

    checkTimeOffScheduled(userTimeOffsData);
  }, [userTimeOffsData]);

  useEffect(() => {
    if (profileData?.getUser.message) {
      showError({ content: profileData.getUser.message });
    }
  }, [profileData]);

  const getTimeOffType = (dayOff: TimeOff): string => {
    if (dayOff.type === TimeOffType.Unpaid) return t('time-off.unpaid');
    if (dayOff.type === TimeOffType.Other) return t('time-off.other');
    if (dayOff.type === TimeOffType.Paid) return t('time-off.paid');

    return t('time-off.timeOff');
  };
  // this regex checks if path starts with "/profile" but is not finished with "skills"
  const startsWithProfileRegex = /^\/profile\/(?!.*skills$).*/;
  return (
    <PageLayout>
      <Container id="userProfilePage">
        <ProfileTopBar
          profilePicture={currentUser?.profilePicture ?? ''}
          variant={VARIANT.TOP_BAR}
        />
        <MainWrapper>
          <SectionsContainer>
            <BreadcrumbsSection>
              <Nav id="employeeDirectoryBlock">
                <Breadcrumb>
                  <Breadcrumb.Item>{t('employee-directory.profile')}</Breadcrumb.Item>
                  <Breadcrumb.Item>{profile.name ?? t('profile.time-off.loading')}</Breadcrumb.Item>
                </Breadcrumb>
                {!userTimeOffsData && timeOffLoading && (
                  <TimeOffLoadingContainer>
                    <Spinner
                      message={t('time-off.time-off-loading')}
                      variant="light"
                      fontSize={13}
                      spinnerSize={16}
                    />
                  </TimeOffLoadingContainer>
                )}
                {userTimeOffsData && currentTimeOff && isTimeOffScheduled && (
                  <TimeOffPopover timeOff={currentTimeOff as TimeOff}>
                    <TimeOffStatusContainer timeOff={currentTimeOff as TimeOff}>
                      <TimeOffIconWrapper>
                        <TimeOffIcon />
                      </TimeOffIconWrapper>
                      <TimeOffText timeOff={currentTimeOff as TimeOff}>
                        {getTimeOffType(currentTimeOff)}
                      </TimeOffText>
                    </TimeOffStatusContainer>
                  </TimeOffPopover>
                )}
              </Nav>
            </BreadcrumbsSection>

            <ProfileNavigation>
              <ProfileNavLink
                className={
                  currentUser && startsWithProfileRegex.test(location.pathname)
                    ? 'active-profile active'
                    : ''
                }
                to={`/profile/${currentUser && currentUser.id}`}
                end
              >
                <ProfileNavLinkIcon as={AccountCircleIcon} />
                {t('profile.navigation.overview')}
              </ProfileNavLink>
              <ProfileNavLink to="skills">
                <ProfileNavLinkIcon as={BriefcaseIcon} />
                {t('profile.navigation.skills')}
              </ProfileNavLink>
            </ProfileNavigation>

            <ContentSection>
              <Outlet />
            </ContentSection>
          </SectionsContainer>
        </MainWrapper>
      </Container>
    </PageLayout>
  );
};

export default ProfilePage;
