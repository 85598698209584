import { RefCallback, useCallback, useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';

import usePrevious from './usePrevious';

export enum IntersectionState {
  Unknown,
  HiddenLeft,
  HiddenRight,
  HiddenTop,
  HiddenBottom,
  InView,
}

/**
 * Custom hook for checking if an element is in view using the IntersectionObserver api
 *
 * Implementation based on:
 *  * https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
 *  * https://stackoverflow.com/questions/45514676/react-check-if-element-is-visible-in-dom
 */
const useInViewport = (
  mode: 'horizontal' | 'vertical',
  options: IntersectionObserverInit = {}
): [RefCallback<Element>, IntersectionState] => {
  const [intersectionState, setIntersectionState] = useState<IntersectionState>(
    IntersectionState.Unknown
  );
  const [newOptions, setNewOptions] = useState(options);
  const prevOptions = usePrevious<IntersectionObserverInit>(options);

  useEffect(() => {
    if (!isEqual(prevOptions, options)) {
      setNewOptions(options);
    }
  }, [prevOptions, options]);

  const ref: RefCallback<Element> = useCallback(
    (node) => {
      const intObserver = new IntersectionObserver((entry) => {
        if (!entry.length || !entry[0].rootBounds)
          return setIntersectionState(IntersectionState.Unknown);
        if (entry[0].isIntersecting) return setIntersectionState(IntersectionState.InView);

        if (mode === 'horizontal') {
          if (entry[0].boundingClientRect.left > entry[0].rootBounds.right)
            return setIntersectionState(IntersectionState.HiddenRight);
          return setIntersectionState(IntersectionState.HiddenLeft);
        }
        if (entry[0].boundingClientRect.top > entry[0].rootBounds.bottom)
          return setIntersectionState(IntersectionState.HiddenBottom);
        return setIntersectionState(IntersectionState.HiddenTop);
      }, newOptions);

      if (node !== null) {
        intObserver.observe(node);
      } else {
        intObserver.disconnect();
      }
    },
    [mode, newOptions]
  );

  return [ref, intersectionState];
};

export default useInViewport;
