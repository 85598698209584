import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Button } from 'components/Button/Button';

import emptyStateImage from 'assets/png/EmptyBench.png';
import { selectSkills } from 'features/Profile/redux/skillSlice';
import { useIsProfileOwner } from 'features/Profile/hooks/useIsProfileOwner';
import { ProfileRouteParams } from 'features/Profile/pages/Profile/Profile';

import {
  EmptyStateButtonContainer,
  EmptyStateContainer,
  EmptyStateImage,
  EmptyStatePrimaryText,
  EmptyStateSecondaryText,
} from './ProfileSkillsEmptyState.styles';

interface ProfileSkillsEmptyStateProps {
  ctaText: string;
  onButtonClick?: () => void;
}

export const ProfileSkillsEmptyState = ({
  ctaText,
  onButtonClick,
}: ProfileSkillsEmptyStateProps): JSX.Element => {
  const { userId } = useParams<{ userId: string }>() as ProfileRouteParams;
  const isProfileOwner = useIsProfileOwner(userId);
  const { t } = useTranslation();
  const { skillModal } = useSelector(selectSkills);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillModal.content]);

  return (
    <EmptyStateContainer>
      <EmptyStateImage src={emptyStateImage} alt="Person sitting on the bench" />
      <EmptyStatePrimaryText>{t('skills-matrix.empty-states.primary-text')}</EmptyStatePrimaryText>
      <EmptyStateSecondaryText>
        {isProfileOwner
          ? t('skills-matrix.empty-states.secondary-text-author')
          : t('skills-matrix.empty-states.secondary-text-guest')}
      </EmptyStateSecondaryText>

      <EmptyStateButtonContainer>
        {isProfileOwner && (
          <Button disabled={loading} onClick={onButtonClick}>
            {ctaText}
          </Button>
        )}
      </EmptyStateButtonContainer>
    </EmptyStateContainer>
  );
};
