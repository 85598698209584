import React from 'react';
import styled from 'styled-components';

import { ReactComponent as Add } from 'assets/Add.svg';
import { ReactComponent as FilterEdit } from 'assets/FilterEdit.svg';

interface ISkillFilterTitleCheckbox {
  children: React.ReactNode;
  checked: boolean;
  onClick: () => void;
}

const Wrapper = styled.div`
  max-width: 251px;
  box-sizing: border-box;
  display: flex;
  height: 65px;
  background: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.calendar.timeOffBorder};
  flex: none;
  order: 1;
  flex-grow: 0;
  color: ${({ theme }) => theme.colors.text.secondary};
  & svg {
    width: 20px;
    height: 20px;
  }
  &:hover {
    cursor: pointer;
    svg path {
      fill: ${({ theme }) => theme.colors.text.overline};
    }
  }
`;
const Group = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 18px;
  box-sizing: border-box;
  width: 100%;
  gap: 12px;
`;

export const SkillFilterTitleCheckbox = ({
  children,
  checked,
  onClick,
}: ISkillFilterTitleCheckbox): JSX.Element => {
  return (
    <Wrapper onClick={onClick}>
      <Group>
        {checked ? <FilterEdit /> : <Add />}
        {children}
      </Group>
    </Wrapper>
  );
};
