import { capitalize } from 'lodash';

import { UserTimeOff } from 'features/TimeOff/types/calendarTypes';
import { showError } from 'utils/helpers/showMessage';
import {
  BoolValue,
  PtoAllowanceResponse,
  ContractTypeResponse,
  HadAnnualVacationLeaveResponse,
  ContractTypeEnum,
} from 'API';

import queryMyTimesOff, { ReturnType } from './queryMyTimesOff';
import QuerySearchResults, { TimeOffQueryData } from './querySearchResults';
import mergeTimeOffRecords, { TimeOffDates } from '../helpers/mergeTimeOffRecords';
import myList from './myList';

export type GetMyTimesOffReturnType = {
  timeOff: {
    name: string;
    id: string;
    position: string;
    profilePicture: string;
    timesOff: TimeOffDates;
  };
  ptoAllowance: PtoAllowanceResponse;
  hadAnnualVacationLeaveUop: HadAnnualVacationLeaveResponse;
  contractType: ContractTypeResponse;
  ptoLeft: {
    ptoLeft: number | null;
    ptoCurrent: number | null;
    ptoUsed: number | null;
    ptoCarryover?: number | null;
  };
  hasUnlimitedPto: boolean;
};

const getMyTimesOff = async (
  userId: string,
  after: string,
  before: string
): Promise<GetMyTimesOffReturnType> => {
  let response: ReturnType = {
    getOwnUser: {
      name: '',
      role: '',
      profilePicture: '',
      ptoAllowance: {
        value: 10,
      },
      hadAnnualVacationLeaveUop: {
        value: false,
      },
      ptoLeft: {
        ptoCurrent: 0,
        ptoLeft: 0,
        ptoUsed: 0,
      },
      hasUnlimitedPto: {
        value: false,
      },
      timeOffDates: {
        timeOffs: [],
      },
      contractType: {
        shortName: ContractTypeEnum.B2B,
      },
    },
  };
  let timesOff: TimeOffDates = [];
  try {
    response = await queryMyTimesOff({ after, before });
    // eslint-disable-next-line no-unsafe-optional-chaining
    const { timeOffDates, ptoLeft } = response?.getOwnUser;

    if ('timeOffs' in timeOffDates) {
      timesOff = mergeTimeOffRecords(timeOffDates.timeOffs);
    }
    if ('message' in timeOffDates) {
      showError({ content: timeOffDates.message });
    }
    if (ptoLeft && 'message' in ptoLeft) {
      showError({ content: ptoLeft.message });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }

  const {
    hasUnlimitedPto,
    role,
    profilePicture,
    name,
    ptoLeft,
    ptoAllowance,
    contractType,
    hadAnnualVacationLeaveUop,
  } = response.getOwnUser;

  return {
    timeOff: {
      name: name ?? '',
      id: userId,
      position: role ?? '',
      profilePicture: profilePicture ?? '',
      timesOff,
    },
    hadAnnualVacationLeaveUop: { value: (hadAnnualVacationLeaveUop as BoolValue).value ?? null },
    ptoAllowance,
    contractType,
    ptoLeft: {
      ptoLeft: ptoLeft && 'ptoLeft' in ptoLeft ? ptoLeft?.ptoLeft : null,
      ptoCurrent: ptoLeft && 'ptoCurrent' in ptoLeft ? ptoLeft?.ptoCurrent : null,
      ptoUsed: ptoLeft && 'ptoUsed' in ptoLeft ? ptoLeft?.ptoUsed : null,
      ptoCarryover: ptoLeft && 'ptoCarryover' in ptoLeft ? ptoLeft?.ptoCarryover : null,
    },
    hasUnlimitedPto: (hasUnlimitedPto as BoolValue).value ?? null,
  };
};

const formatUserTimesOffResponse = (response: TimeOffQueryData) => {
  return response.listUsers.map((user) => ({
    id: user.id,
    name: user.name,
    position: user.role ?? '',
    profilePicture: user.profilePicture,
    timesOff:
      'timeOffs' in user.timeOffDates ? mergeTimeOffRecords(user.timeOffDates.timeOffs) : [],
  }));
};

const searchUserTimesOff = async (
  query: string,
  after: string,
  before: string,
  excludeIds: string[]
): Promise<UserTimeOff[]> => {
  const queryString = query.split(' ').map(capitalize).join().replace(',', ' ');

  const excludeFilter = excludeIds.map((id) => id);
  let response: TimeOffQueryData;
  try {
    response = await QuerySearchResults({
      filters: {
        excludeUsers: { excludedIds: excludeFilter },
        customFilter: { iName: queryString },
      },
      before,
      after,
    });
    return formatUserTimesOffResponse(response);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return [];
  }
};

const getUsersTimesOff = async (
  after: string,
  before: string,
  ids: string[]
): Promise<{ id: string; name: string; position: string; timesOff: TimeOffDates }[]> => {
  const filter = {
    listUsers: { usersIds: ids.map((id) => id) },
  };

  const response = await QuerySearchResults({
    filters: filter,
    before,
    after,
  });

  return formatUserTimesOffResponse(response);
};

export default {
  getMyTimesOff,
  searchUserTimesOff,
  getUsersTimesOff,
  myList,
};
