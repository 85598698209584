import React from 'react';
import styled from 'styled-components';

import style from 'features/TimeOff/constants/style';
import { UserTimeOff } from 'features/TimeOff/types/calendarTypes';

import TimeOffElement from '../TimeOffElement';
import TimeOffPopover from '../TimeOffPopover';
import TimeOffRowBackground from './TimeOffRowBackground';

const TimeOffRowWrapper = styled.div`
  position: relative;
  height: ${style.timeOffRow.height}px;
`;

interface TimeOffRowProps {
  user: UserTimeOff;
}

const TimeOffRow = ({ user }: TimeOffRowProps): JSX.Element => {
  return (
    <TimeOffRowWrapper>
      {user.timesOff.map((timeOff) => {
        return (
          <TimeOffPopover key={timeOff.id} timeOff={timeOff}>
            <TimeOffElement timeOff={timeOff} />
          </TimeOffPopover>
        );
      })}
      <TimeOffRowBackground user={user} />
    </TimeOffRowWrapper>
  );
};

export default TimeOffRow;
