import { createGlobalStyle } from 'styled-components';
import React from 'react';

import { ThemeType } from '../ThemeProvider';

export interface IGlobalStylesProps {
  theme: ThemeType;
}

const GlobalStyleComponent = createGlobalStyle`
  h1, h2, h3, h4, h5, h6 {
    color: ${({ theme }) => theme.colors.text.primary};
  }

  .active-profile{
    border-color: ${({ theme }) => theme.colors.text.overline};
    color: ${({ theme }) => theme.colors.text.primary};

    svg {
      color: ${({ theme }) => theme.colors.text.overline};
    }
  }
  

  .ant-table-thead{
  background: ${({ theme }) => theme.colors.background}!important;
  padding-top: 10px!important;
  padding-bottom: 10px!important;
  tr > th{
    padding: 13px 0px!important;

  }

  }
  tr > thead {
    height: 40px!important;

}

.ant-table-placeholder{
  display: none;
}
.ant-table-wrapper-rtl .ant-table-thead > tr > th {
  text-align: center !important;
}

  th.ant-table-cell{
  color: ${({ theme }) => theme.colors.text.tableHeader}!important;
  background: ${({ theme }) => theme.colors.background}!important;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  
  span.ant-table-column-sorter.ant-table-column-sorter-full {
    padding-right: 24px;
  }

  &::before{
    display: none;
  }
  &:last-child{
      padding-right: 24px!important;
    }
    &:first-child{
      padding-left: 24px!important;
    }
  }
  td.ant-table-cell{
    height: 64px!important;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    &:last-child{
      padding-right: 24px!important;
      justify-content: center;
      align-items: center;

    }
    &:first-child{
      padding-left: 24px!important;
    }
  } 
  
  tr.ant-table-row{
  padding: 0px 24px 0px 24px!important;
  }

  .ant-select-item,.ant-select-item-empty {
      height: 58px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
td{
  .ant-typography{
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
  }
}

  .ant-typography {
    color: ${({ theme }: IGlobalStylesProps) => theme.colors.text.primary};
  }
  .ant-table-tbody > tr > td, .ant-table tfoot > tr > td{
    padding: 0px !important;
  }
  .ant-message-notice.error {
    .ant-message-notice-content {
      width: 100%;
      border-radius: 10px;
      padding: 16px 24px;
      color: ${({ theme }: IGlobalStylesProps) => theme.colors.white};
      text-align: left;
      font: ${({ theme }: IGlobalStylesProps) => theme.typography.subtitles};
      font-size: 24px;
      line-height: 30px;

      .anticon {
        display: none;
      }
    }

    &.error > .ant-message-notice-content{
      background-color: ${({ theme }: IGlobalStylesProps) => theme.colors.warn};
    }
  }

  .ant-popover{
    &.no-padding {
      .ant-popover-inner-content {
        padding: 0;
      }
    }

    .ant-popover-arrow {
      display: none;
    }
  }

  .ant-tooltip-inner {
    background: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    font: ${({ theme }) => theme.typography.paragraph.font};
    letter-spacing: ${({ theme }) => theme.typography.paragraph.letterSpacing};
    border-radius: 0;
    padding: 6px 8px;
  }

  .employee-list-autocomplete {
    .ant-select-item-option-active:not(.ant-select-item-option-disabled),
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: ${({ theme }: IGlobalStylesProps) =>
        theme.colors.neutral['300']} !important;
    }

    div#rc_select_0_list {
    cursor: default;
  }

    .ant-select-item-option:hover {
      background-color: ${({ theme }: IGlobalStylesProps) =>
        theme.colors.neutral['350']} !important;
    }
  }
  
  .editProfileModal {
    .ant-modal-content {
      padding: 32px;
    }
    
    .ant-modal-header {
      padding: 0 0 24px;
      border:none;
    }
    
    .ant-modal-body {
      padding: 0;
      margin-top: 32px;
    }
    
    .ant-modal-title {
      font: ${({ theme }) => theme.typography.heading};
      color: ${({ theme }) => theme.colors.text.primary};
    }
  }
  
  .editProfile-workingHoursDropdown {
    .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
      background: ${({ theme }) => theme.colors.background};
    }
  }
  .ant-picker-ok{
    & button {
      background: ${({ theme }) => theme.colors.text.overline};
      border:${({ theme }) => `1px solid ${theme.colors.text.overline}`};
    }
    & span{
      text-transform: uppercase;
    }
  }
  .ant-checkbox-inner {
    transition: 0.2s ease-in-out;
    border: 2px solid ${({ theme }) => theme.colors.checkbox.primary};
    ::after {
      left: 20%;
    }
  }

  .ant-table-row-selected > td {
    background: ${({ theme }) => theme.colors.backgrounds.light.tableRow} !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.colors.text.overline};
    border-color: ${({ theme }) => theme.colors.text.overline};
  }

  &.ant-checkbox-wrapper {
    span {
      transition: 0.2s ease-in-out;
      text-transform: capitalize;
      color: ${({ theme }) => theme.colors.text.secondary};
    }
  }

  &.ant-checkbox-wrapper-checked {
    span {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  &:hover .ant-checkbox .ant-checkbox-inner {
    border-color: ${({ theme }) => theme.colors.text.overline};
  }

  &:hover span {
    transition: 0.2s ease-in-out;
  }
`;

const GlobalStyle = (): JSX.Element => {
  return <GlobalStyleComponent />;
};

export default GlobalStyle;
