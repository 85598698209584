interface ILanguages {
  [key: string]: {
    name: string;
    flag: string;
  };
}
export const languages: ILanguages = {
  en: {
    name: 'English',
    flag: '🇬🇧',
  },
  es: {
    name: 'Spanish',
    flag: '🇪🇸',
  },
  de: {
    name: 'German',
    flag: '🇩🇪',
  },
  ru: {
    name: 'Russian',
    flag: '🇷🇺',
  },
  cmn: {
    name: 'Mandarin (Chinese)',
    flag: '🇨🇳',
  },
  cs: {
    name: 'Czech',
    flag: '🇨🇿',
  },
  pl: {
    name: 'Polish',
    flag: '🇵🇱',
  },
  lv: {
    name: 'Latvian',
    flag: '🇱🇻',
  },
  lt: {
    name: 'Lithuanian',
    flag: '🇱🇹',
  },
  ko: {
    name: 'Korean',
    flag: '🇰🇷',
  },
  sl: {
    name: 'Slovenian',
    flag: '🇸🇮',
  },
  id: {
    name: 'Indonesian',
    flag: '🇮🇩',
  },
  bg: {
    name: 'Bulgarian',
    flag: '🇧🇬',
  },
  fi: {
    name: 'Finnish',
    flag: '🇫🇮',
  },
  it: {
    name: 'Italian',
    flag: '🇮🇹',
  },
  hu: {
    name: 'Hungarian',
    flag: '🇭🇺',
  },
  uk: {
    name: 'Ukrainian',
    flag: '🇺🇦',
  },
  et: {
    name: 'Estonian',
    flag: '🇪🇪',
  },
  pt: {
    name: 'Portugese',
    flag: '🇵🇹',
  },
  da: {
    name: 'Danish',
    flag: '🇩🇰',
  },
  nl: {
    name: 'Dutch',
    flag: '🇳🇱',
  },
  el: {
    name: 'Greek',
    flag: '🇬🇷',
  },
  sk: {
    name: 'Slovak',
    flag: '🇸🇰',
  },
  tr: {
    name: 'Turkish',
    flag: '🇹🇷',
  },
  ja: {
    name: 'Japanese',
    flag: '🇯🇵',
  },
  ro: {
    name: 'Romanian',
    flag: '🇷🇴',
  },
  ar: {
    name: 'Arabic',
    flag: '🇸🇦',
  },
  sv: {
    name: 'Swedish',
    flag: '🇸🇪',
  },
};
