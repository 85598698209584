import styled from 'styled-components';
import { Typography } from 'antd';

export const EmptyState = styled('div')`
  display: flex;
  flex: 1;
  height: 480px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const IconContainer = styled('div')`
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background};
  margin-bottom: 24px;

  svg {
    width: 34px;
    height: 34px;
  }
`;

export const Title = styled(Typography.Title)`
  &.ant-typography {
    font-size: 17px;
    line-height: 25px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const FindText = styled(Typography.Paragraph)`
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.3px;
  color: ${({ theme }) => theme.colors.text.secondary};
  text-align: center;
`;
