import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button as AntButton, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import Spinner, { FullPageSpinnerContainer } from 'components/Spinner';
import TopHeader from 'components/TopHeader';

import { queries } from 'shared/layout';
import LoginBgAsset from 'assets/LoginBgAsset.svg';
import googleLogoNoColor from 'assets/googleNoColor.svg';
import { useAuth } from 'providers/AuthProvider';

import Signet from './components/Signet';

const Text = styled(Typography.Text)<{ isSecondary?: boolean }>`
  &.ant-typography {
    color: ${({ isSecondary, theme }) =>
      isSecondary ? theme.colors.text.secondaryLight : theme.colors.primary};
    font-size: 15px;
    line-height: 22px;
    max-width: 308px;
  }
`;
const PrivacyText = styled(Text)`
  text-decoration: underline;
  :hover {
    color: ${({ theme }) => theme.colors.white};
  }
`;
const Title = styled(Typography.Title)`
  &.ant-typography {
    color: ${({ theme }) => theme.colors.white};
    font: ${({ theme }) => theme.typography.heading};
    font-size: 43px;
    line-height: 64px;
    white-space: pre-wrap;
    ${queries.tablet} {
      white-space: pre-wrap;
      font-size: 38px;
    }
  }
`;

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.backgrounds.dark.linear};
  ${queries.tablet} {
    flex-direction: row;
  }
`;

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(${LoginBgAsset});
  background-size: auto;
  background-position: bottom;
  background-repeat: no-repeat;

  ${queries.tablet} {
    width: 100%;
  }
`;

const Frame = styled.div`
  max-width: 310px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Button = styled(AntButton)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 32px 20px 24px;
  width: 308px;
  height: 54px;
  gap: 8px;
  margin-bottom: 24px;
  background: ${({ theme }) => theme.colors.button.linear};
  border: none;

  span.ant-typography {
    color: ${({ theme }) => theme.colors.white};
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    text-transform: uppercase;
  }

  &:hover,
  &:focus,
  &:active {
    background: ${({ theme }) => theme.colors.button.linearHover};
  }
`;

const LoginScreen = (): JSX.Element => {
  const { t } = useTranslation();
  const { login } = useAuth();

  const [signingIn, setSigningIn] = useState(false);

  const handleLogin = () => {
    login();
    setSigningIn(true);
  };

  return (
    <Container id="loginPage">
      <Wrapper>
        <Frame>
          <TopHeader />

          {!signingIn ? (
            <>
              <Signet />
              <Title id="loginPageTitle">{t('sign-in.title')}</Title>
              <Button
                icon={<img src={googleLogoNoColor} alt="google-logo" />}
                onClick={handleLogin}
                id="continueWithGoogleButton"
              >
                <Text>{t('sign-in.continue-with-google')}</Text>
              </Button>
              <Text isSecondary id="loginPageSecondaryText">
                {t('sign-in.text-4')}

                <Link to="/privacy-policy" id="privacyPolicyLink">
                  &nbsp;
                  {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                  <PrivacyText isSecondary>{`${t('sign-in.privacy-policy')}`}</PrivacyText>.
                </Link>
              </Text>
            </>
          ) : (
            <FullPageSpinnerContainer>
              <Spinner message={t('sign-in.loading')} variant="dark" />
            </FullPageSpinnerContainer>
          )}
        </Frame>
      </Wrapper>
    </Container>
  );
};

export default LoginScreen;
