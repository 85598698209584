import styled, { css } from 'styled-components';
import { Input, Typography, Button, Skeleton } from 'antd';

export const ColumnContainer = styled('div')`
  display: flex;
  min-height: 100%;
`;

type ColumnProps = {
  isEmpty?: boolean;
  right?: boolean;
  left?: boolean;
};

type LanguageProps = {
  language?: string;
};

type TechnicalProps = {
  technical?: string;
};

export const Column = styled('div')<ColumnProps>`
  padding: 0 32px;
  width: 50%;
  ${(props) =>
    props.left &&
    css`
      max-height: 486px;
    `}
  ${(props) =>
    props.isEmpty &&
    css`
      position: absolute;
      top: 80px;
      right: 0;
    `}

  &:first-child {
    border-right: #e6e6ef 1px solid;
  }
  ${(props) =>
    props.right &&
    css`
      max-height: 492px;
      overflow-y: scroll;
      overflow-x: hidden;
      ::-webkit-scrollbar {
        width: 8px;
      }
      ::-webkit-scrollbar-thumb {
        background: ${({ theme }) => theme.colors.scrollbar.primary};
        opacity: 0.16;
        border-radius: 100px;
      }
      ::-webkit-scrollbar-track {
        background: ${({ theme }) => theme.colors.calendar.timeOffBorder};
        border: 1px solid ${({ theme }) => theme.colors.background};
      }
    `}
`;

export const SearchInput = styled(Input)`
  border-color: ${({ theme }) => theme.colors.pagination.primary};
  box-shadow: none;

  &.ant-input-affix-wrapper {
    padding: 15px 17px;
  }

  .ant-input-prefix {
    margin-right: 9px;
  }

  &:not(.ant-input-affix-wrapper-disabled):hover {
    border: 1px solid ${({ theme }) => theme.colors.secondary['200']};
    box-shadow: none;
  }
`;

export const EmptyText = styled(Typography.Paragraph)`
  overflow: hidden;
  text-align: center;
  max-height: 64px;
  &.ant-typography {
    padding: 22px;
    color: ${({ theme }) => theme.colors.text.secondary};
    margin: 0;
    z-index: 5;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    background-color: white;
  }
`;

export const SubTitleContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  padding-bottom: 18px;
`;

export const SubTitle = styled(Typography.Title)`
  &.ant-typography {
    font-size: 21px;
    line-height: 32px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.primary};
    margin: 0;
  }
`;

export const OutlineButton = styled(Button)`
  box-shadow: none;
  border-color: ${({ theme }) => theme.colors.pagination.primary};
  font-size: 12px;
  line-height: 16px;
  border-radius: 4px;
  height: 36px;
  padding: 4px 16px;

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.secondary['200']};
  }

  svg {
    width: 12px;
    height: 12px;
    margin-right: 8px;
    vertical-align: -1px;
  }
`;

export const TagsContainer = styled('div')`
  padding: 16px 0;
  display: flex;
  gap: 10px;
  overflow-x: hidden;
  position: relative;
`;

export const LeftIconContainer = styled('div')`
  z-index: 2;
  position: absolute;
  top: 22px;
  &:hover {
    cursor: pointer;
    svg rect:last-of-type {
      stroke: ${({ theme }) => theme.colors.text.overline};
    }
  }
`;

export const CloseIconContainer = styled('div')`
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
    svg rect:last-of-type {
      stroke: ${({ theme }) => theme.colors.text.overline};
    }
  }
`;

export const RightIconContainer = styled('div')`
  position: absolute;
  left: 310px;
  top: 22px;
  &:hover {
    cursor: pointer;
    svg rect:last-of-type {
      stroke: ${({ theme }) => theme.colors.text.overline};
    }
  }
`;

export const BgBlur = styled('div')`
  width: 40px;
  height: 36px;
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 46.88%,
    #ffffff 100%
  );
`;

export const TagButton = styled(OutlineButton)`
  border-color: ${({ theme }) => theme.colors.pagination.primary} !important;
  background-color: ${({ theme }) => theme.colors.background} !important;
  position: relative;
  left: 0px;
  &:hover {
    border-color: ${({ theme }) => theme.colors.text.overline} !important;
  }
  &.middle {
    left: -200px;
  }
  &.right {
    left: -380px;
  }
  &.active {
    border-color: ${({ theme }) => theme.colors.text.overline} !important;
    color: ${({ theme }) => theme.colors.text.overline} !important;
  }
`;

export const SkillText = styled(Typography.Paragraph)<LanguageProps>`
  ${(props) =>
    props.language === 'language' &&
    css`
      margin-top: 16px;
    `}
  &.ant-typography {
    margin-bottom: 8px;
  }
  font: ${({ theme }) => theme.typography.paragraph.font};
  letter-spacing: ${({ theme }) => theme.typography.paragraph.letterSpacing};
`;

export const SkillsWrapper = styled('div')<TechnicalProps>`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  ${(props) =>
    props.technical === 'technical'
      ? css`
          height: 282px;
        `
      : css`
          height: 333px;
        `}
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const HeaderWrapper = styled('div')<TechnicalProps>`
  position: sticky;
  top: 0;
  ${(props) =>
    props.technical === 'technical'
      ? css`
          min-height: 196px;
        `
      : css`
          min-height: 144px;
        `}
`;

export const SkeletonSkill = styled(Skeleton.Input)`
  .ant-skeleton-input {
    height: 54px;
    border-radius: 4px;
    margin-bottom: 12px;
  }
`;
