import React from 'react';
import styled from 'styled-components';

import TimeOffHiddenPopover, {
  TimeOffHiddenPopoverProps,
} from 'features/TimeOff/components/Calendar/TimeOffHiddenPopover/TimeOffHiddenPopover';

import TimeOffHidden from '../TimeOffHidden/TimeOffHidden';

const AbsoluteWrapper = styled.div`
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  z-index: 5;

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }
`;

const TimeOffHiddenWithPopover = ({
  direction,
  timesOff,
  ...props
}: TimeOffHiddenPopoverProps): JSX.Element => {
  const count = timesOff.length;
  const sortedTimesOff = timesOff.sort((a, b) =>
    direction === 'left'
      ? Date.parse(b.startDate) - Date.parse(a.startDate)
      : Date.parse(a.startDate) - Date.parse(b.startDate)
  );

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const getParentEl = (node: HTMLElement) => node.parentElement!;

  return (
    <TimeOffHiddenPopover
      timesOff={sortedTimesOff}
      direction={direction}
      getPopupContainer={(node) => getParentEl(node)}
      {...props}
    >
      <AbsoluteWrapper className={direction}>
        <TimeOffHidden count={count} />
      </AbsoluteWrapper>
    </TimeOffHiddenPopover>
  );
};

export default TimeOffHiddenWithPopover;
