import { Divider, DividerProps } from 'antd';
import React from 'react';
import styled from 'styled-components';

import style from 'features/TimeOff/constants/style';

import CalendarBackground from '../CalendarBackground/CalendarBackground';

const DividerWrapper = styled.div<CalendarDividerProps>`
  position: sticky;
  z-index: 10;
  top: ${({ index }) => style.groupName.height * (index ?? 0)}px;
  height: ${style.groupName.height}px;
  background-color: transparent;
`;

const CalendarBGWrapper = styled.div<{ height: number }>`
  height: ${({ height }) => height}px;
  display: flex;
  position: sticky;
  z-index: 5;
`;

const StyledDivider = styled(Divider)`
  position: absolute;
  z-index: 7;
  margin: 0;
  top: 24px;
`;

export interface CalendarDividerProps {
  index?: number;
  halfWidth?: boolean;
  showDivider?: boolean;
}

// Rendering the CalendarBackground is fairly expensive (because of it's width)
// and there is no point in re-rendering it if any of the props on CalendarDivider change
const MemoizedCalendarBackground = React.memo(CalendarBackground);

const CalendarDivider = ({
  index = 0,
  halfWidth = false,
  showDivider = true,
  ...props
}: DividerProps & CalendarDividerProps): JSX.Element => {
  return (
    <DividerWrapper index={index}>
      <CalendarBGWrapper height={(halfWidth ? 0.5 : 1) * style.groupName.height}>
        <MemoizedCalendarBackground />
      </CalendarBGWrapper>
      {showDivider && <StyledDivider {...props} />}
    </DividerWrapper>
  );
};

export default CalendarDivider;
