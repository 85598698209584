import { QueryObserverResult, useQuery } from '@tanstack/react-query';

import GetUserBasicInformation, { ReturnType } from 'api/GetUserBasicInformation';
import { cacheTime } from 'api/Client';
import { User } from 'API';

interface IUseUserQuery {
  currentUser:
    | Pick<
        User,
        | 'id'
        | 'email'
        | 'name'
        | 'profilePicture'
        | 'team'
        | 'role'
        | 'onboardingCompleted'
        | 'technicalRole'
        | 'isTeamLead'
      >
    | undefined;
  refetch(): Promise<QueryObserverResult<ReturnType, unknown>>;

  isLoading: boolean;
  isFetching: boolean;
  error: unknown;
  isError: boolean;
}

export default function useUserQuery(token?: string | null): IUseUserQuery {
  const { data, refetch, isLoading, isFetching, error, isError } = useQuery(
    ['getUserBasicInformation'],
    async () => {
      const result = await GetUserBasicInformation();
      return result;
    },
    {
      staleTime: cacheTime,
      enabled: !!token,
    }
  );
  const { getOwnUser: currentUser } = data || {};
  return { currentUser, refetch, isLoading, isFetching, error, isError };
}
