import styled from 'styled-components';
import { Button as AntButton } from 'antd';

export const Button = styled(AntButton)`
  box-shadow: none;
  display: flex;
  border-color: ${({ theme }) => theme.colors.pagination.primary};
  border-radius: 4px;
  width: 100%;
  text-align: left;
  font-size: 15px;
  line-height: 22px;
  padding: 16px;
  height: 54px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.secondary['200']};
    svg path {
      fill: ${({ theme }) => theme.colors.text.overline};
    }
  }

  svg {
    width: 20px;
  }
`;
