/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectMyPtoUsed } from 'features/TimeOff/redux/timesOffSlice';

import Days from './Days';
import { Wrapper, Flex, Group, Subtitle, SubtitleGroup, Title, Year } from './PtoTooltips.styles';

const UnlimitedPtoTooltipContent = (): JSX.Element => {
  const { t } = useTranslation();
  const d = new Date();
  const year = d.getFullYear();
  const ptoUsed = useSelector(selectMyPtoUsed);
  return (
    <Wrapper>
      <Title>{t('pto-tooltip.balance')}</Title>
      <Group>
        <SubtitleGroup>
          <Subtitle>
            {t('pto-tooltip.taken')}

            <Year> {year}</Year>
          </Subtitle>
          <Flex>
            <Days count={ptoUsed} />
            {ptoUsed === 1 || ptoUsed === -1 ? t('pto-tooltip.day') : t('pto-tooltip.days')}
          </Flex>
        </SubtitleGroup>
      </Group>
      <SubtitleGroup>
        <Subtitle bottom>
          {t('pto-tooltip.available')}
          <Year> {year}</Year>
        </Subtitle>

        <Flex bottom>
          <Days unlimited count={0} />
          {t('pto-tooltip.days')}
        </Flex>
      </SubtitleGroup>
    </Wrapper>
  );
};

export default UnlimitedPtoTooltipContent;
