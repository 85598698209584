import React from 'react';

import UserSection from 'features/Profile/components/ProfileOverview/User/UserSection';
import ProjectsSection from 'features/Profile/components/ProfileOverview/Projects/ProjectsSection';

const ProfileOverviewPage = (): JSX.Element => {
  return (
    <>
      <UserSection />

      <ProjectsSection />
    </>
  );
};

export default ProfileOverviewPage;
