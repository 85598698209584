/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  selectMyPtoAllowance,
  selectMyPtoCurrent,
  selectMyPtoLeft,
  selectMyPtoUsed,
} from 'features/TimeOff/redux/timesOffSlice';

import {
  BottomDiv,
  BottomTitle,
  Flex,
  Group,
  Subtitle,
  SubtitleGroup,
  Title,
  Valid,
  Wrapper,
  Year,
} from './PtoTooltips.styles';
import Days from './Days';

const StackingUOPTooltipContent = (): JSX.Element => {
  const { t } = useTranslation();
  const ptoLeft = useSelector(selectMyPtoLeft);
  const ptoCurrent = useSelector(selectMyPtoCurrent);
  const ptoUsed = useSelector(selectMyPtoUsed);
  const ptoAllowance = useSelector(selectMyPtoAllowance);

  const d = new Date();
  const year = d.getFullYear();
  return (
    <>
      <Wrapper>
        <Title>{t('pto-tooltip.balance')}</Title>
        <Group>
          <SubtitleGroup>
            <Subtitle>
              {t('pto-tooltip.contracted')}
              <Year> {t('pto-tooltip.per-year')}</Year>
            </Subtitle>
            <Flex>
              <Days neutral count={'value' in ptoAllowance ? ptoAllowance.value : 0} />
              {t('pto-tooltip.days')}
            </Flex>
          </SubtitleGroup>
          <SubtitleGroup>
            <Subtitle>{t('pto-tooltip.stacked')}</Subtitle>
            <Flex>
              <Days count={ptoCurrent} />
              {ptoCurrent === 1 ? t('pto-tooltip.day') : t('pto-tooltip.days')}
            </Flex>
          </SubtitleGroup>
          <SubtitleGroup>
            <Subtitle>
              {t('pto-tooltip.taken')}
              <Year> {year}</Year>
            </Subtitle>
            <Flex>
              <Days count={ptoUsed} />
              {ptoUsed === 1 || ptoUsed === -1 ? t('pto-tooltip.day') : t('pto-tooltip.days')}
            </Flex>
          </SubtitleGroup>
        </Group>
        <SubtitleGroup>
          <Subtitle bottom>
            {t('pto-tooltip.available')}
            <Year> {year}</Year>
          </Subtitle>
          <Flex bottom>
            <Days count={ptoLeft} />
            {ptoLeft === 1 || ptoLeft === -1 ? t('pto-tooltip.day') : t('pto-tooltip.days')}
          </Flex>
        </SubtitleGroup>
      </Wrapper>
      <BottomDiv>
        <BottomTitle>{t('pto-tooltip.why')}</BottomTitle>
        <Subtitle>
          <Valid>
            {t('pto-tooltip.1st-uop-1')}
            <span style={{ color: 'white' }}>{t('pto-tooltip.0-days')}</span>
          </Valid>
          <br />
          <Valid>
            {t('pto-tooltip.1st-uop-2')}
            <br /> {t('pto-tooltip.1st-uop-3')} <br /> {t('pto-tooltip.1st-uop-4')}
            <span style={{ color: 'white' }}>{t('pto-tooltip.1st-uop-5')}</span>.
          </Valid>
        </Subtitle>
      </BottomDiv>
    </>
  );
};

export default StackingUOPTooltipContent;
