import React from 'react';
import { useTranslation } from 'react-i18next';

import emptyStateImage from 'assets/png/EmptyBench.png';

import {
  EmptyStateContainer,
  EmptyStateImage,
  EmptyStatePrimaryText,
  EmptyStateSecondaryText,
} from './EmptyTeamLeadViewSkills.styles';

export const EmptyTeamLeadViewSkills = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <EmptyStateContainer>
      <EmptyStateImage src={emptyStateImage} alt="Person sitting on the bench" />
      <EmptyStatePrimaryText>{t('team-lead-view.empty-states.primary-text')}</EmptyStatePrimaryText>
      <EmptyStateSecondaryText>
        {t('team-lead-view.empty-states.secondary-text')}
      </EmptyStateSecondaryText>
    </EmptyStateContainer>
  );
};
