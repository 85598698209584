import { PublicTimeOffRecordsResult, QueryListUsersArgs, TimeOffDatesResponse, User } from 'API';
import generateGraphqlCaller from 'utils/helpers/generateGraphqlCaller';

const query = /* GraphQL */ `
  query QuerySearchResults($filters: UserFilter, $before: Date!, $after: Date!) {
    listUsers(filters: $filters) {
      id
      name
      profilePicture
      role
      timeOffDates(after: $after, before: $before) {
        ... on PublicTimeOffRecordsResult {
          timeOffs {
            id
            date
            status
          }
        }
        ... on SalesforceError {
          message
        }
        ... on JiraError {
          message
        }
        ... on UnexpectedError {
          message
        }
        ... on Error {
          message
        }
      }
    }
  }
`;

export type TimeOffQueryData = {
  listUsers: Array<
    Pick<User, 'id' | 'name' | 'profilePicture' | 'role'> & {
      timeOffDates: TimeOffDatesResponse | PublicTimeOffRecordsResult;
    }
  >;
};

export type VariablesType = Pick<QueryListUsersArgs, 'filters'> & {
  before: string;
  after: string;
};

export default generateGraphqlCaller<TimeOffQueryData, VariablesType>(query);
