import React from 'react';
import styled from 'styled-components';

import useCalendarDateOffset from 'features/TimeOff/hooks/useDateOffset';

import TimeOffBarLoading from './TimeOffBar/Loading';

export interface TimeOffElementProps {
  leftOffset: number;
}

const TimeOffBarWithOffset = styled(TimeOffBarLoading)<TimeOffElementProps>`
  left: ${({ leftOffset }) => leftOffset}px;
`;

export interface TimeOffElementLoadingProps {
  startDate: Date;
  days: number;
  scrollLeft?: number;
}

const TimeOffElementLoading = ({
  startDate,
  days,
  scrollLeft,
}: TimeOffElementLoadingProps): JSX.Element | null => {
  const getTimeOffOffset = useCalendarDateOffset();
  const offset = getTimeOffOffset(startDate) + (scrollLeft ?? 0);
  return <TimeOffBarWithOffset leftOffset={offset} days={days} />;
};

export default TimeOffElementLoading;
