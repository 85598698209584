/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';

import useToggle from 'hooks/useToggle';
import { EditProfileForm } from 'features/Profile/components/EditProfile';
import { showError, showSuccess } from 'utils/helpers/showMessage';
import { useGetProfileById } from 'services/api/profile';
import mutate from 'features/Profile/api/mutateEditContactInformation';
import getResponseErrors from 'utils/helpers/getResponseErrors';

import { EditDetailsButton } from './EditProfile.styles';
import type { EditProfileValues, EditProfileErrors } from './EditProfileForm/EditProfileForm';

const initialErrors = {
  phoneNumber: '',
  employeeLocation: '',
  workingHours: '',
};

interface IEditProfile {
  isEditProfileModalOpen: boolean;
  setIsEditProfileModalOpen: (isOpen: boolean) => void;
}

const EditProfile = ({
  isEditProfileModalOpen,
  setIsEditProfileModalOpen,
}: IEditProfile): JSX.Element => {
  const { userId } = useParams<{ userId: string }>();
  const { t } = useTranslation();
  const { isOpen: isEditing, setIsOpen: setIsEditing } = useToggle(false);
  const { data, refetch } = useGetProfileById(userId as string);
  const { email, phoneNumber, employeeLocation, workingHours } = data?.getUser ?? {};
  const [profileDetailInformation, setProfileDetailInformation] = useState<
    EditProfileValues & { email: string }
  >({
    email: '',
    phoneNumber: '',
    employeeLocation: '',
    workingHours: '',
  });
  const [errors, setErrors] = useState<EditProfileErrors>(initialErrors);

  useEffect(() => {
    setProfileDetailInformation({
      email,
      phoneNumber,
      employeeLocation,
      workingHours,
    });
  }, [email, phoneNumber, employeeLocation, workingHours]);

  const showModal = () => {
    setIsEditProfileModalOpen(true);
  };

  const handleSave = async (values: EditProfileValues) => {
    setIsEditing(true);

    const input = {
      userId: userId || '',
      workingHours: values.workingHours || '',
      employeeLocation: values.employeeLocation || '',
      phoneNumber: values.phoneNumber || null,
    };
    mutate({
      input,
    })
      .then(() => refetch())
      .then(() => {
        showSuccess({
          content: t('profile.edit-contact-information.success-message'),
        });
        setIsEditProfileModalOpen(false);
      })
      .catch((res) => {
        const { fieldErrors } = getResponseErrors(res.errors);
        if (fieldErrors) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setErrors(fieldErrors);
        } else {
          showError({
            content: t('profile.edit-contact-information.error-message'),
          });
        }
      })
      .finally(() => {
        setIsEditing(false);
      });
  };

  const handleCancel = () => {
    setIsEditProfileModalOpen(false);
  };
  return (
    <>
      <Tooltip placement="bottom" title={t('profile.edit-contact-information.description')}>
        <EditDetailsButton onClick={showModal} type="text" id="editDetailsButton">
          <EditOutlined />
        </EditDetailsButton>
      </Tooltip>
      <Modal
        onCancel={handleCancel}
        className="editProfileModal"
        width={404}
        title={t('profile.edit-contact-information.title')}
        visible={isEditProfileModalOpen}
        maskClosable={false}
        footer={null}
      >
        <EditProfileForm
          onCancel={handleCancel}
          onSave={handleSave}
          isLoading={isEditing}
          email={profileDetailInformation.email}
          phoneNumber={profileDetailInformation.phoneNumber}
          employeeLocation={profileDetailInformation.employeeLocation}
          workingHours={profileDetailInformation.workingHours}
          errors={errors}
          variant="profile"
        />
      </Modal>
    </>
  );
};

export default EditProfile;
