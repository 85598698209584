import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type {
  EditProfileValues,
  EditProfileErrors,
} from 'features/Profile/components/EditProfile/EditProfileForm/EditProfileForm';
import getResponseErrors from 'utils/helpers/getResponseErrors';
import useToggle from 'hooks/useToggle';
import mutateEditContactInformation from 'features/Profile/api/mutateEditContactInformation';
import { showError, showSuccess } from 'utils/helpers/showMessage';
import { EditContactInfoErrorResponse } from 'API';
import { useAuth } from 'providers/AuthProvider';
import { EditProfileForm } from 'features/Profile/components/EditProfile';

import { Text, Container, Header, Modal, Title } from './UserDetailsStyles';

const initialErrors = {
  phoneNumber: '',
  employeeLocation: '',
  workingHours: '',
};

interface IUserDetails {
  setCurrentPage: (page: number) => void;
}
const UserDetails = ({ setCurrentPage }: IUserDetails): JSX.Element => {
  const { currentUser } = useAuth();
  const { t } = useTranslation();
  const { isOpen: isLoading, setIsOpen: setIsLoading } = useToggle(false);
  const [errors, setErrors] = useState<EditProfileErrors>(initialErrors);
  const [profileDetailInformation] = useState<EditProfileValues & { email: string }>({
    email: currentUser?.email ?? '',
    phoneNumber: '',
    employeeLocation: '',
    workingHours: '',
  });
  const handleSave = async (values: EditProfileValues) => {
    setIsLoading(true);

    const input = {
      userId: `${currentUser?.id}`,
      workingHours: values.workingHours || '',
      employeeLocation: values.employeeLocation || '',
      phoneNumber: values.phoneNumber || null,
    };

    try {
      await mutateEditContactInformation({
        input,
      });

      showSuccess({
        content: t('profile.edit-contact-information.success-message'),
      });

      setCurrentPage(2);
    } catch (err) {
      const errorResponse = err as EditContactInfoErrorResponse;
      const { errors: errorsArray } = errorResponse;
      if (errorsArray) {
        const { fieldErrors } = getResponseErrors(errorsArray);
        if (fieldErrors) {
          const { employeeLocation, phoneNumber, workingHours } = fieldErrors;
          setErrors({
            employeeLocation: employeeLocation ? employeeLocation[0] : '',
            phoneNumber: phoneNumber ? phoneNumber[0] : '',
            workingHours: workingHours ? workingHours[0] : '',
          });
        }
      } else {
        showError({
          content: t('profile.edit-contact-information.error-message'),
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container id="userDetailsPage">
      <Modal>
        <Header>
          <Title id="userDetailsPageTitle">{t('onboarding.user-details.title')}</Title>
          <Text id="userDetailsPageText">{t('onboarding.user-details.subtitle')}</Text>
        </Header>
        <EditProfileForm
          onSave={handleSave}
          isLoading={isLoading}
          email={profileDetailInformation.email}
          phoneNumber={profileDetailInformation.phoneNumber}
          employeeLocation={profileDetailInformation.employeeLocation}
          workingHours={profileDetailInformation.workingHours}
          errors={errors}
          variant="onboarding"
        />
      </Modal>
    </Container>
  );
};

export default UserDetails;
