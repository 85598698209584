import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { showError } from 'utils/helpers/showMessage';
import {
  PtoAllowanceResponse,
  User,
  ContractTypeResponse,
  HadAnnualVacationLeaveResponse,
} from 'API';
import removeIfKeyValuePairExists from 'utils/helpers/removeIfKeyExists';
import { UserTimeOff } from 'features/TimeOff/types/calendarTypes';
import { RootState } from 'redux/rootReducer';
import addUniq from 'utils/helpers/addUniq';
import removeIfExists from 'utils/helpers/removeIfExists';
import api from 'features/TimeOff/api';
import { SEARCH_STATUS } from 'utils/helpers/searchStatus';
import GetUserBasicInformation from 'api/GetUserBasicInformation';

interface TimesOffState {
  myTimesOff: UserTimeOff | undefined;
  selectedTimesOff: UserTimeOff[];
  favouriteTimesOff: UserTimeOff[];
  ptoAllowance: PtoAllowanceResponse;
  contractType: ContractTypeResponse;
  ptoLeft: {
    ptoLeft: number | null;
    ptoCurrent: number | null;
    ptoUsed?: number | null;
    ptoCarryover?: number | null;
  };
  hasUnlimitedPto: boolean;
  foundTimesOff: UserTimeOff[];
  searchStatus: SEARCH_STATUS;
  isCalendarLoading: boolean;
  hadAnnualVacationLeaveUop: HadAnnualVacationLeaveResponse;
}

const initialState: TimesOffState = {
  myTimesOff: undefined,
  selectedTimesOff: [],
  favouriteTimesOff: [],
  ptoAllowance: { value: null },
  contractType: { message: 'null' },
  ptoLeft: {
    ptoLeft: null,
    ptoCurrent: null,
    ptoUsed: null,
    ptoCarryover: null,
  },
  hasUnlimitedPto: false,
  foundTimesOff: [],
  searchStatus: SEARCH_STATUS.IDLE,
  isCalendarLoading: true,
  hadAnnualVacationLeaveUop: { value: false },
};

const setSearchStatus = (
  state: TimesOffState,
  action: PayloadAction<SEARCH_STATUS>
): TimesOffState => {
  return {
    ...state,
    searchStatus: action.payload,
  };
};

const setMyTimesOff = (state: TimesOffState, action: PayloadAction<UserTimeOff>): TimesOffState => {
  return { ...state, myTimesOff: action.payload };
};

const setCalendarLoading = (state: TimesOffState, action: PayloadAction<boolean>) => {
  return { ...state, isCalendarLoading: action.payload };
};

const setSelectedTimesOff = (
  state: TimesOffState,
  action: PayloadAction<UserTimeOff[]>
): TimesOffState => {
  return { ...state, selectedTimesOff: action.payload };
};

const addSelectedTimeOff = (
  state: TimesOffState,
  action: PayloadAction<UserTimeOff>
): TimesOffState => {
  const selectedTimesOff = addUniq(state.selectedTimesOff, action.payload);
  return { ...state, selectedTimesOff };
};

const removeSelectedTimeOff = (
  state: TimesOffState,
  action: PayloadAction<UserTimeOff>
): TimesOffState => {
  const selectedTimesOff = removeIfExists(state.selectedTimesOff, action.payload);
  return { ...state, selectedTimesOff };
};

const setFavouriteTimesOff = (
  state: TimesOffState,
  action: PayloadAction<UserTimeOff[]>
): TimesOffState => {
  return { ...state, favouriteTimesOff: action.payload };
};

const setFoundTimesOff = (
  state: TimesOffState,
  action: PayloadAction<UserTimeOff[]>
): TimesOffState => {
  return { ...state, foundTimesOff: action.payload };
};

const addFavouriteTimeOff = createAsyncThunk(
  'timesOff/addFavouriteTimeOff',
  async (timeOff: UserTimeOff): Promise<string | null | undefined> => {
    const response = await api.myList.addToMyList(timeOff.id);
    return response;
  }
);
const removeFavouriteTimeOff = createAsyncThunk(
  'timesOff/removeFavouriteTimeOff',
  async (userId: string, { getState }): Promise<User['id'] | undefined | null> => {
    const state = getState() as RootState;
    const myListId = state.calendar.timesOff.favouriteTimesOff.find(
      (userTimeOff) => userTimeOff.id === userId
    );

    if (!myListId) {
      showError({
        content: 'Error removing from my list, please try again later',
      });
      return null;
    }
    const { getOwnUser } = await GetUserBasicInformation();
    const response = await api.myList.removeFromMyList(myListId.id, getOwnUser.id);

    return response;
  }
);

const fetchMyTimesOff = createAsyncThunk(
  'timesOff/fetchMyTimeOff',
  async (
    userId: string,
    { getState }
  ): Promise<{
    timeOff: UserTimeOff;
    ptoAllowance: PtoAllowanceResponse;
    contractType: ContractTypeResponse;
    hadAnnualVacationLeaveUop: HadAnnualVacationLeaveResponse;
    ptoLeft: {
      ptoLeft: number | null;
      ptoCurrent: number | null;
      ptoUsed: number | null;
      ptoCarryover?: number | null;
    };
    hasUnlimitedPto: boolean;
  }> => {
    const state = getState() as RootState;
    const { startDate, endDate } = state.calendar.calendarRange;
    const response = await api.getMyTimesOff(userId, startDate, endDate);
    const responseDTO = {
      timeOff: response.timeOff,
      ptoAllowance: response.ptoAllowance,
      contractType: response.contractType,
      hadAnnualVacationLeaveUop: response.hadAnnualVacationLeaveUop,
      ptoLeft: {
        ptoLeft: response.ptoLeft.ptoLeft,
        ptoCurrent: response.ptoLeft.ptoCurrent,
        ptoUsed: response.ptoLeft.ptoUsed,
        ptoCarryover: response.ptoLeft.ptoCarryover ?? null,
      },
      hasUnlimitedPto: response.hasUnlimitedPto,
    };
    return responseDTO;
  }
);

const fetchFavouriteTimesOff = createAsyncThunk(
  'timesOff/fetchFavouriteTimesOff',
  async (_, { getState }): Promise<UserTimeOff[]> => {
    const state = getState() as RootState;
    const { startDate, endDate } = state.calendar.calendarRange;
    const response = await api.myList.getMyList(startDate, endDate);
    return response;
  }
);

const fetchSelectedUsersTimesOff = createAsyncThunk(
  'timesOff/fetchSelectedUsersTimesOff',
  async (selectedTimesOff: UserTimeOff[], { getState }): Promise<UserTimeOff[]> => {
    const state = getState() as RootState;
    const selectedTimesOffIds = selectedTimesOff.map((timeOff) => timeOff.id);

    if (selectedTimesOffIds.length === 0) {
      return [];
    }

    const { startDate, endDate } = state.calendar.calendarRange;

    const response = await api.getUsersTimesOff(startDate, endDate, selectedTimesOffIds);
    return response;
  }
);

const searchUsersTimesOff = createAsyncThunk(
  'timesOff/searchUsersTimesOff',
  async (query: string, { getState }): Promise<UserTimeOff[]> => {
    const state = getState() as RootState;
    const { startDate, endDate } = state.calendar.calendarRange;

    const { favouriteTimesOff, selectedTimesOff } = state.calendar.timesOff;
    const excludedIds = [...selectedTimesOff, ...favouriteTimesOff].map((timeOff) => timeOff.id);
    if (state.calendar.timesOff.myTimesOff) {
      excludedIds.push(state.calendar.timesOff.myTimesOff.id);
    }
    const response: UserTimeOff[] = await api.searchUserTimesOff(
      query,
      startDate,
      endDate,
      excludedIds
    );
    return response;
  }
);

const timesOffSlice = createSlice({
  name: 'timesOff',
  initialState,
  reducers: {
    setMyTimesOff,
    setCalendarLoading,
    setFavouriteTimesOff,
    setSelectedTimesOff,
    addSelectedTimeOff,
    setFoundTimesOff,
    removeSelectedTimeOff,
    setSearchStatus,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMyTimesOff.pending, (state) => {
      return { ...state, myTimesOff: undefined };
    });
    builder.addCase(
      fetchMyTimesOff.fulfilled,
      (
        state,
        action: PayloadAction<{
          timeOff: UserTimeOff;
          ptoAllowance: PtoAllowanceResponse;
          contractType: ContractTypeResponse;
          hadAnnualVacationLeaveUop: HadAnnualVacationLeaveResponse;
          ptoLeft: {
            ptoLeft: number | null;
            ptoCurrent: number | null;
            ptoUsed?: number | null;
            ptoCarryover?: number | null;
          };
          hasUnlimitedPto: boolean;
        }>
      ) => {
        return {
          ...state,
          myTimesOff: action.payload.timeOff,
          ptoAllowance: action.payload.ptoAllowance,
          contractType: action.payload.contractType,
          hadAnnualVacationLeaveUop: action.payload.hadAnnualVacationLeaveUop,
          ptoLeft: {
            ptoLeft: action.payload.ptoLeft.ptoLeft,
            ptoCurrent: action.payload.ptoLeft.ptoCurrent,
            ptoUsed: action.payload.ptoLeft.ptoUsed,
            ptoCarryover: action.payload.ptoLeft.ptoCarryover ?? null,
          },
          hasUnlimitedPto: action.payload.hasUnlimitedPto,
        };
      }
    );

    builder.addCase(fetchFavouriteTimesOff.pending, (state) => {
      return { ...state, favouriteTimesOff: [] };
    });

    builder.addCase(
      fetchFavouriteTimesOff.fulfilled,
      (state, action: PayloadAction<UserTimeOff[]>) => {
        return { ...state, favouriteTimesOff: action.payload };
      }
    );

    builder.addCase(fetchSelectedUsersTimesOff.pending, (state) => {
      return { ...state, selectedTimesOff: [] };
    });

    builder.addCase(
      fetchSelectedUsersTimesOff.fulfilled,
      (state, action: PayloadAction<UserTimeOff[]>) => {
        return { ...state, selectedTimesOff: action.payload };
      }
    );

    builder.addCase(searchUsersTimesOff.pending, (state) => {
      return { ...state, searchStatus: SEARCH_STATUS.LOADING, foundTimesOff: [] };
    });

    builder.addCase(
      searchUsersTimesOff.fulfilled,
      (state, action: PayloadAction<UserTimeOff[]>) => {
        const searchStatus =
          action.payload.length === 0 ? SEARCH_STATUS.NONE_FOUND : SEARCH_STATUS.FOUND;
        return { ...state, foundTimesOff: action.payload, searchStatus };
      }
    );

    builder.addCase(addFavouriteTimeOff.pending, (state, { meta }) => {
      const favouriteTimesOff = addUniq(state.favouriteTimesOff, meta.arg);
      const selectedTimesOff = removeIfExists(state.selectedTimesOff, meta.arg);
      return { ...state, favouriteTimesOff, selectedTimesOff };
    });

    builder.addCase(addFavouriteTimeOff.rejected, (state, { meta }) => {
      const favouriteTimesOff = removeIfExists(state.favouriteTimesOff, meta.arg);
      const selectedTimesOff = addUniq(state.selectedTimesOff, meta.arg);
      showError({
        content: 'Error adding to my list. Try again later',
      });
      return { ...state, favouriteTimesOff, selectedTimesOff };
    });

    builder.addCase(
      addFavouriteTimeOff.fulfilled,
      (state, action: PayloadAction<string | null | undefined>) => {
        if (action.payload === null) {
          return state;
        }
        const favouriteTimesOff = state.favouriteTimesOff.map((userTimeOff) => {
          const timeOff = { ...userTimeOff };
          // if (userTimeOff.id === action.payload?.user.id) timeOff.myListId = action.payload.id;
          return timeOff;
        });
        return { ...state, favouriteTimesOff };
      }
    );

    builder.addCase(
      removeFavouriteTimeOff.fulfilled,
      (state, action: PayloadAction<User['id'] | undefined | null>) => {
        if (action.payload === null) {
          return state;
        }
        const favouriteTimesOff = removeIfKeyValuePairExists(
          state.favouriteTimesOff,
          action.payload
        );
        return { ...state, favouriteTimesOff };
      }
    );

    builder.addCase(removeFavouriteTimeOff.rejected, (state) => {
      showError({
        content: 'Error removing from my list, please try again later',
      });
      return state;
    });
  },
});

export const actions = {
  ...timesOffSlice.actions,
  fetchMyTimesOff,
  fetchFavouriteTimesOff,
  fetchSelectedUsersTimesOff,
  searchUsersTimesOff,
  addFavouriteTimeOff,
  removeFavouriteTimeOff,
};

export const selectMyTimesOff = (state: RootState): UserTimeOff | undefined =>
  state.calendar.timesOff.myTimesOff;
export const selectSelectedTimesOff = (state: RootState): UserTimeOff[] =>
  state.calendar.timesOff.selectedTimesOff;
export const selectFavouriteTimesOff = (state: RootState): UserTimeOff[] =>
  state.calendar.timesOff.favouriteTimesOff;
export const selectMyPtoLeft = (state: RootState): number | null =>
  state.calendar.timesOff.ptoLeft.ptoLeft;
export const selectMyPtoCurrent = (state: RootState): number | null =>
  state.calendar.timesOff.ptoLeft.ptoCurrent;
export const selectMyPtoUsed = (state: RootState): number | null | undefined =>
  state.calendar.timesOff.ptoLeft.ptoUsed;
export const selectMyPtoCarryover = (state: RootState): number | null | undefined =>
  state.calendar.timesOff.ptoLeft.ptoCarryover;
export const selectMyPtoAllowance = (state: RootState): PtoAllowanceResponse =>
  state.calendar.timesOff.ptoAllowance;
export const selectMyContractType = (state: RootState): ContractTypeResponse =>
  state.calendar.timesOff.contractType;
export const selectMyHasUnlimitedPto = (state: RootState): boolean =>
  state.calendar.timesOff.hasUnlimitedPto;
export const selectFoundTimesOff = (state: RootState): UserTimeOff[] =>
  state.calendar.timesOff.foundTimesOff;
export const selectSearchStatus = (state: RootState): SEARCH_STATUS =>
  state.calendar.timesOff.searchStatus;
export const selectIsCalendarLoading = (state: RootState): boolean =>
  state.calendar.timesOff.isCalendarLoading;
export const selectMyAnnualVacationLeave = (state: RootState): HadAnnualVacationLeaveResponse =>
  state.calendar.timesOff.hadAnnualVacationLeaveUop;

export default timesOffSlice.reducer;
