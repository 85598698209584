import styled from 'styled-components';
import { TabPanel, TabPanels } from '@reach/tabs';

import { StyledTabs } from 'components/Tabs/Tabs.styles';

export const Container = styled.div`
  padding: 32px;
  width: 100%;
  min-height: 690px;
  height: 100%;
  background: #fff;
  display: grid;
  grid-template-rows: auto 1fr;
`;

export const Heading = styled.h3`
  font-size: 13px;
  line-height: 1.54;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const TabsContainer = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-rows: 1fr;
`;

export const Content = styled.div`
  margin-top: 24px;
  background: ${({ theme }) => theme.colors.background};
  flex: 1;
  display: grid;
  place-content: center;
`;

export const TabsComponent = styled(StyledTabs)`
  display: grid;
  grid-template-rows: auto 1fr;
`;

export const TabsPanelsComponent = styled(TabPanels)`
  display: flex;
  flex-direction: column;
`;

export const TabsPanelComponent = styled(TabPanel)`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const TabsAddButton = styled('button')`
  position: relative;
  right: 0;
  text-align: center;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 16px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  padding: 10px 16px;
  min-width: 187px;
  gap: 6px;
  border: 1px solid ${({ theme }) => theme.colors.pagination.primary};
  background: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
`;

export const TabsSeperator = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 23px;
`;

export const TabsWrapper = styled('div')`
  width: 100%;
  display: flex;
`;
