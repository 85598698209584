import React from 'react';
import { useTranslation } from 'react-i18next';

import { ContractTypeEnum, IntValue, PtoLeftResult } from 'API';
import { ReactComponent as FinalizingOffIcon } from 'assets/finalizing-off-icon.svg';
import { ReactComponent as WarrantyLegalIcon } from 'assets/warranty-legal-icon.svg';
import { ReactComponent as StackingIcon } from 'assets/stacking-empty-icon.svg';

import { Container, Span2Week } from './Annual2WeekLeave.styles';

const Annual2WeekLeave = ({
  isTaken,
  contract,
  ptoLeft,
  ptoAllowance,
}: {
  isTaken: boolean;
  contract: ContractTypeEnum | null;
  ptoLeft: PtoLeftResult;
  ptoAllowance: IntValue | null;
}): JSX.Element => {
  const { t } = useTranslation();
  if (contract !== ContractTypeEnum.Uop) {
    return <div style={{ paddingLeft: '20px' }}>-</div>;
  }
  if (
    ptoAllowance &&
    'value' in ptoAllowance &&
    ptoAllowance.value &&
    ptoLeft.ptoCurrent < ptoAllowance.value
  ) {
    return (
      <Container>
        <StackingIcon />
        <Span2Week>{t('time-off.annual-2-week-leave.stacking')}</Span2Week>
      </Container>
    );
  }
  if (isTaken) {
    return (
      <Container>
        <FinalizingOffIcon />
        <Span2Week>{t('time-off.annual-2-week-leave.taken')}</Span2Week>
      </Container>
    );
  }
  return (
    <Container>
      <WarrantyLegalIcon />
      <Span2Week>{t('time-off.annual-2-week-leave.not-taken')}</Span2Week>
    </Container>
  );
};

export default Annual2WeekLeave;
