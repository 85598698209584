import React, { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import _debounce from 'lodash/debounce';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import AutocompleteFilter from 'components/AutocompleteFilter';
import { OnSelectCallback, OptionElement } from 'components/AutocompleteFilter/types';

import { useAppDispatch } from 'redux/store';
import { queries } from 'shared/layout';
import { searchStatusToComponentMap, SEARCH_STATUS } from 'utils/helpers/searchStatus';
import { DEFAULT_DEBOUNCE_TIME, MIN_SEARCH_QUERY_LENGTH } from 'utils/constants';
import {
  actions,
  selectFoundTimesOff,
  selectSearchStatus,
} from 'features/TimeOff/redux/timesOffSlice';

const SearchWrapper = styled.div`
  padding: 16px;

  ${queries.tablet} {
    padding-left: 48px;
    padding-right: 48px;
  }
`;

const Search = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const searchOptions = useSelector(selectFoundTimesOff);
  const searchStatus = useSelector(selectSearchStatus);
  const { t } = useTranslation();
  const searchPromiseRef = useRef<ReturnType<typeof dispatch> | null>(null);

  const optionElements: OptionElement[] = searchOptions.map((searchOption) => {
    const { id, name, position } = searchOption;
    return {
      value: id,
      name,
      position: position || '',
      filterData: [id, name, position || ''].join(),
      userTimeOff: searchOption,
    };
  });

  const handleOptionElement: OnSelectCallback = (value) => {
    const selectedUser = searchOptions.find((user) => user.id === value);
    if (selectedUser) {
      dispatch(actions.addSelectedTimeOff(selectedUser));
      dispatch(actions.setFoundTimesOff([]));
      dispatch(actions.setSearchStatus(SEARCH_STATUS.IDLE));
    }
  };

  const debouncedSearch = useMemo(() => {
    const search = (query: string): void => {
      const promise = searchPromiseRef.current as { abort: () => void };
      promise?.abort();

      if (query.length >= MIN_SEARCH_QUERY_LENGTH) {
        searchPromiseRef.current = dispatch(actions.searchUsersTimesOff(query));
      } else if (query.length) {
        dispatch(actions.setSearchStatus(SEARCH_STATUS.INSUFFICIENT_AMOUT));
      } else {
        dispatch(actions.setSearchStatus(SEARCH_STATUS.IDLE));
      }
    };

    return _debounce(search, DEFAULT_DEBOUNCE_TIME);
  }, [dispatch]);

  return (
    <SearchWrapper>
      <AutocompleteFilter
        options={optionElements}
        onSelect={handleOptionElement}
        onSearch={debouncedSearch}
        notFoundContent={searchStatusToComponentMap(t, true)[searchStatus]}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        getPopupContainer={() => document.getElementById('area-calendar')!}
      />
    </SearchWrapper>
  );
};

export default Search;
