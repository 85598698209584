import styled from 'styled-components';
import { Button as AntButton } from 'antd';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';

import { queries } from 'shared/layout';

export const NavBar = styled.nav`
  padding: 32px 0;
  text-align: center;
  background: ${({ theme }) => theme.colors.backgrounds.dark.linear};
  z-index: 999;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  display: none;
  ${queries.tablet} {
    position: static;
    display: flex;
  }

  .logo {
    max-width: 128px;
    height: 24px;
  }

  .nav-header {
    display: flex;
    padding: 0 32px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 36px;
  }

  .nav-button {
    padding: 0;
    align-self: center;
  }

  .nav-list {
    width: 256px;
    text-align: left;
    display: flex;
    flex-direction: column;
    list-style: none;

    &-shrink {
      width: 88px;
      display: flex;
      flex-direction: column;
      list-style: none;
    }
  }

  .nav-footer {
    display: flex;
    flex-grow: 1;
    width: 100%;
    align-items: flex-end;
    & .nav-item {
      width: 100%;
      & button {
        width: 100%;
        height: 56px;
        text-align: left;
      }
    }
  }
`;

export const NavIcon = styled.svg`
  fill: currentColor;
  font-size: 16px;
`;

export const NavLink = styled(RouterNavLink)`
  padding: 16px 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray['700']};
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 2px;
    height: 100%;
    background-color: transparent;
    transition: background-color 0.2s ease-in-out 0s;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.white};
  }
  &.active {
    color: ${({ theme }) => theme.colors.white};
    background: linear-gradient(90deg, rgba(0, 214, 158, 0) 4.24%, rgba(0, 214, 158, 0.1) 100%);
    &:after {
      background: ${({ theme }) => theme.colors.secondary['100']};
    }
  }

  .link-text {
    margin-left: 1rem;
    flex: 1;
  }
`;

export const Button = styled(AntButton)`
  align-self: flex-end;
  background: none;
  border: none;

  &:active,
  &:focus,
  &:hover {
    background: none;
    border: none;
  }

  &:hover {
    filter: brightness(0) invert(1);
  }

  &::after {
    content: none;
  }
`;

export const MobileHeader = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 13px;
  background: ${({ theme }) => theme.colors.primary};
  gap: 24px;
  .header-content-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
  }

  ${queries.tablet} {
    display: none;
  }
  ${queries.desktop} {
    display: none;
  }

  .nav-list-mobile {
    text-align: left;
    display: flex;
    flex-direction: column;
    list-style: none;

    &-shrink {
      width: 88px;
      display: flex;
      flex-direction: column;
      list-style: none;
    }
  }
`;

export const HamburgerButton = styled.button`
  background: none;
  display: flex;
  border: none;
`;

export const HamburgerIcon = styled(MenuOutlined)`
  color: ${({ theme }) => theme.colors.white};
  font-size: 24px;
`;

export const CloseIcon = styled(CloseOutlined)`
  color: ${({ theme }) => theme.colors.white};
  font-size: 24px;
`;
