import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ThemeProps } from 'components/ThemeProvider/ThemeProvider';

import { queries } from 'shared/layout';

const Wrapper = styled.div<{ isPanelOpen: boolean }>`
  display: ${({ isPanelOpen }) => (isPanelOpen ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 223px;
  margin: 0 auto;
  padding: 32px 0;

  ${queries.tablet} {
    display: flex;
  }
`;

const Heading = styled.span`
  font: ${({ theme }: ThemeProps) => theme.typography.subtitles};
  font-size: 17px;
  line-height: 22px;
  color: ${({ theme }: ThemeProps) => theme.colors.text.primary};
`;

const Secondary = styled.span`
  font: ${({ theme }: ThemeProps) => theme.typography.subtitles};
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }: ThemeProps) => theme.colors.gray['700']};
  opacity: 0.9;
  margin-top: 16px;
`;

const MyListEmptyState = ({ isPanelOpen }: { isPanelOpen: boolean }): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Wrapper isPanelOpen={isPanelOpen}>
      <Heading>{t('time-off.my-list.empty-state-heading')}</Heading>
      <Secondary>{t('time-off.my-list.empty-state-secondary')}</Secondary>
    </Wrapper>
  );
};

export default MyListEmptyState;
