import React, { ReactChild, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { Button as AntButton, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useSelector } from 'react-redux';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import ProfileTopBar from 'components/TopBar/ProfileTopBar';
import { VARIANT } from 'components/AutocompleteFilter/types';
import PageLayout from 'components/PageLayout';

import Calendar from 'features/TimeOff/components/Calendar';
import TimeOffFutureIndicator from 'features/TimeOff/components/Calendar/TimeOffFutureIndicator';
import {
  actions,
  selectFavouriteTimesOff,
  selectIsCalendarLoading,
  selectMyTimesOff,
  selectSelectedTimesOff,
} from 'features/TimeOff/redux/timesOffSlice';
import CalendarHeader from 'features/TimeOff/components/Calendar/CalendarHeader';
import SidePanelUsersGroup from 'features/TimeOff/components/SidePanelUsersGroup';
import { useAppDispatch } from 'redux/store';
import Search from 'features/TimeOff/components/Search';
import Toolbar from 'features/TimeOff/components/Toolbar';
import style from 'features/TimeOff/constants/style';
import CalendarDivider from 'features/TimeOff/components/Calendar/CalendarDivider';
import CalendarBackground from 'features/TimeOff/components/Calendar/CalendarBackground/CalendarBackground';
import useInViewport, { IntersectionState } from 'hooks/useInViewport';
import { useAuth } from 'providers/AuthProvider';
import {
  LOADING_MY_LIST_COUNT,
  LOADING_SEARCH_RESULTS_COUNT,
} from 'features/TimeOff/constants/loading';
import { queries } from 'shared/layout';
import RefreshButton from 'features/TimeOff/components/RefreshButton';
import useDistanceToNow from 'features/TimeOff/hooks/useDistanceToNow';
import useSyncScrollContainers from 'features/TimeOff/helpers/useSyncScrollContainers';
import { CALENDAR_MINIMUM_SCROLL_SPEED } from 'features/TimeOff/constants/controls';
import { UserTimeOff } from 'features/TimeOff/types/calendarTypes';
import { MoveCalendarPayload, onMoveCalendar$ } from 'features/TimeOff/subjects/moveCalendar';
import { MoveCalendarToPayload, onMoveCalendarTo$ } from 'features/TimeOff/subjects/moveCalendarTo';
import {
  CalendarMoveFinishedPayload,
  emitCalendarMoveFinished,
} from 'features/TimeOff/subjects/calendarMoveFinished';
import { emitHoverTimeline } from 'features/TimeOff/subjects/hoverTimeline';
import useSubscription from 'hooks/useSubscription';
import useToggle from 'hooks/useToggle';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { useGetProfileById } from 'services/api/profile';
import getInitials from 'utils/helpers/getInitials';
import { useWindowResize } from 'features/Profile/hooks/useWindowResize';

const { Title: AntTitle, Text } = Typography;

const ContentWrapper = styled.div`
  background: ${({ theme }) => theme.colors.white};
  min-height: 100%;
  display: grid;
  grid-template-areas:
    'toolbar'
    'calendar';
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;

  .grid-title {
    display: none;
  }

  ${queries.tablet} {
    grid-template-areas:
      'title toolbar'
      'calendar calendar';
    grid-template-columns: 340px 1fr;
    grid-template-rows: 107px 1fr;

    .grid-title {
      display: block;
    }
  }
`;

const CalendarWrapper = styled.div`
  margin-left: 0px;
  grid-area: calendar;
  min-height: 100%;
  display: grid;
  overflow: auto;
  grid-template-areas:
    'search'
    'header'
    'content';
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 1fr;

  ${queries.tablet} {
    grid-template-areas:
      'search sidebarDivider header'
      'content content content';
    grid-template-columns: 340px auto 1fr;
    grid-template-rows: auto 1fr;
  }
`;

const CalendarContentWrapper = styled.div`
  grid-area: content;
  height: 100%;
  position: relative;
  overflow: auto;
  display: grid;
  grid-template-areas: '. ptos';
  grid-template-columns: 78px 1fr; // 74px panel width + 4px mobile divider width
  grid-template-rows: 1fr;

  ${queries.tablet} {
    grid-template-areas: 'employeeList ptos';
    grid-template-columns: 342px 1fr; // 340px panel width + 2px mobile divider width
  }
`;

const GridTitleContainer = styled.div`
  padding-left: 48px;
  display: flex;
  flex-direction: column;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled(AntTitle)`
  justify-self: center;
  align-self: center;
  &.ant-typography {
    margin-bottom: 0;
    margin-right: 0.5em;
    font: ${({ theme }) => theme.typography.heading};
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

const LastUpdateText = styled(Text)`
  &.ant-typography {
    display: block;
    padding-top: 8px;
    font: ${({ theme }) => theme.typography.paragraph.font};
    letter-spacing: ${({ theme }) => theme.typography.paragraph.letterSpacing};
    color: ${({ theme }) => theme.colors.gray['700']};
  }
`;

const GridArea = styled.div<{ area: string; align?: string; justify?: string; margin?: boolean }>`
  grid-area: ${(props) => props.area};
  align-self: ${(props) => props.align ?? 'auto'};
  justify-self: ${(props) => props.justify ?? 'auto'};
  overflow: hidden;
  position: relative;
`;

const ToolbarArea = styled(GridArea)`
  ${queries.tablet} {
    border-left: 2px solid ${({ theme }) => theme.colors.neutral['400']};
  }
  margin-left: 0px;
`;

const SidebarDivider = styled.div`
  width: 4px;
  height: 100%;
  background: ${({ theme }) => theme.colors.neutral['400']};

  ${queries.tablet} {
    width: 2px;
  }
`;

const AreaSidebarDivider = styled(SidebarDivider)`
  grid-area: sidebarDivider;
`;

const EmployeeListWrapper = styled(ScrollContainer)<{ isPanelOpen: boolean }>`
  overflow: auto;
  height: 100%;
  padding-bottom: 350px;
  cursor: grab;
  position: relative;
  width: ${({ isPanelOpen }) => (isPanelOpen ? '306px' : '78px')};
  max-width: 306px;
  z-index: 50;
  background: ${({ theme }) => theme.colors.white};

  border-right: 4px solid ${({ theme }) => theme.colors.neutral['400']};

  ${queries.tablet} {
    grid-area: employeeList;
    position: static;
    width: 100%;
    max-width: 342px;
    border-width: 2px;
  }
`;

export const DraggableScrollContainer = styled(ScrollContainer)`
  overflow: auto;
  height: 100%;
  cursor: grab;
`;

const IndicatorWrapper = styled(ScrollContainer)`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-top: ${style.groupName.height}px;
`;

const CalendarBodyWrapper = styled.div`
  position: relative;
  width: max-content;
  height: 100%;
`;

const CalendarOverlayOuter = styled.div`
  position: absolute;
  width: 100%;
`;

const CalendarOverlayInner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const EmployeeListInnerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
`;

const SidePanelUsersGroupsWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const EmployeeListButtonWrapper = styled.div<{ isPanelOpen: boolean }>`
  position: absolute;
  top: 8px;
  left: ${({ isPanelOpen }) => (isPanelOpen ? '302px' : '74px')};
  z-index: 100;
`;

const EmployeeListToggleButton = styled(AntButton)`
  border: 4px solid ${({ theme }) => theme.colors.neutral['400']};
  border-left: 0;
  color: ${({ theme }) => theme.colors.gray['500']};
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 200;
  width: 24px;

  &:hover {
    background: ${({ theme }) => theme.colors.neutral['400']};
    border-color: ${({ theme }) => theme.colors.neutral['400']};
    color: ${({ theme }) => theme.colors.gray['500']};
  }

  &:active,
  &:focus {
    background: ${({ theme }) => theme.colors.neutral['500']};
    border-color: ${({ theme }) => theme.colors.neutral['500']};
    color: ${({ theme }) => theme.colors.gray['500']};
  }

  ${queries.tablet} {
    display: none;
  }
`;

const CalendarOverlayWrapper = ({ children }: { children: ReactChild[] }): JSX.Element => {
  return (
    <CalendarOverlayOuter>
      <CalendarOverlayInner>{children}</CalendarOverlayInner>
    </CalendarOverlayOuter>
  );
};

const GridTitle = (): JSX.Element => {
  const { t } = useTranslation();
  const { distance, onUpdate } = useDistanceToNow();

  return (
    <GridArea className="grid-title" area="title" align="end" justify="start">
      <GridTitleContainer>
        <TitleWrapper>
          <Title level={2}>{t('time-off.time-off')}</Title>
          <RefreshButton onUpdate={onUpdate} />
        </TitleWrapper>
        <LastUpdateText>{`Last update: ${distance || 'now'}`}</LastUpdateText>
      </GridTitleContainer>
    </GridArea>
  );
};

const GridToolbar = (): JSX.Element => {
  const { width } = useWindowResize();
  const shouldMargin = width < 1024;
  return (
    <ToolbarArea margin={shouldMargin} area="toolbar">
      <Toolbar />
    </ToolbarArea>
  );
};

export interface ScrollableElementProps {
  scrollContainerRef: React.RefObject<ScrollContainer>;
  onScroll: () => void;
}

interface EmployeeListProps extends ScrollableElementProps {
  searchResultsVisible: boolean;
  setSearchResultsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const EmployeeList = ({
  scrollContainerRef,
  onScroll,
  searchResultsVisible,
  setSearchResultsVisible,
}: EmployeeListProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const myTimesOff = useSelector(selectMyTimesOff);
  const selectedTimesOff = useSelector(selectSelectedTimesOff);
  const favTimesOff = useSelector(selectFavouriteTimesOff);
  const isCalendarLoading = useSelector(selectIsCalendarLoading);
  const { t } = useTranslation();
  const { isOpen, toggle, setIsOpen } = useToggle(false);
  const employeeListRef = useRef(null);
  useOnClickOutside(employeeListRef, () => setIsOpen(false));

  const settings = useMemo(() => {
    return {
      rootMargin: `-${style.groupName.height}px`,
      root: scrollContainerRef.current?.getElement(),
    };
  }, [scrollContainerRef]);

  const [SearchResultsLastMemberRef, intersectionState] = useInViewport('vertical', settings);

  useEffect(() => {
    const isVisible =
      intersectionState !== IntersectionState.Unknown &&
      !(intersectionState === IntersectionState.HiddenTop);
    setSearchResultsVisible(isVisible);
  }, [intersectionState, setSearchResultsVisible]);

  useEffect(() => {
    // the list is considered visible while it's being loaded
    if (isCalendarLoading) setSearchResultsVisible(true);
    else if (selectedTimesOff.length === 0) setSearchResultsVisible(false);
  }, [selectedTimesOff, isCalendarLoading, setSearchResultsVisible]);

  return (
    <>
      <EmployeeListButtonWrapper isPanelOpen={isOpen}>
        <EmployeeListToggleButton
          aria-label={t('time-off.my-list.toggle-list')}
          onClick={toggle}
          icon={isOpen ? <LeftOutlined /> : <RightOutlined />}
        />
      </EmployeeListButtonWrapper>
      <EmployeeListWrapper isPanelOpen={isOpen} ref={scrollContainerRef} onScroll={onScroll}>
        <EmployeeListInnerWrapper ref={employeeListRef}>
          <SidePanelUsersGroupsWrapper>
            <SidePanelUsersGroup
              // TODO: add when accordions will be implemented
              // icon={<UserOutlined />}
              users={myTimesOff ? [myTimesOff] : []}
              title="MY TIMELINE"
              loading={isCalendarLoading ? 1 : 0}
              isPanelOpen={isOpen}
            />
            <SidePanelUsersGroup
              // TODO: add when accordions will be implemented
              // icon={searchResultsVisible ? <CaretDownOutlined /> : <CaretRightOutlined />}
              addDivider={searchResultsVisible}
              users={selectedTimesOff}
              title="SEARCH RESULTS"
              showCount
              onUserRemove={(user: UserTimeOff) => dispatch(actions.removeSelectedTimeOff(user))}
              onUserAdd={(user: UserTimeOff) => dispatch(actions.addFavouriteTimeOff(user))}
              groupIndex={0}
              loading={isCalendarLoading ? LOADING_SEARCH_RESULTS_COUNT : 0}
              lastMemberRef={SearchResultsLastMemberRef}
              isPanelOpen={isOpen}
            />
            <SidePanelUsersGroup
              // TODO: add when accordions will be implemented
              // icon={favTimesOff.length > 0 ? <CaretDownOutlined /> : <CaretRightOutlined />}
              addDivider
              users={favTimesOff}
              title="MY LIST"
              showCount
              onUserRemove={(user: UserTimeOff) => {
                dispatch(actions.removeFavouriteTimeOff(user.id));
              }}
              loading={isCalendarLoading ? LOADING_MY_LIST_COUNT : 0}
              groupIndex={1}
              isPanelOpen={isOpen}
            />
          </SidePanelUsersGroupsWrapper>
        </EmployeeListInnerWrapper>
      </EmployeeListWrapper>
    </>
  );
};

interface PtosProps extends ScrollableElementProps {
  indicatorContainerRef: React.RefObject<ScrollContainer>;
  searchResultsVisible: boolean;
}

const Ptos = ({
  scrollContainerRef,
  onScroll,
  indicatorContainerRef,
  searchResultsVisible,
}: PtosProps): JSX.Element => {
  const isCalendarLoading = useSelector(selectIsCalendarLoading);
  const selectedTimesOff = useSelector(selectSelectedTimesOff);
  const favTimesOff = useSelector(selectFavouriteTimesOff);
  const myTimesOff = useSelector(selectMyTimesOff);
  const scrollLeft = scrollContainerRef?.current?.getElement()?.scrollLeft ?? 0;

  const myGroup = { key: 'myTimesOff', timesOff: myTimesOff ? [myTimesOff] : [] };
  const selectedGroup = { key: 'selectedTimesOff', timesOff: selectedTimesOff };
  const favGroup = { key: 'favTimesOff', timesOff: favTimesOff };

  return (
    <GridArea area="ptos">
      {/* `IndicatorWrapper` is a `ScrollContainer` with manual scrolling disabled
       to make it compatible with `syncScrollContainers`,
       TODO: Make syncScrollContainers accept any ref type */}
      <IndicatorWrapper ref={indicatorContainerRef} vertical={false} horizontal={false}>
        <TimeOffFutureIndicator timesOff={myGroup} />
        <TimeOffFutureIndicator timesOff={selectedGroup} />
        <TimeOffFutureIndicator timesOff={favGroup} />
      </IndicatorWrapper>
      <DraggableScrollContainer ref={scrollContainerRef} onScroll={onScroll}>
        <CalendarBodyWrapper>
          <CalendarOverlayWrapper>
            <CalendarDivider showDivider={false} />
            <Calendar timesOffGroup={myGroup} loading={isCalendarLoading ? 1 : 0} />
            <CalendarDivider
              index={0}
              showDivider={searchResultsVisible}
              halfWidth={searchResultsVisible}
            />
            <Calendar
              timesOffGroup={selectedGroup}
              loading={isCalendarLoading ? LOADING_SEARCH_RESULTS_COUNT : 0}
              scrollLeft={scrollLeft}
            />
            <CalendarDivider index={1} showDivider halfWidth />
            <Calendar
              timesOffGroup={favGroup}
              loading={isCalendarLoading ? LOADING_MY_LIST_COUNT : 0}
              scrollLeft={scrollLeft}
            />
          </CalendarOverlayWrapper>
          <CalendarBackground
            numGroups={3}
            numElements={selectedTimesOff.length + favTimesOff.length + myGroup.timesOff.length}
          />
        </CalendarBodyWrapper>
      </DraggableScrollContainer>
    </GridArea>
  );
};

const GridCalendarContent = (): JSX.Element => {
  const CalendarHeaderRef = useRef<ScrollContainer>(null);
  const PtoRef = useRef<ScrollContainer>(null);
  const EmployeeListRef = useRef<ScrollContainer>(null);
  const IndicatorRef = useRef<ScrollContainer>(null);
  const [searchResultsVisible, setSearchResultsVisible] = useState<boolean>(false);
  const getOnScroll = useSyncScrollContainers({
    horizontal: [CalendarHeaderRef, PtoRef],
    vertical: [EmployeeListRef, PtoRef, IndicatorRef],
  });

  useSubscription<MoveCalendarPayload>(onMoveCalendar$, (payload: MoveCalendarPayload) => {
    if (!CalendarHeaderRef.current) return;
    const element = CalendarHeaderRef.current.getElement();

    const steps = Math.max(Math.round(payload.distance / CALENDAR_MINIMUM_SCROLL_SPEED), 1);
    const getCallback = (stepsLeft: number) => {
      return () => {
        if (stepsLeft === 0) {
          getOnScroll(CalendarHeaderRef)();
          return;
        }
        element.scrollLeft +=
          CALENDAR_MINIMUM_SCROLL_SPEED * (payload.direction === 'left' ? -1 : 1);
        requestAnimationFrame(getCallback(stepsLeft - 1));
      };
    };
    requestAnimationFrame(getCallback(steps));
  });

  useSubscription<MoveCalendarToPayload>(onMoveCalendarTo$, (payload: MoveCalendarToPayload) => {
    const calendarMoveFinished = (moveFinishedPayload?: CalendarMoveFinishedPayload) => {
      if (moveFinishedPayload) emitCalendarMoveFinished(moveFinishedPayload);
      emitHoverTimeline(null);
      getOnScroll(CalendarHeaderRef)();
    };

    if (!CalendarHeaderRef.current) return;
    const element = CalendarHeaderRef.current.getElement();
    const direction = element.scrollLeft < payload.destination ? 'right' : 'left';
    const destination = Math.max(
      0,
      Math.min(payload.destination, element.scrollWidth - element.clientWidth)
    );
    // requestAnimationFrame is sometimes inconsistent with how often it fires
    // (since it depends on the display refresh rate AND how busy the cpu is)
    // but an assumption of 60 times per second should be good enough for almost every scenario
    // (as noted in MDN https://developer.mozilla.org/en-US/docs/Web/API/window/requestAnimationFrame)
    const speed = Math.max(
      CALENDAR_MINIMUM_SCROLL_SPEED,
      Math.abs(element.scrollLeft - destination) / 60
    );
    const cb = () => {
      if (
        (direction === 'left' && element.scrollLeft < destination) ||
        (direction === 'right' && element.scrollLeft >= destination)
      ) {
        calendarMoveFinished(payload.target);
        return;
      }

      const distance = Math.min(Math.abs(element.scrollLeft - destination), speed);
      element.scrollLeft += distance * (direction === 'left' ? -1 : 1);
      requestAnimationFrame(cb);
    };
    requestAnimationFrame(cb);
  });

  return (
    <CalendarWrapper id="area-calendar">
      <GridArea area="search" align="center">
        <Search />
      </GridArea>

      <AreaSidebarDivider />

      <GridArea area="header">
        <CalendarHeader
          scrollContainerRef={CalendarHeaderRef}
          onScroll={getOnScroll(CalendarHeaderRef)}
        />
      </GridArea>

      <CalendarContentWrapper>
        <Ptos
          scrollContainerRef={PtoRef}
          indicatorContainerRef={IndicatorRef}
          onScroll={getOnScroll(PtoRef)}
          searchResultsVisible={searchResultsVisible}
        />

        <EmployeeList
          scrollContainerRef={EmployeeListRef}
          onScroll={getOnScroll(EmployeeListRef)}
          searchResultsVisible={searchResultsVisible}
          setSearchResultsVisible={setSearchResultsVisible}
        />
      </CalendarContentWrapper>
    </CalendarWrapper>
  );
};

const PageContent = (): JSX.Element => {
  const { currentUser } = useAuth();
  const userId = currentUser ? currentUser.id : '';
  const { data } = useGetProfileById(userId);
  const profile = data?.getUser ?? {};

  const name = currentUser?.name && getInitials(currentUser?.name);
  return (
    <>
      <ProfileTopBar profilePicture={profile?.profilePicture ?? name} variant={VARIANT.TOP_BAR} />
      <ContentWrapper id="timeOffPage">
        <GridTitle />
        <GridToolbar />
        <GridCalendarContent />
      </ContentWrapper>
    </>
  );
};

const TimeOffPage = (): JSX.Element => {
  const { currentUser } = useAuth();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (currentUser) {
      Promise.all([
        dispatch(actions.fetchMyTimesOff(currentUser.id)),
        dispatch(actions.fetchFavouriteTimesOff()),
      ]).finally(() => {
        dispatch(actions.setCalendarLoading(false));
      });
    }
  }, [currentUser, dispatch]);

  return (
    <PageLayout>
      <PageContent />
    </PageLayout>
  );
};

export default TimeOffPage;
