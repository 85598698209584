import { createApi } from '@reduxjs/toolkit/query/react';

export const apiService = createApi({
  reducerPath: 'api',
  baseQuery: async ({ fn, payload }) => {
    const result = await fn(payload);
    return { data: result ?? null };
  },
  endpoints: () => ({}),
});
