import { client } from 'graphql/client';

const generateGraphqlCaller = <
  ReturnType extends Record<string, unknown>,
  VariablesType extends Record<string, unknown> = Record<string, unknown>
>(
  query: string
): ((variables?: VariablesType) => Promise<ReturnType>) => {
  return (variables?: VariablesType): Promise<ReturnType> => {
    return client.request(query, variables);
  };
};
export default generateGraphqlCaller;
