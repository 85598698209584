import { formatDistanceToNow } from 'date-fns';
import { useState, useRef, useCallback, useEffect } from 'react';

type ReturnValue = {
  distance: string;
  onUpdate: () => void;
};

const useDistanceToNow = (interval = 30000): ReturnValue => {
  const [distance, setDistance] = useState('');
  const lastUpdatedAtRef = useRef(new Date());

  const updateDistance = useCallback(() => {
    const distanceToNow = formatDistanceToNow(lastUpdatedAtRef.current, {
      addSuffix: true,
      includeSeconds: true,
    });
    setDistance(distanceToNow);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateDistance();
    }, interval);

    return () => {
      clearInterval(intervalId);
    };
  }, [interval, updateDistance]);

  const onUpdate = useCallback(() => {
    lastUpdatedAtRef.current = new Date();
    updateDistance();
  }, [updateDistance]);

  return {
    distance,
    onUpdate,
  };
};

export default useDistanceToNow;
