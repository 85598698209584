import React from 'react';
import { ButtonProps } from 'antd';

import { ReactComponent as Chevron } from 'assets/Chevron.svg';

import { Button } from './SkillButton.styles';

interface SkillButtonProps extends ButtonProps {
  label: string;
}

const SkillButton = ({ label, onClick }: SkillButtonProps): JSX.Element => {
  return (
    <Button onClick={onClick}>
      {label}
      <Chevron />
    </Button>
  );
};

export default SkillButton;
