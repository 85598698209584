import type { QueryGetUserArgs, PublicUser } from 'API';
import generateGraphqlCaller from 'utils/helpers/generateGraphqlCaller';

export const getUserByIdQuery = /* GraphQL */ `
  query GetUser($id: String!) {
    getUser(id: $id) {
      ... on PublicUser {
        id
        email
        phoneNumber
        name
        profilePicture
        team
        role
        technicalRole
        seniority
        seniorityLevel
        department
        workingHours
        employeeLocation
      }
      ... on Error {
        message
      }
      ... on UserNotFoundError {
        message
      }
      ... on MultipleUsersFound {
        message
      }
    }
  }
`;

export type ReturnType = {
  getUser?: Pick<
    PublicUser,
    | 'id'
    | 'email'
    | 'phoneNumber'
    | 'name'
    | 'profilePicture'
    | 'team'
    | 'role'
    | 'technicalRole'
    | 'seniority'
    | 'seniorityLevel'
    | 'department'
    | 'workingHours'
    | 'employeeLocation'
  >;
};

export default generateGraphqlCaller<ReturnType, QueryGetUserArgs>(getUserByIdQuery);
