import React from 'react';
import styled from 'styled-components';

import { ReactComponent as ArrowLeftIcon } from 'assets/ArrowLeft.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/ArrowRight.svg';
import style from 'features/TimeOff/constants/style';
import { queries } from 'shared/layout';

const ArrowContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: none;

  ${queries.tablet} {
    display: block;
  }
`;

const Arrow = styled.button`
  position: absolute;
  bottom: 0;
  z-index: 4;
  border: 0;
  border-radius: 0;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.primary};
  height: ${style.dayHead.height}px;
  width: ${style.dayHead.width}px;
  box-shadow: none;
  transition: 0.2s ease-in-out;
  margin-bottom: 1px;

  &:hover {
    background: ${({ theme }) => theme.colors.neutral['400']};
  }

  &:active {
    background: ${({ theme }) => theme.colors.neutral['500']};
  }
`;

const LeftArrowButton = styled(Arrow)`
  left: 0;
`;

const RightArrowButton = styled(Arrow)`
  right: 0;
`;

const LeftArrow = ({ onClick }: { onClick: () => void }): JSX.Element => {
  return (
    <LeftArrowButton onClick={onClick}>
      <ArrowLeftIcon />
    </LeftArrowButton>
  );
};

const RightArrow = ({ onClick }: { onClick: () => void }): JSX.Element => {
  return (
    <RightArrowButton onClick={onClick}>
      <ArrowRightIcon />
    </RightArrowButton>
  );
};

export interface ArrowButtonsProps {
  onLeftArrowClick: () => void;
  onRightArrowClick: () => void;
}

const ArrowButtons = ({ onLeftArrowClick, onRightArrowClick }: ArrowButtonsProps): JSX.Element => {
  return (
    <ArrowContainer>
      <LeftArrow onClick={onLeftArrowClick} />
      <RightArrow onClick={onRightArrowClick} />
    </ArrowContainer>
  );
};

export default ArrowButtons;
