import { Subject } from 'rxjs';

import { IntersectionState } from 'hooks/useInViewport';

import { TimeOff } from '../helpers/mergeTimeOffRecords';

export interface TimeOffVisibilityChangePayload {
  timeOff: TimeOff;
  state: IntersectionState;
}
const timeOffVisibilityChange$ = new Subject<TimeOffVisibilityChangePayload>();

export const emitTimeOffVisibilityChange = (payload: TimeOffVisibilityChangePayload): void =>
  timeOffVisibilityChange$.next(payload);
export const onTimeOffVisibilityChange$ = timeOffVisibilityChange$.asObservable();
