import React from 'react';

import { Wrapper, PageNavigation } from './Pagination.styles';

type PaginationPropsType = {
  currentPage: number;
  total: number;
  amountItems: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  disabled?: boolean;
};

const Pagination = ({
  setCurrentPage,
  setPageSize,
  currentPage,
  amountItems,
  total,
  disabled = false,
}: PaginationPropsType): JSX.Element => {
  const onChangePage = (current: number, pageSize: number | undefined) => {
    setCurrentPage(current);
    if (pageSize) {
      setPageSize(pageSize);
    }
  };

  return (
    <Wrapper>
      <PageNavigation
        onChange={(current, pageSize) => onChangePage(current, pageSize)}
        total={total}
        defaultPageSize={amountItems}
        current={currentPage}
        disabled={disabled}
      />
    </Wrapper>
  );
};

export default Pagination;
