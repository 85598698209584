import React, { ComponentPropsWithRef, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Icon from 'components/Icon';

import { ReactComponent as RemoveIcon } from 'assets/Remove.svg';
import { ReactComponent as AddIcon } from 'assets/Plus.svg';
import IconButton from 'features/TimeOff/components/SidePanelUsersGroup/UsersGroup/UserCard/IconButton';
import { queries } from 'shared/layout';
import { UserAvatar } from 'components';

const { Text } = Typography;

const TruncatableText = styled(Text)`
  &.ant-typography {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.name {
      font: ${({ theme }) => theme.typography.subtitles};
    }

    &.role {
      font: ${({ theme }) => theme.typography.paragraph.font};
      letter-spacing: ${({ theme }) => theme.typography.paragraph.letterSpacing};
      color: ${({ theme }) => theme.colors.gray['700']};
    }
    a span:hover {
      text-decoration: underline;
    }
  }
`;

const Wrapper = styled.div<{ isPanelOpen: boolean }>`
  height: 64px;
  width: 100%;
  cursor: default;
  display: grid;
  gap: 16px;
  grid-template-columns: ${({ isPanelOpen }) => (isPanelOpen ? '24px 42px 1fr 24px' : 'auto')};
  align-items: center;
  padding: 12px 16px;

  ${queries.tablet} {
    grid-template-columns: 24px 42px 1fr 24px;
    gap: 8px;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.calendar.hover};
  }
`;

const ActionButtonWrapper = styled.div<{ isPanelOpen: boolean }>`
  display: ${({ isPanelOpen }) => (isPanelOpen ? 'block' : 'none')};

  ${queries.tablet} {
    display: block;
  }
`;

const AvatarWrapper = styled.div``;

const UserDetailsWrapper = styled.div<{ isPanelOpen: boolean }>`
  display: ${({ isPanelOpen }) => (isPanelOpen ? 'flex' : 'none')};
  flex-direction: column;
  width: 100%;
  max-width: 136px;

  ${queries.tablet} {
    display: flex;
    max-width: 192px;
  }
`;

export interface UserCardProps extends ComponentPropsWithRef<'div'> {
  hovered: boolean;
  name: string;
  title: string;
  profilePicture?: string | null;
  id: string;
  onRemove?: (id: string) => void;
  onAdd?: (id: string) => void;
  useRef?: React.RefObject<HTMLDivElement> | React.RefCallback<Element>;
  isPanelOpen: boolean;
}

const UserCard = ({
  hovered,
  name,
  title,
  profilePicture,
  id,
  onRemove,
  onAdd,
  useRef,
  isPanelOpen,
  ...props
}: UserCardProps): JSX.Element => {
  const { t } = useTranslation();
  const { colors, typography } = useContext(ThemeContext);

  const tooltipOverlayInnerStyles = {
    borderRadius: '2px',
    padding: '8px 6px',
    background: colors.primary,
    color: colors.white,
    font: typography.paragraph.font,
    letterSpacing: typography.paragraph.letterSpacing,
  };

  return (
    <Wrapper isPanelOpen={isPanelOpen} ref={useRef} {...props}>
      <ActionButtonWrapper isPanelOpen={isPanelOpen}>
        {onRemove && (
          <Tooltip
            mouseEnterDelay={0.7}
            overlayInnerStyle={tooltipOverlayInnerStyles}
            placement="top"
            title={onAdd ? t('time-off.search.remove') : t('time-off.my-list.remove')}
          >
            <IconButton
              className="remove-user-btn"
              aria-label={onAdd ? t('time-off.search.remove') : t('time-off.my-list.remove')}
              onClick={() => onRemove(id)}
              isHovered={hovered}
            >
              <Icon focusable={false} as={RemoveIcon} />
            </IconButton>
          </Tooltip>
        )}
      </ActionButtonWrapper>

      <AvatarWrapper>
        <UserAvatar size={42} name={name} profilePicture={profilePicture} />
      </AvatarWrapper>

      <UserDetailsWrapper isPanelOpen={isPanelOpen}>
        <TruncatableText className="name">
          <Link to={`/profile/${id}`}>
            <span>{name}</span>
          </Link>
        </TruncatableText>
        <TruncatableText className="role" type="secondary">
          {title || t('time-off.no-role')}
        </TruncatableText>
      </UserDetailsWrapper>
      <ActionButtonWrapper isPanelOpen={isPanelOpen}>
        {onAdd && (
          <Tooltip
            mouseEnterDelay={0.7}
            overlayInnerStyle={tooltipOverlayInnerStyles}
            placement="top"
            title={t('time-off.my-list.add')}
          >
            <IconButton
              className="add-user-btn"
              aria-label={t('time-off.my-list.add')}
              onClick={() => onAdd(id)}
              isHovered={hovered}
            >
              <Icon focusable={false} as={AddIcon} />
            </IconButton>
          </Tooltip>
        )}
      </ActionButtonWrapper>
    </Wrapper>
  );
};

export default UserCard;
