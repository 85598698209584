import React from 'react';
import styled from 'styled-components';
import { Button as AntButton, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { ArrowRightOutlined } from '@ant-design/icons';

import WelcomeHero from 'assets/png/OnboardingWelcome.png';
import CheckmarkAsset from 'assets/Checkmark.svg';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 200px;
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  gap: 24px;
  width: 404px;
  height: 612px;
  background: #ffffff;
  box-shadow: 0px 20px 40px -4px rgba(114, 114, 146, 0.12);
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  padding-bottom: 8px;
  gap: 32px;

  border-bottom: 2px solid #e6e6ef;
`;

const ContentInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 340px;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 8px;
  color: ${({ theme }) => theme.colors.text.primary};

  & ul > li {
    padding-bottom: 8px;
  }
  & ul > li::before {
    content: url(${CheckmarkAsset});
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: bottom;
    margin-right: 8px;
  }
`;

const Title = styled(Typography.Title)`
  &.ant-typography {
    color: ${({ theme }) => theme.colors.primary};
    font: ${({ theme }) => theme.typography.heading};
    height: 40px;
    font-weight: 600;
    font-size: 27px;
    line-height: 40px;
    margin-bottom: 8px;
  }
`;

const Text = styled(Typography.Text)<{ variant: 'primary' | 'secondary' }>`
  &.ant-typography {
    color: ${({ theme }) => theme.colors.text.secondary};
    width: 340px;
    height: 40px;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.2px;
    text-align: ${({ variant }) => (variant === 'primary' ? 'left' : 'center')};
    margin-bottom: 16px;
  }
`;

const ButtonLink = styled.a`
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-transform: ${({ theme }) => theme.typography.button.textTransform};
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.button.linear};
  width: 340px;
  height: 54px;
  border: none;

  & span.anticon-arrow-right {
    position: absolute;
    display: block;
    font-size: 13px;
    top: 20px;
    right: 18px;
  }

  &:hover,
  &:focus {
    background: ${({ theme }) => theme.colors.button.linearHover};
  }

  &:active {
    background: ${({ theme }) => theme.colors.button.linearPressed};
  }
`;

interface IWelcome {
  setCurrentPage: (page: number) => void;
}

const Welcome = ({ setCurrentPage }: IWelcome): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Container id="welcomePage">
      <Modal>
        <img src={WelcomeHero} alt="WelcomeHeroIMG" />
        <Content>
          <ContentInner>
            <Title id="pageTitle">{t('onboarding.welcome.title')}</Title>
            <Text variant="primary" id="pageText">
              {t('onboarding.welcome.subtitle')}
            </Text>
            <ListWrapper>
              <ul>
                <li id="list1">{t('onboarding.welcome.list.1')}</li>
                <li id="list2">{t('onboarding.welcome.list.2')}</li>
                <li id="list3">{t('onboarding.welcome.list.3')}</li>
                <li id="list4">{t('onboarding.welcome.list.4')}</li>
              </ul>
            </ListWrapper>
          </ContentInner>
        </Content>
        <Footer>
          <ButtonLink
            as={AntButton}
            type="primary"
            size="large"
            onClick={() => setCurrentPage(1)}
            id="getStartedButton"
          >
            {t('onboarding.welcome.start')}
            <ArrowRightOutlined />
          </ButtonLink>
          <Text variant="secondary" id="pageSecondaryText">
            {t('onboarding.welcome.footer')}
          </Text>
        </Footer>
      </Modal>
    </Container>
  );
};

export default Welcome;
