import { BoolValue } from 'API';
import generateGraphqlCaller from 'utils/helpers/generateGraphqlCaller';

const query = `
query HasSkillsInReview {
  hasSkillsToReview {
    value
  }
}
`;

export default generateGraphqlCaller<{ hasSkillsToReview: BoolValue }>(query);
