import { combineReducers } from '@reduxjs/toolkit';

import users from './profileSlice';
import projects from './projectsSlice';

const rootReducer = combineReducers({
  users,
  projects,
});
export default rootReducer;
