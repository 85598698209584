import { Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';

export const DetailInformationListItem = styled.li`
  height: 64px;
  display: flex;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }

  .anticon {
    font-size: 24px;
  }
`;
export const DetailInformationIconContainer = styled.div<{ variant?: 'empty' | 'default' }>`
  width: 64px;
  background: ${({ theme, variant }) =>
    variant === 'empty' ? theme.colors.white : theme.colors.background};
  display: flex;
  justify-content: center;
  fill: ${({ theme, variant }) =>
    variant === 'empty' ? theme.colors.text.overline : theme.colors.text.primary};
  align-items: center;
  cursor: ${({ variant }) => (variant === 'empty' ? 'pointer' : 'initial')};
  border: ${({ variant }) => (variant === 'empty' ? '1px dashed #AAAABE' : 'none')};
`;

export const DetailInformationName = styled(Typography.Paragraph)`
  font: ${({ theme }) => theme.typography.subtitles};
  margin: 0;
  width: 200px;
  white-space: pre-wrap;
  display: inline-block;
  overflow: hidden !important;
  &.ant-typography {
    margin: 0;
  }
`;

export const DetailInformationValue = styled.span`
  margin-top: 6px;
  color: ${({ theme }) => theme.colors.text.secondary};
  font: ${({ theme }) => theme.typography.paragraph.font};
  letter-spacing: ${({ theme }) => theme.typography.paragraph.letterSpacing};
`;

export const DetailInformationContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 16px;
`;

interface IProfileDetailsListItem {
  icon: React.ReactNode;
  name: string;
  value: string;
  onClick?: () => void;
  id?: string;
}

const ProfileDetailsListItem = ({
  icon,
  name,
  value,
  onClick,
  id,
}: IProfileDetailsListItem): JSX.Element => {
  return onClick ? (
    <DetailInformationListItem id={id}>
      <DetailInformationIconContainer onClick={onClick} variant="empty" id="itemIcon">
        {icon}
      </DetailInformationIconContainer>
      <DetailInformationContentContainer>
        <DetailInformationName id="itemValue">{name}</DetailInformationName>
        <DetailInformationValue id="itemLabel">{value}</DetailInformationValue>
      </DetailInformationContentContainer>
    </DetailInformationListItem>
  ) : (
    <DetailInformationListItem id={id}>
      <DetailInformationIconContainer id="itemIcon">{icon}</DetailInformationIconContainer>
      <DetailInformationContentContainer>
        <DetailInformationName ellipsis={{ tooltip: true }} id="itemValue">
          {name}
        </DetailInformationName>
        <DetailInformationValue id="itemLabel">{value}</DetailInformationValue>
      </DetailInformationContentContainer>
    </DetailInformationListItem>
  );
};

export default ProfileDetailsListItem;
