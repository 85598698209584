import { useTranslation } from 'react-i18next';
import React from 'react';

import { ReactComponent as PendingSetupIcon } from 'assets/pending-setup-icon.svg';
import { ReactComponent as FinalizingOffIcon } from 'assets/finalizing-off-icon.svg';
import { ReactComponent as OnHoldIcon } from 'assets/on-hold-icon.svg';
import { ReactComponent as ProgressMaintenanceIcon } from 'assets/progress-maintenance-icon.svg';
import { ReactComponent as WarrantyLegalIcon } from 'assets/warranty-legal-icon.svg';

import { StatusContainer } from '../Table.styles';

export enum Stages {
  Pending = 'Pending',
  Setup = 'Setup',
  InProgress = 'In Progress',
  OnHold = 'On Hold',
  Maintenance = 'Maintenance',
  Warranty = 'Warranty',
  LegalDispute = 'Legal Dispute',
  Finalizing = 'Finalizing',
  OffBoarded = 'Off-boarded',
}

interface IAssignmentStage {
  stage: string | null | undefined;
}

const AssignmentStage = ({ stage }: IAssignmentStage): JSX.Element => {
  const { t } = useTranslation();
  switch (stage) {
    case Stages.Pending:
      return (
        <StatusContainer>
          <PendingSetupIcon />
          {t('profile.user-assignments.statuses.pending')}
        </StatusContainer>
      );
    case Stages.Setup:
      return (
        <StatusContainer>
          <PendingSetupIcon />
          {t('profile.user-assignments.statuses.setup')}
        </StatusContainer>
      );
    case Stages.InProgress:
      return (
        <StatusContainer>
          <ProgressMaintenanceIcon />
          {t('profile.user-assignments.statuses.in-progress')}
        </StatusContainer>
      );
    case Stages.Maintenance:
      return (
        <StatusContainer>
          <ProgressMaintenanceIcon />
          {t('profile.user-assignments.statuses.maintenance')}
        </StatusContainer>
      );
    case Stages.OnHold:
      return (
        <StatusContainer>
          <OnHoldIcon />
          {t('profile.user-assignments.statuses.on-hold')}
        </StatusContainer>
      );
    case Stages.Warranty:
      return (
        <StatusContainer>
          <WarrantyLegalIcon />
          {t('profile.user-assignments.statuses.warranty')}
        </StatusContainer>
      );
    case Stages.LegalDispute:
      return (
        <StatusContainer>
          <WarrantyLegalIcon />
          {t('profile.user-assignments.statuses.warranty')}
        </StatusContainer>
      );
    case Stages.Finalizing:
      return (
        <StatusContainer>
          <FinalizingOffIcon />
          {t('profile.user-assignments.statuses.finalizing')}
        </StatusContainer>
      );
    case Stages.OffBoarded:
      return (
        <StatusContainer>
          <FinalizingOffIcon />
          {t('profile.user-assignments.statuses.off-boarded')}
        </StatusContainer>
      );
    default:
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
  }
};

export default AssignmentStage;
