import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uniqueId } from 'lodash';

import {
  SkillsAssignmentsResponse,
  SkillsResponse,
  SkillAssignmentResponse,
  ReviewOutcome,
} from 'API';
import getAllSkills from 'api/profile/getSkillsByType';
import { RootState } from 'redux/rootReducer';
import queryHasSkillsInReview from 'features/TeamLeadView/api/queryHasSkillsInReview';

import queryGetAssignedSkills from '../api/queryGetAssignedSkills';
import queryGetOwnAssignedSkills from '../api/queryGetOwnAssignedSkills';

export interface SkillUpdate {
  name: string;
  index: number;
  lvl: string;
  updated: string;
}

export interface CountSkillStatuses {
  approved: number;
  in_review: number;
  rejected: number;
}

export interface ModalState {
  visible: boolean;
  content: number;
}

export interface SkillChosen extends SkillAssignmentResponse {
  action: string;
  id: string;
}

export interface AssignedSkill {
  action: string;
  id: string;
  langCode: string;
  lvl: string;
  name: string;
  type: string;
  updated: string;
  status?: ReviewOutcome | null;
}

export interface SkillsAssignedExtended extends SkillsAssignmentsResponse {
  assignments: AssignedSkill[];
}

export interface SkillState {
  listTechnicalSkills: SkillsResponse;
  listLanguageSkills: SkillsResponse;
  listAssignedSkills: SkillsAssignedExtended;
  listChosenSkills: SkillsAssignedExtended;
  skillModal: ModalState;
  skillDelete: UpdateState;
  isLoading: boolean;
  hasSkillsInReview: boolean;
  countSkillStatuses: CountSkillStatuses;
}

export interface UpdateState {
  visible: boolean;
  name: string;
  isAssignedSkills: boolean;
  lvl?: string;
}

export interface DeleteModal {
  name: string;
  isAssignedSkills: boolean;
}

const initialState: SkillState = {
  isLoading: false,
  hasSkillsInReview: false,
  listTechnicalSkills: { skills: [] },
  listLanguageSkills: { skills: [] },
  listAssignedSkills: { assignments: [] },
  listChosenSkills: { assignments: [] },
  skillModal: { visible: false, content: 0 },
  skillDelete: { visible: false, name: '', isAssignedSkills: false },
  countSkillStatuses: { approved: 0, in_review: 0, rejected: 0 },
};

export const fetchSkillList = createAsyncThunk('profile/fetchSkillList', async (type: string) => {
  const { getSkills } = await getAllSkills({ type });
  return getSkills;
});

export const fetchAssignedSkillList = createAsyncThunk(
  'profile/fetchAssignedSkillList',
  async (id: string) => {
    const { getUser } = await queryGetAssignedSkills({ id });
    return getUser?.skillAssignments.assignments.map((skill) => ({
      ...skill,
      action: '',
      id: uniqueId(),
    }));
  }
);

export const fetchOwnAssignedSkillList = createAsyncThunk(
  'profile/fetchOwnAssignedSkillList',
  async () => {
    const { getOwnUser } = await queryGetOwnAssignedSkills();
    return getOwnUser?.skillAssignments.assignments.map((skill) => ({
      ...skill,
      action: '',
      id: uniqueId(),
    }));
  }
);

export const checkIfSkillsInReview = createAsyncThunk(
  'team-lead/checkIfSkillsInReview',
  async () => {
    const {
      hasSkillsToReview: { value },
    } = await queryHasSkillsInReview();
    return value;
  }
);

const setAssignedSkills = (
  state: SkillState,
  action: PayloadAction<SkillsAssignedExtended>
): SkillState => {
  const filteredSkills = action.payload.assignments.concat(
    state.listAssignedSkills.assignments.filter((assigned) => {
      if (state.skillModal.content === 0) {
        return assigned.type === 'LANGUAGE_SKILL';
      }
      return assigned.type === 'TECHNICAL_SKILL';
    })
  );

  return {
    ...state,
    listAssignedSkills: {
      assignments: filteredSkills.sort((a, b) => a.name.localeCompare(b.name)),
    },
    isLoading: false,
  };
};

const setChosenSkills = (state: SkillState, action: PayloadAction<SkillChosen>): SkillState => {
  const sortedSkills = [...state.listChosenSkills.assignments, action.payload].sort((a, b) =>
    a.name.localeCompare(b.name)
  );
  return {
    ...state,
    listChosenSkills: { assignments: sortedSkills },
  };
};

const updateChosenSkills = (
  state: SkillState,
  action: PayloadAction<SkillsAssignedExtended>
): SkillState => {
  const sortedSkills = [...action.payload.assignments].sort((a, b) => a.name.localeCompare(b.name));
  return { ...state, listChosenSkills: { assignments: sortedSkills } };
};

const updateSkillLevel = (state: SkillState, action: PayloadAction<SkillUpdate>): SkillState => {
  const table = state.listChosenSkills.assignments.map((skill, index) => {
    if (index === action.payload.index) {
      return { ...skill, lvl: action.payload.lvl, updated: action.payload.updated };
    }
    return skill;
  });
  return { ...state, listChosenSkills: { assignments: table } };
};

const updateAssignedSkillLevel = (
  state: SkillState,
  action: PayloadAction<SkillUpdate>
): SkillState => {
  const table = state.listAssignedSkills.assignments.map((skill, index) => {
    if (skill.name === action.payload.name) {
      return {
        ...skill,
        lvl: action.payload.lvl,
        updated: action.payload.updated,
        status: ReviewOutcome.InProgress,
      };
    }
    return skill;
  });
  return { ...state, listAssignedSkills: { assignments: table } };
};

const deleteSkill = (state: SkillState, action: PayloadAction<DeleteModal>): SkillState => {
  if (!state.skillDelete.isAssignedSkills) {
    const index = state.listChosenSkills.assignments.findIndex((skill) => {
      return skill.name === action.payload.name;
    });
    const table = [
      ...state.listChosenSkills.assignments.slice(0, index),
      ...state.listChosenSkills.assignments.slice(index + 1),
    ];
    return { ...state, listChosenSkills: { assignments: table } };
  }
  const index = state.listAssignedSkills.assignments.findIndex((skill) => {
    return skill.name === action.payload.name;
  });
  const table = [
    ...state.listAssignedSkills.assignments.slice(0, index),
    ...state.listAssignedSkills.assignments.slice(index + 1),
  ];
  return {
    ...state,
    listAssignedSkills: { assignments: table },
    listChosenSkills: { assignments: table },
  };
};

const setModal = (state: SkillState, action: PayloadAction<ModalState>): SkillState => {
  return {
    ...state,
    skillModal: { visible: action.payload.visible, content: action.payload.content },
  };
};

const setModalDelete = (state: SkillState, action: PayloadAction<UpdateState>): SkillState => {
  return {
    ...state,
    skillDelete: {
      visible: action.payload.visible,
      name: action.payload.name,
      isAssignedSkills: action.payload.isAssignedSkills,
    },
  };
};

const setLoading = (state: SkillState, action: PayloadAction<boolean>): SkillState => {
  return {
    ...state,
    isLoading: action.payload,
  };
};

const skillSlice = createSlice({
  name: 'skill',
  initialState,
  reducers: {
    setAssignedSkills,
    setChosenSkills,
    setModal,
    updateSkillLevel,
    deleteSkill,
    setModalDelete,
    updateAssignedSkillLevel,
    updateChosenSkills,
    setLoading,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSkillList.pending, (state) => {
      if (state.skillModal.content === 0) {
        return {
          ...state,
          isLoading: true,
          listTechnicalSkills: {
            ...state.listTechnicalSkills,
            skills: [],
          },
        };
      }
      return {
        ...state,
        isLoading: true,
        listLanguageSkills: {
          ...state.listLanguageSkills,
          skills: [],
        },
      };
    });
    builder.addCase(fetchSkillList.fulfilled, (state, action: PayloadAction<SkillsResponse>) => {
      const sortedSkills = action.payload.skills.sort((a, b) => a.name.localeCompare(b.name));
      if (state.skillModal.content === 0) {
        return {
          ...state,
          isLoading: false,
          listTechnicalSkills: {
            ...state.listTechnicalSkills,
            skills: sortedSkills,
          },
        };
      }
      return {
        ...state,
        isLoading: false,
        listLanguageSkills: {
          ...state.listLanguageSkills,
          skills: sortedSkills,
        },
      };
    });
    builder.addCase(fetchAssignedSkillList.fulfilled, (state, action) => {
      const sortedSkills: Array<AssignedSkill> | undefined = action?.payload?.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      if (sortedSkills) {
        return {
          ...state,
          isLoading: false,
          listAssignedSkills: {
            assignments: sortedSkills,
          },
          listChosenSkills: {
            assignments: [],
          },
        };
      }
      return {
        ...state,
        isLoading: false,
        listAssignedSkills: {
          assignments: [],
        },
        listChosenSkills: {
          assignments: [],
        },
      };
    });
    builder.addCase(fetchOwnAssignedSkillList.pending, (state) => {
      return {
        ...state,
        isLoading: true,
        listAssignedSkills: {
          assignments: [],
        },
        listChosenSkills: {
          assignments: [],
        },
      };
    });
    builder.addCase(fetchOwnAssignedSkillList.fulfilled, (state, action) => {
      const sortedSkills: Array<AssignedSkill> | undefined = action?.payload?.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      const approvedSkills: number =
        action?.payload?.filter(
          (skill) =>
            (skill.status && skill.status === ReviewOutcome.Approved) || skill.status === null
        ).length || 0;
      const inReviewSkills: number =
        action?.payload?.filter((skill) => skill.status === ReviewOutcome.InProgress).length || 0;
      const rejectedSkills: number =
        action?.payload?.filter((skill) => skill.status === ReviewOutcome.Rejected).length || 0;
      if (sortedSkills) {
        return {
          ...state,
          isLoading: false,
          listAssignedSkills: {
            assignments: sortedSkills,
          },
          listChosenSkills: {
            assignments: [],
          },
          countSkillStatuses: {
            approved: approvedSkills,
            in_review: inReviewSkills,
            rejected: rejectedSkills,
          },
        };
      }
      return {
        ...state,
        isLoading: false,
        listAssignedSkills: {
          assignments: [],
        },
        listChosenSkills: {
          assignments: [],
        },
      };
    });
    builder.addCase(checkIfSkillsInReview.pending, (state) => {
      return {
        ...state,
        hasSkillsInReview: false,
      };
    });
    builder.addCase(checkIfSkillsInReview.fulfilled, (state, action) => {
      return {
        ...state,
        hasSkillsInReview: action.payload,
      };
    });
  },
});

export const actions = {
  ...skillSlice.actions,
  fetchSkillList,
  fetchAssignedSkillList,
  fetchOwnAssignedSkillList,
  checkIfSkillsInReview,
};

export const selectSkills = (state: RootState): SkillState => state.skill;

export default skillSlice.reducer;
