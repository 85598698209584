import styled from 'styled-components';

type HeadProps = {
  rejected?: boolean;
};

export const Container = styled.div`
  margin: 0 0;
  padding: 5px;
  width: 100%;
`;

export const Head = styled.h4<HeadProps>`
  color: ${(props) => (props.rejected ? props.theme.colors.warn : props.theme.colors.white)};
  font-size: 13px;
  padding-bottom: 4px;
`;

export const Header = styled.h3`
  color: ${({ theme }) => theme.colors.white};
  font-size: 15px;
  padding-bottom: 4px;
`;

export const StatusWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Paragraph = styled.p`
  color: ${({ theme }) => theme.colors.checkbox.primary};
  font-size: 12px;
  padding-bottom: 5px;
`;

export const ParagraphWrapped = styled.p`
  inline-size: 160px;
  color: ${({ theme }) => theme.colors.checkbox.primary};
  font-size: 12px;
  padding-bottom: 5px;
`;

export const Button = styled.button`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.pagination.primary};
  padding: 10px 16px;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.3px;
  background: none;
  color: ${({ theme }) => theme.colors.pagination.primary};
`;
