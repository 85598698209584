import { TFunction } from 'react-i18next';

export const getLevelDescription = (t: TFunction<'translation', undefined>, level: string) => {
  switch (level.toLowerCase().trim()) {
    case 'beginner':
      return t('skills-matrix.skill-experience.beginner-desc');
      break;
    case 'intermediate':
      return t('skills-matrix.skill-experience.intermediate-desc');
      break;
    case 'expert':
      return t('skills-matrix.skill-experience.expert-desc');
      break;
    default:
      return '';
  }
};
