import React, { useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line import/order
import { Tab, TabList, TabPanel } from '@reach/tabs';
import '@reach/tabs/styles.css';

import { ConfigProvider, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { JiraActionLink, ProjectNameParagraph } from 'components/Table/Table.styles';
import AssignmentStage from 'components/Table/AssignmentStage';

import { shouldRenderSalesforceLink } from 'features/Profile/helpers/roleChecks';
import {
  EmptyFilter,
  EmptyProjects,
  NoAccess,
} from 'features/Profile/components/ProfileOverview/Projects/EmptyStates';
import Pagination from 'features/SearchResults/components/Pagination/Pagination';
import { UserProject } from 'API';
import { ReactComponent as SFIcon } from 'assets/Link.svg';
import { ReactComponent as JiraIcon } from 'assets/Jira.svg';
import { useGetProfileById } from 'services/api/profile';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_PAGE_SIZE,
} from 'features/SearchResults/utils/constants/pagination';
import { getCurrentWeekRange } from 'features/Profile/helpers/getCurrentWeekRange';
import useProjectsListData from 'hooks/useProjectsListData';
import { ProfileRouteParams } from 'features/Profile/pages/Profile/Profile';
import { useWindowResize } from 'features/Profile/hooks/useWindowResize';
import { useAuth } from 'providers/AuthProvider';

import {
  SectionTitle,
  SFLink,
  StyledTabPanels,
  StyledTabs,
  TableSpacing,
  TabName,
  TabValue,
  UserAssignmentsSection,
} from './ProjectsSection.styles';
import LeadAvatarElement from './LeadAvatarElement';

enum AssignmentsTabs {
  all = 0,
  current = 1,
  previous = 2,
}

const ProfileOverviewProjectsSection = (): JSX.Element => {
  const { userId } = useParams<{ userId: string }>() as ProfileRouteParams;
  const { currentUser } = useAuth();
  const [currentPage, setCurrentPage] = useState<number>(DEFAULT_CURRENT_PAGE);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const [isHistory, setIsHistory] = useState<boolean>(false);
  const { width } = useWindowResize();
  const { t } = useTranslation();
  const { startDate: after, endDate: before } = getCurrentWeekRange(new Date());
  const { data: listProjects = [], status } = useProjectsListData({
    userId,
    after,
    before,
  });
  const { data: profileData } = useGetProfileById(userId);
  const { role } = profileData?.getUser || {};
  const [tabIndex, setTabIndex] = useState(AssignmentsTabs.all);

  const { thisWeek: thisWeekProjects, history: historyProjects } = useMemo(() => {
    const tempProjects = [...listProjects].sort((a, b) =>
      a.projectName.localeCompare(b.projectName)
    );

    const thisWeek = tempProjects.filter((project) => project.weeklyHours !== 0);
    const history = tempProjects.filter((project) => !thisWeek.includes(project));
    return { thisWeek, history };
  }, [listProjects]);

  const handleTabsChange = (index: number) => {
    setCurrentPage(DEFAULT_CURRENT_PAGE);
    setIsHistory(index === 1);
    setTabIndex(index);
  };

  useEffect(() => {
    setCurrentPage(DEFAULT_CURRENT_PAGE);
  }, [userId]);

  const projectHeaders: ColumnsType<
    Pick<
      UserProject,
      | 'lead'
      | 'projectStatus'
      | 'weeklyHours'
      | 'totalEstimatedHours'
      | 'currentLoggedHours'
      | 'projectName'
      | 'entryKey'
      | 'projectUrl'
      | 'jiraUrl'
    >
  > = [
    {
      key: 'lead',
      dataIndex: 'lead',
      width: width > 1700 ? '7%' : '10%',
      title: t('profile.user-assignments.lead'),
      render: (_, { lead }): JSX.Element => <LeadAvatarElement leadID={lead ?? ''} />,
    },
    {
      key: 'projectName',
      width: '45%',
      align: 'left',
      dataIndex: 'projectName',
      title: t('profile.user-assignments.project-name'),
      render: (_, { projectName, projectUrl }): JSX.Element => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ProjectNameParagraph ellipsis={{ tooltip: true }}>{projectName}</ProjectNameParagraph>
          {shouldRenderSalesforceLink(currentUser?.role, currentUser?.technicalRole) &&
            projectUrl && (
              <Tooltip placement="bottom" title={t('profile.user-assignments.go-to-sf')}>
                <SFLink href={projectUrl} target="_blank" rel="noreferrer">
                  <SFIcon />
                </SFLink>
              </Tooltip>
            )}
        </div>
      ),
    },
    {
      key: 'weeklyHours',
      width: '20%',
      align: 'left',
      dataIndex: 'weeklyHours',
      title: !isHistory ? t('profile.user-assignments.forecast-hours') : '',
      render: (_, { weeklyHours }) =>
        weeklyHours || weeklyHours !== 0 ? weeklyHours?.toFixed(0) : '',
    },
    {
      key: 'projectStatus',
      width: '35%',
      align: 'left',
      dataIndex: 'projectStatus',
      title: t('profile.user-assignments.status'),
      render: (_, { projectStatus }): JSX.Element => <AssignmentStage stage={projectStatus} />,
    },
    {
      key: 'jiraUrl',
      width: '5%',
      dataIndex: 'jiraUrl',
      title: t('profile.user-assignments.action'),
      render: (_, { jiraUrl }) =>
        jiraUrl && (
          <Tooltip placement="bottom" title={t('profile.user-assignments.go-to-jira')}>
            <JiraActionLink href={jiraUrl} target="_blank">
              <JiraIcon />
            </JiraActionLink>
          </Tooltip>
        ),
    },
  ];

  const shouldShowEmptyProjectsEmptyState =
    listProjects.length === 0 && status === 'success' && role !== 'Non-Delivery';

  const shouldShowEmptyFilterThisWeek =
    listProjects.length !== 0 && thisWeekProjects.length === 0 && status === 'success';

  const shouldShowEmptyFilterHistory =
    listProjects.length !== 0 && historyProjects.length === 0 && status === 'success';

  return (
    <UserAssignmentsSection id="userAssignmentsBlock">
      <SectionTitle id="userAssignmentsTitle">{t('profile.user-assignments.title')}</SectionTitle>

      <StyledTabs index={tabIndex} onChange={handleTabsChange}>
        {status !== 'loading' && listProjects.length !== 0 && role !== 'Non-Delivery' && (
          <TabList>
            <Tab key="this-week">
              <TabName>{t('profile.user-assignments.tabs.this-week')}</TabName>
              <TabValue isSelected={tabIndex === 0}>{thisWeekProjects.length}</TabValue>
            </Tab>
            <Tab key="history">
              <TabName>{t('profile.user-assignments.tabs.history')}</TabName>
              <TabValue isSelected={tabIndex === 1}>{historyProjects.length}</TabValue>
            </Tab>
          </TabList>
        )}
        {role !== 'Non-Delivery' && (
          <StyledTabPanels>
            <TabPanel>
              <TableSpacing>
                <ConfigProvider>
                  {!shouldShowEmptyProjectsEmptyState && (
                    <Table
                      dataSource={thisWeekProjects.slice(
                        currentPage === 1 ? 0 : (currentPage - 1) * pageSize,
                        currentPage * pageSize
                      )}
                      loading={listProjects.length === 0 && status !== 'success'}
                      columns={projectHeaders}
                      pagination={false}
                      rowKey="entryKey"
                    />
                  )}
                </ConfigProvider>
                {shouldShowEmptyProjectsEmptyState && <EmptyProjects />}
                {shouldShowEmptyFilterThisWeek && <EmptyFilter />}
              </TableSpacing>
              <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                setPageSize={setPageSize}
                amountItems={pageSize}
                total={thisWeekProjects.length}
              />
            </TabPanel>

            <TabPanel>
              <TableSpacing>
                <ConfigProvider
                  renderEmpty={() =>
                    shouldShowEmptyProjectsEmptyState ? <EmptyProjects /> : <EmptyFilter />
                  }
                >
                  <Table
                    dataSource={historyProjects.slice(
                      currentPage === 1 ? 0 : (currentPage - 1) * pageSize,
                      currentPage * pageSize
                    )}
                    loading={listProjects.length === 0 && status !== 'success'}
                    columns={projectHeaders}
                    pagination={false}
                    rowKey="entryKey"
                  />
                </ConfigProvider>
                {shouldShowEmptyProjectsEmptyState && <EmptyProjects />}
                {shouldShowEmptyFilterHistory && <EmptyFilter />}
              </TableSpacing>
              <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                setPageSize={setPageSize}
                amountItems={pageSize}
                total={historyProjects.length}
              />
            </TabPanel>
          </StyledTabPanels>
        )}

        {role === 'Non-Delivery' && <NoAccess />}
      </StyledTabs>
    </UserAssignmentsSection>
  );
};

export default ProfileOverviewProjectsSection;
