import { Subject } from 'rxjs';

import { TimeOff } from '../helpers/mergeTimeOffRecords';

export type MoveCalendarToPayload = {
  destination: number;
  target?: TimeOff;
};

const moveCalendarTo$ = new Subject<MoveCalendarToPayload>();

export const emitMoveCalendarTo = (payload: MoveCalendarToPayload): void =>
  moveCalendarTo$.next(payload);
export const onMoveCalendarTo$ = moveCalendarTo$.asObservable();
