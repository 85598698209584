import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { addDays, parseISO } from 'date-fns';

import { TimeOffGroup, UserTimeOff } from 'features/TimeOff/types/calendarTypes';
import style from 'features/TimeOff/constants/style';
import { selectCalendarRange } from 'features/TimeOff/redux/calendarRange';

import TimeOffRow from './TimeOffRow';
import TimeOffRowLoading from './TimeOffRow/Loading';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  .borderless {
    border: 0;
    border-radius: 0;
    height: ${style.dayHead.height}px;
    width: ${style.dayHead.width}px;
    box-shadow: none;
  }
  .left {
    position: sticky;
    left: 0;
    top: ${style.monthNames.height}px;
    margin-right: -${style.dayHead.width}px;
    z-index: 10;
  }
  .right {
    position: sticky;
    right: 0;
    top: ${style.monthNames.height}px;
    margin-left: -${style.dayHead.width}px;
    z-index: 10;
  }
`;

const TimeOffsOverlayWrapper = styled.div`
  width: 100%;
  top: ${style.groupName.height}px;
  margin: 0;
  left: 0;
`;

const TimesOffGroupsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  // gap between list elements
  & > * {
    &:not(:last-child) {
      margin-bottom: ${style.groupName.height}px;
    }
  }
`;

const TimesOffGroup = styled.div``;
export interface CalendarProps {
  timesOffGroup: TimeOffGroup;
  loading: number;
  scrollLeft?: number;
}

const Calendar = ({ timesOffGroup, loading, scrollLeft }: CalendarProps): JSX.Element => {
  const { startDate } = useSelector(selectCalendarRange);
  return (
    <Wrapper>
      <TimeOffsOverlayWrapper>
        <TimesOffGroupsWrapper>
          <TimesOffGroup key={timesOffGroup.key}>
            {timesOffGroup.timesOff.map((user: UserTimeOff) => (
              <TimeOffRow user={user} key={user.id} />
            ))}
            {Array(loading)
              .fill(undefined)
              .map((_, idx) => (
                <TimeOffRowLoading
                  scrollLeft={scrollLeft}
                  startDate={addDays(parseISO(startDate), idx + 2)}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${timesOffGroup.key}:loading:${idx}`}
                />
              ))}
          </TimesOffGroup>
        </TimesOffGroupsWrapper>
      </TimeOffsOverlayWrapper>
    </Wrapper>
  );
};

export default Calendar;
