/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  selectMyPtoCurrent,
  selectMyPtoLeft,
  selectMyPtoUsed,
  selectMyContractType,
  selectMyAnnualVacationLeave,
  selectMyPtoAllowance,
} from 'features/TimeOff/redux/timesOffSlice';
import { ContractTypeEnum } from 'API';

import Days from './Days';
import {
  Title,
  Wrapper,
  Group,
  Subtitle,
  SubtitleGroup,
  Year,
  Flex,
  BottomDiv,
} from './PtoTooltips.styles';
import AnnualVacationLeave from './AnnualVacationLeave';

const DefaultTooltipContent = (): JSX.Element => {
  const { t } = useTranslation();
  const ptoLeft = useSelector(selectMyPtoLeft);
  const ptoCurrent = useSelector(selectMyPtoCurrent);
  const ptoAllowance = useSelector(selectMyPtoAllowance);
  const ptoUsed = useSelector(selectMyPtoUsed);
  const contractType = useSelector(selectMyContractType);
  const isUop = 'shortName' in contractType && contractType.shortName === ContractTypeEnum.Uop;
  const hadAnnualVacationLeave = useSelector(selectMyAnnualVacationLeave);
  const d = new Date();
  const year = d.getFullYear();
  return (
    <>
      <Wrapper>
        <Title>{t('pto-tooltip.balance')}</Title>
        <Group>
          <SubtitleGroup>
            <Subtitle>
              {isUop ? t('pto-tooltip.granted') : t('pto-tooltip.contracted')}
              {isUop ? <Year> {year}</Year> : <Year> {t('pto-tooltip.per-year')}</Year>}
            </Subtitle>
            <Flex>
              <Days neutral count={'value' in ptoAllowance ? ptoAllowance.value : 0} />
              {t('pto-tooltip.days')}
            </Flex>
          </SubtitleGroup>
          {!isUop && (
            <SubtitleGroup>
              <Subtitle>{t('pto-tooltip.stacked')}</Subtitle>
              <Flex>
                <Days count={ptoCurrent} />
                {ptoCurrent === 1 || ptoCurrent === -1
                  ? t('pto-tooltip.day')
                  : t('pto-tooltip.days')}
              </Flex>
            </SubtitleGroup>
          )}

          <SubtitleGroup>
            <Subtitle>
              {t('pto-tooltip.taken')}
              <Year> {year}</Year>
            </Subtitle>
            <Flex>
              <Days count={ptoUsed} />
              {ptoUsed === 1 || ptoUsed === -1 ? t('pto-tooltip.day') : t('pto-tooltip.days')}
            </Flex>
          </SubtitleGroup>
        </Group>
        <SubtitleGroup>
          <Subtitle style={{ marginTop: '10px' }}>
            {t('pto-tooltip.available')}
            {isUop && <Year> {year}</Year>}
          </Subtitle>
          <span style={{ marginTop: '10px' }}>
            <Days count={ptoLeft} />
            {ptoLeft === 1 || ptoLeft === -1 ? t('pto-tooltip.day') : t('pto-tooltip.days')}
          </span>
        </SubtitleGroup>
      </Wrapper>
      {'value' in hadAnnualVacationLeave && hadAnnualVacationLeave.value === false && isUop && (
        <BottomDiv>
          <AnnualVacationLeave />
        </BottomDiv>
      )}
    </>
  );
};

export default DefaultTooltipContent;
