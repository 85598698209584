import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReactComponent as Beginner } from 'assets/Beginner.svg';
import { ReactComponent as Intermediate } from 'assets/Intermediate.svg';
import { ReactComponent as Expert } from 'assets/Expert.svg';
import { selectSkills } from 'features/Profile/redux/skillSlice';
import { getLanguageNames } from 'features/Profile/helpers/getLanguageNames';

import {
  ColumnContainer,
  SubContainer,
  SubHeader,
  SubHeaderTitle,
  SubTitle,
} from './SkillLevelSelection.styles';
import SkillLevel from '../SkillLevel/SkillLevel';

interface SkillLevelSelectionProps {
  handleLevel: (lvl: string) => void;
  skillName: string;
  skillLevel: string;
}

const SkillLevelSelection = ({
  handleLevel,
  skillName,
  skillLevel,
}: SkillLevelSelectionProps): JSX.Element => {
  const { skillModal } = useSelector(selectSkills);
  const { t } = useTranslation();
  const [skill, setSkill] = useState(skillLevel.split(' ')[0]);
  const expert = t('skills-matrix.skill-experience.expert');
  const intermediate = t('skills-matrix.skill-experience.intermediate');
  const beginner = t('skills-matrix.skill-experience.beginner');
  const isTechnical = skillModal.content === 0;
  const languageLevels = [
    t('skills-matrix.CEFR.native'),
    t('skills-matrix.CEFR.C2'),
    t('skills-matrix.CEFR.C1'),
    t('skills-matrix.CEFR.B2'),
    t('skills-matrix.CEFR.B1'),
    t('skills-matrix.CEFR.A2'),
    t('skills-matrix.CEFR.A1'),
  ];

  const handleSkill = (lvl: string) => {
    setSkill(lvl.split(' ')[0]);
  };
  return (
    <ColumnContainer>
      <SubHeader>
        <SubHeaderTitle>{skillName}</SubHeaderTitle>
      </SubHeader>
      {!isTechnical && (
        <SubContainer>
          <SubTitle>{t('skills-matrix.skill-experience.one-option')}</SubTitle>
          {languageLevels.map((lvl) => {
            return (
              <SkillLevel
                key={lvl}
                handleSkill={handleSkill}
                handleLevel={handleLevel}
                name={lvl}
                skill={getLanguageNames(skill)}
              />
            );
          })}
        </SubContainer>
      )}
      {isTechnical && (
        <SubContainer>
          <SubTitle>{t('skills-matrix.skill-experience.one-option')}</SubTitle>
          <SkillLevel
            handleSkill={handleSkill}
            handleLevel={handleLevel}
            name={expert}
            icon={<Expert />}
            skill={skill[0] + skill.slice(1).toLowerCase()}
          />
          <SkillLevel
            handleSkill={handleSkill}
            handleLevel={handleLevel}
            name={intermediate}
            icon={<Intermediate />}
            skill={skill[0] + skill.slice(1).toLowerCase()}
          />
          <SkillLevel
            handleSkill={handleSkill}
            handleLevel={handleLevel}
            name={beginner}
            icon={<Beginner />}
            skill={skill[0] + skill.slice(1).toLowerCase()}
          />
        </SubContainer>
      )}
    </ColumnContainer>
  );
};

export default SkillLevelSelection;
