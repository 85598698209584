import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  actions,
  selectSkillAssignmentsModalState,
} from 'features/SearchResults/redux/skillsAssignmentSlice';
import { useAppDispatch } from 'redux/store';

import {
  ColumnContainer,
  SubContainer,
  SkillSubHeader,
  SubHeaderTitle,
  SubTitle,
} from './SkillLevelSelection.styles';
import SkillLevelCheckbox from '../SkillLevel/SkillLevelCheckbox';
import { OutlineButton } from '../SkillSelection/SkillSelection.styles';

interface SkillLevelSelectionProps {
  skillName: string;
  level?: boolean[];
}

const LanguageLevelSelectionCheckboxes = ({
  skillName,
  level,
}: SkillLevelSelectionProps): JSX.Element => {
  const { t } = useTranslation();
  const modalArray = useSelector(selectSkillAssignmentsModalState);
  const dispatch = useAppDispatch();
  const languageLevels = [
    t('skills-matrix.CEFR.native'),
    t('skills-matrix.CEFR.C2'),
    t('skills-matrix.CEFR.C1'),
    t('skills-matrix.CEFR.B2'),
    t('skills-matrix.CEFR.B1'),
    t('skills-matrix.CEFR.A2'),
    t('skills-matrix.CEFR.A1'),
  ];

  useEffect(() => {
    dispatch(actions.setModal({ array: level || [] }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [level]);

  useEffect(() => {
    let newCheckedArray = [...modalArray];
    if (modalArray && modalArray.length < 7) {
      newCheckedArray = [...newCheckedArray, false, false, false, false];
    }
    if (!modalArray) {
      actions.setModal({ array: [false, false, false] });
    } else {
      actions.setModal({ array: newCheckedArray });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ColumnContainer language>
      <SkillSubHeader>
        <SubHeaderTitle>{skillName}</SubHeaderTitle>
        {modalArray?.some((element) => element === true) ? (
          <OutlineButton
            onClick={() => {
              dispatch(actions.setModal({ array: Array(7).fill(false) }));
            }}
          >
            {t('skills-search.modal.unselect-all')}
          </OutlineButton>
        ) : (
          <OutlineButton
            onClick={() => {
              dispatch(actions.setModal({ array: Array(7).fill(true) }));
            }}
          >
            {t('skills-search.modal.select-all')}
          </OutlineButton>
        )}
      </SkillSubHeader>

      <SubContainer>
        <SubTitle>{t('skills-matrix.skill-experience.one-option')}</SubTitle>
        {languageLevels.map((lvl, i) => {
          return (
            <SkillLevelCheckbox
              name={lvl}
              icon=""
              key={lvl}
              checked={modalArray[i] === true}
              onClick={() => {
                const updatedModalArray = [...modalArray];
                updatedModalArray[i] = !updatedModalArray[i];
                dispatch(actions.setModal({ array: updatedModalArray }));
              }}
              language
            />
          );
        })}
      </SubContainer>
    </ColumnContainer>
  );
};

export default LanguageLevelSelectionCheckboxes;
