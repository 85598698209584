import React, { ComponentPropsWithRef } from 'react';
import styled from 'styled-components';

import style from 'features/TimeOff/constants/style';

export const timeOffStatus = {
  APPROVED: 'APPROVED',
  SICKLEAVE: 'SICKLEAVE',
  PENDING: 'PENDING',
};

const Wrapper = styled.div`
  position: relative;
  height: ${style.timeOffBar.height}px;
  width: ${style.timeOffBar.height}px;
  padding: 10px 16px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font: ${({ theme: { typography } }) => typography.subtitles};

  background: ${({ theme: { colors } }) => colors.white};
  color: ${({ theme: { colors } }) => colors.secondary['200']};
  border: 1px solid ${({ theme: { colors } }) => colors.text.overline};
  &:hover {
    background: #f8f8fc;
    transition: 0.2s ease-in-out;
  }

  &:active {
    background: #f8f8fc;
  }
`;

export interface TimeOffHiddenProps extends ComponentPropsWithRef<'div'> {
  count: number;
}

const TimeOffHidden = ({ count }: TimeOffHiddenProps): null | JSX.Element => {
  if (!count) return null;

  return <Wrapper>{count}</Wrapper>;
};

export default TimeOffHidden;
