import { User } from 'API';
import generateGraphqlCaller from 'utils/helpers/generateGraphqlCaller';

const query = /* GraphQL */ `
  query GetFavouriteUsers($before: Date!, $after: Date!) {
    getOwnUser {
      ... on PrivateUser {
        id
        favouriteUsers {
          ... on PublicUser {
            id
            name
            profilePicture
            role
            timeOffDates(after: $after, before: $before) {
              ... on PublicTimeOffRecordsResult {
                timeOffs {
                  id
                  date
                  status
                }
              }
              ... on SalesforceError {
                message
              }
              ... on JiraError {
                message
              }
              ... on UnexpectedError {
                message
              }
              ... on Error {
                message
              }
            }
          }
        }
      }
      ... on UserNotFoundError {
        message
      }
      ... on MultipleUsersFound {
        message
      }
      ... on UnexpectedError {
        message
      }
      ... on Error {
        message
      }
    }
  }
`;

export type ReturnType = {
  getOwnUser: {
    favouriteUsers: Array<User>;
    message?: string;
  };
};

export default generateGraphqlCaller<ReturnType>(query);
