/* eslint-disable react/no-unused-prop-types */
import React, { ReactChild, useState } from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { Popover, PopoverProps } from 'antd';
import { useTranslation } from 'react-i18next';

import { TimeOffType } from 'API';
import { TimeOff } from 'features/TimeOff/helpers/mergeTimeOffRecords';

import { Heading, StatusIndicator, StatusText, TextContent } from '../TimeOffPopoverStyles';

const InnerWrapper = styled.button<{ border: boolean }>`
  background: transparent;
  padding: 8px 18px 18px;
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  cursor: pointer;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid
    ${({ border, theme }) => (border ? theme.colors.calendar.timeOffBorder : 'none')};

  &:hover {
    background-color: ${({ theme }) => theme.colors.calendar.timeOffHiddenHover};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.calendar.timeOffHiddenHover};
  }
`;

const OuterWrapper = styled.div``;

const Status = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const StatusIndicatorWrapper = styled.div`
  grid-area: dot;
`;

const StatusTextWrapper = styled.div`
  grid-area: text;
`;

const Dates = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  align-items: center;
  width: fit-content;
`;

const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ListWrapper = styled.div`
  max-height: 300px;
  overflow-y: auto;
`;

const getDate = (date: Date) => {
  return format(date, 'dd/MMM/yyyy');
};

export interface TimeOffHiddenPopoverProps extends PopoverProps {
  timesOff: TimeOff[];
  direction: 'left' | 'right';
  onTimeOffClick?: (arg0: TimeOff) => void;
  onVisibleChange?: (arg0: boolean) => void;
}

const TimeOffHiddenPopoverContent = ({
  timesOff,
  direction,
  onTimeOffClick,
}: TimeOffHiddenPopoverProps) => {
  const { t } = useTranslation();
  const getTimeOffType = (dayOff: TimeOff) => {
    if (dayOff.type === TimeOffType.Unpaid) return t('time-off.unpaid');
    if (dayOff.type === TimeOffType.Other) return t('time-off.other');
    if (dayOff.type === TimeOffType.Paid) return t('time-off.paid');

    return t('time-off.timeOff');
  };

  return (
    <ListWrapper>
      {timesOff.map((timeOff, idx) => (
        <OuterWrapper key={timeOff.id}>
          <InnerWrapper
            border={idx < timesOff.length - 1}
            onClick={() => onTimeOffClick?.(timeOff)}
          >
            <Status className={direction}>
              <StatusIndicatorWrapper>
                <StatusIndicator timeOff={timeOff} />
              </StatusIndicatorWrapper>
              <StatusTextWrapper>
                <StatusText>{getTimeOffType(timeOff)}</StatusText>
              </StatusTextWrapper>
            </Status>
            <Dates>
              <DateWrapper>
                <Heading style={{ display: 'flex' }}>{t('time-off.start-date')}</Heading>
                <TextContent>{getDate(new Date(timeOff.startDate))}</TextContent>
              </DateWrapper>
              <DateWrapper>
                <Heading>{t('time-off.end-date')}</Heading>
                <TextContent>{getDate(new Date(timeOff.endDate))}</TextContent>
              </DateWrapper>
            </Dates>
          </InnerWrapper>
        </OuterWrapper>
      ))}
    </ListWrapper>
  );
};

const TimeOffHiddenPopover = ({
  timesOff,
  children,
  direction,
  onTimeOffClick: onTimeOffClickOuter,
  onVisibleChange: onVisibleChangeOuter,
  ...props
}: TimeOffHiddenPopoverProps & { children: ReactChild }): JSX.Element => {
  const [visible, setVisible] = useState<boolean>(false);

  const onTimeOffClick: typeof onTimeOffClickOuter = (...args) => {
    onTimeOffClickOuter?.(...args);
    setVisible(false);
  };

  const onVisibleChange = (isVisible: boolean) => {
    onVisibleChangeOuter?.(isVisible);
    setVisible(isVisible);
  };

  return (
    <Popover
      content={
        // prettier and eslint have different ideas how to format this line ...
        // eslint-disable-next-line react/jsx-wrap-multilines
        <TimeOffHiddenPopoverContent
          direction={direction}
          onTimeOffClick={onTimeOffClick}
          timesOff={timesOff}
          {...props}
        />
      }
      trigger="click"
      overlayClassName="no-padding"
      visible={visible}
      onVisibleChange={onVisibleChange}
      {...props}
    >
      {children}
    </Popover>
  );
};

export default TimeOffHiddenPopover;
