import { Pagination } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  margin: 8px 0;
  display: flex;
  justify-content: flex-end;
`;

export const PageNavigation = styled(Pagination)`
  margin-right: 32px;

  .ant-pagination-item-link {
    border-radius: 0;
    border-color: ${({ theme }) => theme.colors.pagination.primary};
    span {
      color: ${({ theme }) => theme.colors.pagination.primary};
    }
  }

  .ant-pagination-item {
    border-radius: 0;
    border-color: ${({ theme }) => theme.colors.pagination.primary};
    a {
      color: ${({ theme }) => theme.colors.text.primary};
    }

    &:hover {
      border-color: ${({ theme }) => theme.colors.text.overline};
      a {
        color: ${({ theme }) => theme.colors.text.overline};
      }
    }
  }

  .ant-pagination-item-active {
    border-color: ${({ theme }) => theme.colors.text.overline};
    a {
      color: ${({ theme }) => theme.colors.text.overline};
    }
  }

  .ant-pagination-jump-next,
  .ant-pagination-jump-prev {
    .ant-pagination-item-container {
      .ant-pagination-item-ellipsis {
        color: ${({ theme }) => theme.colors.pagination.seondary};
      }

      .ant-pagination-item-link-icon {
        color: ${({ theme }) => theme.colors.text.overline};
      }
    }
  }

  .ant-pagination-next,
  .ant-pagination-prev {
    &[aria-disabled='false'] {
      .ant-pagination-item-link {
        span {
          color: ${({ theme }) => theme.colors.text.primary};
        }
      }

      &:hover .ant-pagination-item-link {
        border-color: ${({ theme }) => theme.colors.text.overline};
        span {
          color: ${({ theme }) => theme.colors.text.overline};
        }
      }
    }
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 0;
  }

  .ant-select-open {
    .ant-select-arrow {
      color: ${({ theme }) => theme.colors.text.overline};
      transform: rotate(180deg);
    }

    .ant-select-selector {
      border-color: ${({ theme }) => theme.colors.text.overline};
    }
  }

  .ant-select-arrow {
    transition: 0.2s ease-in-out;
  }

  .ant-select:not(.ant-select-disabled):hover {
    .ant-select-selector {
      border-color: ${({ theme }) => theme.colors.text.overline};
      box-shadow: none;
    }
  }

  .ant-select:not(.ant-select-disabled) {
    .ant-select-open {
      .ant-select-selector {
        border-color: ${({ theme }) => theme.colors.text.overline};
      }
    }
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: ${({ theme }) => theme.colors.white};
  }

  .ant-select-item {
    padding: 0;
    &-option-active {
      background-color: ${({ theme }) => theme.colors.white};

      .ant-select-item-option-content {
        color: ${({ theme }) => theme.colors.text.overline};
        background-color: ${({ theme }) => theme.colors.background};
      }
    }
    &-option-content {
      padding: 5px 21px 5px 12px;
    }
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    box-shadow: none;
  }
`;
