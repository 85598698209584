import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Tooltip, Avatar } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import TooltipContent, {
  PtoTooltip,
} from 'features/TeamLeadView/components/TooltipContent/TooltipContent';
import useMyTeamQuery from 'features/TeamLeadView/hooks/useMyTeamQuery';
import defaultPhoto from 'assets/png/10clouds_sygnet.png';
import {
  BoolValue,
  ContractType,
  IntValue,
  PrivateUser,
  PtoLeftResult,
  UopptoLeftResult,
} from 'API';
import { ReactComponent as QuestionMarkIcon } from 'assets/question-mark-icon.svg';
import Annual2WeekLeave from 'features/TeamLeadView/components/Annual2WeekLeave/Annual2WeekLeave';

import {
  Section,
  SectionTitle,
  RightAllignedColumn,
  UserName,
  SpanWrapper,
  AnnualVacationDiv,
  NoDataDiv,
} from './TeamTimeOff.styles';

type TeamTimeOffColumns = Pick<
  PrivateUser,
  | 'contractType'
  | 'name'
  | 'id'
  | 'profilePicture'
  | 'ptoAllowance'
  | 'ptoLeft'
  | 'hasUnlimitedPto'
  | 'hadAnnualVacationLeaveUop'
>;

interface IUserAvatarElement {
  id: string;
  src?: string;
}

const UserAvatarElement = ({ src = defaultPhoto, id }: IUserAvatarElement): JSX.Element => {
  return <Avatar size={32} src={src} />;
};

const TeamTimeOff = (): JSX.Element => {
  const { t } = useTranslation();
  const { myTeam } = useMyTeamQuery();

  const nullHandler = (value: number | null | undefined): string => {
    if (value === null || value === undefined) {
      return '?';
    }
    return value.toString();
  };

  const ptoHeaders: ColumnsType<TeamTimeOffColumns> = [
    {
      key: 'name',
      dataIndex: 'name',
      width: '30%',
      title: 'Employee name',
      render: (_, { name, id, profilePicture }) => (
        <div>
          {profilePicture && <UserAvatarElement id={id} src={profilePicture} />}
          <UserName href={`../profile/${id}`} target="_blank">
            {name}
          </UserName>
        </div>
      ),
    },
    {
      key: 'contractType',
      dataIndex: 'contractType',
      width: '8%',
      title: () => <div>{t('profile.detail-information.contract-type')}</div>,
      sorter: (a, b) =>
        (a.contractType as ContractType).shortName.localeCompare(
          (b.contractType as ContractType).shortName
        ),
      sortDirections: ['ascend', 'descend'],

      render: (_, { contractType }): JSX.Element => (
        <div>
          {contractType && 'message' in contractType
            ? '?'
            : (contractType as ContractType).shortName}
        </div>
      ),
    },
    {
      key: 'ptoLeft',
      dataIndex: 'ptoLeft',
      width: '7%',
      title: () => <RightAllignedColumn>{t('time-off.left-pto')}</RightAllignedColumn>,
      render: (_, { ptoLeft, hasUnlimitedPto }): JSX.Element =>
        (hasUnlimitedPto as BoolValue).value ? (
          <RightAllignedColumn>{`∞ ${t('pto-tooltip.days')}`}</RightAllignedColumn>
        ) : (
          <RightAllignedColumn>
            {ptoLeft && 'message' in ptoLeft
              ? `? ${t('pto-tooltip.days')}`
              : `${nullHandler((ptoLeft as PtoLeftResult)?.ptoLeft)} ${t('pto-tooltip.days')}`}
          </RightAllignedColumn>
        ),
    },
    {
      key: 'ptoUsed',
      dataIndex: 'ptoUsed',
      width: '8%',
      title: () => (
        <RightAllignedColumn>
          {t('time-off.taken-pto', { year: new Date().getFullYear() })}
        </RightAllignedColumn>
      ),
      render: (_, { ptoLeft }): JSX.Element => (
        <RightAllignedColumn>
          {ptoLeft && 'message' in ptoLeft
            ? `? ${t('pto-tooltip.days')}`
            : `${nullHandler((ptoLeft as PtoLeftResult)?.ptoUsed)} ${t('pto-tooltip.days')}`}
        </RightAllignedColumn>
      ),
    },
    {
      key: 'ptoCurrent',
      dataIndex: 'ptoCurrent',
      width: '8%',
      title: () => (
        <RightAllignedColumn>
          {t('time-off.stacked-pto')}
          <SpanWrapper>
            <Tooltip title={<TooltipContent type={PtoTooltip.STACKED} />}>
              <QuestionMarkIcon />
            </Tooltip>
          </SpanWrapper>
        </RightAllignedColumn>
      ),
      render: (_, { ptoLeft, hasUnlimitedPto }): JSX.Element =>
        (hasUnlimitedPto as BoolValue).value ? (
          <RightAllignedColumn>{`∞ ${t('pto-tooltip.days')}`}</RightAllignedColumn>
        ) : (
          <RightAllignedColumn>
            {ptoLeft && 'message' in ptoLeft
              ? `? ${t('pto-tooltip.days')}`
              : `${nullHandler((ptoLeft as PtoLeftResult).ptoCurrent)} ${t('pto-tooltip.days')}`}
          </RightAllignedColumn>
        ),
    },
    {
      key: 'ptoAllowance',
      dataIndex: 'ptoAllowance',
      width: '10%',
      title: () => (
        <RightAllignedColumn>
          {t('time-off.contracted-pto')}
          <SpanWrapper>
            <Tooltip title={<TooltipContent type={PtoTooltip.CONTRACTED} />}>
              <QuestionMarkIcon />
            </Tooltip>
          </SpanWrapper>
        </RightAllignedColumn>
      ),
      render: (_, { ptoAllowance, hasUnlimitedPto }): JSX.Element =>
        (hasUnlimitedPto as BoolValue).value ? (
          <RightAllignedColumn>{`∞ ${t('pto-tooltip.days')}`}</RightAllignedColumn>
        ) : (
          <RightAllignedColumn>
            {ptoAllowance && 'message' in ptoAllowance
              ? `? ${t('pto-tooltip.days')}`
              : `${nullHandler((ptoAllowance as IntValue).value)} ${t('pto-tooltip.days')}`}
          </RightAllignedColumn>
        ),
    },
    {
      key: 'ptoCarryover',
      dataIndex: 'ptoCarryover',
      width: '10%',
      title: () => (
        <RightAllignedColumn>
          {t('time-off.carryover', { year: new Date().getFullYear() - 1 })}
          <SpanWrapper>
            <Tooltip title={<TooltipContent type={PtoTooltip.CARRYOVER} />}>
              <QuestionMarkIcon />
            </Tooltip>
          </SpanWrapper>
        </RightAllignedColumn>
      ),
      render: (_, { ptoLeft }): JSX.Element =>
        (ptoLeft as UopptoLeftResult).ptoCarryover ? (
          <RightAllignedColumn>
            {ptoLeft && 'message' in ptoLeft
              ? `? ${t('pto-tooltip.days')}`
              : `${nullHandler((ptoLeft as UopptoLeftResult).ptoCarryover)} ${t(
                  'pto-tooltip.days'
                )}`}
          </RightAllignedColumn>
        ) : (
          <RightAllignedColumn>-</RightAllignedColumn>
        ),
    },
    {
      key: 'hadAnnualVacationLeaveUop',
      dataIndex: 'hadAnnualVacationLeaveUop',
      width: '16%',
      title: () => <AnnualVacationDiv>{t('time-off.annual-2-week-leave.title')}</AnnualVacationDiv>,
      render: (
        _,
        { hadAnnualVacationLeaveUop, contractType, ptoLeft, ptoAllowance }
      ): JSX.Element =>
        hadAnnualVacationLeaveUop ? (
          <Annual2WeekLeave
            contract={(contractType as ContractType).shortName}
            isTaken={(hadAnnualVacationLeaveUop as BoolValue)?.value}
            ptoLeft={ptoLeft as PtoLeftResult}
            ptoAllowance={ptoAllowance as IntValue}
          />
        ) : (
          <NoDataDiv>-</NoDataDiv>
        ),
    },
  ];

  return (
    <Section>
      <SectionTitle>{t('team-lead-view.time-off')}</SectionTitle>
      <Table
        loading={!myTeam}
        dataSource={myTeam}
        columns={ptoHeaders}
        pagination={false}
        rowKey="id"
      />
    </Section>
  );
};

export default TeamTimeOff;
