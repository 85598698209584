import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as IconSearch } from 'assets/iconSearch.svg';

import { EmptyState, IconContainer, Title, FindText } from './SkillListEmptyState.styles';

export const SkillListEmptyState = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <EmptyState>
      <IconContainer>
        <IconSearch />
      </IconContainer>
      <Title level={3}>{t('skills-matrix.add-skills.find-your-skills')}</Title>
      <FindText>{t('skills-matrix.add-skills.look-for-skills')}</FindText>
    </EmptyState>
  );
};
