import { EditDeleteSkillResponse, MutationDeleteSkillAssignmentFromUserArgs } from 'API';
import generateGraphqlCaller from 'utils/helpers/generateGraphqlCaller';

const query = `
mutation deleteSkillAssignmentFromUser($skillName: String!){
    deleteSkillAssignmentFromUser(skillName: $skillName) {
            ... on EditDeleteSkillSuccessResponse {
                    skill{
                        name
                    },
                owner{
                    id
                }
            }
            ... on EditDeleteSkillErrorResponse {
                ownerErr: owner{
                    id
                },
                errors {
                ... on Error {
                  message
                }
              }
            }

          }
}
`;

export default generateGraphqlCaller<
  EditDeleteSkillResponse,
  MutationDeleteSkillAssignmentFromUserArgs
>(query);
