import styled from 'styled-components';
import { Typography } from 'antd';

import { queries } from 'shared/layout';

export const Container = styled.div`
  height: auto;
  display: grid;
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ProfileNavigation = styled.div`
  margin-top: 24px;
  width: 100%;
  max-width: 1600px;
  display: flex;
  gap: 24px;
`;

export const MainWrapper = styled.div`
  width: 100%;
  padding: 40px 32px;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;

  ${queries.desktop} {
    padding: 40px 48px;
  }
`;

export const ContentSection = styled.div`
  display: flex;
  width: 100%;
  max-width: 1900px;
  margin-top: 24px;
`;

export const SectionsContainer = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Section = styled.section`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 32px;
  margin-top: 16px;
  width: 100%;
  max-width: 1600px;
  min-height: 690px;
  display: flex;
  flex-direction: column;
`;

export const SectionTitle = styled.h3`
  font: ${({ theme }) => theme.typography.paragraph.font};
  letter-spacing: ${({ theme }) => theme.typography.paragraph.letterSpacing};
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.text.secondary};
  padding-bottom: 20px;
`;

export const RightAllignedColumn = styled.div`
  text-align: right;
  font-size: 13px;
  display: flex;
`;

export const UserName = styled.a`
  margin-left: 20px;
  :hover {
    text-decoration: underline;
  }
`;

export const AnnualVacationDiv = styled.div`
  padding-left: 20px;
`;

export const NoDataDiv = styled.div`
  padding-left: 20px;
`;

export const SpanWrapper = styled.span`
  margin: auto 0;
  padding-left: 5px;
  display: flex;
`;

export const SkillsRowName = styled(Typography.Title)`
  &.ant-typography {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 15px;
    line-height: 22px;
    font-weight: 400;
    margin-bottom: 0px;
  }
`;

export const SkillsRowSubName = styled(Typography.Text)`
  &.ant-typography {
    color: ${({ theme }) => theme.colors.text.secondary};
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: 0.2px;
    margin-bottom: 0px;
  }
`;

export const SkillsRowLevelContainer = styled('div')`
  span:first-letter {
    text-transform: uppercase;
  }
  text-transform: lowercase;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0px;
`;

export const SkillsRowLevelIcon = styled('div')`
  width: 20px;
  height: 22px;
  font-size: 1.2em;
`;

export const SkillWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const SectionWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
  margin-left: 40px;
`;

export const HeaderWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const ButtonWrapper = styled('div')`
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
`;

export const HeaderButton = styled('button')`
  display: flex;
  gap: 6px;
  padding: 10px 16px;
  border: 1px solid #d4d4e1;
  border-radius: 4px;
  align-items: center;
  background-color: white;
`;

export const SelectAllReminder = styled('div')`
  background: ${({ theme }) => theme.colors.background};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 17.5px 0px;
  margin-bottom: 24px;
  gap: 24px;
`;

export const SelectAll = styled('div')`
  color: ${({ theme }) => theme.colors.text.overline};
  cursor: pointer;
`;

export const Content = styled.div`
  margin-top: 24px;
  background: ${({ theme }) => theme.colors.background};
  flex: 1;
  display: grid;
  place-content: center;
`;
