import { useEffect, useRef, useLayoutEffect } from 'react';
import { Observable } from 'rxjs';

const useSubscription = <T>(observable: Observable<T>, callback: (payload: T) => void): void => {
  const callbackRef = useRef(callback);

  useLayoutEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    const subscription = observable.subscribe(callbackRef.current);
    return () => subscription.unsubscribe();
  }, [observable]);
};

export default useSubscription;
