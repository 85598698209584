import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import TopHeader from 'components/TopHeader';

import { ReactComponent as GoBackIcon } from 'assets/arrow-back.svg';

import {
  Container,
  Content,
  GoBackButton,
  GoBackText,
  SecondaryText,
  TextGroup,
  Title,
  Wrapper,
} from './PrivacyPolicy.styles';

const PrivacyPolicy = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Container>
      <TopHeader variant="privacy" />
      <Wrapper id="privacyPolicyPage">
        <Content>
          <Link to="/" id="goBackLink">
            <GoBackButton>
              <GoBackIcon />
              <GoBackText>{t('privacy-policy.back')}</GoBackText>
            </GoBackButton>
          </Link>
          <Title id="privacyPolicyPageMainTitle">{t('privacy-policy.title')}</Title>
          <SecondaryText id="privacyPolicyPageLastUpdateText">
            {`${t('privacy-policy.last-updated')} ${t('privacy-policy.update-date')}`}
          </SecondaryText>
          <Title isSecondary id="privacyPolicyPageSecondaryTitle">
            {t('privacy-policy.what-info-we-collect')}
          </Title>
          <TextGroup id="privacyPolicyPageMainText">
            <SecondaryText>{t('privacy-policy.paragraph-1.1')}</SecondaryText>
            <SecondaryText>{t('privacy-policy.paragraph-1.2')}</SecondaryText>
            <SecondaryText>{t('privacy-policy.paragraph-1.3')}</SecondaryText>
          </TextGroup>
        </Content>
      </Wrapper>
    </Container>
  );
};

export default PrivacyPolicy;
