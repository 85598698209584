import { Subject } from 'rxjs';

import { UserTimeOff } from 'features/TimeOff/types/calendarTypes';

export type HoverTimelinePayload = UserTimeOff['id'] | null;

const hoverTimeline$ = new Subject<HoverTimelinePayload>();

export const emitHoverTimeline = (payload: HoverTimelinePayload): void =>
  hoverTimeline$.next(payload);
export const onHoverTimeline$ = hoverTimeline$.asObservable();
