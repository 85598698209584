import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ConfigProvider, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useParams } from 'react-router-dom';

import TooltipContent from 'features/TeamLeadView/components/TooltipContent/TooltipContent';
import { getLanguageFlag } from 'utils/helpers/getLanguageFlag';
import Pagination from 'features/SearchResults/components/Pagination/Pagination';
import { ReactComponent as ActionButton } from 'assets/actionButton.svg';
import { ReactComponent as AcceptedIcon } from 'assets/finalizing-off-icon.svg';
import { ReactComponent as RejectedIcon } from 'assets/warranty-legal-icon.svg';
import { ReactComponent as InProgressIcon } from 'assets/stacking-empty-icon.svg';
import { ReactComponent as QuestionMarkIcon } from 'assets/question-mark-icon.svg';
import { actions, AssignedSkill, selectSkills } from 'features/Profile/redux/skillSlice';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_PAGE_SIZE_SKILLS,
} from 'features/SearchResults/utils/constants/pagination';
import { ReviewOutcome, SkillAssignmentResponse } from 'API';
import { useIsProfileOwner } from 'features/Profile/hooks/useIsProfileOwner';
import { ProfileRouteParams } from 'features/Profile/pages/Profile/Profile';
import { getLanguageNames } from 'features/Profile/helpers/getLanguageNames';
import { useAppDispatch } from 'redux/store';
import { getSkillLevelIcon } from 'utils/helpers/getSkillLevelIcon';

import {
  SkillsRowName,
  SkillsRowSubName,
  SkillsRowLevelContainer,
  SkillsRowDate,
  SkillsRowActionContainer,
  TooltipActions,
  Action,
  ActionWrapper,
  SkillsRowLevelIcon,
  TableSpacing,
  SkillsRowStatus,
  StatusRowHeader,
} from './SkillsTable.styles';

export interface SkillExtended extends SkillAssignmentResponse {
  id: string;
  action: string;
}

export interface UpdateSkill {
  name: string;
  lvl: string;
}

export interface TableProps {
  onSkillClick: (data: UpdateSkill) => void;
  filteredAssignedSkills: Array<AssignedSkill>;
}

export const statusHandler = (status: ReviewOutcome | null | undefined) => {
  const getStatus = (icon: JSX.Element, text: string) => (
    <SkillsRowStatus>
      {icon}
      {text}
    </SkillsRowStatus>
  );

  switch (status) {
    case ReviewOutcome.Rejected:
      return getStatus(<RejectedIcon />, 'Rejected');
    case ReviewOutcome.InProgress:
      return getStatus(<InProgressIcon />, 'In Review');
    default:
      return getStatus(<AcceptedIcon />, 'Approved');
  }
};

const SkillsTable = ({ onSkillClick, filteredAssignedSkills }: TableProps): JSX.Element => {
  const { userId } = useParams<{ userId: string }>() as ProfileRouteParams;
  const isProfileOwner = useIsProfileOwner(userId);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isLoading, countSkillStatuses } = useSelector(selectSkills);
  const [currentPage, setCurrentPage] = useState<number>(DEFAULT_CURRENT_PAGE);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE_SKILLS);
  const handleDeleteSkill = (data: UpdateSkill) => {
    dispatch(actions.setModalDelete({ visible: true, name: data.name, isAssignedSkills: true }));
  };
  const menu = (data: UpdateSkill): JSX.Element => {
    return (
      <ActionWrapper>
        <Action
          onClick={() => {
            onSkillClick({ name: data.name, lvl: data.lvl });
          }}
        >
          Edit
        </Action>
        <Action
          onClick={() => {
            handleDeleteSkill({ name: data.name, lvl: data.lvl });
          }}
          remove
        >
          Remove
        </Action>
      </ActionWrapper>
    );
  };

  const skillsHeaders: ColumnsType<
    Pick<
      SkillExtended,
      'id' | 'name' | 'langCode' | 'lvl' | 'type' | 'updated' | 'status' | 'action'
    >
  > = isProfileOwner
    ? [
        {
          key: 'name',
          dataIndex: 'name',
          width: '50%',
          title: t('skills-matrix.table-header.name'),
          render: (_: string, { name, type }): JSX.Element => (
            <>
              <SkillsRowName>{name}</SkillsRowName>
              <SkillsRowSubName>{`${
                type === 'TECHNICAL_SKILL' ? 'Technical' : 'Language'
              } skill`}</SkillsRowSubName>
            </>
          ),
        },
        {
          key: 'skillLvl',
          dataIndex: 'skillLvl',
          width: '15%',
          title: t('skills-matrix.table-header.level'),
          render: (_: string, { lvl, langCode }): JSX.Element => (
            <SkillsRowLevelContainer>
              <SkillsRowLevelIcon>
                {langCode === '' ? getSkillLevelIcon(lvl) : getLanguageFlag(langCode)}
              </SkillsRowLevelIcon>
              <span>
                {langCode === '' || lvl.toLowerCase() !== 'native' ? lvl : getLanguageNames(lvl)}
              </span>
            </SkillsRowLevelContainer>
          ),
        },
        {
          key: 'date',
          dataIndex: 'date',
          width: '15%',
          title: t('skills-matrix.table-header.updated'),
          render: (_: string, { updated }): JSX.Element => (
            <SkillsRowDate>
              {new Intl.DateTimeFormat('en-US', {
                year: 'numeric',
                month: 'short',
                day: '2-digit',
              }).format(new Date(updated))}
            </SkillsRowDate>
          ),
        },
        {
          key: 'status',
          dataIndex: 'status',
          width: '15%',
          title: () => (
            <StatusRowHeader>
              {t('skills-matrix.table-header.status')}
              <Tooltip title={<TooltipContent count={countSkillStatuses} type="skill_status" />}>
                <QuestionMarkIcon />
              </Tooltip>
            </StatusRowHeader>
          ),
          render: (_: string, { status }): JSX.Element => statusHandler(status),
        },
        {
          key: 'action',
          dataIndex: 'action',
          width: '5%',
          align: 'center',
          title: t('skills-matrix.table-header.action'),
          render: (_: string, { name, lvl }): JSX.Element => (
            <SkillsRowActionContainer>
              <TooltipActions
                placement="bottomRight"
                overlay={() => menu({ name, lvl })}
                trigger={['click']}
              >
                <ActionButton />
              </TooltipActions>
            </SkillsRowActionContainer>
          ),
        },
      ]
    : [
        {
          key: 'name',
          dataIndex: 'name',
          width: '65%',
          title: t('skills-matrix.table-header.name'),
          render: (_: string, { name, type }): JSX.Element => (
            <>
              <SkillsRowName>{name}</SkillsRowName>
              <SkillsRowSubName>{`${
                type === 'TECHNICAL_SKILL' ? 'Technical' : 'Language'
              } skill`}</SkillsRowSubName>
            </>
          ),
        },
        {
          key: 'skillLvl',
          dataIndex: 'skillLvl',
          width: '15%',
          title: t('skills-matrix.table-header.level'),
          render: (_: string, { lvl, langCode }): JSX.Element => (
            <SkillsRowLevelContainer>
              <SkillsRowLevelIcon>
                {langCode === '' ? getSkillLevelIcon(lvl) : getLanguageFlag(langCode)}
              </SkillsRowLevelIcon>
              <span>{lvl}</span>
            </SkillsRowLevelContainer>
          ),
        },
        {
          key: 'date',
          dataIndex: 'date',
          width: '15%',
          title: t('skills-matrix.table-header.updated'),
          render: (_: string, { updated }): JSX.Element => (
            <SkillsRowDate>
              {new Intl.DateTimeFormat('en-US', {
                year: 'numeric',
                month: 'short',
                day: '2-digit',
              }).format(new Date(updated))}
            </SkillsRowDate>
          ),
        },
      ];

  return (
    <>
      <TableSpacing>
        <ConfigProvider>
          <Table
            loading={isLoading}
            dataSource={filteredAssignedSkills.slice(
              currentPage === 1 ? 0 : (currentPage - 1) * pageSize,
              currentPage * pageSize
            )}
            rowKey="id"
            pagination={false}
            columns={skillsHeaders}
          />
        </ConfigProvider>
      </TableSpacing>
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setPageSize={setPageSize}
        amountItems={pageSize}
        total={filteredAssignedSkills.length}
      />
    </>
  );
};

export default SkillsTable;
