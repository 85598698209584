import React from 'react';
import styled from 'styled-components';

import { ReactComponent as SpinnerAsset } from 'assets/Spinner.svg';

export const FullPageSpinnerContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 500px;
  padding: 48px;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const templateStyle = (i: number, delay: number) => `
  & :nth-child(${i}) {
    animation: spinner 1.8s ease-in-out ${delay * 0.1}s infinite both;
  }
  `;

const getAnimationString = (): string => {
  let style = '';
  const iterations = 6;
  for (let i = 0; i <= iterations; i += 1) {
    style += templateStyle(iterations + 1 - i, i);
  }
  return style;
};

const SpinnerSVG = styled(SpinnerAsset)<{ variant: 'dark' | 'light'; spinnerSize?: string }>`
  ${getAnimationString()}
  height: ${({ spinnerSize }) => spinnerSize};
  width: ${({ spinnerSize }) => spinnerSize};

  @keyframes spinner {
    0% {
      fill: ${({ theme, variant }) =>
        variant === 'dark'
          ? theme.colors.spinner.dark.primary
          : theme.colors.spinner.light.primary};
    }
    37.5% {
      fill: ${({ theme, variant }) =>
        variant === 'dark'
          ? theme.colors.spinner.dark.secondary
          : theme.colors.spinner.light.secondary};
    }
    75% {
      fill: ${({ theme, variant }) =>
        variant === 'dark'
          ? theme.colors.spinner.dark.primary
          : theme.colors.spinner.light.primary};
    }
    100% {
      fill: ${({ theme, variant }) =>
        variant === 'dark'
          ? theme.colors.spinner.dark.primary
          : theme.colors.spinner.light.primary};
    }
  }
`;

const Description = styled.p<{ variant?: 'dark' | 'light'; fontSize: number }>`
  color: ${({ theme, variant }) =>
    variant === 'dark' ? theme.colors.white : theme.colors.primary};
  font: ${({ theme }) => theme.typography.subtitles};
  font-weight: 400;
  font-size: ${({ fontSize }) => `${fontSize}px`};
  line-height: 22px;
  margin-left: 8px;
`;

interface ISpinner {
  message: string;
  variant?: 'dark' | 'light';
  fontSize?: number;
  spinnerSize?: number;
}

const Spinner = ({
  message = 'Loading... please wait',
  variant = 'light',
  fontSize = 15,
  spinnerSize = 24,
}: ISpinner): JSX.Element => {
  return (
    <SpinnerWrapper>
      <SpinnerSVG variant={variant} width={`${spinnerSize}px`} height={`${spinnerSize}px`} />
      <Description variant={variant} fontSize={fontSize} id="spinnerMessage">
        {message}
      </Description>
    </SpinnerWrapper>
  );
};

export default Spinner;
