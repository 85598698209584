import React, { ChangeEvent, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as IconSearch } from 'assets/iconSearch.svg';
import { ReactComponent as Arrow } from 'assets/Arrow.svg';
import { DEFAULT_CURRENT_PAGE } from 'features/SearchResults/utils/constants/pagination';
import { Button as ClearButton } from 'features/SearchResults/pages/SearchResults/SearchResults.styles';
import { deboucedSearch } from 'features/SearchResults/utils/helpers/filtersDebouncedSearch';
import { hasEveryFilterEmpty } from 'features/SearchResults/utils/helpers/hasEveryFilterEmpty';
import { actions } from 'features/SearchResults/redux/skillsAssignmentSlice';
import { useAppDispatch } from 'redux/store';

import {
  Wrapper,
  Header,
  ListIcon,
  Title,
  Menu,
  ItemsContainer,
  Item,
  Check,
  SearchInput,
  EmptyText,
} from './Filters.styles';
import SkillSearchLevelModal from '../SkillSearchLevelModal/SkillSearchLevelModal';
import { SkillFilterTitleCheckbox } from '../SkillFilterTitleCheckbox/SkillFilterTitleCheckbox';

export type FiltersType = {
  title: string;
  filters: {
    name: string;
    checkbox: boolean;
    checkedArray?: boolean[];
    level?: boolean[];
  }[];
}[];

type FiltersPropsType = {
  values: FiltersType;
  setFiltersValues: React.Dispatch<React.SetStateAction<FiltersType>>;
  activeFilters: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
};

const SkillFilters = ({
  values,
  setFiltersValues,
  activeFilters,
  setCurrentPage,
}: FiltersPropsType): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [results, setResults] = useState<FiltersType>(values);
  const [showModal, setShowModal] = useState(false);
  const [skill, setSkill] = useState({
    name: '',
    title: '',
    level: [false, false, false],
    checkedArray: [false, false, false],
  });

  useEffect(() => {
    setResults(values);
  }, [values]);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSkillClick = (title: string, name: string, level?: boolean[], array?: boolean[]) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setSkill((prevSkill) => {
      return {
        name,
        title,
        checkedArray: array ?? [false, false, false],
        level: level ?? [false, false, false],
      };
    });
    setShowModal(true);
  };

  const clearAllFilters = (): void => {
    const newValue = results.reduce<FiltersType>((acc, item) => {
      acc.push({
        title: item.title,
        filters: item.filters.map((elem) => ({
          name: elem.name,
          checkbox: false,
          checkedArray: [false, false, false],
        })),
      });
      return acc;
    }, []);
    setResults(newValue);
    setFiltersValues(newValue);
    setCurrentPage(DEFAULT_CURRENT_PAGE);
  };

  const handleConfirm = (title: string, name: string, array: boolean[]) => {
    const newValue = values.reduce<FiltersType>((acc, item) => {
      acc.push(
        item.title === title
          ? {
              title: item.title,
              filters: item.filters.map((elem) =>
                elem.name === name
                  ? {
                      name: elem.name,
                      checkbox: array.some((i) => i === true),
                      level: array,
                      checkedArray: array,
                    }
                  : elem
              ),
            }
          : item
      );
      return acc;
    }, []);
    setResults(newValue);
    setFiltersValues(newValue);
    setShowModal(false);
    setSkill({ name, title, level: array, checkedArray: array });
    dispatch(actions.setModal({ array }));
    setCurrentPage(DEFAULT_CURRENT_PAGE);
  };

  const handleClick = (title: string, name: string) => {
    const newValue = values.reduce<FiltersType>((acc, item) => {
      acc.push(
        item.title === title
          ? {
              title: item.title,
              filters: item.filters.map((elem) =>
                elem.name === name ? { name: elem.name, checkbox: !elem.checkbox } : elem
              ),
            }
          : item
      );
      return acc;
    }, []);

    setFiltersValues(newValue);
    setResults(newValue);
    setCurrentPage(DEFAULT_CURRENT_PAGE);
  };

  return (
    <Wrapper>
      <Header>
        <Title>
          <ListIcon />
          {t('filters.title')}
        </Title>
        {activeFilters > 0 ? (
          <ClearButton style={{ margin: 0 }} onClick={clearAllFilters}>
            {t('search-results.clear-all')}
          </ClearButton>
        ) : null}
      </Header>
      <SearchInput
        defaultValue=""
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          e.preventDefault();
          deboucedSearch({ searchTerm: e.target.value, values, setter: setResults });
        }}
        prefix={<IconSearch />}
        placeholder={t('skills-matrix.add-skills.search')}
        allowClear
      />
      <Menu
        expandIcon={({ isActive }) => (
          <Arrow className={isActive ? 'arrow-expanded' : 'arrow-collapsed'} />
        )}
        expandIconPosition="right"
        defaultActiveKey={['language skill', 'technical skill', 'seniority', 'technical role']}
      >
        {hasEveryFilterEmpty(results) ? (
          <EmptyText>{t('filters.empty-search')}</EmptyText>
        ) : (
          results.map((item) => (
            <ItemsContainer header={item.title} key={item.title}>
              {item.filters.map((element) => (
                <Item key={element.name}>
                  {item.title === t('filters.titles.technical-skill') && (
                    <SkillFilterTitleCheckbox
                      onClick={() =>
                        handleSkillClick(
                          item.title,
                          element.name,
                          element.level,
                          element.checkedArray
                        )
                      }
                      checked={element.checkbox}
                    >
                      {element.name}
                    </SkillFilterTitleCheckbox>
                  )}
                  {item.title === t('filters.titles.language-skill') && (
                    <SkillFilterTitleCheckbox
                      onClick={() =>
                        handleSkillClick(
                          item.title,
                          element.name,
                          element.level,
                          element.checkedArray
                        )
                      }
                      checked={element.checkbox}
                    >
                      {element.name}
                    </SkillFilterTitleCheckbox>
                  )}
                  {item.title !== t('filters.titles.technical-skill') &&
                    item.title !== t('filters.titles.language-skill') && (
                      <Check
                        onClick={() => handleClick(item.title, element.name)}
                        checked={element.checkbox}
                      >
                        {element.name}
                      </Check>
                    )}
                </Item>
              ))}
            </ItemsContainer>
          ))
        )}
      </Menu>
      <SkillSearchLevelModal
        visible={showModal}
        onCancelClick={handleCloseModal}
        skill={skill}
        handleConfirm={handleConfirm}
      />
    </Wrapper>
  );
};

export default SkillFilters;
