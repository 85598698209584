import { useQuery } from '@tanstack/react-query';

import { ReviewOutcome } from 'API';

import queryMyTeamSkills from '../api/queryMyTeamSkills';

export type AssignedSkills = {
  name: string;
  id: string;
  avatar?: string | null;
  skillName: string;
  skillLvl: string;
  skillDate: string;
  skillFlag: string;
  skillType: string;
  select: string;
  skillId: string;
  status?: ReviewOutcome | undefined;
}[];

interface IUseMyTeamSkillsQuery {
  skills: AssignedSkills | undefined;
  isLoading: boolean;
  isFetching: boolean;
  error: unknown;
  isError: boolean;
}

export default function useMyTeamSkillsQuery(): IUseMyTeamSkillsQuery {
  const { data, isLoading, isFetching, error, isError } = useQuery(
    ['queryMyTeamSkills'],
    async () => {
      const result = await queryMyTeamSkills();
      const skills: AssignedSkills = [];
      const { members } = result.getOwnUser.teamMembers;
      members.forEach((member) => {
        const { name, profilePicture, id } = member;
        const { assignments } = member.skillAssignments;
        assignments.forEach((skill) => {
          const { name: skillName, lvl: skillLvl, updated: skillDate, type, langCode } = skill;
          const skillFlag = type === 'LANGUAGE_SKILL' ? langCode : '';
          if (skill.status === ReviewOutcome.InProgress) {
            skills.push({
              name,
              id,
              avatar: profilePicture,
              skillName,
              skillLvl,
              skillDate,
              skillFlag,
              skillType: type,
              select: '',
              skillId: `${id}_${skillName}`,
            });
          }
        });
      });
      return skills;
    },
    {
      staleTime: 1,
    }
  );
  const skills = data || undefined;
  return { skills, isLoading, isFetching, error, isError };
}
