/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Typography, Button as AntButton, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import { InfoCircleOutlined } from '@ant-design/icons';

import { ThemeType } from 'components/ThemeProvider';

import { queries } from 'shared/layout';
import { ContractTypeEnum } from 'API';

import {
  selectMyPtoCurrent,
  selectMyHasUnlimitedPto,
  selectMyPtoCarryover,
  selectMyPtoLeft,
  selectMyPtoAllowance,
  selectMyContractType,
} from '../redux/timesOffSlice';
import {
  CarryoverTooltipContent,
  DefaultTooltipContent,
  LoadingStateTooltipContent,
  StackingUOPTooltipContent,
  UnlimitedPtoTooltipContent,
} from './PtoTooltips';

const Text = styled(Typography.Text)`
  font: ${({ theme }: { theme: ThemeType }) => theme.typography.body};
`;

const ButtonLink = styled.a`
  font: ${({ theme }) => theme.typography.button.font};
  letter-spacing: ${({ theme }) => theme.typography.button.letterSpacing};
  text-transform: ${({ theme }) => theme.typography.button.textTransform};
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.button.linear};
  border: none;
  &:hover,
  &:focus {
    background: ${({ theme }) => theme.colors.button.linearHover};
  }

  &:active {
    background: ${({ theme }) => theme.colors.button.linearPressed};
  }
`;

const CalendarHead = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  padding: 16px;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.white};
  align-items: center;
  gap: 4px;

  ${queries.tablet} {
    grid-template-columns: repeat(4, auto);
    justify-content: flex-end;
    padding: 32px;
    gap: 16px;
  }

  .avatar-divider {
    height: 2em;
  }

  .info-icon,
  .avatar-divider {
    display: none;

    ${queries.tablet} {
      display: inline-block;
    }
  }
`;

const DaysOffWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const IconCircleButton = styled(AntButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2px;
  color: ${({ theme }) => theme.colors.primary};
  background-color: transparent;
  &:hover {
    background: ${({ theme }) => theme.colors.neutral['400']};
  }

  &:active,
  &:focus {
    background: ${({ theme }) => theme.colors.neutral['500']};
  }
`;

const TooltipContent = () => {
  const ptoCarryover = useSelector(selectMyPtoCarryover);
  const ptoCurrent = useSelector(selectMyPtoCurrent);
  const ptoAllowance = useSelector(selectMyPtoAllowance);
  const hasUnlimitedPto = useSelector(selectMyHasUnlimitedPto);
  const contractType = useSelector(selectMyContractType);

  if (hasUnlimitedPto) {
    return <UnlimitedPtoTooltipContent />;
  }
  if (!ptoCurrent && ptoCurrent !== 0) {
    return <LoadingStateTooltipContent />;
  }

  if (!ptoCurrent && ptoCurrent !== 0) {
    return <LoadingStateTooltipContent />;
  }

  if ('shortName' in contractType && contractType.shortName === ContractTypeEnum.Uop) {
    if ('value' in ptoAllowance && ptoAllowance.value && ptoCurrent < ptoAllowance.value) {
      return <StackingUOPTooltipContent />;
    }

    if (ptoCarryover && ptoCarryover > 0) {
      return <CarryoverTooltipContent />;
    }
  }

  return <DefaultTooltipContent />;
};

const Toolbar = (): JSX.Element => {
  const { t } = useTranslation();
  const ptoLeft = useSelector(selectMyPtoLeft);
  const hasUnlimitedPto = useSelector(selectMyHasUnlimitedPto);
  const getPTOLeft = (pto: number | null): number | string => {
    if (hasUnlimitedPto) return '∞';
    return pto ?? '?';
  };

  const ptoText =
    getPTOLeft(ptoLeft as number | null) === 1 || getPTOLeft(ptoLeft as number | null) === -1
      ? t('time-off.day-left', { daysLeft: getPTOLeft(ptoLeft as number | null) })
      : t('time-off.days-left', { daysLeft: getPTOLeft(ptoLeft as number | null) });

  return (
    <CalendarHead>
      <DaysOffWrapper>
        <Text>{ptoText}</Text>
        <Tooltip
          overlayInnerStyle={{
            padding: '0',
          }}
          color="#100A23"
          placement="bottom"
          title={<TooltipContent />}
        >
          <IconCircleButton type="text" shape="circle">
            <InfoCircleOutlined />
          </IconCircleButton>
        </Tooltip>
      </DaysOffWrapper>

      <ButtonLink
        as={AntButton}
        type="primary"
        size="large"
        href="https://10clouds.atlassian.net/servicedesk/customer/portal/6/group/18/create/82"
        target="_blank"
        rel="noreferrer"
      >
        {t('time-off.request-time-off')}
      </ButtonLink>
    </CalendarHead>
  );
};

export default Toolbar;
