export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
};

export type AssignSkillsErrorResponse = {
  __typename?: 'AssignSkillsErrorResponse';
  errors: Array<SkillErrors>;
  owner?: Maybe<PrivateUser>;
  skillsAssignments?: Maybe<Array<SkillAssignmentRequest>>;
};

export type AssignSkillsResponse = AssignSkillsErrorResponse | AssignSkillsSuccessResponse;

export type AssignSkillsSuccessResponse = {
  __typename?: 'AssignSkillsSuccessResponse';
  owner: PrivateUser;
  skillsAssignments?: Maybe<Array<SkillAssignmentRequest>>;
};

export type BiError = Error & ExternalServiceError & {
  __typename?: 'BIError';
  message: Scalars['String'];
};

export type BoolValue = {
  __typename?: 'BoolValue';
  value: Scalars['Boolean'];
};

export type ContactInformationInput = {
  employeeLocation: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
  workingHours: Scalars['String'];
};

export type ContractType = {
  __typename?: 'ContractType';
  shortName: ContractTypeEnum;
};

export enum ContractTypeEnum {
  B2B = 'B2B',
  CLevel = 'C_LEVEL',
  Uod = 'UOD',
  Uop = 'UOP',
  Uz = 'UZ'
}

export type ContractTypeResponse = ContractType | SalesforceError | UnexpectedError;

export type CreateFavouriteErrorResponse = {
  __typename?: 'CreateFavouriteErrorResponse';
  createdFavouriteId?: Maybe<Scalars['ID']>;
  errors: Array<FavouriteErrors>;
  owner?: Maybe<PrivateUser>;
};

export type CreateFavouriteResponse = CreateFavouriteErrorResponse | CreateFavouriteSuccessResponse;

export type CreateFavouriteSuccessResponse = {
  __typename?: 'CreateFavouriteSuccessResponse';
  createdFavouriteId: Scalars['ID'];
  owner: PrivateUser;
};

export type CustomFilterInput = {
  iDepartment?: InputMaybe<Array<Scalars['String']>>;
  iName?: InputMaybe<Scalars['String']>;
  iRole?: InputMaybe<Array<Scalars['String']>>;
  iSeniority?: InputMaybe<Array<Scalars['String']>>;
  iTechnicalRole?: InputMaybe<Array<Scalars['String']>>;
};

export type DeleteFavouriteErrorResponse = {
  __typename?: 'DeleteFavouriteErrorResponse';
  deletedFavouriteId?: Maybe<Scalars['ID']>;
  errors: Array<FavouriteErrors>;
  owner?: Maybe<PrivateUser>;
};

export type DeleteFavouriteResponse = DeleteFavouriteErrorResponse | DeleteFavouriteSuccessResponse;

export type DeleteFavouriteSuccessResponse = {
  __typename?: 'DeleteFavouriteSuccessResponse';
  deletedFavouriteId: Scalars['ID'];
  owner: PrivateUser;
};

export type DuplicatedSkillNameError = Error & {
  __typename?: 'DuplicatedSkillNameError';
  message: Scalars['String'];
};

export type EditContactInfoErrorResponse = {
  __typename?: 'EditContactInfoErrorResponse';
  errors: Array<EditContactInfoErrors>;
  user?: Maybe<PrivateUser>;
};

export type EditContactInfoErrors = SlackError | UnexpectedError | UserNotFoundError;

export type EditContactInfoResponse = EditContactInfoErrorResponse | EditContactInfoSuccessResponse;

export type EditContactInfoSuccessResponse = {
  __typename?: 'EditContactInfoSuccessResponse';
  user: PrivateUser;
};

export type EditDeleteErrors = ReviewError | SkillAssignmentNotFoundError | SkillNotFoundError | UnexpectedError | UserNotFoundError;

export type EditDeleteSkillErrorResponse = {
  __typename?: 'EditDeleteSkillErrorResponse';
  errors: Array<EditDeleteErrors>;
  owner?: Maybe<PrivateUser>;
  skill?: Maybe<Skill>;
};

export type EditDeleteSkillResponse = EditDeleteSkillErrorResponse | EditDeleteSkillSuccessResponse;

export type EditDeleteSkillSuccessResponse = {
  __typename?: 'EditDeleteSkillSuccessResponse';
  owner: PrivateUser;
  skill?: Maybe<Skill>;
};

export type Error = {
  message: Scalars['String'];
};

export type ExcludedIdsInput = {
  excludedIds: Array<Scalars['String']>;
};

export type ExternalServiceError = {
  message: Scalars['String'];
};

export type FavouriteErrors = UnexpectedError | UserNotFoundError;

export type HadAnnualVacationLeaveResponse = BoolValue | JiraError | UnexpectedError;

export type IntValue = {
  __typename?: 'IntValue';
  value?: Maybe<Scalars['Int']>;
};

export type JiraError = Error & ExternalServiceError & {
  __typename?: 'JiraError';
  message: Scalars['String'];
};

export type LanguageInput = {
  languageNameAndLvls?: InputMaybe<Array<NameAndLvlsInput>>;
};

export type ListIdsInput = {
  usersIds: Array<Scalars['String']>;
};

export type MultipleUsersFound = Error & {
  __typename?: 'MultipleUsersFound';
  id: Scalars['ID'];
  message: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  approveSkillAssignments: ReviewSkillsResponse;
  assignSkillsToUser: AssignSkillsResponse;
  createFavouriteUser: CreateFavouriteResponse;
  deleteFavouriteUser: DeleteFavouriteResponse;
  deleteSkillAssignmentFromUser: EditDeleteSkillResponse;
  /** Sets/edits user's contact info and always marks user's onboardingComplete status to True */
  editContactInformation: EditContactInfoResponse;
  editSkillAssignmentOfUser: EditDeleteSkillResponse;
  rejectSkillAssignments: ReviewSkillsResponse;
};


export type MutationApproveSkillAssignmentsArgs = {
  input: Array<SkillReviewInput>;
};


export type MutationAssignSkillsToUserArgs = {
  nameLvlList: Array<NameLvlInput>;
};


export type MutationCreateFavouriteUserArgs = {
  favouritedId: Scalars['String'];
  ownerId: Scalars['String'];
};


export type MutationDeleteFavouriteUserArgs = {
  favouritedId: Scalars['String'];
  ownerId: Scalars['String'];
};


export type MutationDeleteSkillAssignmentFromUserArgs = {
  skillName: Scalars['String'];
};


export type MutationEditContactInformationArgs = {
  input: ContactInformationInput;
};


export type MutationEditSkillAssignmentOfUserArgs = {
  lvl: Scalars['String'];
  skillName: Scalars['String'];
};


export type MutationRejectSkillAssignmentsArgs = {
  input: Array<SkillReviewInput>;
};

export type NameAndLvlsInput = {
  lvls?: InputMaybe<Array<Scalars['String']>>;
  name: Scalars['String'];
};

export type NameLvlInput = {
  lvl: Scalars['String'];
  name: Scalars['String'];
};

export type NonTeamLeadError = Error & {
  __typename?: 'NonTeamLeadError';
  id: Scalars['ID'];
  message: Scalars['String'];
};

export type NonUnlimitedPtoLeftResult = {
  ptoCurrent: Scalars['Float'];
  ptoLeft: Scalars['Float'];
  ptoUsed: Scalars['Float'];
};

export type OffsetPaginationInput = {
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
};

export enum OrderingType {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING'
}

export type PtoAllowanceResponse = IntValue | SalesforceError | UnexpectedError;

export type PtoLeftResponse = BiError | JiraError | PtoLeftResult | SalesforceError | UopptoLeftResult | UnexpectedError | UnlimitedPtoLeftResult;

export type PtoLeftResult = NonUnlimitedPtoLeftResult & PtoLeftResultBase & {
  __typename?: 'PTOLeftResult';
  ptoCurrent: Scalars['Float'];
  ptoLeft: Scalars['Float'];
  ptoUsed: Scalars['Float'];
};

export type PtoLeftResultBase = {
  ptoUsed: Scalars['Float'];
};

export enum PtoStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING'
}

export type PrivateTimeOffRecord = TimeOffRecord & {
  __typename?: 'PrivateTimeOffRecord';
  date: Scalars['Date'];
  id: Scalars['ID'];
  jiraTicketLink: Scalars['String'];
  resolution?: Maybe<Scalars['String']>;
  status: PtoStatus;
  subtype?: Maybe<Scalars['String']>;
  type: TimeOffType;
};

export type PrivateTimeOffRecordsResult = {
  __typename?: 'PrivateTimeOffRecordsResult';
  timeOffs: Array<PrivateTimeOffRecord>;
};

export type PrivateUser = User & {
  __typename?: 'PrivateUser';
  active: Scalars['Boolean'];
  contractType: ContractTypeResponse;
  department?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  employeeLocation?: Maybe<Scalars['String']>;
  favouriteUsers: Array<Maybe<User>>;
  hadAnnualVacationLeaveUop: HadAnnualVacationLeaveResponse;
  hasUnlimitedPto: UnlimitedPtoResponse;
  id: Scalars['ID'];
  isTeamLead: Scalars['Boolean'];
  name: Scalars['String'];
  /** This is always set to True on user's data update */
  onboardingCompleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  ptoAllowance: PtoAllowanceResponse;
  ptoLeft?: Maybe<PtoLeftResponse>;
  role?: Maybe<Scalars['String']>;
  seniority?: Maybe<Scalars['String']>;
  seniorityLevel?: Maybe<Scalars['String']>;
  skillAssignments: SkillsAssignmentsResponse;
  slackId?: Maybe<Scalars['String']>;
  team?: Maybe<Scalars['String']>;
  teamMembers: TeamMembersResponse;
  technicalRole?: Maybe<Scalars['String']>;
  timeOffDates: TimeOffDatesResponse;
  userProjects: UserProjectsResponse;
  workingHours?: Maybe<Scalars['String']>;
};


export type PrivateUserFavouriteUsersArgs = {
  input?: InputMaybe<OffsetPaginationInput>;
};


export type PrivateUserTeamMembersArgs = {
  input?: InputMaybe<OffsetPaginationInput>;
};


export type PrivateUserTimeOffDatesArgs = {
  after: Scalars['Date'];
  before: Scalars['Date'];
};


export type PrivateUserUserProjectsArgs = {
  after: Scalars['Date'];
  before: Scalars['Date'];
};

export type PrivateUserResponse = MultipleUsersFound | PrivateUser | UnexpectedError | UserNotFoundError;

export type PublicTimeOffRecord = TimeOffRecord & {
  __typename?: 'PublicTimeOffRecord';
  date: Scalars['Date'];
  id: Scalars['ID'];
  jiraTicketLink: Scalars['String'];
  status: PtoStatus;
};

export type PublicTimeOffRecordsResult = {
  __typename?: 'PublicTimeOffRecordsResult';
  timeOffs: Array<PublicTimeOffRecord>;
};

export type PublicUser = User & {
  __typename?: 'PublicUser';
  active: Scalars['Boolean'];
  department?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  employeeLocation?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isTeamLead: Scalars['Boolean'];
  name: Scalars['String'];
  /** This is always set to True on user's data update */
  onboardingCompleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  seniority?: Maybe<Scalars['String']>;
  seniorityLevel?: Maybe<Scalars['String']>;
  skillAssignments: SkillsAssignmentsResponse;
  slackId?: Maybe<Scalars['String']>;
  team?: Maybe<Scalars['String']>;
  technicalRole?: Maybe<Scalars['String']>;
  timeOffDates: TimeOffDatesResponse;
  userProjects: UserProjectsResponse;
  workingHours?: Maybe<Scalars['String']>;
};


export type PublicUserTimeOffDatesArgs = {
  after: Scalars['Date'];
  before: Scalars['Date'];
};


export type PublicUserUserProjectsArgs = {
  after: Scalars['Date'];
  before: Scalars['Date'];
};

export type PublicUserResponse = MultipleUsersFound | PublicUser | UnexpectedError | UserNotFoundError;

export type Query = {
  __typename?: 'Query';
  getOwnUser: PrivateUserResponse;
  getSkills: SkillsResponse;
  getSkillsWithAssignments: SkillsAssignmentResponse;
  getUser: PublicUserResponse;
  hasSkillsToReview: BoolValue;
  listUsers: Array<PublicUser>;
};


export type QueryGetSkillsArgs = {
  type?: InputMaybe<Scalars['String']>;
};


export type QueryGetSkillsWithAssignmentsArgs = {
  filters?: InputMaybe<SkillFilter>;
  ordering?: InputMaybe<SkillsOrderInput>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryGetUserArgs = {
  id: Scalars['String'];
};


export type QueryListUsersArgs = {
  filters?: InputMaybe<UserFilter>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};

export type ReviewError = Error & {
  __typename?: 'ReviewError';
  message: Scalars['String'];
};

export type ReviewErrors = ReviewError | UnexpectedError;

export enum ReviewOutcome {
  Approved = 'APPROVED',
  InProgress = 'IN_PROGRESS',
  Rejected = 'REJECTED'
}

export type ReviewSkillsErrorResponse = {
  __typename?: 'ReviewSkillsErrorResponse';
  errors: Array<ReviewErrors>;
  reviewOutcome: ReviewOutcome;
  users: Array<PrivateUser>;
};

export type ReviewSkillsResponse = ReviewSkillsErrorResponse | ReviewSkillsSuccessResponse;

export type ReviewSkillsSuccessResponse = {
  __typename?: 'ReviewSkillsSuccessResponse';
  reviewOutcome: ReviewOutcome;
  users: Array<PrivateUser>;
};

export type SalesforceError = Error & ExternalServiceError & {
  __typename?: 'SalesforceError';
  message: Scalars['String'];
};

export type SeniorityInput = {
  seniority?: InputMaybe<Array<Scalars['String']>>;
};

export type Skill = {
  __typename?: 'Skill';
  categories: Array<Scalars['String']>;
  id: Scalars['ID'];
  languageCode: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type SkillAssignmentNotFoundError = Error & {
  __typename?: 'SkillAssignmentNotFoundError';
  message: Scalars['String'];
  name: Scalars['String'];
};

export type SkillAssignmentRequest = {
  __typename?: 'SkillAssignmentRequest';
  id: Scalars['ID'];
  lastModified: Scalars['Date'];
  lvl: Scalars['String'];
  owner: User;
  skill: Skill;
};

export type SkillAssignmentResponse = {
  __typename?: 'SkillAssignmentResponse';
  langCode: Scalars['String'];
  lvl: Scalars['String'];
  name: Scalars['String'];
  status?: Maybe<ReviewOutcome>;
  type: Scalars['String'];
  updated: Scalars['Date'];
};

export type SkillErrors = DuplicatedSkillNameError | SkillNotFoundError | UnexpectedError | UserNotFoundError;

export type SkillFilter = {
  languageFilter?: InputMaybe<LanguageInput>;
  seniorityFilter?: InputMaybe<SeniorityInput>;
  skillsFilter?: InputMaybe<SkillFilterInputAssignments>;
  technicalRoleFilter?: InputMaybe<TechnicalRoleInput>;
};

export type SkillFilterInputAssignments = {
  iSkillLvls?: InputMaybe<Array<NameAndLvlsInput>>;
};

export enum SkillMatrixField {
  Seniority = 'SENIORITY',
  SkillLvl = 'SKILL_LVL',
  SkillName = 'SKILL_NAME',
  TechnicalRole = 'TECHNICAL_ROLE',
  UserName = 'USER_NAME'
}

export type SkillNotFoundError = Error & {
  __typename?: 'SkillNotFoundError';
  message: Scalars['String'];
  name: Scalars['String'];
};

export type SkillReviewInput = {
  skillNames: Array<Scalars['String']>;
  userId: Scalars['String'];
};

export type SkillsAssignmentResponse = {
  __typename?: 'SkillsAssignmentResponse';
  currentPage: Scalars['Int'];
  pageSize: Scalars['Int'];
  skillsAssignments: Array<SkillsWithAssignments>;
  totalPages: Scalars['Int'];
};

export type SkillsAssignmentsResponse = {
  __typename?: 'SkillsAssignmentsResponse';
  assignments: Array<SkillAssignmentResponse>;
};

export type SkillsOrderInput = {
  field: SkillMatrixField;
  order: OrderingType;
};

export type SkillsResponse = {
  __typename?: 'SkillsResponse';
  skills: Array<Skill>;
};

export type SkillsWithAssignments = {
  __typename?: 'SkillsWithAssignments';
  entryKey: Scalars['ID'];
  languageCode: Scalars['String'];
  name: Scalars['String'];
  ownerId: Scalars['String'];
  profilePicture: Scalars['String'];
  seniority: Scalars['String'];
  skillLvl: Scalars['String'];
  skillName: Scalars['String'];
  skillType: Scalars['String'];
  technicalRole: Scalars['String'];
};

export type SlackError = Error & ExternalServiceError & {
  __typename?: 'SlackError';
  message: Scalars['String'];
};

export type TeamMembersResponse = NonTeamLeadError | TeamMembersResult | UnexpectedError | UserNotFoundError;

export type TeamMembersResult = {
  __typename?: 'TeamMembersResult';
  members: Array<Maybe<PrivateUser>>;
};

export type TechnicalRoleInput = {
  technicalRole?: InputMaybe<Array<Scalars['String']>>;
};

export type TimeOffDatesResponse = JiraError | PrivateTimeOffRecordsResult | PublicTimeOffRecordsResult | SalesforceError | UnexpectedError;

export type TimeOffRecord = {
  date: Scalars['Date'];
  id: Scalars['ID'];
  jiraTicketLink: Scalars['String'];
  status: PtoStatus;
};

export enum TimeOffType {
  Other = 'OTHER',
  Paid = 'PAID',
  Unpaid = 'UNPAID'
}

export type UopptoLeftResult = NonUnlimitedPtoLeftResult & PtoLeftResultBase & {
  __typename?: 'UOPPTOLeftResult';
  ptoCarryover: Scalars['Float'];
  ptoCurrent: Scalars['Float'];
  ptoLeft: Scalars['Float'];
  ptoUsed: Scalars['Float'];
};

export type UnexpectedError = Error & {
  __typename?: 'UnexpectedError';
  message: Scalars['String'];
};

export type UnlimitedPtoLeftResult = PtoLeftResultBase & {
  __typename?: 'UnlimitedPTOLeftResult';
  ptoUsed: Scalars['Float'];
};

export type UnlimitedPtoResponse = BoolValue | SalesforceError | UnexpectedError;

export type User = {
  active: Scalars['Boolean'];
  department?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  employeeLocation?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isTeamLead: Scalars['Boolean'];
  name: Scalars['String'];
  /** This is always set to True on user's data update */
  onboardingCompleted?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  seniority?: Maybe<Scalars['String']>;
  seniorityLevel?: Maybe<Scalars['String']>;
  skillAssignments: SkillsAssignmentsResponse;
  slackId?: Maybe<Scalars['String']>;
  team?: Maybe<Scalars['String']>;
  technicalRole?: Maybe<Scalars['String']>;
  timeOffDates: TimeOffDatesResponse;
  userProjects: UserProjectsResponse;
  workingHours?: Maybe<Scalars['String']>;
};


export type UserTimeOffDatesArgs = {
  after: Scalars['Date'];
  before: Scalars['Date'];
};


export type UserUserProjectsArgs = {
  after: Scalars['Date'];
  before: Scalars['Date'];
};

export type UserFilter = {
  customFilter?: InputMaybe<CustomFilterInput>;
  excludeUsers?: InputMaybe<ExcludedIdsInput>;
  listUsers?: InputMaybe<ListIdsInput>;
};

export type UserNotFoundError = Error & {
  __typename?: 'UserNotFoundError';
  id: Scalars['ID'];
  message: Scalars['String'];
};

export type UserProject = {
  __typename?: 'UserProject';
  active: Scalars['Boolean'];
  currentLoggedHours: Scalars['Float'];
  entryKey: Scalars['ID'];
  jiraUrl?: Maybe<Scalars['String']>;
  lead?: Maybe<Scalars['ID']>;
  projectName: Scalars['String'];
  projectStatus: Scalars['String'];
  projectUrl: Scalars['String'];
  totalEstimatedHours: Scalars['Float'];
  userId: Scalars['ID'];
  weeklyHours: Scalars['Float'];
};

export type UserProjectsResponse = SalesforceError | UnexpectedError | UserProjectsResult;

export type UserProjectsResult = {
  __typename?: 'UserProjectsResult';
  projects: Array<UserProject>;
};
