import React, { useState } from 'react';
import styled from 'styled-components';

import TopHeader from 'components/TopHeader';

import Welcome from './components/Welcome/Welcome';
import Success from './components/Success/Success';
import UserDetails from './components/UserDetails/UserDetails';

const Container = styled.div`
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.background};
`;
const Onboarding = (): JSX.Element => {
  const [currentPage, setCurrentPage] = useState<number>(0);

  return (
    <Container>
      <TopHeader variant="onboarding" />
      {currentPage === 0 && <Welcome setCurrentPage={setCurrentPage} />}
      {currentPage === 1 && <UserDetails setCurrentPage={setCurrentPage} />}
      {currentPage === 2 && <Success />}
    </Container>
  );
};

export default Onboarding;
