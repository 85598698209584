type Role = string | undefined | null;
type ReturnType = boolean;

export const areRolesNotEqual = (employeeRole: Role, technicalRole: Role): ReturnType => {
  if (!employeeRole || !technicalRole) {
    return false;
  }
  return employeeRole.toLowerCase() !== technicalRole.toLowerCase();
};

export const shouldRenderTechnicalRole = (employeeRole: Role, technicalRole: Role): ReturnType => {
  if (!employeeRole || !technicalRole) {
    return false;
  }
  if (technicalRole !== 'Product Delivery' && technicalRole !== 'Other') {
    if (employeeRole !== 'QA (Automation)' && employeeRole !== 'QA (manual)') {
      return true;
    }
  }
  return false;
};

export const shouldRenderSalesforceLink = (employeeRole: Role, technicalRole: Role): ReturnType =>
  (employeeRole && technicalRole && employeeRole === 'Head') ||
  employeeRole === 'Product Delivery Manager' ||
  employeeRole === 'Pre-Sales' ||
  employeeRole === 'Assigment Manager' ||
  technicalRole === 'Sales';
