import React, { ReactChild } from 'react';
import styled from 'styled-components';
import { format, parseISO } from 'date-fns';
import { Popover } from 'antd';
import { useTranslation } from 'react-i18next';

import { workingDaysBetweenDates } from 'features/TimeOff/utils/dateUtils';
import { TimeOffType } from 'API';
import { TimeOff } from 'features/TimeOff/helpers/mergeTimeOffRecords';

import {
  Heading,
  JiraLink,
  JiraTicket,
  StatusIndicator,
  StatusText,
  TextContent,
} from '../TimeOffPopoverStyles';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  max-width: 190px;
  color: ${({ theme }) => theme.colors.text.primary};
  padding: 2px 0;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
`;

const Status = styled.div`
  display: flex;
  align-items: center;
`;

const Dates = styled.div`
  margin: 16px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  align-items: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const getDate = (date: Date) => {
  return format(date, 'dd/MMM/yyyy');
};

interface ITimeOffPopoverContentProps {
  timeOff: TimeOff;
  daysOffCount: number;
}

const TimeOffPopoverContent = ({ timeOff, daysOffCount }: ITimeOffPopoverContentProps) => {
  const { t } = useTranslation();

  const getTimeOffType = (dayOff: TimeOff): string => {
    if (dayOff.type === TimeOffType.Unpaid) return t('time-off.unpaid');
    if (dayOff.type === TimeOffType.Other) return t('time-off.other');
    if (dayOff.type === TimeOffType.Paid) return t('time-off.paid');

    return t('time-off.timeOff');
  };

  const getTimeOffTypeShorthand = (dayOff: TimeOff): string => {
    if (dayOff.type === TimeOffType.Unpaid) return t('time-off.uto');
    if (dayOff.type === TimeOffType.Other) return t('time-off.otf');
    if (dayOff.type === TimeOffType.Paid) return t('time-off.pto');

    return '';
  };

  return (
    <Wrapper>
      <Status>
        <StatusIndicator timeOff={timeOff} />
        <StatusText>{getTimeOffType(timeOff)}</StatusText>
      </Status>
      {timeOff.subtype && (
        <Column style={{ marginTop: '20px' }}>
          <Heading>{t('time-off.type')}</Heading>
          <TextContent>{timeOff.subtype}</TextContent>
        </Column>
      )}

      <Dates>
        <Column>
          <Heading>{t('time-off.start-date')}</Heading>
          <TextContent>{getDate(new Date(timeOff.startDate))}</TextContent>
        </Column>
        <Column>
          <Heading>{t('time-off.end-date')}</Heading>
          <TextContent>{getDate(new Date(timeOff.endDate))}</TextContent>
        </Column>
      </Dates>
      <Column>
        <Heading>{t('time-off.total')}</Heading>
        <TextContent>
          {daysOffCount === 1
            ? `${daysOffCount} ${t('time-off.day-off')} ${getTimeOffTypeShorthand(timeOff)}`
            : `${daysOffCount} ${t('time-off.days-off')} ${getTimeOffTypeShorthand(timeOff)}`}
        </TextContent>
      </Column>
      {timeOff.jiraTicketLink && (
        <JiraTicket>
          <Heading>{t('time-off.more')}</Heading>

          <JiraLink href={timeOff.jiraTicketLink}>{t('time-off.go-to-jsd')}</JiraLink>
        </JiraTicket>
      )}
    </Wrapper>
  );
};

interface ITimeOffPopoverProps {
  timeOff: TimeOff;
  children: ReactChild;
}

const TimeOffPopover = ({ timeOff, children }: ITimeOffPopoverProps): JSX.Element => {
  const daysOffCount = workingDaysBetweenDates(
    parseISO(timeOff.startDate),
    parseISO(timeOff.endDate)
  );

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const getParentEl = (node: HTMLElement) => node.parentElement!;

  return (
    <Popover
      className="timeOffPopover"
      content={<TimeOffPopoverContent timeOff={timeOff} daysOffCount={daysOffCount} />}
      trigger="click"
      placement="bottom"
      getPopupContainer={(node) => getParentEl(node)}
    >
      {children}
    </Popover>
  );
};

export default TimeOffPopover;
