import styled from 'styled-components';
import { Radio } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';

import { ThemeProps } from 'components/ThemeProvider/ThemeProvider';

export const LevelContainer = styled('div')`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  border: 1px solid ${({ theme }: ThemeProps) => theme.colors.pagination.primary};
  border-radius: 4px;
  margin-bottom: 4px;
  width: 340px;

  &:hover {
    border-color: ${({ theme }: ThemeProps) => theme.colors.secondary['200']};
    cursor: pointer;
  }
  &:hover .ant-radio-inner {
    border-color: ${({ theme }: ThemeProps) => theme.colors.secondary['200']};
  }
  &.active {
    border-color: ${({ theme }: ThemeProps) => theme.colors.secondary['200']} !important;
    background-color: ${({ theme }: ThemeProps) => theme.colors.background} !important;
  }
`;

export const LevelButtons = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 16px 16px;
  gap: 6px;
  border-radius: 4px;
  width: 340px;
`;

export const LevelButtonHeader = styled('div')`
  display: flex;
  align-items: center;
  padding: 0px;
  gap: 8px;
  user-select: none;
`;

export const InputRadio = styled(Radio)`
  color: ${({ theme }: ThemeProps) => theme.colors.text.secondary};
  width: 20px;
  height: 20px;
  margin-right: 0px !important;

  .ant-radio-checked .ant-radio-inner::after {
    background-color: ${({ theme }: ThemeProps) => theme.colors.secondary['200']};
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: ${({ theme }: ThemeProps) => theme.colors.secondary['200']};
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: ${({ theme }: ThemeProps) => theme.colors.secondary['200']};
  }
`;

export const InputCheckbox = styled(Checkbox)`
  color: ${({ theme }: ThemeProps) => theme.colors.text.secondary};
  width: 20px;
  height: 20px;
  margin-right: 0px !important;

  .ant-checkbox-inner {
    transition: 0.2s ease-in-out;
    border: 2px solid ${({ theme }) => theme.colors.checkbox.primary};
    ::after {
      left: 20%;
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.colors.text.overline};
    border-color: ${({ theme }) => theme.colors.text.overline};
  }

  &.ant-checkbox-wrapper {
    span {
      transition: 0.2s ease-in-out;
      text-transform: capitalize;
      color: ${({ theme }) => theme.colors.text.secondary};
    }
  }

  &.ant-checkbox-wrapper-checked {
    span {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  &:hover .ant-checkbox .ant-checkbox-inner {
    border-color: ${({ theme }) => theme.colors.text.overline};
  }

  &:hover span {
    transition: 0.2s ease-in-out;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const LevelInfo = styled('div')`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0px 16px 15px;
  gap: 6px;
  border-radius: 4px;
`;

export const LevelData = styled('p')`
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  color: ${({ theme }: ThemeProps) => theme.colors.text.secondary};
  user-select: none;
`;
