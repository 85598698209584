import React from 'react';
import styled from 'styled-components';
import { Button as AntButton, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { ArrowRightOutlined } from '@ant-design/icons';

import SuccessCloud from 'assets/png/SuccessCloud.png';
import { useAuth } from 'providers/AuthProvider';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 200px;
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  gap: 24px;

  width: 408px;
  height: 446px;

  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 20px 40px -4px rgba(114, 114, 146, 0.12);
`;

const Title = styled(Typography.Title)`
  &.ant-typography {
    color: ${({ theme }) => theme.colors.primary};
    font: ${({ theme }) => theme.typography.heading};
    font-weight: 600;
    font-size: 27px;
    line-height: 40px;
    width: 340px;
    height: 40px;
    text-align: center;
    margin-bottom: 0px;
  }
`;

const Text = styled(Typography.Text)`
  &.ant-typography {
    color: ${({ theme }) => theme.colors.text.secondary};
    width: 340px;
    height: 40px;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.2px;
    text-align: center;
  }
`;

const TopContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
  gap: 32px;
  width: 344px;
  height: 304px;
`;

const TextGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 16px;

  width: 340px;
  height: 88px;
`;

const ButtonLink = styled.a`
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-transform: ${({ theme }) => theme.typography.button.textTransform};
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.button.linear};
  width: 340px;
  height: 54px;
  border: none;
  & span.anticon-arrow-right {
    position: absolute;
    display: block;
    top: 17px;
    font-size: 20px;
    right: 18px;
  }

  &:hover,
  &:focus {
    background: ${({ theme }) => theme.colors.button.linearHover};
  }

  &:active {
    background: ${({ theme }) => theme.colors.button.linearPressed};
  }
`;
const HorizontalLine = styled.div`
  border: 1px solid #e6e6ef;
  width: 100%;
`;

const Success = (): JSX.Element => {
  const { t } = useTranslation();
  const { getUser } = useAuth();

  const handleClick = async () => {
    await getUser();
  };

  return (
    <Container id="successPage">
      <Modal>
        <TopContent>
          <img src={SuccessCloud} alt="Success cloud" id="tenCShortLogo" />
          <TextGroup>
            <Title id="successPageTitle">{t('onboarding.success.title')}</Title>
            <Text id="successPageText">{t('onboarding.success.description')}</Text>
          </TextGroup>
          <HorizontalLine />
        </TopContent>
        <ButtonLink
          as={AntButton}
          type="primary"
          size="large"
          onClick={handleClick}
          id="successPageButton"
        >
          {t('onboarding.success.explore')}
          <ArrowRightOutlined />
        </ButtonLink>
      </Modal>
    </Container>
  );
};

export default Success;
