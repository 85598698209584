import styled from 'styled-components';
import { Typography } from 'antd';

export const SkillContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 340px;
  margin-bottom: 12px;
`;

export const SkillHeader = styled('div')`
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 16px;
  width: 340px;
  height: 54px;
  background: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.pagination.primary};
  border-radius: 4px 4px 0px 0px;
  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.colors.backgrounds.light.negative};
    border-color: ${({ theme }) => theme.colors.text.negative};
    svg path {
      fill: ${({ theme }) => theme.colors.text.negative};
    }
  }
`;

export const SkillHeaderText = styled(Typography.Title)`
  &.ant-typography {
    font-size: 15px;
    line-height: 22px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const SkillData = styled('div')`
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 16px;
  width: 340px;
  height: 54px;
  border: 1px solid ${({ theme }) => theme.colors.pagination.primary};
  border-radius: 0px 0px 4px 4px;
  &:hover {
    cursor: pointer;
    border-color: ${({ theme }) => theme.colors.text.overline};
    div:last-child svg path {
      fill: ${({ theme }) => theme.colors.text.overline};
    }
  }
`;

export const LevelDataContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const DataContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
  text-transform: lowercase;
  span:first-letter {
    text-transform: uppercase;
  }
`;
