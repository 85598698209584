const style = {
  favoritesList: {
    width: 341, // with border
  },
  groupName: {
    height: 48,
  },
  calendarHead: {
    height: 104,
  },
  monthNames: {
    height: 32,
  },
  dayHead: {
    width: 42,
    height: 47,
  },
  listWrapper: {
    marginTop: 24,
  },
  timeOffRow: {
    height: 64,
  },
  timeOffBar: {
    height: 36,
    top: 16,
  },
  highlight: {
    animationDuration: 1000,
  },
};

export default style;
