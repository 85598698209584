import generateGraphqlCaller from 'utils/helpers/generateGraphqlCaller';
import { SkillsAssignmentResponse, QueryGetSkillsWithAssignmentsArgs } from 'API';

const query = /* GraphQL */ `
  query GetSkillsWithAssignments(
    $filters: SkillFilter
    $ordering: SkillsOrderInput
    $pagination: OffsetPaginationInput
  ) {
    getSkillsWithAssignments(filters: $filters, ordering: $ordering, pagination: $pagination) {
      pageSize
      currentPage
      totalPages
      skillsAssignments {
        entryKey
        languageCode
        name
        ownerId
        profilePicture
        seniority
        skillLvl
        skillName
        skillType
        technicalRole
      }
    }
  }
`;

type ReturnType = { getSkillsWithAssignments: SkillsAssignmentResponse };

export default generateGraphqlCaller<ReturnType, QueryGetSkillsWithAssignmentsArgs>(query);
