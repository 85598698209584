import styled from 'styled-components';

import { ThemeProps } from 'components/ThemeProvider/ThemeProvider';

type ColumnContainerProps = {
  language?: boolean;
};

export const ColumnContainer = styled('div')<ColumnContainerProps>`
  display: flex;
  flex-direction: column;
  max-height: 486px;
  padding: 0px 32px;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const LeftArrow = styled('div')`
  width: 24px;
  height: 24px;
  margin: 0px;
  padding-top: 2px;

  &:hover {
    cursor: pointer;
  }
`;

export const HeaderTitle = styled('h1')`
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
  margin: 0px;
  color: ${({ theme }: ThemeProps) => theme.colors.text.primary};
`;

export const Header = styled('div')`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const SubHeader = styled('div')`
  display: flex;
  padding: 0px;
  gap: 8px;
`;
export const SkillSubHeader = styled(SubHeader)`
  justify-content: space-between;
  align-items: center;
`;

export const SubHeaderTitle = styled('h1')`
  font-size: 15px;
  font-weight: 400;
  margin-top: 2px;
`;
export const SubContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  margin-top: 26px;
  width: 340px;
`;
export const SubTitle = styled('h1')`
  font-size: 13px;
  line-height: 20px;
  margin: 0px;
  font-weight: 400;
  letter-spacing: 0.2px;
  color: ${({ theme }: ThemeProps) => theme.colors.text.primary};
`;
