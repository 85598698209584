import type { QueryGetUserArgs, PublicUser } from 'API';
import generateGraphqlCaller from 'utils/helpers/generateGraphqlCaller';

const query = `
query GetAssignedSkills($id: String!){
    getUser(id: $id) {
      ... on PublicUser {
        id
        skillAssignments {
          assignments {
            lvl
            name
            type
            updated
            langCode
            status
          }
        }
      }
      ... on UserNotFoundError {
        message
      }
      ... on MultipleUsersFound {
        message
      }
      ... on UnexpectedError {
        message
      }
      ... on Error {
        message
      }
    
  }
}`;

export type ReturnType = {
  getUser?: Pick<PublicUser, 'skillAssignments'>;
};

export type VariablesType = Pick<QueryGetUserArgs, 'id'>;

export default generateGraphqlCaller<ReturnType, VariablesType>(query);
