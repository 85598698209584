import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'redux/rootReducer';
import api from 'features/Profile/api';
import { UserProject } from 'API';
import { SEARCH_STATUS } from 'utils/helpers/searchStatus';

import { getCurrentWeekRange } from '../helpers/getCurrentWeekRange';

export type ProjectsType = Pick<
  UserProject,
  | 'lead'
  | 'projectStatus'
  | 'weeklyHours'
  | 'totalEstimatedHours'
  | 'currentLoggedHours'
  | 'projectName'
  | 'projectUrl'
  | 'jiraUrl'
>;

interface ProjectsState {
  searchStatus: SEARCH_STATUS;
  listProjects: ProjectsType[];
}

const initialState: ProjectsState = {
  searchStatus: SEARCH_STATUS.IDLE,
  listProjects: [],
};

const fetchProjectsList = createAsyncThunk('profile/fetchProjectsList', async (userId: string) => {
  const { startDate, endDate } = getCurrentWeekRange(new Date());
  const response = await api.searchProjectsList(userId, startDate, endDate);
  return response;
});

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProjectsList.pending, (state) => {
      return { ...state, listProjects: [], searchStatus: SEARCH_STATUS.LOADING };
    });

    builder.addCase(fetchProjectsList.fulfilled, (state, action: PayloadAction<ProjectsType[]>) => {
      return { ...state, listProjects: action.payload, searchStatus: SEARCH_STATUS.FOUND };
    });
  },
});

export const actions = {
  ...profileSlice.actions,
  fetchProjectsList,
};

export const selectFoundProjects = (state: RootState): ProjectsState => state.profile.projects;

export const selectSearchStatusProjects = (state: RootState): SEARCH_STATUS =>
  state.profile.projects.searchStatus;

export default profileSlice.reducer;
