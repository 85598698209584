import { SelectProps, SelectValue } from 'antd/lib/select';

export enum VARIANT {
  MAIN,
  TOP_BAR,
}
export type Variant = { variant?: VARIANT };
export type AutocompleteFilterProps = SelectProps<SelectValue> & Variant;

export type Option = NonNullable<AutocompleteFilterProps['options']>[number];
export type OnSelectCallback = AutocompleteFilterProps['onSelect'];
export type OnSearchCallback = AutocompleteFilterProps['onSearch'];

export type OptionElement = Option & {
  display?: string;
  filterData?: string;
};

export type AutocompleteOptionProps = {
  primaryLabel: string;
  secondaryLabel?: string;
};
