/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';

import { Count } from './PtoTooltips.styles';

export interface IDays {
  count: number | null | undefined;
  neutral?: boolean;
  unlimited?: boolean;
}

const Days = ({ count, neutral = false, unlimited = false }: IDays): JSX.Element => {
  const isNegative = count ? count < 0 : false;
  const isPositive = count ? count > 0 : false;
  if (!count && count !== 0) return <Count variant="neutral">?&nbsp;</Count>;
  if (unlimited) return <Count variant="positive">∞&nbsp;</Count>;
  if (isPositive && !neutral)
    return (
      <Count variant="positive">
        {count}
        &nbsp;
      </Count>
    );
  if (isNegative && !neutral)
    return (
      <Count variant="negative">
        {count}
        &nbsp;
      </Count>
    );

  return <Count variant="neutral">{count}&nbsp;</Count>;
};

export default Days;
