import styled, { css } from 'styled-components';
import { TabPanels, Tabs } from '@reach/tabs';

export const Section = styled.section`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 32px;
`;

export const SectionTitle = styled.h3`
  font: ${({ theme }) => theme.typography.paragraph.font};
  letter-spacing: ${({ theme }) => theme.typography.paragraph.letterSpacing};
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const UserAssignmentsSection = styled(Section)`
  flex: 1;
  align-self: stretch;
  max-width: 1205px;
`;

export const StyledTabs = styled(Tabs)`
  margin: 24px 0 0;

  [data-reach-tab-list] {
    background: none;
  }

  [data-reach-tab] {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 24px;
    padding: 0 0 16px;

    &:last-child {
      margin-right: 0;
    }

    &[data-selected] {
      border-bottom-color: ${({ theme }) => theme.colors.accents.darkA};
    }

    &:active {
      background: none;
    }
  }
`;

export const TabName = styled.div`
  font: ${({ theme }) => theme.typography.subtitles};
  margin-right: 8px;
`;

export const TabValue = styled.div<{ isSelected: boolean }>`
  font: ${({ theme }) => theme.typography.subtitles};
  background: ${({ theme }) => theme.colors.background};
  padding: 2px 8px;

  ${({ isSelected }) =>
    isSelected &&
    css`
      color: ${({ theme }) => theme.colors.white};
      background: ${({ theme }) => theme.colors.accents.darkA};
    `};
`;

export const StyledTabPanels = styled(TabPanels)`
  margin-top: 24px;
  overflow-y: auto;
`;

export const TableSpacing = styled.div`
  min-height: 690px;
`;

export const SFLink = styled.a`
  color: #727292;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  svg {
    fill: #727292 !important;
  }
`;
