import { combineReducers } from '@reduxjs/toolkit';

import timesOff from './timesOffSlice';
import calendarRange from './calendarRange';

const rootReducer = combineReducers({
  timesOff,
  calendarRange,
});
export default rootReducer;
