import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReactComponent as Beginner } from 'assets/Beginner.svg';
import { ReactComponent as Intermediate } from 'assets/Intermediate.svg';
import { ReactComponent as Expert } from 'assets/Expert.svg';
import { selectSkills, SkillChosen } from 'features/Profile/redux/skillSlice';
import { getLanguageFlag } from 'utils/helpers/getLanguageFlag';
import { getLanguageNames } from 'features/Profile/helpers/getLanguageNames';
import { SkillListEmptyState } from 'features/Profile/components/ProfileSkills/EmptyState/SkillListEmptyState';

import {
  SkillState,
  SkillTitle,
  SubSkillTitle,
  SkillTitleContainer,
  SubSkillTitleContainer,
} from './SkillList.styles';
import SkillSelected from '../SkillSelected';

interface SkillListProps {
  handleSkillEdit: (skill: string, language: string, level?: string) => void;
}

const SkillList = ({ handleSkillEdit }: SkillListProps): JSX.Element => {
  const { t } = useTranslation();
  const { listChosenSkills, skillModal, listAssignedSkills } = useSelector(selectSkills);
  const [chosenSkills, setChosenSkills] = useState<SkillChosen[]>([]);
  const getSkillLevelIcon = (skillLvl: string): JSX.Element => {
    if (skillLvl === 'EXPERT') {
      return <Expert />;
    }
    if (skillLvl === 'INTERMEDIATE') {
      return <Intermediate />;
    }
    return <Beginner />;
  };

  useEffect(() => {
    setChosenSkills(
      [...listChosenSkills.assignments]
        .sort((a, b) => a.name.localeCompare(b.name))
        .filter((skill) => {
          if (skillModal.content === 0) {
            return (
              skill.type === 'TECHNICAL_SKILL' && !listAssignedSkills.assignments.includes(skill)
            );
          }
          return skill.type === 'LANGUAGE_SKILL' && !listAssignedSkills.assignments.includes(skill);
        })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listChosenSkills, skillModal]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {chosenSkills.length === 0 ? (
        <SkillListEmptyState />
      ) : (
        <SkillState>
          <SkillTitleContainer>
            <SkillTitle>{t('skills-matrix.your-skills.title')}</SkillTitle>
          </SkillTitleContainer>
          <SubSkillTitleContainer>
            <SubSkillTitle>{t('skills-matrix.your-skills.edit-or-remove')}</SubSkillTitle>
          </SubSkillTitleContainer>
          {chosenSkills.map((skill) => {
            return (
              <SkillSelected
                key={skill.name}
                level={skill.langCode === '' ? skill.lvl : getLanguageNames(skill.lvl)}
                name={skill.name}
                icon={
                  skill.langCode === ''
                    ? getSkillLevelIcon(skill.lvl)
                    : getLanguageFlag(skill.langCode)
                }
                handleSkill={handleSkillEdit}
              />
            );
          })}
        </SkillState>
      )}
    </>
  );
};

export default SkillList;
