import React from 'react';
import styled from 'styled-components';

import Navbar from 'components/Navbar';

import { queries } from 'shared/layout';

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;

  ${queries.tablet} {
    flex-direction: row-reverse;
  }
`;

const PageWrapper = styled.div`
  width: 100%;
  position: relative;
  height: 100vh;
  background: ${({ theme }) => theme.colors.background};
  overflow: auto;
`;

type PageLayoutProps = {
  children: React.ReactNode;
};

const PageLayout = ({ children }: PageLayoutProps): JSX.Element => {
  return (
    <Container>
      <PageWrapper>{children}</PageWrapper>
      <Navbar />
    </Container>
  );
};

export default PageLayout;
