import { message } from 'antd';
import type { ArgsProps, MessageType } from 'antd/lib/message';

export const showSuccess = (config: Partial<ArgsProps>): MessageType =>
  message.success({
    type: 'success',
    ...config,
  });

export const showError = (config: Partial<ArgsProps>): MessageType => {
  return message.error({
    type: 'error',
    ...config,
  });
};
