import { useEffect, useState } from 'react';

import { useAuth } from 'providers/AuthProvider';

export const useIsProfileOwner = (userId: string): boolean => {
  const { currentUser } = useAuth();
  const [isProfileOwner, setIsProfileOwner] = useState(currentUser?.id === userId);

  useEffect(() => {
    setIsProfileOwner(currentUser ? currentUser.id === userId : false);
  }, [currentUser, userId]);

  return isProfileOwner;
};
